<template>
  <f7-popup
    tablet-fullscreen
    class="shift-role-requirements-select"
    @popup:closed="onPopupClosed"
    @popup:open="onPopupOpen"
  >
    <f7-page name="shift-role-requirements-select">
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close=".shift-role-requirements-select" />
        </f7-nav-left>
        <f7-nav-title title="Select Requirements" />
        <f7-nav-right>
          <f7-link popup-close=".shift-role-requirements-select" @click="onDoneClick">DONE</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-searchbar :custom-search="true" @searchbar:search="onSearch" @searchbar:clear="onClear" />
      <f7-progressbar v-show="showProgressbar" infinite />
      <f7-list v-show="selectedRoleRequirement.length > 0" class="no-margin">
        <f7-list-group>
          <f7-list-item title="Selected" group-title />
          <f7-list-item
            v-for="roleRequirement in selectedRoleRequirement"
            :title="roleRequirement.text"
            :key="roleRequirement.id"
            :value="roleRequirement.id"
            checkbox
            checked
            @change="onChange(roleRequirement.text, $event)"
          />
        </f7-list-group>
      </f7-list>

      <f7-list v-show="showNotFound" class="no-margin-top">
        <f7-list-item title="Nothing found" />
      </f7-list>
      <f7-list v-show="showResultList" class="no-margin-top">
        <f7-list-group v-for="(roleRequirementGroup, gIndx) in roleRequirementList" :key="gIndx">
          <f7-list-item :title="roleRequirementGroup.text" group-title />
          <f7-list-item
            v-for="roleRequirement in roleRequirementGroup.children"
            :title="roleRequirement.text"
            :key="roleRequirement.id"
            :checked="isSelected(roleRequirement.id)"
            :name="roleRequirementGroup.text"
            :value="roleRequirement.id"
            checkbox
            @change="onChange(roleRequirement.text, $event)"
          />
        </f7-list-group>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    requirements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      query: '',
      showResultList: true,
      showNotFound: false,
      showProgressbar: false,
      selectedRoleRequirement: this.requirements,
    };
  },
  computed: {
    roleRequirementList() {
      return this.$store.state.calendar.roleRequirement;
    },
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    isSelected(value) {
      return _.find(this.selectedRoleRequirement, { id: value }) !== undefined;
    },

    onChange(text, event) {
      if (event.target.checked) {
        const newRequirements = [...this.selectedRoleRequirement];
        newRequirements.push({ id: event.target.value, text });
        this.selectedRoleRequirement = newRequirements;
      } else {
        this.selectedRoleRequirement = _.filter(this.selectedRoleRequirement, (r) => r.id !== event.target.value);
      }
    },
    onSearch(searchbar, query) {
      if (query) {
        this.query = query;
        this.getRequirementsList();
      }
    },
    onClear() {
      this.showProgressbar = false;
      this.showNotFound = false;
      this.showResultList = true;
      this.$store.commit('calendar/setCalendarShiftRoleRequirement', []);
    },
    getRequirementsList: _.debounce(function fetchCalendarShiftRoleRequirement() {
      this.showProgressbar = true;
      this.$store.dispatch('calendar/fetchCalendarShiftRoleRequirement', { query: this.query }).then(() => {
        this.showProgressbar = false;
        this.showNotFound = false;
        this.showResultList = true;
        if (this.roleRequirementList.length < 1) {
          this.showNotFound = true;
          this.showResultList = false;
        }
      });
    }, 500),
    onDoneClick() {
      this.$emit('selected', this.selectedRoleRequirement);
    },
    onPopupClosed() {
      this.onClear();
    },
    onPopupOpen() {
      this.selectedRoleRequirement = this.requirements;
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-card>
    <f7-card-header>
      <span>{{ wallet.wname }}</span>
      <span style="font-weight: 500">{{ wallet.currency }}{{ wallet.balance }}</span>
    </f7-card-header>
    <f7-card-content>
      <f7-list>
        <f7-list-input
          outline
          floating-label
          label="Withdraw methods"
          type="select"
          :value="withdrawMethod"
          @change="withdrawMethod = $event.target.value"
        >
          <option v-for="wmethod in wallet.withdraw_methods" :key="wmethod.id" :value="wmethod.id">
            {{ wmethod.label }}
          </option>
        </f7-list-input>

        <f7-list-input
          v-if="withdrawMethod === 'bank'"
          outline
          floating-label
          label="Select bank"
          type="select"
          :value="bankId"
          @change="onBankChange"
        >
          <option v-for="bank in banks" :key="bank.id" :value="bank.id" :disabled="!bank.approved">
            {{ bank.bname }}
          </option>
        </f7-list-input>

        <f7-list-input
          v-if="withdrawMethod === 'paypal' || withdrawMethod === 'prepaid'"
          outline
          floating-label
          label="E-mail"
          type="email"
          placeholder="E-mail"
          :value="email"
          clear-button
          @input="email = $event.target.value"
        />

        <f7-list-input
          outline
          floating-label
          label="Amount"
          type="number"
          placeholder="Amount"
          :value="amount"
          clear-button
          @input="amount = $event.target.value"
        />
      </f7-list>
    </f7-card-content>
    <f7-card-footer>
      <div></div>
      <f7-button
        :class="{
          disabled: isLoadingData || (withdrawMethod === 'bank' && (bankId === undefined || !bankApproved)),
        }"
        raised
        fill
        @click="onWithdrawClick"
        >WITHDRAW</f7-button
      >
    </f7-card-footer>
  </f7-card>
</template>
<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';

export default {
  props: {
    wallet: {
      type: Object,
      required: true,
    },
  },
  data() {
    const banks = _.find(this.wallet.withdraw_methods, { id: 'bank' }).options;
    return {
      withdrawMethod: this.wallet.withdraw_methods[0].id,
      email: this.$store.state.account.user.email,
      amount: this.wallet.balance,
      bankId: _.get(banks[0], 'id'),
      bankApproved: _.get(banks[0], 'approved'),
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    banks() {
      return _.find(this.wallet.withdraw_methods, { id: 'bank' }).options;
    },
  },
  methods: {
    onBankChange(event) {
      const bankId = Number(event.target.value);
      const banks = _.find(this.wallet.withdraw_methods, { id: 'bank' }).options;
      this.bankId = bankId;
      this.bankApproved = _.find(banks, { id: bankId }).approved;
    },
    onWithdrawClick() {
      this.$store
        .dispatch('payrolls/fetchWithdrawCode', {
          userId: this.userId,
          walletId: this.wallet.id,
          amount: this.amount,
          email: this.email,
          withdrawMethod: this.withdrawMethod,
          bankId: this.bankId,
        })
        .then((result) => {
          if (result !== false) {
            if (result.otp_sent) {
              f7.dialog.prompt(result.message, '', (code) => {
                this.$store
                  .dispatch('payrolls/fetchWithdrawCode', {
                    userId: this.userId,
                    walletId: this.wallet.id,
                    amount: this.amount,
                    email: this.email,
                    withdrawMethod: this.withdrawMethod,
                    bankId: this.bankId,
                    otp: code,
                  })
                  .then((result) => {
                    if (result !== false) {
                      this.$emit('sc-payroll-wallet-card:withdraw');
                      f7.toast.show({
                        text: result.message,
                        closeTimeout: 2000,
                        closeButton: true,
                        closeButtonText: 'Close',
                        closeButtonColor: 'blue',
                        destroyOnClose: true,
                      });
                    }
                  });
              });
            }
          }
        });
    },
  },
};
</script>

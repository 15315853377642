<template>
  <f7-message
    :class="{
      'sc-message-sms-sent': messageType === 'sent' && Number(message.sms) === 1,
      'sc-message-sms-received': messageType === 'received' && Number(message.sms) === 1,
    }"
    :type="messageType"
    :avatar="message.ppic_a || 'assets/img/default-user-image.png'"
    :name="message.name"
    :first="isFirstMessage(message, messageIndex)"
    :last="isLastMessage(message, messageIndex)"
    :tail="isTailMessage(message, messageIndex)"
  >
    <template #text>
      <div style="white-space: pre-line" v-html="message.content" />
    </template>
    <template #text-footer>
      <div style="display: flex">
        {{ formatDate(message.created_at) }}
        <div v-show="message.new_status === 'error'">
          <f7-icon material="error" color="red" icon="sc-message__status-icon" />
        </div>
        <div v-show="message.new_status === 'sending'">
          <div class="preloader sc-message__status-icon" />
        </div>
      </div>
      <div @click="onSeenByClick">
        <img
          v-for="participant in message.seen_by"
          :key="participant.id"
          :src="participant.ppic_a || 'assets/img/default-user-image.png'"
          class="sc-message__seen-by-pic"
        />
      </div>
    </template>
  </f7-message>
</template>
<script>
import { f7 } from 'framework7-vue';

import moment from 'moment';

export default {
  props: {
    messageIndex: {
      type: Number,
      required: true,
    },
    f7route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sender_id() {
      return this.$store.state.account.user.id;
    },
    thread_messages() {
      return this.$store.getters['chats/getChatThreadMessages'](Number(this.f7route.params.thread_id));
    },
    message() {
      return this.$store.getters['chats/getChatThreadMessages'](Number(this.f7route.params.thread_id))[
        this.messageIndex
      ];
    },
    messageType() {
      return this.sender_id !== Number(this.message.sender_id) ? 'received' : 'sent';
    },
  },

  methods: {
    onSeenByClick() {
      const names = this.message.seen_by.reduce((acc, cur) => {
        return acc.length > 0 ? acc + ', ' + cur.name : cur.name;
      }, '');
      const toastCenter = f7.toast.create({
        text: `${names}`,
        position: 'center',
        closeTimeout: 1000,
      });
      toastCenter.open();
    },
    isFirstMessage(message, index) {
      const previousMessage = this.thread_messages[index - 1];
      if (!previousMessage || previousMessage.sender_id !== message.sender_id) {
        return true;
      }
      return false;
    },
    isLastMessage(message, index) {
      const nextMessage = this.thread_messages[index + 1];
      if (!nextMessage || nextMessage.sender_id !== message.sender_id) {
        return true;
      }
      return false;
    },
    isTailMessage(message, index) {
      const nextMessage = this.thread_messages[index + 1];
      if (!nextMessage || nextMessage.sender_id !== message.sender_id) {
        return true;
      }
      return false;
    },
    formatDate(date) {
      const messageDate = moment(date).add(new Date().getTimezoneOffset() * -1, 'm');
      const curDate = moment();
      let result = '';
      if (messageDate.format('YYYY') === curDate.format('YYYY')) {
        result = messageDate.format('MMM D LT');
      } else {
        result = messageDate.format('ll LT');
      }
      return result;
    },
  },
};
</script>
<style scoped>
.sc-message__seen-by-pic {
  width: 20px;
  border-radius: 50%;
  margin: 2px 0 0 2px;
}
.sc-message__status-icon {
  margin: 0 0 0 2px;
  width: 14px;
  height: 14px;
  font-size: 14px;
}
::v-deep(.sc-message-sms-sent .message-bubble) {
  background-color: #009688;
}
::v-deep(.sc-message-sms-sent .message-bubble::before) {
  border-bottom-color: #009688 !important;
}
::v-deep(.sc-message-sms-received .message-bubble) {
  background-color: #009688;
  color: white;
}
</style>

<template>
  <f7-page-content>
    <sc-file-picker
      @sc-file-picker:ready="openFilePicker = $event"
      @sc-file-picker:loaded="onFileLoaded"
    />
    <f7-block
      class="display-flex justify-content-space-between sc-align-items-baseline"
    >
      <p class="text-color-gray">{{ photoMessage }}</p>
    </f7-block>
    <f7-block v-show="images.length > 0" class="sc-image-gallery">
      <sc-profile-media-image-card
        v-for="(image, imageIndx) in images"
        :key="image.id"
        :image="image"
        :image-indx="imageIndx"
        :photo-browser="photoBrowser"
        :user-id="userId"
      />
    </f7-block>
    <f7-block>
      <f7-row class="justify-content-center">
        <f7-button @click="openFilePicker">
          <f7-icon material="add_a_photo" /> Upload photo
        </f7-button>
      </f7-row>
    </f7-block>
    <template v-if="isVideoEnable">
      <f7-block
        class="display-flex justify-content-space-between sc-align-items-baseline"
      >
        <p class="text-color-gray">{{ videoMessage }}</p>
      </f7-block>
      <f7-card v-for="video in videos" :key="video.id" class="sc-video-card">
        <f7-card-content :padding="false">
          <video
            :poster="video.thumbnail"
            :src="video.path"
            class="display-block no-fastclick"
            controls
            width="100%"
          >
            <source :src="video.path" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </f7-card-content>
        <f7-card-footer>
          <div>{{ new Date(video.created_at).toDateString() }}</div>
          <f7-button
            icon-material="delete"
            color="gray"
            @click="onDeleteClick(video.id)"
          />
        </f7-card-footer>
      </f7-card>
      <f7-block>
        <f7-row class="justify-content-center">
          <f7-button @click="openFilePicker">
            <f7-icon size="28px" material="video_call" /> Upload video
          </f7-button>
        </f7-row>
      </f7-block>
    </template>
    <f7-row class="margin justify-content-space-between">
      <f7-button @click="registerBackStep"> Back </f7-button>
      <f7-button
        :disabled="isLoadingData"
        raised
        fill
        @click="registerNextStep"
      >
        Save & Continue
      </f7-button>
    </f7-row>
  </f7-page-content>
</template>
<script>
import { f7 } from 'framework7-vue';

import scProfileMediaImageCard from '../../components/Profile/Media/Image/ProfileMediaImageCard.vue';
import scFilePicker from '../../components/SCFilePicker.vue';

export default {
  components: {
    scProfileMediaImageCard,
    scFilePicker,
  },
  data() {
    return {
      openFilePicker: () => {},
      photoBrowser: undefined,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    photoMessage() {
      return this.$store.state.account.settings.profile_photo_msg;
    },
    videoMessage() {
      return this.$store.state.account.settings.profile_video_msg;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    images() {
      if (this.photoBrowser !== undefined) {
        this.photoBrowser.destroy();
      }
      const photos = this.$store.state.profile.images.map(
        (image) => image.path
      );
      this.photoBrowser = f7.photoBrowser.create({
        photos: photos,
        backLinkText: '',
      });
      return this.$store.state.profile.images;
    },
    isVideoEnable() {
      return (
        Number(this.$store.state.account.settings.profile_video_enable) === 1
      );
    },
    videos() {
      return this.$store.state.profile.videos;
    },
  },
  beforeCreate() {
    const userId = this.$store.state.account.user.id;
    this.$store.dispatch('profile/getProfileImages', { userId });
    this.$store.dispatch('profile/getProfileVideos', { userId });
  },
  beforeUnmount() {
    if (this.photoBrowser !== undefined) {
      this.photoBrowser.destroy();
    }
  },
  methods: {
    registerNextStep() {
      this.$store.dispatch('account/registerNextStep');
    },
    registerBackStep() {
      this.$store.commit('account/setRegStepNum', 4);
    },
    onDeleteClick(videoId) {
      f7.dialog.confirm(
        "This video will be deleted and you won't be able to find it anymore.",
        'Delete the video?',
        () => {
          this.$store.dispatch('profile/deleteProfileVideo', { videoId });
        }
      );
    },
    onFileLoaded(file) {
      if (file.type === 'image') {
        this.$store.dispatch('profile/setProfileImage', {
          image_blob: file.blob,
          userId: this.userId,
        });
      } else if (file.type === 'video') {
        this.$store.dispatch('profile/setProfileVideo', {
          video_blob: file.blob,
          userId: this.userId,
        });
      }
    },
  },
};
</script>
<style scoped>
.sc-image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sc-image-card {
  width: 44%;
  max-width: 200px;
  min-width: 120px;
}
.sc-image-card .card-content {
  min-height: 120px;
}
.sc-image-card .card-content img {
  width: 100%;
  display: block;
}
</style>

<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:closed="onPopupClosed">
    <f7-page>
      <f7-navbar no-shadow>
        <f7-nav-left>
          <f7-link popup-close icon-material="close" icon-only />
        </f7-nav-left>
        <f7-nav-title>New Unavailability</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onAddClick">ADD</f7-link>
        </f7-nav-right>
        <template #after-inner>
          <f7-progressbar v-show="isLoadingData" infinite />
        </template>
      </f7-navbar>

      <f7-list no-hairlines no-hairlines-between>
        <f7-list-item>
          <span :class="{ 'text-color-gray': !isRegular }"> Weekly Recurring </span>
          <f7-toggle :checked="isRegular" @change="isRegular = $event.target.checked" />
        </f7-list-item>

        <f7-list-input
          outline
          floating-label
          label="Reason*"
          :placeholder="isRegular ? 'eg. University' : 'eg. Holiday'"
          required
          validate
          clear-button
          type="text"
          :value="reasone"
          @input="reasone = $event.target.value"
        />

        <f7-list-input
          input-id="unavailability-start-date"
          outline
          floating-label
          required
          validate
          readonly
          label="Start Date*"
          type="text"
          :value="startDate"
          @change="startDate = $event.target.value"
        />

        <f7-list-input
          input-id="unavailability-end-date"
          outline
          floating-label
          required
          validate
          readonly
          label="End Date*"
          type="text"
          :value="endDate"
          @change="endDate = $event.target.value"
        />

        <f7-list-input
          v-show="isRegular"
          outline
          floating-label
          label="Repeats every"
          type="select"
          :default-value="day"
          :value="day"
          @change="day = $event.target.value"
        >
          <option value="1">Sunday</option>
          <option value="2">Monday</option>
          <option value="3">Tuesday</option>
          <option value="4">Wednesday</option>
          <option value="5">Thursday</option>
          <option value="6">Friday</option>
          <option value="7">Saturday</option>
        </f7-list-input>

        <f7-list-item v-show="isRegular">
          <f7-list-item-row>
            <f7-list-item-cell>
              <f7-list-input
                input-id="unavailability-start-time"
                outline
                floating-label
                readonly
                label="Start Time"
                type="text"
                :value="startTime"
                @change="startTime = $event.target.value"
              />
            </f7-list-item-cell>
            <f7-list-item-cell>
              <f7-list-input
                input-id="unavailability-end-time"
                outline
                floating-label
                readonly
                label="End Time"
                type="text"
                :value="endTime"
                @change="endTime = $event.target.value"
              />
            </f7-list-item-cell>
          </f7-list-item-row>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import { f7 } from 'framework7-vue';

import moment from 'moment';

export default {
  props: {
    isPopupOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isRegular: false,
      reasone: '',
      startDate: moment().format('MMMM DD YYYY'),
      endDate: moment().add(1, 'd').format('MMMM DD YYYY'),
      day: '1',
      startTime: '09:00',
      endTime: '17:00',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  mounted() {
    const today = new Date();

    this.startDateElement = f7.calendar.create({
      inputEl: '#unavailability-start-date',
      closeOnSelect: true,
      dateFormat: 'MM dd yyyy',
      locale: 'en',
      minDate: today,
      routableModals: false,
    });
    this.endDateElement = f7.calendar.create({
      inputEl: '#unavailability-end-date',
      closeOnSelect: true,
      dateFormat: 'MM dd yyyy',
      locale: 'en',
      minDate: today,
      routableModals: false,
    });
    this.startTimeElement = f7.picker.create({
      inputEl: '#unavailability-start-time',
      rotateEffect: true,
      formatValue: (values) => `${values[0]}:${values[1]}`,
      cols: [
        {
          textAlign: 'left',
          values: '01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 18 20 21 22 23 24'.split(' '),
        },
        {
          divider: true,
          content: ':',
        },
        {
          values: (
            '00 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 ' +
            '21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 ' +
            '41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59'
          ).split(' '),
        },
      ],
    });
    this.endTimeElement = f7.picker.create({
      inputEl: '#unavailability-end-time',
      rotateEffect: true,
      formatValue: (values) => `${values[0]}:${values[1]}`,
      cols: [
        {
          textAlign: 'left',
          values: '01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 18 20 21 22 23 24'.split(' '),
        },
        {
          divider: true,
          content: ':',
        },
        {
          values: (
            '00 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 ' +
            '21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 ' +
            '41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59'
          ).split(' '),
        },
      ],
    });
  },
  beforeUnmount() {
    this.startDateElement.destroy();
    this.endDateElement.destroy();
    this.startTimeElement.destroy();
    this.endTimeElement.destroy();
  },
  methods: {
    onPopupClosed() {
      this.isRegular = false;
      this.reasone = '';
      this.startDate = moment().format('MMMM DD YYYY');
      this.endDate = moment().add(1, 'd').format('MMMM DD YYYY');
      this.day = '1';
      this.startTime = '09:00';
      this.endTime = '17:00';
      this.$emit('sc-unavailability-add-popup:closed');
    },
    onAddClick() {
      if (this.reasone !== '') {
        this.$store
          .dispatch('unavailability/addUnavailability', {
            title: this.reasone,
            start:
              moment(this.startDate, 'MMMM DD YYYY').format('YYYY-MM-DD') +
              ' ' +
              (this.startTime !== '' ? this.startTime : '00:00:00'),
            end:
              moment(this.endDate, 'MMMM DD YYYY').format('YYYY-MM-DD') +
              ' ' +
              (this.endTime !== '' ? this.endTime : '23:59:59'),
            weekday: this.isRegular ? this.day : undefined,
            userId: this.userId,
          })
          .then((result) => {
            if (result !== false) {
              f7.popup.close();
            }
          });
      }
    },
  },
};
</script>

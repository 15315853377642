import axios from '../../libs/axios';


export default {
  getPosts() {
    return axios.get('/posts/main/0/10/0')
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  getPinnedPosts() {
    return axios.get('/pinnedPosts/main/0')
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  getPostDetails(payload) {
    return axios.get(`/post/${payload.postId}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  setPostLiked(payload) {
    return axios.put(`/post/${payload.postId}/like/${payload.liked}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  setPostPinned(payload) {
    return axios.put(`/post/${payload.postId}/pin/${payload.pinned}`, { title: payload.title })
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  setPostApproved(payload) {
    return axios.put(`/post/${payload.postId}/approve/${payload.approved}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  deletePost(payload) {
    return axios.delete(`/post/${payload.postId}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  setPostCommentLiked(payload) {
    return axios.put(`/post/comment/${payload.commentId}/like/${payload.liked}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  setPostCommentApproved(payload) {
    return axios.put(`/post/comment/${payload.commentId}/approve/${payload.approved}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  editPostComment(payload) {
    return axios.put(`/post/comment/${payload.commentId}`, { content: payload.commentContent })
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  deletePostComment(payload) {
    return axios.delete(`/post/comment/${payload.commentId}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },


  getPostComments(payload) {
    return axios.get(`/post/${payload.postId}/comments/${payload.commentsOnPage}/${payload.pageId}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  addNewPost(payload) {
    const data = new FormData();
    if (payload.file) {
      data.append('file', payload.file, payload.file.name);
    }
    data.append('ptype', 'main');
    data.append('content', payload.content);
    return axios.post(
      '/post',
      data,
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  editPost(payload) {
    const data = new FormData();
    if (payload.file) {
      data.append('file', payload.file, payload.file.name);
    }
    data.append('content', payload.content);
    data.append('delete_file', payload.deleteFile);
    return axios.post(
      `/post/${payload.postId}`,
      data,
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  sendPostComment(payload) {
    return axios.post(
      `/post/${payload.postId}/comment`,
      {
        content: payload.content,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  getHomeNotifications() {
    return axios.get('/notifications')
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  getPostLikesUserList(payload) {
    return axios.get(`/post/${payload.postId}/likes`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

};

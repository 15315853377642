import axios from '../../libs/axios';


export default {
  mapPayrollActions(action) {
    let result;
    switch (action) {
      case 'approve':
        result = 'approve';
        break;
      case 'unapprove':
        result = 'unapprove';
        break;
      case 'record_processing':
        result = 'process';
        break;
      case 'record_payment':
        result = 'recordPayment';
        break;
      case 'pay_xtrm':
        result = 'xtrm/pay';
        break;
      case 'reject':
        result = 'reject';
        break;
      case 'cancel':
        result = 'cancel';
        break;
      default:
        break;
    }
    return result;
  },
  fetchPayrolls(payload) {
    let url = `/payrolls/${payload.itemsOnPage}/${payload.pageId}/${payload.payrollType}`;
    url += payload.fetchFilter !== '' ? `/${payload.fetchFilter}` : '';
    return axios.get(url)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  fetchPayroll(payload) {
    return axios.get(`/payroll/${payload.payrollId}`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  fetchPayrollActivityHistory(payload) {
    const url = `/activity/payroll/${payload.payrollId}/%5B%5D/${payload.itemsOnPage}/${payload.pageId}`;
    return axios.get(url)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  fetchPayrollAdminNotes(payload) {
    return axios.get(`/payroll/${payload.payrollId}/notes`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  fetchUsers(payload) {
    return axios.get(`/autocomplete/users/staff/${payload.query}`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  groupPayrollActions(payload) {
    return axios.put(
      `/payrolls/${payload.actionType}`, { ids: payload.ids },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },
  singlePayrollActions(payload) {
    let result;
    if (payload.actionType === 'xtrm/pay') {
      result = axios.post(
        `/payroll/${payload.payrollId}/${payload.actionType}`, {},
      ).then(response => response).catch(error => this.apiErrorHandler(error));
    } else if (payload.actionType === 'cancel') {
      result = axios.delete(
        `/payroll/${payload.payrollId}`, {},
      ).then(response => response).catch(error => this.apiErrorHandler(error));
    } else {
      const data = payload.reason !== undefined ? { reason: payload.reason } : {};
      result = axios.put(
        `/payroll/${payload.payrollId}/${payload.actionType}`, data,
      ).then(response => response).catch(error => this.apiErrorHandler(error));
    }
    return result;
  },

  addPayrollAdminNote(payload) {
    return axios.post(
      `/payroll/${payload.payrollId}/note`,
      {
        note: payload.note,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  editPayrollAdminNote(payload) {
    return axios.put(
      `/payroll/note/${payload.adminNoteId}`,
      {
        note: payload.note,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  deletePayrollAdminNote(payload) {
    return axios.delete(`/payroll/note/${payload.adminNoteId}`).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  fetchTrackingOptions(payload) {
    return axios.get(`/autocomplete/tracking/0/options/${payload.query}`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  generatePayrollByType(payload) {
    const url = `/payroll/generate/${payload.payrollType}/${payload.dateFrom}/${payload.dateTo}/${payload.сompletedOnly}/${payload.selectedTrackingOptionId}/${payload.approvedPayItemsOnly}`;
    return axios.get(url)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  generatePayrollForStaff(payload) {
    const url = `/staffInvoice/generate/${payload.dateFrom}/${payload.dateTo}`;
    return axios.get(url)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  generatePayrollSave(payload) {
    return axios.post(
      '/payroll/save',
      {
        type: payload.payrollType,
        completed_only: payload.сompletedOnly,
        approved_items_only: payload.approvedPayItemsOnly,
        payroll: payload.payrollItems,
        from: payload.dateFrom,
        to: payload.dateTo,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  generatePayrollSaveForStaff(payload) {
    return axios.post(
      '/staffInvoice/save',
      {
        payroll: payload.payrollItems,
        from: payload.dateFrom,
        to: payload.dateTo,
        combine: payload.invoiceCombine,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  fetchXeroDates() {
    return axios.get('/xero/payroll/dates')
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  fetchWallets(payload) {
    return axios.get(`/user/${payload.userId}/xtrm/wallets`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  fetchWithdrawCode(payload) {
    return axios.put(
      `/user/${payload.userId}/xtrm/wallet/${payload.walletId}/withdraw`,
      {
        amount: payload.amount,
        email: payload.email,
        method: payload.withdrawMethod,
        bank_id: payload.bankId,
        otp: payload.otp,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },


};

<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Skills & Qualifications" />
      <f7-nav-right />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <sc-sisp-block v-if="sispEnable" :user-id="userId" />
    <sc-skills-list :skills="skills" :user-id="userId" />
  </f7-page>
</template>

<script>
import scSispBlock from '../../components/Profile/SkillsQualifications/SispBlock.vue';
import scSkillsList from '../../components/Profile/SkillsQualifications/SkillsList.vue';

export default {
  components: {
    scSispBlock,
    scSkillsList,
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    skills() {
      return this.$store.state.profile.skills;
    },
    sispEnable() {
      return this.$store.state.account.settings.sisp_enable === '1';
    },
  },
  beforeCreate() {
    const userId = this.$store.state.account.user.id;
    this.$store.dispatch('profile/getProfileSkillsQualifications', { userId });
  },
  methods: {},
};
</script>

<template>
  <f7-sheet
    :opened="isOpened"
    class="sc-payrolls-detail-add-edit-admin-note--sheet"
    backdrop
    @sheet:closed="onSheetClosed"
    @sheet:open="onSheetOpen"
  >
    <f7-toolbar>
      <div class="left">
        <f7-link
          icon-material="close"
          icon-only
          sheet-close
        />
      </div>
      <div class="right">
        <f7-link
          sheet-close
          @click="onAddClick"
        >{{ sheetType === 'ADD' ? 'ADD': 'SAVE' }}</f7-link>
      </div>
    </f7-toolbar>
    <f7-page-content>
      <f7-list no-hairlines class="no-margin-vertical">
        <f7-list-input
          outline
          floating-label
          label="Note"
          type="textarea"
          :value="noteText"
          @input="noteText = $event.target.value"
        />
      </f7-list>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
export default {
  props: {
    payrollId: {
      type: Number,
      required: true,
    },
    isOpened: {
      type: Boolean,
      required: true,
    },
    sheetType: {
      type: String,
      required: true,
    },
    adminNoteId: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      noteText: '',
    };
  },
  computed: {
    adminNote() {
      return this.$store.getters['payrolls/getPayrollAdminNoteById'](this.adminNoteId);
    },
  },
  methods: {
    onAddClick() {
      if (this.sheetType === 'ADD') {
        this.$store.dispatch('payrolls/addPayrollAdminNote', {
          payrollId: this.payrollId,
          note: this.noteText,
        });
      } else if (this.sheetType === 'EDIT') {
        this.$store.dispatch('payrolls/editPayrollAdminNote', {
          payrollId: this.payrollId,
          adminNoteId: this.adminNoteId,
          note: this.noteText,
        });
      }
    },
    onSheetClosed() {
      this.noteText = '';
      this.$emit('sc-payrolls-detail-add-edit-admin-note-sheet:closed');
    },
    onSheetOpen() {
      if (this.sheetType === 'EDIT' && this.adminNote) {
        this.noteText = this.adminNote.note;
      }
    },
  },
};
</script>

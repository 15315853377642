<template>
  <f7-page>
    <f7-list media-list no-hairlines>
      <f7-list-item
        v-for="unavail in unavailabilities"
        :key="unavail.id"
        :title="unavail.title"
        :subtitle="unavail.dates"
        :text="unavail.recurring"
      />
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    unavailabilities() {
      return this.$store.state.unavailability.unavailabilities;
    },
  },
  beforeCreate() {
    const userId = Number(this.f7route.params.user_id);
    this.$store.dispatch('unavailability/getUnavailabilities', { userId });
  },
};
</script>

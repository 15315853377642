<template>
  <f7-page>
    <sc-sisp-block v-if="sispEnable" :user-id="userId" />

    <f7-list no-hairlines class="no-margin-top">
      <f7-list-group v-for="category in skills" :key="category.id">
        <f7-list-item :title="category.cname" group-title />
        <f7-list-item
          v-for="skill in category.attributes"
          :key="skill.id"
          :checked="skill.set === 1"
          :title="skill.aname"
          checkbox
          @change="onElementChanged(skill.id, $event)"
        />
      </f7-list-group>
    </f7-list>
  </f7-page>
</template>

<script>
import scSispBlock from '../../../components/Profile/SkillsQualifications/SispBlock.vue';

export default {
  components: {
    scSispBlock,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userId() {
      return Number(this.f7route.params.user_id);
    },
    skills() {
      return this.$store.state.profile.skills;
    },
    sispEnable() {
      return this.$store.state.account.settings.sisp_enable === '1';
    },
  },
  beforeCreate() {
    const userId = Number(this.f7route.params.user_id);
    this.$store.dispatch('profile/getProfileSkillsQualifications', { userId });
  },
  methods: {
    onElementChanged(skillId, event) {
      const set = event.target.checked ? 1 : 0;
      this.$store.dispatch('profile/setProfileSkillsQualifications', {
        attributeId: skillId,
        set,
        userId: this.userId,
      });
    },
  },
};
</script>

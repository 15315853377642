<template>
  <li :style="commentApproved === 0 ? 'background: lightgrey;' : ''">
    <div class="item-content">
      <div class="item-media">
        <img :src="commentPpic_a" width="50px" style="border-radius: 50%" />
      </div>
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title">
            {{ commentName }}
          </div>
          <div class="item-after">
            <f7-link
              v-if="isUserLvlOwnerOrAdmin"
              :class="'sc-home-post-comment-action' + commentId"
              icon-material="more_vert"
              style="min-width: 0px"
              color="gray"
              @click="onMenuClick"
            />
          </div>
        </div>
        <div class="text-color-gray" style="font-size: 13px">
          {{ createdAt }}
        </div>
        <div class="item-text" />
        <div>
          <div style="font-size: 15px">{{ commentContent }}</div>

          <f7-link
            :icon-color="commentLiked === 1 ? 'red' : 'gray'"
            icon-material="favorite"
            color="gray"
            class="float-right"
            @click="onLikeClick"
          >
            &nbsp;{{ commentLikes }}
          </f7-link>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { f7 } from 'framework7-vue';

import moment from 'moment';

export default {
  props: {
    commentPpic_a: {
      type: String,
      default: '',
    },
    commentName: {
      type: String,
      default: '',
    },
    commentCreatedAt: {
      type: String,
      default: '',
    },
    commentContent: {
      type: String,
      default: '',
    },
    commentLiked: {
      type: Number,
      default: 0,
    },
    commentLikes: {
      type: Number,
      default: 0,
    },
    commentId: {
      type: Number,
      required: true,
    },
    commentApproved: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    createdAt() {
      return moment(this.commentCreatedAt).isValid()
        ? moment(this.commentCreatedAt).format('ll LTS')
        : 'Date unknown';
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
  },
  methods: {
    onLikeClick() {
      this.$store.dispatch('posts/setPostCommentLiked', {
        commentId: this.commentId,
      });
    },
    onApproveClick() {
      this.$store.dispatch('posts/setPostCommentApproved', {
        commentId: this.commentId,
      });
    },
    onDeleteClick() {
      f7.dialog.confirm(
        "This comment will be deleted and you won't be able to find it anymore. You can also edit this comment if you just want to change something.",
        'Delete the comment?',
        () => {
          this.$store.dispatch('posts/deletePostComment', {
            commentId: this.commentId,
          });
        }
      );
    },
    onEditClick() {
      this.$emit('sc-home-post-comment:comment-edit-click', {
        commentId: this.commentId,
        commentContent: this.commentContent,
      });
    },
    onMenuClick() {
      const buttons = [
        {
          id: 'approve',
          text: this.commentApproved ? 'Unapprove' : 'Approve',
          onClick: this.onApproveClick,
          icon: `<i class="icon material-icons color-gray">${
            this.commentApproved
              ? 'assignment_returned'
              : 'assignment_turned_in'
          }</i>`,
        },
        {
          id: 'edit',
          text: 'Edit',
          onClick: this.onEditClick,
          icon: '<i class="icon material-icons color-gray">edit</i>',
        },
        {
          id: 'delete',
          text: 'Delete',
          onClick: this.onDeleteClick,
          icon: '<i class="icon material-icons color-gray">delete</i>',
        },
      ];
      const actinSheet = f7.actions.create({
        buttons,
        targetEl: `.sc-home-post-comment-action${this.commentId}`,
      });
      actinSheet.open();
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-list-item :title="category.cname" accordion-item>
    <template #media>
      <f7-icon :material="categoryIcon" />
    </template>
    <f7-accordion-content>
      <f7-list accordion-list>
        <template v-for="element in category.elements">
          <template v-if="element.ename">
            <sc-profile-info-category-element
              :key="`${element.ename}+${element.id}`"
              :element="element"
            />
          </template>
          <template v-else>
            <sc-profile-info-category
              :key="`${element.cname}+${element.id}`"
              :category="element"
            />
          </template>
        </template>
      </f7-list>
    </f7-accordion-content>
  </f7-list-item>
</template>

<script>
// import scProfileInfoCategory from './ProfileInfoCategory.vue';
import scProfileInfoCategoryElement from './ProfileInfoCategoryElement.vue';

export default {
  name: 'ScProfileInfoCategory',
  components: {
    // scProfileInfoCategory,
    scProfileInfoCategoryElement,
  },
  props: ['category'],
  computed: {
    categoryIcon() {
      let categoryIcon = 'view_list';
      switch (this.category.id) {
        case 2:
          categoryIcon = 'account_circle';
          break;
        case 3:
          categoryIcon = 'contact_phone';
          break;
        case 5:
          categoryIcon = 'home';
          break;
        case 6:
          categoryIcon = 'mail';
          break;
        case 7:
          categoryIcon = 'error';
          break;
        default:
          categoryIcon = 'view_list';
      }
      return categoryIcon;
    },
  },
};
</script>

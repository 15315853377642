<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Shift Role Staff" />
      <f7-nav-right>
        <f7-link
          icon-only
          icon-material="person_add"
          class="shift-role-staff-add-actions-button"
          @click="onActionsClick"
        />
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar top scrollable class="sc-toolbar-relative">
      <f7-link
        tab-link="#tab-selected"
        text="Selected"
        tab-link-active
        @click="onTabClick('tab-selected')"
      />
      <f7-link
        tab-link="#tab-standby"
        text="Standby"
        @click="onTabClick('tab-standby')"
      />
      <f7-link
        tab-link="#tab-applicants"
        text="Applicants"
        @click="onTabClick('tab-applicants')"
      />
      <f7-link
        tab-link="#tab-invited"
        text="Invited"
        @click="onTabClick('tab-invited')"
      />
      <f7-link tab-link="#tab-na" text="N/A" @click="onTabClick('tab-na')" />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-toolbar>

    <f7-tabs>
      <f7-tab id="tab-selected" tab-active class="page-content">
        <sc-calendar-shift-detail-role-staff-type-tab
          tab-type="selected"
          :f7route="f7route"
        />
      </f7-tab>

      <f7-tab id="tab-standby" class="page-content">
        <sc-calendar-shift-detail-role-staff-type-tab
          tab-type="standby"
          :f7route="f7route"
        />
      </f7-tab>

      <f7-tab id="tab-applicants" class="page-content">
        <sc-calendar-shift-detail-role-staff-type-tab
          tab-type="applicants"
          :f7route="f7route"
        />
      </f7-tab>

      <f7-tab id="tab-invited" class="page-content">
        <sc-calendar-shift-detail-role-staff-type-tab
          tab-type="invited"
          :f7route="f7route"
        />
      </f7-tab>

      <f7-tab id="tab-na" class="page-content">
        <sc-calendar-shift-detail-role-staff-type-tab
          tab-type="na"
          :f7route="f7route"
        />
      </f7-tab>
    </f7-tabs>

    <sc-calendar-shift-detail-role-staff-add-popup
      :add-staff-action-type="addStaffActionType"
      :is-opened="addStaffPopupOpened"
      :f7route="f7route"
      @sc-calendar-shift-detail-role-staff-add-popup:closed="
        addStaffPopupOpened = false
      "
    />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import scCalendarShiftDetailRoleStaffTypeTab from './Staff/CalendarShiftDetailRoleStaffTypeTab.vue';
import scCalendarShiftDetailRoleStaffAddPopup from './Staff/CalendarShiftDetailRoleStaffAddPopup.vue';

export default {
  components: {
    scCalendarShiftDetailRoleStaffTypeTab,
    scCalendarShiftDetailRoleStaffAddPopup,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      curTabId: 'tab-selected',
      addStaffActionType: 'SELECT', // or INVITE
      addStaffPopupOpened: false,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    onTabClick(tabId) {
      this.curTabId = tabId;
    },
    onActionsClick() {
      const buttons = [
        {
          id: 'select',
          text: 'Select Staff',
          onClick: () => {
            this.addStaffActionType = 'SELECT';
            this.addStaffPopupOpened = true;
          },
          icon: '<i class="icon material-icons color-gray">check</i>',
        },
        {
          id: 'invite',
          text: 'Invite Staff',
          onClick: () => {
            this.addStaffActionType = 'INVITE';
            this.addStaffPopupOpened = true;
          },
          icon: '<i class="icon material-icons color-gray">add</i>',
        },
      ];
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        buttons,
        targetEl: '.shift-role-staff-add-actions-button',
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
      });
      actionSheet.open();
    },
  },
};
</script>
<style scoped>
::v-deep(.sc-toolbar-relative .toolbar-inner) {
  position: relative; /* for preloader */
}
</style>

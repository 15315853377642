<template>
  <f7-popup tablet-fullscreen class="shift-role-surveys-select" @popup:closed="onPopupClosed" @popup:open="onPopupOpen">
    <f7-page name="shift-role-surveys-select">
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close=".shift-role-surveys-select" />
        </f7-nav-left>
        <f7-nav-title title="Select surveys" />
        <f7-nav-right>
          <f7-link popup-close=".shift-role-surveys-select" @click="onDoneClick">DONE</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-searchbar :custom-search="true" @searchbar:search="onSearch" @searchbar:clear="onClear" />
      <f7-progressbar v-show="showProgressbar" infinite />
      <f7-list v-show="selectedRoleSurvey.length > 0" class="no-margin">
        <f7-list-group>
          <f7-list-item title="Selected" group-title />
          <f7-list-item
            v-for="roleSurvey in selectedRoleSurvey"
            :key="roleSurvey.id"
            :title="roleSurvey.text"
            :value="roleSurvey.id"
            checkbox
            checked
            @change="onChange(roleSurvey.text, $event)"
          />
        </f7-list-group>
      </f7-list>

      <f7-list v-show="showNotFound" class="no-margin-top">
        <f7-list-item title="Nothing found" />
      </f7-list>
      <f7-list v-show="showResultList" class="no-margin-top">
        <f7-list-group>
          <f7-list-item v-show="roleSurveyList.length > 0" title="Found" group-title />
          <f7-list-item
            v-for="roleSurvey in roleSurveyList"
            :key="roleSurvey.id"
            :title="roleSurvey.rname"
            :checked="isSelected(roleSurvey.id)"
            :value="roleSurvey.id"
            checkbox
            @change="onChange(roleSurvey.rname, $event)"
          />
        </f7-list-group>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    surveys: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      query: '',
      showResultList: true,
      showNotFound: false,
      showProgressbar: false,
      selectedRoleSurvey: this.surveys,
    };
  },
  computed: {
    roleSurveyList() {
      return this.$store.state.calendar.roleSurvey;
    },
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    isSelected(value) {
      return _.find(this.selectedRoleSurvey, { id: value }) !== undefined;
    },

    onChange(text, event) {
      if (event.target.checked) {
        const newSurveys = [...this.selectedRoleSurvey];
        newSurveys.push({ id: Number(event.target.value), text });
        this.selectedRoleSurvey = newSurveys;
      } else {
        this.selectedRoleSurvey = _.filter(this.selectedRoleSurvey, (r) => r.id !== Number(event.target.value));
      }
    },
    onSearch(searchbar, query) {
      if (query) {
        this.query = query;
        this.getSurveysList();
      }
    },
    onClear() {
      this.showProgressbar = false;
      this.showNotFound = false;
      this.showResultList = true;
      this.$store.commit('calendar/setCalendarShiftRoleSurvey', []);
    },
    getSurveysList: _.debounce(function fetchCalendarShiftRoleSurvey() {
      this.showProgressbar = true;
      this.$store.dispatch('calendar/fetchCalendarShiftRoleSurvey', { query: this.query }).then(() => {
        this.showProgressbar = false;
        this.showNotFound = false;
        this.showResultList = true;
        if (this.roleSurveyList.length < 1) {
          this.showNotFound = true;
          this.showResultList = false;
        }
      });
    }, 500),
    onDoneClick() {
      this.$emit('selected', this.selectedRoleSurvey);
    },
    onPopupClosed() {
      this.onClear();
    },
    onPopupOpen() {
      this.selectedRoleSurvey = this.surveys;
    },
  },
};
</script>
<style scoped></style>

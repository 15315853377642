<template>
  <f7-popup
    tablet-fullscreen
    :opened="isPopupOpened"
    @popup:open="onPopupOpen"
    @popup:closed="
      $store.commit('calendar/setCalendarShiftAddEditPopup', {
        isPopupOpened: false,
        type: 'ADD',
      })
    "
  >
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>{{ ['ADD', 'DUPLICATE'].includes(actionType) ? 'New Shift' : 'Edit Shift' }}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="onAddSaveClick">
              {{ ['ADD', 'DUPLICATE'].includes(actionType) ? 'ADD' : 'SAVE' }}
            </f7-link>
          </f7-nav-right>
          <template #after-inner>
            <f7-progressbar v-show="isLoadingData" infinite />
          </template>
        </f7-navbar>

        <f7-block-title>General Information</f7-block-title>
        <f7-list no-hairlines>
          <f7-list-input
            outline
            floating-label
            label="Title*"
            required
            validate
            clear-button
            type="text"
            :value="title"
            @input="title = $event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Generic Title"
            clear-button
            type="text"
            :value="genericTitle"
            @input="genericTitle = $event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Location"
            clear-button
            type="text"
            :value="location"
            @input="location = $event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Generic Location"
            clear-button
            type="text"
            :value="genericLocation"
            @input="genericLocation = $event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Address"
            clear-button
            type="text"
            :value="address"
            @input="address = $event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Venue Contact"
            clear-button
            type="text"
            :value="venueContact"
            @input="venueContact = $event.target.value"
          />
        </f7-list>

        <f7-block-title>Date & Times</f7-block-title>
        <f7-list no-hairlines>
          <f7-list-input
            input-id="shift-date"
            outline
            floating-label
            readonly
            label="Date"
            type="text"
            :value="shiftDate"
            @change="shiftDate = $event.target.value"
          />

          <f7-list-item>
            <f7-list-item-row>
              <f7-list-item-cell>
                <f7-list-input
                  input-id="shift-start-time"
                  outline
                  floating-label
                  readonly
                  label="Start Time"
                  type="text"
                  :value="shiftStartTime"
                  @change="shiftStartTime = $event.target.value"
                />
              </f7-list-item-cell>
              <f7-list-item-cell>
                <f7-list-input
                  input-id="shift-end-time"
                  outline
                  floating-label
                  readonly
                  label="End Time"
                  type="text"
                  :value="shiftEndTime"
                  @change="shiftEndTime = $event.target.value"
                />
              </f7-list-item-cell>
            </f7-list-item-row>
          </f7-list-item>
        </f7-list>

        <f7-block-title>Tracking</f7-block-title>
        <f7-list no-hairlines>
          <f7-list-item
            class="managers-smart-select"
            :smart-select-params="{
              openIn: 'popup',
              searchbar: true,
              searchbarPlaceholder: 'Search manager',
            }"
            title="Managers"
            smart-select
          >
            <select name="managersSelect" multiple @change="onManagersChanged($event)">
              <option
                v-for="manager in managers"
                :key="manager.id"
                :selected="managerIds.indexOf(manager.id) !== -1"
                :value="manager.id"
              >
                {{ manager.name }}
              </option>
            </select>
          </f7-list-item>
          <f7-list-item
            class="client-smart-select"
            :smart-select-params="{
              openIn: 'popup',
              searchbar: true,
              searchbarPlaceholder: 'Search client',
            }"
            title="Client"
            smart-select
          >
            <select name="clientSelect" @change="onClientChanged($event)">
              <option :selected="clientId === null" :value="null">No client</option>
              <option v-for="client in clients" :key="client.id" :selected="clientId === client.id" :value="client.id">
                {{ client.cname }}
              </option>
            </select>
          </f7-list-item>

          <f7-list-item
            class="workareas-smart-select"
            :smart-select-params="{
              openIn: 'popup',
              searchbar: true,
              searchbarPlaceholder: 'Search work area',
            }"
            title="Work Areas"
            smart-select
          >
            <select name="workAreasSelect" multiple @change="onWorkAreasChanged($event)">
              <option
                v-for="workArea in workAreas"
                :key="workArea.id"
                :selected="workAreasIds.indexOf(workArea.id) !== -1"
                :value="workArea.id"
              >
                {{ workArea.aname }}
              </option>
            </select>
          </f7-list-item>
        </f7-list>

        <f7-block-title>Shift Notes</f7-block-title>
        <f7-text-editor placeholder="Enter text..." :value="notes" @texteditor:change="(v) => (notes = v)" />
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      genericTitle: '',
      location: '',
      genericLocation: '',
      address: '',
      venueContact: '',
      shiftDate: moment().format('MMMM DD YYYY'),
      shiftStartTime: '09:00',
      shiftEndTime: '17:00',
      managerIds: [],
      clientId: null,
      workAreasIds: [],
      notes: '',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isPopupOpened() {
      return this.$store.state.calendar.shiftAddEditPopup.isPopupOpened;
    },
    actionType() {
      return this.$store.state.calendar.shiftAddEditPopup.type;
    },
    managers() {
      return this.$store.state.calendar.managers;
    },
    clients() {
      return this.$store.state.calendar.clients;
    },
    workAreas() {
      return this.$store.state.calendar.workAreas;
    },
    shiftId() {
      return this.$store.state.calendar.shift.id;
    },
  },
  beforeCreate() {
    this.$store.dispatch('calendar/fetchCalendarShiftManagers');
    this.$store.dispatch('calendar/fetchCalendarShiftClients');
    this.$store.dispatch('calendar/fetchCalendarShiftWorkAreas');
  },
  created() {},
  mounted() {
    const today = new Date();
    this.shiftDateElement = f7.calendar.create({
      inputEl: '#shift-date',
      dateFormat: 'MM dd yyyy',
      locale: 'en',
      minDate: today,
      closeOnSelect: true,
      routableModals: false,
    });
    this.startTimeElement = f7.picker.create({
      inputEl: '#shift-start-time',
      rotateEffect: true,
      formatValue: (values) => `${values[0]}:${values[1]}`,
      cols: [
        {
          textAlign: 'left',
          values: 'AM 01, AM 02, AM 03, AM 04, AM 05, AM 06, AM 07, AM 08, AM 09, AM 10, AM 11, AM 12, PM 01, PM 02, PM 03, PM 04, PM 05, PM 06, PM 07, PM 08, PM 09, PM 10, PM 11, PM 12'.split(
            ','
          ),
        },
        {
          divider: true,
          content: ':',
        },
        {
          values: (
            '00 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 ' +
            '21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 ' +
            '41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59'
          ).split(' '),
        },
      ],
    });
    this.endTimeElement = f7.picker.create({
      inputEl: '#shift-end-time',
      rotateEffect: true,
      formatValue: (values) => `${values[0]}:${values[1]}`,
      cols: [
        {
          textAlign: 'left',
          values: 'AM 01, AM 02, AM 03, AM 04, AM 05, AM 06, AM 07, AM 08, AM 09, AM 10, AM 11, AM 12, PM 01, PM 02, PM 03, PM 04, PM 05, PM 06, PM 07, PM 08, PM 09, PM 10, PM 11, PM 12'.split(
            ','
          ),
        },
        {
          divider: true,
          content: ':',
        },
        {
          values: (
            '00 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 ' +
            '21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 ' +
            '41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59'
          ).split(' '),
        },
      ],
    });
  },
  methods: {
    onPopupOpen() {
      const managersSmartSelect = f7.smartSelect.get('.managers-smart-select > .smart-select');
      const workareasSmartSelect = f7.smartSelect.get('.workareas-smart-select > .smart-select');
      const clientSmartSelect = f7.smartSelect.get('.client-smart-select > .smart-select');
      managersSmartSelect.unsetValue();
      workareasSmartSelect.unsetValue();
      clientSmartSelect.setValue('');

      this.title = '';
      this.genericTitle = '';
      this.location = '';
      this.genericLocation = '';
      this.address = '';
      this.venueContact = '';
      this.shiftDate = moment().format('MMMM DD YYYY');
      this.shiftStartTime = 'AM 09:00';
      this.shiftEndTime = 'PM 05:00';
      this.managerIds = [];
      this.clientId = null;
      this.workAreasIds = [];
      this.notes = '';
      if (['EDIT', 'DUPLICATE'].includes(this.actionType)) {
        const { shift } = this.$store.state.calendar;
        this.title = shift.title;
        this.genericTitle = shift.generic_title;
        this.location = shift.location;
        this.genericLocation = shift.generic_location;
        this.address = shift.address;
        this.venueContact = shift.contact;
        this.shiftDate = moment(shift.date, 'DD/MM/YYYY').format('MMMM DD YYYY');
        this.shiftStartTime = moment(shift.start, 'h:mm a').format('A hh:mm');
        this.shiftEndTime = moment(shift.end, 'h:mm a').format('A hh:mm');
        this.clientId = shift.client_id !== null ? Number(shift.client_id) : null;
        clientSmartSelect.setValue(this.clientId ? this.clientId.toString() : '');
        this.notes = shift.notes;
        this.managerIds = _.reduce(
          shift.managers,
          (result, manager) => {
            result.push(Number(manager.id));
            return result;
          },
          []
        );
        managersSmartSelect.setValue(this.managerIds.map((item) => item.toString()));
        this.workAreasIds = _.reduce(
          shift.work_areas,
          (result, area) => {
            result.push(Number(area.id));
            return result;
          },
          []
        );
        workareasSmartSelect.setValue(this.workAreasIds.map((item) => item.toString()));
      }
    },
    onManagersChanged(event) {
      const result = [];
      _.forEach(event.target.options, (option) => {
        if (option.selected) {
          result.push(Number(option.value));
        }
      });
      this.managerIds = result;
    },
    onClientChanged(event) {
      _.forEach(event.target.options, (option) => {
        if (option.selected) {
          this.clientId = Number(option.value);
        }
      });
    },
    onWorkAreasChanged(event) {
      const result = [];
      _.forEach(event.target.options, (option) => {
        if (option.selected) {
          result.push(Number(option.value));
        }
      });
      this.workAreasIds = result;
    },
    onAddSaveClick() {
      if (this.title !== '') {
        const actionName = ['ADD', 'DUPLICATE'].includes(this.actionType) ? 'addCalendarShift' : 'editCalendarShift';
        this.$store
          .dispatch(`calendar/${actionName}`, {
            isDuplicate: this.actionType === 'DUPLICATE',
            title: this.title,
            genericTitle: this.genericTitle,
            location: this.location,
            genericLocation: this.genericLocation,
            address: this.address,
            venueContact: this.venueContact,
            shiftStart: ['ADD', 'DUPLICATE'].includes(this.actionType)
              ? [
                  moment(this.shiftDate, 'MMMM DD YYYY').format('YYYY-MM-DD') +
                    ' ' +
                    moment(this.shiftStartTime, 'A hh:mm').format('HH:mm'),
                ]
              : moment(this.shiftDate, 'MMMM DD YYYY').format('YYYY-MM-DD') +
                ' ' +
                moment(this.shiftStartTime, 'A hh:mm').format('HH:mm'),
            shiftEnd: ['ADD', 'DUPLICATE'].includes(this.actionType)
              ? [
                  moment(this.shiftDate, 'MMMM DD YYYY').format('YYYY-MM-DD') +
                    ' ' +
                    moment(this.shiftEndTime, 'A hh:mm').format('HH:mm'),
                ]
              : moment(this.shiftDate, 'MMMM DD YYYY').format('YYYY-MM-DD') +
                ' ' +
                moment(this.shiftEndTime, 'A hh:mm').format('HH:mm'),
            clientId: this.clientId,
            managerIds: this.managerIds,
            workAreasIds: this.workAreasIds,
            notes: this.notes,
          })
          .then((result) => {
            if (result !== false) {
              const shiftId = ['ADD', 'DUPLICATE'].includes(this.actionType) ? result.data.data[0].id : this.shiftId;
              this.f7router.navigate(`/calendar/shift/${shiftId}/`);
              f7.popup.close();
            }
          });
      }
    },
  },
};
</script>

<template>
  <f7-tab id="document" class="page-content">
    <div class="sc-doc-gallery">
      <div
        v-for="document in documents"
        :key="document.id"
        class="card sc-doc-card"
      >
        <div class="card-content">
          <a @click="onExternalLinkClick(document.path)">
            <img :src="document.thumbnail" width="50%" />
          </a>
          <p>{{ document.oname }}</p>
        </div>
        <div class="card-footer">
          <div>{{ new Date(document.created_at).toDateString() }}</div>
          <f7-button
            v-if="document.delete"
            color="gray"
            icon-material="delete"
            @click="onDeleteClick(document.id)"
          />
        </div>
      </div>
    </div>
    <f7-fab
      position="right-bottom"
      style="position: fixed"
      @click="openFilePicker"
    >
      <f7-icon material="add" />
    </f7-fab>
    <sc-file-picker
      @sc-file-picker:ready="openFilePicker = $event"
      @sc-file-picker:loaded="onFileLoaded"
    />
  </f7-tab>
</template>
<script>
import { mapState } from 'vuex';
import { f7 } from 'framework7-vue';

import scFilePicker from '../../SCFilePicker.vue';

export default {
  components: {
    scFilePicker,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      openFilePicker: () => {},
    };
  },
  computed: mapState({
    documents: (state) => state.profile.documents,
  }),
  created() {
    this.$store.dispatch('profile/getProfileDocuments', {
      userId: this.userId,
    });
  },
  methods: {
    onExternalLinkClick(link) {
      window.open(link, '_system');
    },
    onDeleteClick(documentId) {
      f7.dialog.confirm(
        "This document will be deleted and you won't be able to find it anymore.",
        'Delete the document?',
        () => {
          this.$store.dispatch('profile/deleteProfileDocument', {
            document_id: documentId,
          });
        }
      );
    },
    onFileLoaded(file) {
      this.$store.dispatch('profile/setProfileDocument', {
        document_blob: file.blob,
        userId: this.userId,
      });
    },
  },
};
</script>

<style scoped>
.sc-doc-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sc-doc-card {
  width: 44%;
  max-width: 200px;
  min-width: 120px;
}
::v-deep(.sc-doc-card .card-content) {
  min-height: 120px;
  text-align: center;
}
::v-deep(.sc-doc-card .card-content img) {
  width: 50%;
  display: block;
  margin: 15px auto;
}
::v-deep(.sc-doc-card .card-content p) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}
::v-deep(.sc-doc-card .card-footer .button) {
  max-width: 30px;
  min-width: 30px;
  padding: 0 0 0 5px;
}
</style>

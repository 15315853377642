<template>
  <f7-page @page:afterout="onPageAfterOut">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title v-if="payroll !== undefined">{{
        payroll.display
      }}</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="isUserLvlOwnerOrAdmin"
          icon-only
          icon-material="history"
          popup-open=".sc-payrolls-activity-history"
        />
        <f7-link
          v-if="
            isUserLvlOwnerOrAdmin &&
            payroll !== undefined &&
            payroll.actions !== undefined &&
            payroll.actions.length > 0
          "
          class="sc-payroll-actions-link"
          icon-only
          icon-material="more_vert"
          @click="onPayrollActionsLinkClick(payroll.id)"
        />
      </f7-nav-right>
      <template #after-inner>
        <f7-progressbar
          v-show="isLoadingData && payroll !== undefined"
          infinite
        />
      </template>
    </f7-navbar>
    <f7-block
      v-show="isLoadingData && payroll === undefined"
      inset
      class="text-align-center"
    >
      <f7-preloader />
    </f7-block>
    <f7-block v-if="payroll !== undefined" class="no-margin no-padding">
      <f7-block
        class="no-margin-vertical padding-vertical"
        :class="{
          'bg-color-red': payroll.status === 'Rejected',
          'bg-color-yellow': payroll.status === 'Unpaid',
          'bg-color-green': payroll.status === 'Paid',
          'bg-color-orange': payroll.status === 'Processing',
          'bg-color-blue': payroll.status === 'Approved',
          'bg-color-gray': payroll.status === 'Deleted',
        }"
      >
        <f7-row>
          <f7-col>
            <div class="text-color-white">{{ payroll.company.name }}</div>
          </f7-col>
          <f7-col class="text-align-right">
            <div
              class="text-color-white"
              style="font-size: 18px; font-weight: 500"
            >
              {{ payroll.status }}
            </div>
          </f7-col>
        </f7-row>
        <div
          class="text-color-white margin-top"
          style="font-size: 28px; font-weight: 500"
        >
          {{ payroll.currency_symbol }}{{ payroll.total }}
        </div>
        <div class="text-color-white" style="font-weight: 500">
          {{ formatDate(payroll.created_at) }}
        </div>
      </f7-block>
      <f7-block>
        <div>{{ `${payroll.user.fname} ${payroll.user.lname}` }}</div>
        <div>
          {{
            `${payroll.user.postcode || ''} ${payroll.user.address || ''} ${
              payroll.user.city || ''
            } ${payroll.user.state || ''}`
          }}
        </div>
        <div>{{ payroll.user.email }}</div>
        <div>{{ payroll.user.mob }}</div>
      </f7-block>
      <f7-list media-list>
        <template v-if="payroll.shift.length > 0">
          <f7-list-item divider title="Shifts" />
          <f7-list-item
            v-for="(item, indx) in payroll.shift"
            :key="`shift${indx}`"
            :title="item.title"
            :text="`${item.qty} x ${item.u_amt}`"
            :after="item.l_amt"
          />
        </template>
        <template v-if="payroll.bonus.length > 0">
          <f7-list-item divider title="Bonus" />
          <f7-list-item
            v-for="(item, indx) in payroll.bonus"
            :key="`bon${indx}`"
            :title="item.title"
            :text="`${item.qty} x ${item.u_amt}`"
            :after="item.l_amt"
          />
        </template>
        <template v-if="payroll.deduction.length > 0">
          <f7-list-item divider title="Deduction" />
          <f7-list-item
            v-for="(item, indx) in payroll.deduction"
            :key="`ded${indx}`"
            :title="item.title"
            :text="`${item.qty} x ${item.u_amt}`"
            :after="item.l_amt"
          />
        </template>
        <template v-if="payroll.expense.length > 0">
          <f7-list-item divider title="Expense" />
          <f7-list-item
            v-for="(item, indx) in payroll.expense"
            :key="`exp${indx}`"
            :title="item.title"
            :text="`${item.qty} x ${item.u_amt}`"
            :after="item.l_amt"
          />
        </template>
        <template v-if="payroll.travel.length > 0">
          <f7-list-item divider title="Travel" />
          <f7-list-item
            v-for="(item, indx) in payroll.travel"
            :key="`trvl${indx}`"
            :title="item.title"
            :text="`${item.qty} x ${item.u_amt}`"
            :after="item.l_amt"
          />
        </template>
        <template v-if="payroll.time.length > 0">
          <f7-list-item divider title="Time" />
          <f7-list-item
            v-for="(item, indx) in payroll.time"
            :key="`tme${indx}`"
            :title="item.title"
            :text="`${item.qty} x ${item.u_amt}`"
            :after="item.l_amt"
          />
        </template>
        <template v-if="payroll.mileage.length > 0">
          <f7-list-item divider title="Mileage" />
          <f7-list-item
            v-for="(item, indx) in payroll.mileage"
            :key="`mil${indx}`"
            :title="item.title"
            :text="`${item.qty} x ${item.u_amt}`"
            :after="item.l_amt"
          />
        </template>
        <template v-if="payroll.other.length > 0">
          <f7-list-item divider title="Other" />
          <f7-list-item
            v-for="(item, indx) in payroll.other"
            :key="`oth${indx}`"
            :title="item.title"
            :text="`${item.qty} x ${item.u_amt}`"
            :after="item.l_amt"
          />
        </template>
      </f7-list>
      <template v-if="isUserLvlOwnerOrAdmin">
        <f7-block-title
          class="display-flex align-items-center justify-content-space-between"
        >
          <span>ADMIN NOTES</span>
          <f7-button text="ADD" small @click="onAdminNoteAddClick" />
        </f7-block-title>
        <f7-list media-list>
          <f7-list-item
            v-for="note in payrollAdminNotes"
            :key="note.id"
            :title="note.name"
            :text="note.note"
            :after="formatDate(note.updated_at)"
            swipeout
          >
            <f7-swipeout-actions right>
              <f7-swipeout-button
                color="green"
                @click="onAdminNoteEditClick(note.id)"
              >
                <f7-icon material="edit" color="white" />
              </f7-swipeout-button>
              <f7-swipeout-button
                delete
                color="red"
                @click="onAdminNoteDeleteClick(note.id)"
              >
                <f7-icon material="delete" color="white" />
              </f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
      </template>
    </f7-block>
    <sc-payrolls-detail-add-edit-admin-note-sheet
      v-if="isUserLvlOwnerOrAdmin"
      :payroll-id="payrollId"
      :is-opened="addEditAdminNoteSheetOpened"
      :sheet-type="addEditAdminNoteSheetType"
      :admin-note-id="editAdminNoteId"
      @sc-payrolls-detail-add-edit-admin-note-sheet:closed="
        addEditAdminNoteSheetOpened = false
      "
    />
    <sc-payrolls-activity-history-popup :payroll-id="payrollId" />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import moment from 'moment';

import scPayrollsDetailAddEditAdminNoteSheet from './Detail/PayrollsDetailAddEditAdminNoteSheet.vue';
import scPayrollsActivityHistoryPopup from './Detail/PayrollsActivityHistoryPopup.vue';

export default {
  components: {
    scPayrollsDetailAddEditAdminNoteSheet,
    scPayrollsActivityHistoryPopup,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addEditAdminNoteSheetOpened: false,
      addEditAdminNoteSheetType: 'ADD', // ADD or EDIT
      editAdminNoteId: undefined,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    payroll() {
      return this.$store.state.payrolls.payroll;
    },
    payrollId() {
      return Number(this.f7route.params.payroll_id);
    },
    payrollAdminNotes() {
      return this.$store.state.payrolls.payrollAdminNotes;
    },
  },
  mounted() {
    this.$store.dispatch('payrolls/fetchPayroll', {
      payrollId: this.f7route.params.payroll_id,
    });
    if (this.isUserLvlOwnerOrAdmin) {
      this.$store.dispatch('payrolls/fetchPayrollAdminNotes', {
        payrollId: this.f7route.params.payroll_id,
      });
    }
  },
  methods: {
    onPageAfterOut() {
      this.$store.commit('payrolls/setPayroll', undefined);
      this.$store.commit('payrolls/setPayrollAdminNotes', []);
    },

    formatDate(date) {
      const formatedDate = moment(date).add(
        this.$store.state.account.utcOffset,
        's'
      );
      return formatedDate.format('MMM D YYYY');
    },

    singlePayrollActionClick(actionType, payrollId) {
      this.$store
        .dispatch('payrolls/singlePayrollActions', {
          actionType,
          payrollId,
        })
        .then(() => {
          this.$store.dispatch('payrolls/fetchPayroll', { payrollId });
        });
    },
    onPayrollActionsLinkClick(payrollId) {
      const buttons = this.payroll.actions.map((action) => {
        return {
          text: action.label,
          onClick: () => {
            this.singlePayrollActionClick(action.action, payrollId);
          },
        };
      });
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
        targetEl: '.sc-payroll-actions-link',
        buttons,
      });
      actionSheet.open();
    },
    onAdminNoteAddClick() {
      this.addEditAdminNoteSheetType = 'ADD';
      this.addEditAdminNoteSheetOpened = true;
    },
    onAdminNoteEditClick(adminNoteId) {
      this.addEditAdminNoteSheetType = 'EDIT';
      this.editAdminNoteId = adminNoteId;
      this.addEditAdminNoteSheetOpened = true;
    },
    onAdminNoteDeleteClick(adminNoteId) {
      this.$store.dispatch('payrolls/deletePayrollAdminNote', {
        adminNoteId,
        payrollId: this.payrollId,
      });
    },
  },
};
</script>
<style scoped></style>

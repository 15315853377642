<template>
  <f7-page @page:reinit="onReInit">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-only panel-open="left" icon-material="menu" />
      </f7-nav-left>
      <f7-nav-title>{{ systemName }}</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-only popup-open=".sc-home-notifications-popup">
          <f7-icon material="notifications">
            <f7-badge v-show="notificationsNum > 0" color="red">{{
              notificationsNum
            }}</f7-badge>
          </f7-icon>
        </f7-link>

        <f7-link
          v-if="isTimelinePostEnabled || isUserLvlOwnerOrAdmin"
          icon-only
          icon-material="add"
          @click="addEditPostPopup.opened = true"
        />
      </f7-nav-right>
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <sc-home-post-card
      v-for="post in posts"
      :key="post.id"
      :page-type="'HOME'"
      :post-id="Number(post.id)"
      :post-ppic_a="post.ppic_a"
      :post-name="post.name"
      :post-created-at="post.created_at"
      :post-content="post.content"
      :post-link="post.link"
      :post-thumb="post.thumb"
      :post-liked="Number(post.liked)"
      :post-likes="Number(post.likes)"
      :post-likes-text="post.likes_text"
      :post-comments="Number(post.comments)"
      :post-pinned="Number(post.pinned)"
      :post-approved="Number(post.approved)"
      :f7router="f7router"
      @sc-home-post-card:post-edit-click="onPostEditClick"
    />

    <sc-home-add-edit-post-popup
      :is-popup-opened="addEditPostPopup.opened"
      :popup-action-type="addEditPostPopup.type"
      :edit-post-id="addEditPostPopup.editPostId"
      @sc-home-add-edit-post-popup:closed="onAddEditPostPopupClosed"
    />
    <sc-calendar-shift-detail-role-form-quiz-connect-popup :f7route="f7route" />
    <sc-home-notifications-popup />
    <sc-home-post-likes-sheet-modal />
  </f7-page>
</template>

<script>
import scHomeAddEditPostPopup from './Home/HomeAddEditPostPopup.vue';
import scHomePostCard from './Home/Posts/HomePostCard.vue';
import scHomeNotificationsPopup from './Home/HomeNotificationsPopup.vue';
import scHomePostLikesSheetModal from './Home/Posts/HomePostLikesSheetModal.vue';

import scCalendarShiftDetailRoleFormQuizConnectPopup from './Calendar/Shift/Detail/Role/CalendarShiftDetailRoleFormQuizConnectPopup.vue';

export default {
  components: {
    scHomeAddEditPostPopup,
    scHomePostCard,
    scHomeNotificationsPopup,
    scHomePostLikesSheetModal,
    scCalendarShiftDetailRoleFormQuizConnectPopup,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addEditPostPopup: {
        opened: false,
        editPostId: undefined,
        type: 'ADD', // 'ADD' or 'EDIT'
      },
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    systemName() {
      return this.$store.state.account.settings.system_name;
    },
    posts() {
      return this.$store.state.posts.posts;
    },
    isTimelinePostEnabled() {
      return this.$store.state.account.settings.staff_timeline_post === '1';
    },
    notificationsNum() {
      return this.$store.getters['posts/getNotificationsNum'];
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
  },
  beforeCreate() {
    if (this.$store.state.posts.posts.length === 0) {
      this.$store.dispatch('posts/getPosts');
    } else {
      this.$store.dispatch('posts/getPosts', { silent: true });
    }
    this.$store.dispatch('posts/getPinnedPosts');
    this.$store.dispatch('posts/getHomeNotifications');
    this.$store.dispatch('chats/getChatThreads', { silent: true }); // for unread message num
  },
  methods: {
    onReInit() {
      this.$store.dispatch('posts/getPosts', { silent: true });
      this.$store.dispatch('posts/getPinnedPosts');
      this.$store.dispatch('posts/getHomeNotifications');
    },
    onAddEditPostPopupClosed() {
      this.addEditPostPopup.editPostId = undefined;
      this.addEditPostPopup.type = 'ADD';
      this.addEditPostPopup.opened = false;
    },
    onPostEditClick(postId) {
      this.addEditPostPopup.editPostId = postId;
      this.addEditPostPopup.type = 'EDIT';
      this.addEditPostPopup.opened = true;
    },
  },
};
</script>

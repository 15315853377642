import account from './modules/account.api';
import calendar from './modules/calendar.api';
import profile from './modules/profile.api';
import chats from './modules/chats.api';
import posts from './modules/posts.api';
import unavailability from './modules/unavailability.api';
import users from './modules/users.api';
import payrolls from './modules/payrolls.api';

function apiErrorHandler(error) {
  console.dir(error);
  throw error;
}

account.apiErrorHandler = apiErrorHandler;
posts.apiErrorHandler = apiErrorHandler;
unavailability.apiErrorHandler = apiErrorHandler;
profile.apiErrorHandler = apiErrorHandler;
users.apiErrorHandler = apiErrorHandler;
chats.apiErrorHandler = apiErrorHandler;
calendar.apiErrorHandler = apiErrorHandler;
payrolls.apiErrorHandler = apiErrorHandler;

const api = {
  account,
  posts,
  unavailability,
  profile,
  users,
  chats,
  calendar,
  payrolls,
};

export default api;

<template>
  <f7-popup tablet-fullscreen class="sc-profile-payment-link-bank" @popup:opened="onPopupOpened">
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>Add Bank Account</f7-nav-title>
          <template #after-inner>
            <f7-progressbar v-show="isLoadingData" infinite />
          </template>
        </f7-navbar>

        <f7-list no-hairlines no-hairlines-between>
          <f7-list-input
            label="Bank Country"
            floating-label
            outline
            validate
            type="select"
            @change="onCountryChange($event.target.value)"
          >
            <option v-for="country in countries" :key="country.iso2" :value="country.iso2">
              {{ country.option }}
            </option>
          </f7-list-input>
          <f7-list-input
            label="Account Name"
            outline
            floating-label
            required
            validate
            type="text"
            clear-button
            :value="accountName"
            @change="accountName = $event.target.value"
          />
          <f7-list-input
            label="Bank Name"
            outline
            floating-label
            required
            validate
            type="text"
            clear-button
            :value="bankName"
            @change="bankName = $event.target.value"
          />
          <f7-list-input
            v-show="showSWIFTCode"
            label="SWIFT Code"
            outline
            floating-label
            required
            validate
            type="text"
            clear-button
            :value="SWIFTCode"
            @change="SWIFTCode = $event.target.value"
          />
          <f7-list-input
            v-show="routingLabel !== undefined"
            :label="routingLabel"
            outline
            floating-label
            required
            validate
            :minlength="routingMinLength"
            :maxlength="routingMaxLength"
            type="text"
            clear-button
            :value="routingNumber"
            @change="routingNumber = $event.target.value"
          />
          <f7-list-input
            label="Account No."
            outline
            floating-label
            required
            validate
            type="text"
            clear-button
            :value="accountNumber"
            @change="accountNumber = $event.target.value"
          />
          <f7-list-input
            label="Currency"
            floating-label
            outline
            required
            validate
            type="select"
            :value="currencyCode"
            @change="currencyCode = $event.target.value"
          >
            <option v-for="currency in currencies" :key="currency.id" :value="currency.currency_code">
              {{ currency.currency_code }}
            </option>
          </f7-list-input>
        </f7-list>
        <f7-block class="margin-vertical text-align-center">
          <f7-button v-show="!isLoadingData" raised fill @click="onSaveClick">SAVE</f7-button>
          <f7-preloader v-show="isLoadingData" />
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';

export default {
  components: {},
  data() {
    return {
      countries: [],
      countryCode: undefined,
      currencies: [],
      currencyCode: undefined,
      accountName: `${this.$store.state.account.user.fname} ${this.$store.state.account.user.lname}`,
      bankName: undefined,
      showSWIFTCode: false,
      SWIFTCode: undefined,
      routingNumber: undefined,
      routingLabel: undefined,
      routingMinLength: undefined,
      routingMaxLength: undefined,
      accountNumber: undefined,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
  },
  mounted() {
    this.$store.dispatch('profile/fetchSettingsPaymentСurrencies').then((result) => {
      this.currencies = result;
      this.$store.dispatch('profile/fetchSettingsPaymentСountries').then((result) => {
        if (result && result.length > 0) {
          this.countries = result;
          this.countryCode = this.countries[0].iso2;
          this.showSWIFTCode = !(this.countryCode === 'CA' || this.countryCode === 'US');
          this.currencyCode = this.countries[0].currency_code;
          this.routingLabel = this.countries[0].bank_routing_label || undefined;
          this.routingMinLength = this.countries[0].bank_routing_max || undefined;
          this.routingMaxLength = this.countries[0].bank_routing_min || undefined;
        }
      });
    });
  },
  methods: {
    onPopupOpened() {},
    onCountryChange(countryCode) {
      const country = _.find(this.countries, { iso2: countryCode });
      this.countryCode = countryCode;
      this.showSWIFTCode = !(this.countryCode === 'CA' || this.countryCode === 'US');
      this.currencyCode = country.currency_code;
      this.routingLabel = country.bank_routing_label || undefined;
      this.routingMinLength = country.bank_routing_max || undefined;
      this.routingMaxLength = country.bank_routing_min || undefined;
    },
    onSaveClick() {
      this.$store
        .dispatch('profile/linkBankAccountSettingsPayment', {
          userId: this.userId,
          accountNumber: this.accountNumber,
          countryCode: this.countryCode,
          bankName: this.bankName,
          routingNumber: this.routingNumber,
          SWIFTCode: this.SWIFTCode,
          accountName: this.accountName,
          country: this.country,
          currencyCode: this.currencyCode,
        })
        .then((result) => {
          if (result !== false) {
            f7.popup.close('.sc-profile-payment-link-bank');
          }
        });
    },
  },
};
</script>
<style scoped></style>

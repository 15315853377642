import _ from 'lodash';
import moment from 'moment';
import api from '../../api';
import { toRaw } from 'vue';

export default {
  namespaced: true,
  state: {
    threads: [],
    thread: {
      thread_id: undefined,
      page_id: 1,
      new_message_id: -1,
      messages: [],
    },
    add_user_or_thread: {
      show_not_found: false,
      show_result_list: false,
      user_list: [],
    },
  },
  getters: {
    getChatThreadActive: (state) => (id) =>
      _.find(state.threads, { id }).active,
    getChatThreadShiftId: (state) => (id) =>
      _.find(state.threads, { id }).shift_id,
    getChatThreadName: (state) => (id) => _.find(state.threads, { id }).name,
    getChatThreadMessages: (state) => (id) => {
      const { participants } = _.find(state.threads, { id });
      return state.thread.messages.map((message) => {
        const formatMessage = { ...message };
        formatMessage.sender = _.find(participants, {
          id: formatMessage.sender_id,
        });
        formatMessage.seen_by = _.filter(participants, (p) =>
          _.includes(message.seen_by_ids, p.id)
        );
        return formatMessage;
      });
    },
    getChatThreadParticipantsNum: (state) => (id) =>
      _.find(state.threads, { id }).participants.length,
    getChatThreadParticipantsIds: (state, getters, rootState) => (id) => {
      const { participants } = _.find(state.threads, { id });
      return _.reduce(
        participants,
        (result, participant) => {
          if (participant.id !== rootState.account.user.id) {
            result.push(participant.id);
          }
          return result;
        },
        []
      );
    },
    getChatThreadParticipants: (state, getters, rootState) => (id) => {
      const { participants } = _.find(state.threads, { id });
      return _.filter(participants, (p) => p.id !== rootState.account.user.id);
    },
    getChatThreadsUnreadMessagesNum(state) {
      return state.threads.reduce((acc, thread) => acc + thread.unread, 0);
    },
  },
  mutations: {
    setChatAddUserOrThread(state, payload) {
      if ({}.hasOwnProperty.call(payload, 'show_not_found'))
        state.add_user_or_thread.show_not_found = payload.show_not_found;
      if ({}.hasOwnProperty.call(payload, 'show_result_list'))
        state.add_user_or_thread.show_result_list = payload.show_result_list;
      if ({}.hasOwnProperty.call(payload, 'user_list'))
        state.add_user_or_thread.user_list = payload.user_list;
    },
    setChatThreads(state, payload) {
      state.threads = payload.threads;
    },
    setChatThread(state, payload) {
      if ({}.hasOwnProperty.call(payload, 'thread_id')) {
        state.thread.thread_id = payload.thread_id;
        state.thread.messages = [];
      }
      if ({}.hasOwnProperty.call(payload, 'messages')) {
        const union = _.unionBy(payload.messages, state.thread.messages, 'id');
        state.thread.messages = _.sortBy(union, ['id']);
      }
    },
    setChatThreadNewMessage(state, payload) {
      const messages = [...state.thread.messages];
      messages.push(payload.new_message);
      state.thread.messages = messages;
      state.thread.new_message_id -= 1;
    },
    deleteChatThreadMessage(state, payload) {
      const messages = [...state.thread.messages];
      _.remove(messages, { id: payload.id });
      state.thread.messages = messages;
    },

    updateChatThreadNewMessagesSeen(state, payload) {
      const messages = [...state.thread.messages];
      messages.forEach((message) => {
        if (message.seen_by_ids.indexOf(payload.reader) === -1) {
          message.seen_by_ids.push(payload.reader);
        }
      });
      state.thread.messages = messages;
    },
  },
  actions: {
    getChatThreads({ commit, dispatch }, payload) {
      if (!payload || !payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.chats
        .getChatThreads()
        .then((result) => {
          commit('setChatThreads', { threads: result.data });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    getShiftChatThread({ commit, dispatch }, payload) {
      if (!payload || !payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.chats
        .getShiftChatThread({
          threadId: payload.threadId,
        })
        .then((result) => {
          commit('setChatThreads', { threads: result.data });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getChatThreadMessages({ state, commit, dispatch }, payload) {
      if (!payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.chats
        .getChatThreadMessages({
          thread_id: payload.thread_id,
          page_id: !payload.prev ? 0 : state.thread.page_id,
        })
        .then((result) => {
          commit('setChatThread', { messages: result.data });
          commit('setIsLoadingData', false, { root: true }); // close preloader
          dispatch('updateChatThreadSeen', { thread_id: payload.thread_id });
          if (payload.prev && result.data.length > 0) {
            state.thread.page_id += 1;
          }
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getChatThreadUnreadMessages({ commit, dispatch }, payload) {
      return api.chats
        .getChatThreadUnreadMessages({
          thread_id: payload.thread_id,
        })
        .then((result) => {
          commit('setChatThread', { messages: result.data });
          dispatch('updateChatThreadSeen', { thread_id: payload.thread_id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getChatSearchUserList({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.chats
        .getChatSearchUserList({
          query: payload.query,
        })
        .then((result) => {
          if (result.data.length > 0) {
            commit('setChatAddUserOrThread', {
              show_not_found: false,
              show_result_list: true,
              user_list: result.data,
            });
          } else {
            commit('setChatAddUserOrThread', {
              show_not_found: true,
              show_result_list: false,
              user_list: [],
            });
          }
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    getChatSearchUserListForThread({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.chats
        .getChatSearchUserListForThread({
          query: payload.query,
          thread_id: payload.thread_id,
        })
        .then((result) => {
          if (result.data.length > 0) {
            commit('setChatAddUserOrThread', {
              show_not_found: false,
              show_result_list: true,
              user_list: result.data,
            });
          } else {
            commit('setChatAddUserOrThread', {
              show_not_found: true,
              show_result_list: false,
              user_list: [],
            });
          }
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    createChatThread({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.chats
        .createChatThread({
          content: payload.content,
          recipient_id: payload.recipient_id,
          shift_id: payload.shift_id,
        })
        .then((result) => {
          commit('setChatThread', { thread_id: result.data.thread_id });
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('getChatThreads').then(() => result.data.thread_id);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    sendChatThreadMessage({ commit, state, dispatch, rootState }, payload) {
      const messageId = state.thread.new_message_id;
      const newMessage = {
        id: `new${messageId}`,
        content: payload.content,
        sms: payload.sms,
        new_status: 'sending',
        sender_id: rootState.account.user.id,
        ppic_a: rootState.account.user.ppic_a,
        created_at: moment()
          .subtract(rootState.account.utcOffset, 's')
          .format('YYYY-MM-DD HH:mm:ss'),
      };
      commit('setChatThreadNewMessage', { new_message: newMessage });
      return api.chats
        .sendChatThreadMessage({
          content: payload.content,
          sms: payload.sms,
          sender_id: rootState.account.user.id,
          thread_id: state.thread.thread_id,
        })
        .then((result) => {
          commit('deleteChatThreadMessage', { id: `new${messageId}` });
          dispatch('getChatThreadMessages', {
            thread_id: state.thread.thread_id,
            silent: true,
          });
          return result;
        })
        .catch((error) => {
          _.remove(state.thread.messages, { id: `new${messageId}` });
          newMessage.new_status = 'error';
          commit('setChatThreadNewMessage', { new_message: newMessage });
          return dispatch('errorHandler', error, { root: true });
        });
    },
    addUserChatThread({ commit, state, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.chats
        .addUserChatThread({
          thread_id: state.thread.thread_id,
          user_id: payload.user_id,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('getChatThreads', { silent: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    deleteUserChatThread({ commit, state, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.chats
        .deleteUserChatThread({
          thread_id: state.thread.thread_id,
          user_id: payload.user_id,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('getChatThreads', { silent: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    deleteChatThread({ commit, state, dispatch }) {
      commit('setIsLoadingData', true, { root: true });
      return api.chats
        .deleteChatThread({
          thread_id: state.thread.thread_id,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    updateChatThreadSeen({ dispatch }, payload) {
      return api.chats
        .updateChatThreadSeen({
          thread_id: payload.thread_id,
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
  },
};

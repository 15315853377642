<template>
  <f7-popup
    tablet-fullscreen
    :opened="popupOpened"
    @popup:open="onPopupOpen"
    @popup:closed="$emit('sc-calendar-legend-popup:closed')"
  >
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>Calendar Legend</f7-nav-title>
          <template #after-inner>
            <f7-progressbar v-show="isLoadingData" infinite />
          </template>
        </f7-navbar>
        <f7-list no-hairlines>
          <f7-list-item v-for="item in legend" :key="item.id" :title="item.status">
            <template #media>
              <f7-icon material="lens" size="50px" :style="{ color: item.bg_color }" />
            </template>
          </f7-list-item>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
export default {
  components: {},
  props: {
    popupOpened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      legend: [],
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  mounted() {},
  methods: {
    onPopupOpen() {
      if (this.legend.length === 0) {
        this.$store.dispatch('calendar/fetchCalendarLegend').then((result) => {
          if (result) {
            this.legend = result;
          }
        });
      }
    },
  },
};
</script>
<style scoped></style>

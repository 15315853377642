<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Media Files" />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <f7-toolbar bottom>
      <f7-link tab-link="#image" tab-link-active>
        <f7-icon material="collections" />
      </f7-link>
      <f7-link v-if="isVideoEnable" tab-link="#video">
        <f7-icon material="video_library" />
      </f7-link>
      <f7-link tab-link="#document">
        <f7-icon material="attach_file" />
      </f7-link>
      <f7-link tab-link="#form">
        <f7-icon material="assignment" />
      </f7-link>
    </f7-toolbar>
    <f7-tabs>
      <sc-profile-media-image-tab :user-id="userId" />
      <sc-profile-media-video-tab :user-id="userId" />
      <sc-profile-media-document-tab :user-id="userId" />
      <sc-profile-media-form-tab :user-id="userId" />
    </f7-tabs>
  </f7-page>
</template>

<script>
import scProfileMediaImageTab from '../../components/Profile/Media/ProfileMediaImageTab.vue';
import scProfileMediaVideoTab from '../../components/Profile/Media/ProfileMediaVideoTab.vue';
import scProfileMediaDocumentTab from '../../components/Profile/Media/ProfileMediaDocumentTab.vue';
import scProfileMediaFormTab from '../../components/Profile/Media/ProfileMediaFormTab.vue';

export default {
  components: {
    scProfileMediaImageTab,
    scProfileMediaVideoTab,
    scProfileMediaDocumentTab,
    scProfileMediaFormTab,
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isVideoEnable() {
      return (
        Number(this.$store.state.account.settings.profile_video_enable) === 1
      );
    },
    userId() {
      return this.$store.state.account.user.id;
    },
  },
};
</script>

<style scoped>
.sc-media-form {
  height: 0px;
}
</style>

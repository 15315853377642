<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Work Areas" />
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-work-areas"
          icon-material="search"
        />
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-work-areas"
        expandable
        search-container=".search-list-work-areas"
      />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <f7-list
      no-hairlines
      class="no-margin-top search-list-work-areas searchbar-found"
    >
      <f7-list-group v-for="category in work_areas" :key="category.id">
        <f7-list-item :title="category.cname" group-title />
        <f7-list-item
          v-for="area in category.work_areas"
          :key="area.id"
          :checked="area.set === 1"
          :title="area.aname"
          checkbox
          @change="onElementChanged(area.id, $event)"
        />
      </f7-list-group>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    work_areas() {
      return this.$store.state.profile.work_areas;
    },
  },
  created() {
    this.$store.dispatch('profile/getProfileWorkAreas', {
      userId: this.userId,
    });
  },
  methods: {
    onElementChanged(areaId, event) {
      const set = event.target.checked ? 1 : 0;
      this.$store.dispatch('profile/setProfileWorkAreas', {
        workAreaId: areaId,
        set,
        userId: this.userId,
      });
    },
  },
};
</script>

import axios from '../../libs/axios';

export default {
  getProfileStructure(payload) {
    return axios
      .get(`/profile/${payload.user_id}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  updateProfileElement(payload) {
    if (payload.element_id === 'country_name') {
      return axios
        .put(`/user/${payload.user_id}`, { country_id: payload.data })
        .then((response) => response)
        .catch((error) => this.apiErrorHandler(error));
    }
    return axios
      .put(`/profile/${payload.user_id}/${payload.element_id}`, { data: payload.data })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getProfileImages(payload) {
    return axios
      .get(`/profile/${payload.user_id}/photos`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setProfileImage(payload) {
    const data = new FormData();
    data.append('photo[]', payload.image_blob, payload.image_blob.name);
    return axios
      .post(`/profile/${payload.user_id}/photo`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setAsMainProfileImage(payload) {
    return axios
      .put(`/profile/${payload.userId}/photo/${payload.imageId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteProfileImage(payload) {
    return axios
      .delete(`/file/profile_photo/${payload.imageId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getProfileVideos(payload) {
    return axios
      .get(`/profile/${payload.user_id}/videos`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setProfileVideo(payload) {
    const data = new FormData();
    data.append('video[]', payload.video_blob, payload.video_blob.name);
    return axios
      .post(`/profile/${payload.user_id}/video`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setLargeProfileVideoChunk(payload) {
    const data = new FormData();
    data.append('video', payload.video_blob, payload.video_blob.name);
    data.append('part', payload.part);
    data.append('isLastPart', payload.isLastPart);
    return axios
      .post(`/profile/${payload.user_id}/video/chunk`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteProfileVideo(payload) {
    return axios
      .delete(`/file/profile_video/${payload.videoId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getProfileDocuments(payload) {
    return axios
      .get(`/profile/${payload.user_id}/documents`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getProfileForms(payload) {
    return axios
      .get(`/profile/${payload.user_id}/forms`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setProfileDocument(payload) {
    const data = new FormData();
    data.append('document[]', payload.document_blob, payload.document_blob.name);
    return axios
      .post(`/profile/${payload.user_id}/document`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteProfileDocument(payload) {
    return axios
      .delete(`/file/profile_document/${payload.document_id}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getProfileWorkAreas(payload) {
    return axios
      .get(`/profile/${payload.user_id}/workAreas`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setProfileWorkAreas(payload) {
    return axios
      .put(`/profile/${payload.userId}/workArea`, { work_area_id: payload.workAreaId, set: payload.set })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getProfileSkillsQualifications(payload) {
    return axios
      .get(`/profile/${payload.user_id}/attributes`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setProfileSkillsQualifications(payload) {
    return axios
      .put(`/profile/${payload.userId}/attribute`, {
        attribute_id: payload.attributeId,
        set: payload.set,
        expiry: payload.expiryDate,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getSafeInStorePass(payload) {
    return axios
      .get(`/profile/${payload.userId}/sisp`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },
  setSafeInStorePass(payload) {
    return axios
      .put(`/profile/${payload.userId}/sisp`, { number: payload.number, email: payload.email })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchProfileCompletedShifts(payload) {
    return axios
      .get(`/user/${payload.userId}/completedShifts/10/${payload.pageId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchProfileExperience(payload) {
    return axios
      .get(`/profile/${payload.user_id}/experience`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  addProfileExperience(payload) {
    return axios
      .post(`/profile/${payload.userId}/experience`, payload.experience)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  editProfileExperience(payload) {
    return axios
      .put(`/profile/experience/${payload.experience.id}`, payload.experience)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteProfileExperience(payload) {
    return axios
      .delete(`/profile/experience/${payload.experienceId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getProfileAdminNotes(payload) {
    return axios
      .get(`/profile/${payload.user_id}/adminNote`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  addProfileAdminNote(payload) {
    return axios
      .post(`/profile/${payload.user_id}/adminNote`, {
        note: payload.note,
        mentions: payload.mentions,
        type: payload.type,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  editProfileAdminNote(payload) {
    return axios
      .put(`/profile/adminNote/${payload.adminNoteId}`, {
        note: payload.note,
        mentions: payload.mentions,
        type: payload.type,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteProfileAdminNote(payload) {
    return axios
      .delete(`/profile/adminNote/${payload.adminNoteId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchSettingsPaymentSetup(payload) {
    return axios
      .get(`/user/${payload.userId}/xtrm/setup`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  createLinkXTRMAccountSettingsPayment(payload) {
    return axios
      .post(`/user/${payload.userId}/xtrm/setup`, {})
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchSettingsPaymentWallets(payload) {
    return axios
      .get(`/user/${payload.userId}/xtrm/wallets`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchSettingsPaymentСountries() {
    return axios
      .get('/helpers/bank/countries')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchSettingsPaymentСurrencies() {
    return axios
      .get('/helpers/currencies')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  linkBankAccountSettingsPayment(payload) {
    return axios
      .post(`/user/${payload.userId}/xtrm/bank`, {
        bank_account: payload.accountNumber,
        bank_country_code: payload.countryCode,
        bank_name: payload.bankName,
        bank_routing: payload.routingNumber,
        bank_swift: payload.SWIFTCode,
        contact_name: payload.accountName,
        country_code: payload.country,
        currency: payload.currencyCode,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteBankAccountSettingsPayment(payload) {
    return axios
      .delete(`/user/${payload.userId}/xtrm/bank/${payload.bankId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchSettingsOptionsTimezones() {
    return axios
      .get('/helpers/timezones')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchSettingsOptions(payload) {
    return axios
      .get(`/user/${payload.userId}/options`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setTimezoneSettingsOptions(payload) {
    return axios
      .put(`/user/${payload.userId}`, {
        php_tz: payload.timezoneCode,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  setOptionSettingsOptions(payload) {
    return axios
      .put(`/user/${payload.userId}/option`, {
        oname: payload.optionCode,
        set: payload.optionValue,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchProfileInfoСountries() {
    return axios
      .get('/helpers/countries')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchSharedFiles() {
    return axios
      .get('/reportsUploads/shared')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  uploadSharedFiles(payload) {
    const data = new FormData();
    if (payload.files.length > 0) {
      payload.files.forEach((file) => {
        data.append('file[]', file, file.name);
      });
    }
    data.append('folder', 'shared_files');
    return axios
      .post('/reportsUploads/upload', data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteSharedFile(payload) {
    const id = payload.documentId.split(':')[1];
    return axios
      .delete(`/file/file/${id}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  approveUser(payload) {
    return axios
      .put(`/user/${payload.userId}/approve`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  rejectUser(payload) {
    return axios
      .put(`/user/${payload.userId}/reject`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },
};

<template>
  <f7-page :page-content="false" name="shift-detail" bg-color="white">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title :title="shift.title" />
      <f7-nav-right>
        <f7-link
          v-show="isChatEnable"
          :icon-badge="shift.unread_count"
          icon-material="forum"
          badge-color="red"
          icon-only
          @click="onChatClick(shift.thread_id)"
        />
        <f7-link
          v-if="isUserLvlOwnerOrAdmin"
          v-show="curTabId === 'detail'"
          icon-material="more_vert"
          icon-only
          class="sc-shift-detail-actions-button"
          @click="onActionsClick"
        />
        <f7-link
          v-if="isUserLvlOwnerOrAdmin"
          v-show="curTabId === 'roles'"
          icon-only
          icon-material="group_add"
          @click="
            $store.commit('calendar/setCalendarShiftRoleAddEditPopup', {
              isPopupOpened: true,
              type: 'ADD',
            })
          "
        />
        <f7-link
          v-if="isUserLvlOwnerOrAdmin"
          v-show="curTabId === 'document'"
          icon-only
          icon-material="add"
          @click="openFilePicker"
        />
        <f7-link
          v-if="isUserLvlOwnerOrAdmin || isUserTeamLeader"
          v-show="curTabId === 'check-in-out'"
          class="searchbar-enable"
          data-searchbar=".searchbar-check-in-out"
          icon-material="search"
        />
      </f7-nav-right>
      <f7-searchbar :init="false" class="searchbar-check-in-out" expandable />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <f7-toolbar tabbar bottom class="sc-calendar-shift-detail-toolbar">
      <f7-link
        tab-link
        href="./"
        route-tab-id="roles"
        @click="onTabClick('roles')"
      >
        <f7-icon material="group" />
      </f7-link>
      <f7-link
        tab-link
        href="detail/"
        route-tab-id="detail"
        @click="onTabClick('detail')"
      >
        <f7-icon material="info" />
      </f7-link>
      <f7-link
        v-if="isUserLvlOwnerOrAdmin || isUserTeamLeader"
        tab-link
        href="check-in-out/"
        route-tab-id="check-in-out"
        @click="onTabClick('check-in-out')"
      >
        <f7-icon material="transfer_within_a_station" />
      </f7-link>
      <f7-link
        v-if="isUserLvlOwnerOrAdmin || shift.show_files === 1"
        tab-link
        href="document/"
        route-tab-id="document"
        @click="onTabClick('document')"
      >
        <f7-icon material="attach_file" />
      </f7-link>
      <f7-link
        v-if="isUserLvlOwnerOrAdmin"
        tab-link
        href="map/"
        route-tab-id="map"
        @click="onTabClick('map')"
      >
        <f7-icon material="map" />
      </f7-link>
    </f7-toolbar>

    <sc-file-picker
      @sc-file-picker:ready="openFilePicker = $event"
      @sc-file-picker:loaded="onFileLoaded"
    />

    <f7-tabs routable>
      <f7-tab id="roles" />
      <f7-tab id="detail" />
      <f7-tab id="check-in-out" />
      <f7-tab id="document" />
      <f7-tab id="map" />
    </f7-tabs>
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';
import Socket from '../../libs/socket';
import scFilePicker from '../../components/SCFilePicker.vue';

export default {
  components: {
    scFilePicker,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      curTabId: 'roles',
      openFilePicker: () => {},
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    isUserLvlExt() {
      return this.$store.getters['account/isUserLvlExt'];
    },
    isUserTeamLeader() {
      return Number(this.$store.state.calendar.shift.team_leader) === 1;
    },
    shift() {
      return this.$store.state.calendar.shift;
    },
    shiftWebSocket() {
      return this.$store.state.calendar.shiftWebSocket;
    },
    isPublished() {
      return Number(this.$store.state.calendar.shift.live) === 1;
    },
    isBooking() {
      return Number(this.shift.shift_status_id) === 1;
    },
    isChatEnable() {
      return Number(this.$store.state.account.settings.chat_enable) === 1 
        && this.shift.thread_id !== null 
        && !this.isBooking
        && !this.isUserLvlExt;
    },
  },

  beforeMount() {
    const senderId = this.$store.state.account.user.id;
    this.$store.commit(
      'calendar/setCalendarShiftWebSocket',
      new Socket({ onMessage: this.onSocketMessage, senderId })
    );
    this.shiftWebSocket.sendData(
      JSON.stringify({
        type: 'shift',
        payload: {
          action: 'shiftOpen',
          shift: this.f7route.params.shift_id,
          user: senderId,
        },
      })
    );
  },

  beforeUnmount() {
    const senderId = this.$store.state.account.user.id;
    this.shiftWebSocket.sendData(
      JSON.stringify({
        type: 'shift',
        payload: {
          action: 'shiftClose',
          shift: this.f7route.params.shift_id,
          user: senderId,
        },
      })
    );
    this.$store.commit('calendar/closeCalendarShiftWebSocket');
  },

  methods: {
    onTabClick(tabId) {
      this.curTabId = tabId;
    },
    onChatClick(threadId) {
      if (threadId == 0) {
        f7.dialog.prompt('', 'New Message', (message) => {
          this.$store
            .dispatch('chats/createChatThread', {
              content: message,
              shift_id: this.f7route.params.shift_id,
            })
            .then((newThreadId) => {
              if (newThreadId !== false) {
                this.f7router.navigate(`/chats/${newThreadId}/`);
              }
            });
        });
      } else {
        this.$store
          .dispatch('chats/getShiftChatThread', { threadId })
          .then(() => {
            this.f7router.navigate(`/chats/${threadId}/`);
          });
      }
    },
    onFileLoaded(file) {
      this.$store.dispatch('calendar/addCalendarShiftFile', {
        document_blob: file.blob,
      });
    },
    onActionsClick() {
      const buttons = [
        {
          id: 'edit',
          text: 'Edit',
          onClick: () =>
            this.$store.commit('calendar/setCalendarShiftAddEditPopup', {
              isPopupOpened: true,
              type: 'EDIT',
            }),
          icon: '<i class="icon material-icons color-gray">edit</i>',
        },
        {
          id: 'isPublished',
          text: this.isPublished ? 'Unpublish' : 'Publish',
          onClick: () =>
            this.$store
              .dispatch('calendar/publishCalendarShift')
              .then((result) => {
                f7.toast
                  .create({
                    text: result.data.message,
                    closeTimeout: 2000,
                    closeButton: true,
                    closeButtonText: 'Close',
                    closeButtonColor: 'blue',
                  })
                  .open();
              }),
          icon: `<i class="icon material-icons color-gray">${
            this.isPublished ? 'visibility_off' : 'visibility'
          }</i>`,
        },
        {
          id: 'delete',
          text: 'Delete',
          onClick: () =>
            this.$store.dispatch('calendar/deleteCalendarShift').then(() => {
              this.f7router.back();
            }),
          icon: '<i class="icon material-icons color-gray">delete</i>',
        },
      ];
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        buttons,
        targetEl: '.sc-shift-detail-actions-button',
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
      });
      actionSheet.open();
    },
    onSocketMessage(payload) {
      const res = JSON.parse(payload);
      if (
        !_.isNil(res.type) &&
        res.type === 'shiftUpdated' &&
        res.data.shift === Number(this.f7route.params.shift_id)
      ) {
        this.$store.dispatch('calendar/getCalendarShift', {
          shift_id: this.f7route.params.shift_id,
        });
      }
    },
    // onRefresh() {
    //   this.$store.dispatch('getCalendarShift', { shift_id: this.f7route.params.shift_id }).then(() => {
    //     if (this.shift.lat && this.shift.lon) {
    //       this.map.setCenter({lat: this.shift.lat, lng: this.shift.lon});
    //       const marker = new google.maps.Marker({
    //         position: {lat: this.shift.lat, lng: this.shift.lon},
    //         map: this.map
    //       });
    //     }
    //   });
    // }
  },
};
</script>
<style scoped>
::v-deep(.sc-calendar-shift-detail-toolbar .toolbar-inner) {
  justify-content: space-around;
}
</style>

import _ from 'lodash';
import api from '../../api';

export default {
  namespaced: true,
  state: {
    usersList: [],
    usersFilter: [],
    usersTypeFilter: [],
    fetchUsersFilter: ['utype:=:all'],
    pageId: 0,
    usersOnPage: 20,
    showLoadMore: false,
    loading: false,
  },
  getters: {},
  mutations: {
    setUsersList(state, payload) {
      const union = _.unionBy(state.usersList, payload.usersList, 'id');
      state.usersList = union;
    },
    clearUsersList(state) {
      state.usersList = [];
      state.pageId = 0;
    },
    setPageId(state, payload) {
      state.pageId = payload;
    },
    setShowLoadMore(state, payload) {
      state.showLoadMore = payload;
    },
    setUsersFilter(state, payload) {
      state.usersFilter = payload;
    },
    setUsersTypeFilter(state, payload) {
      state.usersTypeFilter = payload;
    },
    setFetchUsersFilter(state, payload) {
      state.fetchUsersFilter = payload;
    },
    setUsersLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    fetchUsersList({ state, commit, dispatch }, payload) {
      commit('setUsersLoading', true);
      if (!_.get(payload, 'silent')) {
        commit('setIsLoadingData', true, { root: true });
      }
      if (_.get(payload, 'nextPage')) {
        commit('setPageId', state.pageId + 1);
      }
      if (_.get(payload, 'filter')) {
        commit('clearUsersList');
      }
      return api.users
        .fetchUsersList({
          pageId: state.pageId,
          usersOnPage: state.usersOnPage,
          filter: encodeURI(JSON.stringify(state.fetchUsersFilter)),
        })
        .then((result) => {
          if (result.data.data.length > 0) {
            commit('setUsersList', { usersList: result.data.data });
          }
          commit('setIsLoadingData', false, { root: true });
          commit('setUsersLoading', false);
          if (Math.ceil(Number(result.data.total_counts) / Number(result.data.page_size)) > state.pageId + 1) {
            commit('setShowLoadMore', true);
          } else {
            commit('setShowLoadMore', false);
          }
          return result;
        })
        .catch((error) => {
          commit('setUsersLoading', false);
          dispatch('errorHandler', error, { root: true });
        });
    },

    fetchUsersFilter({ commit, dispatch }) {
      return api.users
        .fetchUsersFilter()
        .then((result) => {
          if (result.data.length > 0) {
            commit('setUsersFilter', result.data);
          }
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchUsersTypeFilter({ commit, dispatch }) {
      return api.users
        .fetchUsersTypeFilter()
        .then((result) => {
          if (result.data.length > 0) {
            commit('setUsersTypeFilter', result.data);
          }
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchOwnerAdminUsersList({ dispatch }) {
      return api.users
        .fetchUsersList({
          pageId: 0,
          usersOnPage: 500,
          filter: encodeURI(JSON.stringify(['utype:=:owner_admin'])),
        })
        .then((result) => result.data.data)
        .catch((error) => {
          dispatch('errorHandler', error, { root: true });
        });
    },
  },
};

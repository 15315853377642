<template>
  <f7-page-content>
    <f7-list no-hairlines>
      <f7-list-input
        outline
        floating-label
        label="First Name"
        required
        validate
        clear-button
        type="text"
        :value="firstName"
        @input="firstName = $event.target.value"
      />
      <f7-list-input
        outline
        floating-label
        label="Last Name"
        required
        validate
        clear-button
        type="text"
        :value="lastName"
        @input="lastName = $event.target.value"
      />
      <f7-list-input
        outline
        floating-label
        label="E-mail"
        required
        validate
        clear-button
        type="email"
        :value="email"
        @input="email = $event.target.value"
      />
      <f7-list-input
        outline
        floating-label
        label="Password"
        required
        validate
        clear-button
        type="password"
        :minlength="7"
        :value="password"
        @input="password = $event.target.value"
      />
      <f7-list-input
        outline
        floating-label
        label="Mobile Number"
        required
        validate
        clear-button
        type="tel"
        :value="mobileNumber"
        @input="mobileNumber = $event.target.value"
      />
    </f7-list>
    <f7-row class="margin justify-content-flex-end">
      <f7-button :disabled="isLoadingData" raised fill @click="registerNewUser">
        Save & Continue
      </f7-button>
    </f7-row>
  </f7-page-content>
</template>
<script>
export default {
  props: {
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      mobileNumber: '',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  methods: {
    registerNewUser() {
      this.$store
        .dispatch('account/registerNewUser', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          mobileNumber: this.mobileNumber,
        })
        .then((result) => {
          if (result) {
            this.f7router.refreshPage();
          }
        });
    },
  },
};
</script>

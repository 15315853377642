import _ from 'lodash';
import moment from 'moment';
import api from '../../api';

export default {
  namespaced: true,
  state: {
    payrolls: [],
    payroll: undefined,
    payrollActivityHistory: {
      data: [],
      pageId: 0,
      itemsOnPage: 20,
    },
    payrollAdminNotes: [],
    fetchFilter: {
      payrollType: 'all', // 'approved', 'processing', 'paid', 'unpaid', 'rejected'
      selectedUsers: [],
      dateFrom: [],
      dateTo: [],
    },
    pageId: 0,
    itemsOnPage: 20,
    wallets: [],
  },
  getters: {
    getPayrollAdminNoteById: (state) => (id) =>
      _.find(state.payrollAdminNotes, { id }),
  },
  mutations: {
    setPayrolls(state, payload) {
      const union = _.unionBy(state.payrolls, payload, 'id');
      state.payrolls = union;
    },
    setPayroll(state, payload) {
      state.payroll = payload;
    },
    setPayrollActivityHistory(state, payload) {
      const union = _.union(state.payrollActivityHistory.data, payload);
      state.payrollActivityHistory.data = union;
    },
    setPayrollActivityHistoryPageId(state, payload) {
      if (payload === undefined) {
        state.payrollActivityHistory.pageId = 0;
        state.payrollActivityHistory.data = [];
      } else {
        state.payrollActivityHistory.pageId = payload;
      }
    },
    setPayrollAdminNotes(state, payload) {
      state.payrollAdminNotes = payload;
    },
    setPageId(state, payload) {
      state.pageId = payload;
    },
    setFetchFilter(state, payload) {
      state.payrolls = [];
      state.pageId = 0;
      if (payload === undefined) {
        state.fetchFilter.payrollType = 'all';
        state.fetchFilter.selectedUsers = [];
        state.fetchFilter.dateFrom = [];
        state.fetchFilter.dateTo = [];
      } else {
        state.fetchFilter.payrollType = payload.payrollType;
        state.fetchFilter.selectedUsers = payload.selectedUsers;
        state.fetchFilter.dateFrom = payload.dateFrom;
        state.fetchFilter.dateTo = payload.dateTo;
      }
    },
    updatePayrolls(state, payload) {
      Object.keys(payload.statuses).forEach((id) => {
        _.find(state.payrolls, { id: Number(id) }).status =
          payload.statuses[id];
      });
      Object.keys(payload.actions).forEach((id) => {
        _.find(state.payrolls, { id: Number(id) }).actions =
          payload.actions[id];
      });
    },
    updatePayroll(state, payload) {
      _.find(state.payrolls, { id: payload.payrollId }).status = payload.status;
      _.find(state.payrolls, { id: payload.payrollId }).actions =
        payload.actions;
    },
    setWallets(state, payload) {
      state.wallets = payload;
    },
  },
  actions: {
    fetchPayrolls({ state, commit, dispatch }, payload) {
      if (!_.get(payload, 'silent', false)) {
        commit('setIsLoadingData', true, { root: true });
      }
      let fetchFilter = [];
      if (state.fetchFilter.selectedUsers.length > 0) {
        fetchFilter = state.fetchFilter.selectedUsers.map(
          (user) => `user:${user.id}`
        );
      }
      if (state.fetchFilter.dateFrom[0]) {
        fetchFilter.push(
          `from:${moment(state.fetchFilter.dateFrom[0], 'MMM DD YYYY').format(
            'YYYY-MM-DD'
          )}`
        );
      }
      if (state.fetchFilter.dateTo[0]) {
        fetchFilter.push(
          `to:${moment(state.fetchFilter.dateTo[0], 'MMM DD YYYY').format(
            'YYYY-MM-DD'
          )}`
        );
      }
      return api.payrolls
        .fetchPayrolls({
          pageId: state.pageId,
          itemsOnPage: state.itemsOnPage,
          payrollType: state.fetchFilter.payrollType,
          fetchFilter:
            fetchFilter.length > 0
              ? encodeURI(JSON.stringify(fetchFilter))
              : '',
        })
        .then((result) => {
          if (result.data.data.length > 0) {
            commit('setPayrolls', result.data.data);
            commit('setPageId', state.pageId + 1);
          }
          commit('setIsLoadingData', false, { root: true });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchPayroll({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .fetchPayroll({
          payrollId: payload.payrollId,
        })
        .then((result) => {
          commit('setPayroll', result.data);
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchPayrollActivityHistory({ state, commit, dispatch }, payload) {
      if (!_.get(payload, 'silent', false)) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.payrolls
        .fetchPayrollActivityHistory({
          payrollId: payload.payrollId,
          pageId: state.payrollActivityHistory.pageId,
          itemsOnPage: state.payrollActivityHistory.itemsOnPage,
        })
        .then((result) => {
          if (result.data.length > 0) {
            commit('setPayrollActivityHistory', result.data);
            commit(
              'setPayrollActivityHistoryPageId',
              state.payrollActivityHistory.pageId + 1
            );
          }
          commit('setIsLoadingData', false, { root: true });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchPayrollAdminNotes({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .fetchPayrollAdminNotes({
          payrollId: payload.payrollId,
        })
        .then((result) => {
          commit('setPayrollAdminNotes', result.data);
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => {
          dispatch('errorHandler', error, { root: true });
        });
    },

    fetchUsers({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .fetchUsers({
          query: payload.query,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    groupPayrollActions({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .groupPayrollActions({
          actionType: api.payrolls.mapPayrollActions(payload.actionType),
          ids: payload.ids,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          if (
            result.data.errors !== undefined &&
            !(result.data.errors instanceof Array)
          ) {
            const errorMessage = Object.keys(result.data.errors).reduce(
              (errMsg, id) => `${errMsg}\n${result.data.errors[id]}`,
              ''
            );
            commit(
              'setError',
              {
                showError: true,
                errorMessage,
              },
              { root: true }
            );
          }
          if (result.data.statuses !== undefined) {
            commit('updatePayrolls', {
              statuses: result.data.statuses,
              actions: result.data.actions,
            });
          }
          return result.data;
        })
        .catch((error) => {
          dispatch('errorHandler', error, { root: true });
        });
    },
    singlePayrollActions({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .singlePayrollActions({
          actionType: api.payrolls.mapPayrollActions(payload.actionType),
          payrollId: payload.payrollId,
          reason: payload.reason,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          if (result.data.status !== undefined) {
            commit('updatePayroll', {
              payrollId: payload.payrollId,
              status: result.data.status,
              actions: result.data.actions,
            });
          }

          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    addPayrollAdminNote({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .addPayrollAdminNote({
          payrollId: payload.payrollId,
          note: payload.note,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('fetchPayrollAdminNotes', {
            payrollId: payload.payrollId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    editPayrollAdminNote({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .editPayrollAdminNote({
          adminNoteId: payload.adminNoteId,
          note: payload.note,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('fetchPayrollAdminNotes', {
            payrollId: payload.payrollId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deletePayrollAdminNote({ dispatch }, payload) {
      return api.payrolls
        .deletePayrollAdminNote({
          adminNoteId: payload.adminNoteId,
        })
        .then(() => {
          return dispatch('fetchPayrollAdminNotes', {
            payrollId: payload.payrollId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchTrackingOptions({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .fetchTrackingOptions({
          query: payload.query,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    generatePayrollByType({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .generatePayrollByType({
          payrollType: payload.payrollType,
          dateFrom: moment(payload.dateFrom[0], 'MMM DD YYYY').format(
            'YYYY-MM-DD'
          ),
          dateTo: moment(payload.dateTo[0], 'MMM DD YYYY').format('YYYY-MM-DD'),
          сompletedOnly: payload.сompletedOnly ? 1 : 0,
          approvedPayItemsOnly: payload.approvedPayItemsOnly ? 1 : 0,
          selectedTrackingOptionId:
            payload.selectedTrackingOptions.length > 0
              ? payload.selectedTrackingOptions[0].id
              : 0,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    generatePayrollForStaff({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .generatePayrollForStaff({
          dateFrom: moment(payload.dateFrom[0], 'MMM DD YYYY').format(
            'YYYY-MM-DD'
          ),
          dateTo: moment(payload.dateTo[0], 'MMM DD YYYY').format('YYYY-MM-DD'),
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    generatePayrollSave({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .generatePayrollSave({
          payrollType: payload.payrollType,
          dateFrom: moment(payload.dateFrom[0], 'MMM DD YYYY').format(
            'YYYY-MM-DD'
          ),
          dateTo: moment(payload.dateTo[0], 'MMM DD YYYY').format('YYYY-MM-DD'),
          сompletedOnly: payload.сompletedOnly ? 1 : 0,
          approvedPayItemsOnly: payload.approvedPayItemsOnly ? 1 : 0,
          payrollItems: payload.payrollItems,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          commit('setFetchFilter'); // for reset filter
          dispatch('fetchPayrolls');
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    generatePayrollSaveForStaff({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .generatePayrollSaveForStaff({
          dateFrom: moment(payload.dateFrom[0], 'MMM DD YYYY').format(
            'YYYY-MM-DD'
          ),
          dateTo: moment(payload.dateTo[0], 'MMM DD YYYY').format('YYYY-MM-DD'),
          payrollItems: payload.payrollItems,
          invoiceCombine: payload.invoiceCombine,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          commit('setFetchFilter'); // for reset filter
          dispatch('fetchPayrolls');
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchXeroDates({ commit, dispatch }) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .fetchXeroDates()
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchWallets({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .fetchWallets({
          userId: payload.userId,
        })
        .then((result) => {
          commit('setWallets', result.data);
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchWithdrawCode({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.payrolls
        .fetchWithdrawCode({
          userId: payload.userId,
          walletId: payload.walletId,
          amount: payload.amount,
          email: payload.email,
          withdrawMethod: payload.withdrawMethod,
          bankId: payload.bankId,
          otp: payload.otp,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
  },
};

<template>
  <f7-popup tablet-fullscreen :opened="isOpened" @popup:open="onOpen" @popup:closed="onClosed">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close />
        </f7-nav-left>
        <f7-nav-title>{{ actionType == 'ADD' ? 'Add' : 'Edit' }} Admin Note</f7-nav-title>
        <template #after-inner>
          <f7-progressbar v-show="isLoadingData" infinite />
        </template>
      </f7-navbar>

      <f7-list no-hairlines class="no-margin-bottom">
        <f7-list-input
          outline
          floating-label
          label="Note Type"
          type="select"
          default-value="info"
          :value="noteType"
          @change="noteType = $event.target.value"
        >
          <option value="info">Info</option>
          <option value="interview">Interview</option>
          <option value="positive">Positive</option>
          <option value="negative">Negative</option>
          <option value="system">System</option>
        </f7-list-input>

        <!-- <f7-list-input
          outline
          floating-label
          label="Note"
          type="textarea"
          :value="noteText"
          @input="noteText = $event.target.value"
        /> -->
      </f7-list>
      <sc-mentions-textarea :init-text="noteText" @sc-mentions-textarea:changed="onMentionsTextareaChanged" />
      <f7-block>
        <f7-button raised fill :disabled="isAddDisabled" @click="onAddClick">
          {{ actionType == 'ADD' ? 'Add' : 'Save' }}
        </f7-button>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import scMentionsTextarea from '../../../components/SCMentionsTextarea/SCMentionsTextarea.vue';
import { f7 } from 'framework7-vue';
import { nextTick } from 'vue';

export default {
  components: {
    scMentionsTextarea,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    adminNoteId: {
      type: Number,
      required: false,
      default: undefined,
    },
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      noteText: '',
      mentions: [],
      noteType: 'info',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    userId() {
      return Number(this.f7route.params.user_id);
    },
    adminNote() {
      return this.$store.getters['profile/getAdminNoteById'](this.adminNoteId);
    },
    isAddDisabled() {
      return this.isLoadingData || this.noteText === '';
    },
  },
  methods: {
    onAddClick() {
      let result;
      if (this.actionType === 'ADD') {
        result = this.$store.dispatch('profile/addProfileAdminNote', {
          userId: this.userId,
          note: this.noteText,
          mentions: this.mentions,
          type: this.noteType,
        });
      } else if (this.actionType === 'EDIT') {
        result = this.$store.dispatch('profile/editProfileAdminNote', {
          userId: this.userId,
          adminNoteId: this.adminNoteId,
          note: this.noteText,
          mentions: this.mentions,
          type: this.noteType,
        });
      }
      result.then((result) => {
        if (result !== false) {
          f7.popup.close();
        }
      });
    },
    onClosed() {
      this.noteText = '';
      this.noteType = 'info';
      this.$emit('sc-profile-info-add-edit-admin-note-popup:closed');
    },
    async onOpen() {
      if (this.actionType === 'EDIT' && this.adminNote) {
        console.dir(this.adminNote);
        await nextTick();
        this.noteText = this.adminNote.note;
        this.noteType = this.adminNote.type;
      }
    },
    onMentionsTextareaChanged(payload) {
      this.noteText = payload.noteText;
      this.mentions = payload.mentions;
    },
  },
};
</script>

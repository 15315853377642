<template>
  <f7-page-content>
    <sc-file-picker
      @sc-file-picker:ready="openFilePicker = $event"
      @sc-file-picker:loaded="onFileLoaded"
    />
    <template v-if="forms.length > 0">
      <f7-block>
        <p class="text-color-gray">Forms available to complete</p>
      </f7-block>
      <f7-list no-hairlines>
        <f7-list-item
          v-for="form in forms"
          :key="form.id"
          :title="form.fname"
          link
          @click="onClick(form.id)"
        />
      </f7-list>
    </template>

    <f7-block
      class="display-flex justify-content-space-between sc-align-items-baseline"
    >
      <p class="text-color-gray">{{ infoMessage }}</p>
      <f7-button @click="openFilePicker">
        <f7-icon material="library_add" />
      </f7-button>
    </f7-block>
    <f7-block class="sc-doc-gallery">
      <div
        v-for="document in documents"
        :key="document.id"
        class="card sc-doc-card"
      >
        <div class="card-content">
          <a @click="onExternalLinkClick(document.path)">
            <img :src="document.thumbnail" width="50%" />
          </a>
          <p>{{ document.oname }}</p>
        </div>
        <div class="card-footer">
          <div>{{ new Date(document.created_at).toDateString() }}</div>
          <f7-button
            v-if="document.delete"
            color="gray"
            icon-material="delete"
            @click="onDeleteClick(document.id)"
          />
        </div>
      </div>
    </f7-block>

    <f7-row class="margin justify-content-space-between">
      <f7-button @click="registerBackStep"> Back </f7-button>
      <f7-button
        :disabled="isLoadingData"
        raised
        fill
        @click="registerNextStep"
      >
        Save & Continue
      </f7-button>
    </f7-row>
    <sc-profile-media-form-connect-popup
      :is-popup-opened="isPopupOpened"
      :form-id="formId"
      :user-id="userId"
      @closed="onClosed"
    />
  </f7-page-content>
</template>
<script>
import { f7 } from 'framework7-vue';

import scProfileMediaFormConnectPopup from '../../components/Profile/Media/Form/ProfileMediaFormConnectPopup.vue';
import scFilePicker from '../../components/SCFilePicker.vue';

export default {
  components: {
    scProfileMediaFormConnectPopup,
    scFilePicker,
  },
  data() {
    return {
      isPopupOpened: false,
      formId: undefined,
      openFilePicker: () => {},
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    infoMessage() {
      return this.$store.state.account.settings.profile_doc_message;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    forms() {
      return this.$store.state.profile.forms;
    },
    documents() {
      return this.$store.state.profile.documents;
    },
  },
  beforeCreate() {
    const userId = this.$store.state.account.user.id;
    this.$store.dispatch('profile/getProfileForms', { userId });
    this.$store.dispatch('profile/getProfileDocuments', { userId });
  },
  methods: {
    registerNextStep() {
      this.$store.dispatch('account/registerNextStep');
    },
    registerBackStep() {
      this.$store.commit('account/setRegStepNum', 5);
    },
    onClick(formId) {
      this.formId = formId;
      this.isPopupOpened = true;
    },
    onClosed() {
      this.formId = undefined;
      this.isPopupOpened = false;
    },
    onExternalLinkClick(link) {
      window.open(link, '_system');
    },
    onDeleteClick(documentId) {
      f7.dialog.confirm(
        "This document will be deleted and you won't be able to find it anymore.",
        'Delete the document?',
        () => {
          this.$store.dispatch('profile/deleteProfileDocument', {
            document_id: documentId,
          });
        }
      );
    },
    onFileLoaded(file) {
      this.$store.dispatch('profile/setProfileDocument', {
        document_blob: file.blob,
        userId: this.userId,
      });
    },
  },
};
</script>
<style scoped>
.sc-doc-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sc-doc-card {
  width: 44%;
  max-width: 200px;
  min-width: 120px;
}
::v-deep(.sc-doc-card .card-content) {
  min-height: 120px;
  text-align: center;
}
::v-deep(.sc-doc-card .card-content img) {
  width: 50%;
  display: block;
  margin: 15px auto;
}
::v-deep(.sc-doc-card .card-content p) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}
::v-deep(.sc-doc-card .card-footer .button) {
  max-width: 30px;
  min-width: 30px;
  padding: 0 0 0 5px;
}
</style>

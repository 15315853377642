<template>
  <form class="sc-file-picker">
    <input ref="file" type="file" @change="onFileInputChange" />
  </form>
</template>
<script>
import moment from 'moment';
import { f7 } from 'framework7-vue';

export default {
  data() {
    return {
      file: {
        blob: null,
        name: '',
        type: '',
        createdAt: '',
        src: '',
      },
    };
  },
  mounted() {
    this.$emit('sc-file-picker:ready', this.openFilePicker);
  },
  methods: {
    openFilePicker() {
      if (f7.device.desktop || f7.device.ios) {
        this.$refs.file.click();
      } else {
        const dialog = f7.dialog.create({
          title: 'Add File',
          destroyOnClose: true,
          closeByBackdropClick: true,
          buttons: [
            {
              text: 'Take a Picture',
              onClick: () => {
                dialog.close(false);
                this.openCamera('CAMERA');
              },
            },
            {
              text: 'Choose from Library',
              onClick: () => {
                dialog.close(false);
                this.$refs.file.click();
              },
            },
          ],
          verticalButtons: true,
        });
        dialog.open();
      }
    },
    onFileInputChange() {
      const file = this.$refs.file.files[0];
      if (file !== undefined) {
        this.file.blob = file;
        this.file.name = file.name;
        this.file.type = file.type.split('/')[0];
        this.file.createdAt = moment(file.lastModified).format('lll');
        this.file.src = '';
        const reader = new FileReader();
        reader.onload = (e) => {
          this.file.src = e.target.result;
          this.$emit('sc-file-picker:loaded', this.file);
        };
        if (this.file.type === 'image') {
          this.$emit('sc-file-picker:loading');
          reader.readAsDataURL(file);
        } else {
          this.$emit('sc-file-picker:loaded', this.file);
        }
      }
    },
    openCamera(sourceType) {
      this.$emit('sc-file-picker:loading');
      navigator.camera.getPicture(
        (imageData) => {
          // onSuccess
          this.file.type = 'image';
          this.file.src = `data:image/jpeg;base64,${imageData}`;
          this.file.blob = this.dataURItoBlob(this.file.src);
          this.$emit('sc-file-picker:loaded', this.file);
        },
        (message) => {
          // onError
          this.$emit('sc-file-picker:loaded', null);
          f7.toast
            .create({
              text: message,
              closeTimeout: 2000,
              closeButton: true,
              closeButtonText: 'Close',
              closeButtonColor: 'red',
            })
            .open();
        },
        {
          sourceType: Camera.PictureSourceType[sourceType],
          destinationType: Camera.DestinationType.DATA_URL,
          mediaType: Camera.MediaType.PICTURE,
        }
      );
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      const byteString = atob(dataURI.split(',')[1]);
      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        // eslint-disable-line
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
  },
};
</script>

<style scoped>
.sc-file-picker {
  height: 0px;
  visibility: hidden;
  position: absolute;
  top: -100px;
}
</style>

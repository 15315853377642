<template>
  <f7-popup tablet-fullscreen class="sc-payrolls-filter-users-select" @popup:opened="onPopupOpened">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close=".sc-payrolls-filter-users-select" />
        </f7-nav-left>
        <f7-nav-title title="Select Users" />
        <f7-nav-right>
          <f7-link popup-close=".sc-payrolls-filter-users-select" @click="onDoneClick">DONE</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-searchbar :custom-search="true" @searchbar:search="onSearch" @searchbar:clear="onClear" />
      <f7-progressbar v-show="isLoadingData" infinite />
      <f7-list v-show="selected.length > 0" class="no-margin">
        <f7-list-group>
          <f7-list-item title="Selected" group-title />
          <f7-list-item
            v-for="user in selected"
            :key="user.id"
            :title="user.name"
            :value="user.id"
            checkbox
            checked
            @change="onChange(user.id, $event)"
          />
        </f7-list-group>
      </f7-list>
      <f7-list v-show="showNotFound" class="no-margin-top">
        <f7-list-item title="Nothing found" />
      </f7-list>
      <f7-list v-show="showResultList" class="no-margin-top">
        <f7-list-group>
          <f7-list-item v-show="users.length > 0" title="Found" group-title />
          <f7-list-item
            v-for="user in users"
            :key="user.id"
            :title="user.name"
            :checked="isSelected(user.id)"
            :value="user.id"
            checkbox
            @change="onChange(user.id, $event)"
          />
        </f7-list-group>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    selectedUsers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      users: [],
      selected: this.selectedUsers,
      query: '',
      showResultList: true,
      showNotFound: false,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  methods: {
    isSelected(userId) {
      return _.find(this.selected, { id: userId }) !== undefined;
    },

    onChange(userId, event) {
      if (event.target.checked) {
        const selectedUser = _.find(this.users, { id: Number(userId) });
        this.selected = _.union(this.selected, [selectedUser], 'id');
      } else {
        this.selected = _.filter(this.selected, (user) => user.id !== Number(userId));
      }
    },
    onSearch(searchbar, query) {
      if (query) {
        this.query = query;
        this.fetchUsers();
      }
    },
    fetchUsers: _.debounce(function fetchCalendarShiftRoleSurvey() {
      this.$store.dispatch('payrolls/fetchUsers', { query: this.query }).then((result) => {
        this.users = result;
        this.showNotFound = false;
        this.showResultList = true;
        if (this.users.length < 1) {
          this.showNotFound = true;
          this.showResultList = false;
        }
      });
    }, 500),
    onClear() {
      this.showNotFound = false;
      this.showResultList = true;
      this.users = [];
    },
    onDoneClick() {
      this.$emit('sc-payrolls-filter-users-select:selected', this.selected);
    },
    onPopupOpened() {
      this.selected = this.selectedUsers;
    },
  },
};
</script>
<style scoped></style>

import _ from 'lodash';
import api from '../../api';
import Framework7 from 'framework7/lite-bundle';

export default {
  namespaced: true,
  state: {
    login: '',
    utcOffset: 0,
    isLoggedIn: false,
    accessToken: '',
    refreshToken: '',
    tokenType: '',
    expiresIn: '',
    user: {},
    settings: {},
    permissions: {},
    firebaseDevice: '',
    formconnectToken: {},
    quizconnectToken: {},
    requiredForms: [],
    regStepNum: 1,
  },
  getters: {
    isUserLvlOwnerOrAdmin(state) {
      return state.user.lvl === 'owner' || state.user.lvl === 'admin';
    },
    isUserLvlStaff(state) {
      return state.user.lvl === 'staff';
    },
    isUserLvlClient(state) {
      return state.user.lvl === 'client';
    },
    isUserLvlRegistrant(state) {
      return state.user.lvl && state.user.lvl.indexOf('registrant') !== -1;
    },
    isUserLvlExt(state) {
      return state.user.lvl === 'ext';
    },
  },
  mutations: {
    setUtcOffset(state, payload) {
      state.utcOffset = payload.utcOffset;
    },
    setLogin(state, payload) {
      state.login = payload;
    },
    setAccount(state, payload) {
      state.isLoggedIn = payload.isLoggedIn || false;
      state.accessToken = payload.accessToken || '';
      state.expiresIn = payload.expiresIn || '';
      state.refreshToken = payload.refreshToken || '';
      state.tokenType = payload.tokenType || '';
      state.user = payload.user || {};
      state.settings = payload.settings || {};
      state.permissions = payload.permissions || {};
      state.formconnectToken = payload.formconnect || {};
      state.quizconnectToken = payload.quizconnect || {};
      state.requiredForms = payload.requiredForms || [];
      state.steps = payload.steps || {};
    },
    setUserData(state, payload) {
      state.user = payload;
    },
    refreshAccountTokens(state, payload) {
      state.isLoggedIn = payload.isLoggedIn || false;
      state.tokenType = payload.tokenType || '';
      state.accessToken = payload.accessToken || '';
      state.expiresIn = payload.expiresIn || '';
      state.refreshToken = payload.refreshToken || '';
    },
    setFirebaseDevice(state, firebaseDevice = '') {
      state.firebaseDevice = firebaseDevice;
    },
    setFormConnectToken(state, payload) {
      state.formconnectToken = payload;
    },
    setQuizConnectToken(state, payload) {
      state.quizconnectToken = payload;
    },
    setRequiredForms(state, requiredForms = []) {
      state.requiredForms = requiredForms;
    },
    setRegStepNum(state, regStepNum = 1) {
      state.regStepNum = regStepNum;
    },
  },

  actions: {
    changeUserProfilePhoto({ commit }, payload) {
      const account = api.account.getAccountFromLocalStorage();
      account.user.ppic_a = payload.imageUrl;
      commit('setUserData', account.user);
      api.account.setAccountToLocalStorage(account);
    },
    changeUserTimezone({ commit }, payload) {
      const account = api.account.getAccountFromLocalStorage();
      account.user.php_tz = payload.timezoneCode;
      commit('setUserData', account.user);
      api.account.setAccountToLocalStorage(account);
    },
    loadAccountFromStorage({ commit, dispatch, state }) {
      let resultPromise = Promise.resolve();
      const account = api.account.getAccountFromLocalStorage();
      if (Framework7.device.ios || Framework7.device.android) {
        commit(
          'setFirebaseDevice',
          api.account.getFirebaseDeviceFromLocalStorage()
        );
      }
      if (account && account.access_token) {
        commit('setAccount', {
          isLoggedIn: false,
          accessToken: account.access_token,
          expiresIn: account.expires_in,
          refreshToken: account.refresh_token,
          tokenType: account.token_type,
          user: account.user,
          settings: account.settings,
          permissions: account.permissions,
          steps: account.steps,
        });
        api.account.setAuthorizationHeader({
          token_type: account.token_type,
          access_token: account.access_token,
        });
        resultPromise = api.account
          .refreshToken({
            refreshToken: state.refreshToken,
          })
          .then((result) => {
            commit('setRegStepNum', 2); // for lvl 'registrant*'
            commit('refreshAccountTokens', {
              isLoggedIn: true,
              tokenType: result.data.token_type,
              accessToken: result.data.access_token,
              expiresIn: result.data.expires_in,
              refreshToken: result.data.refresh_token,
            });
            dispatch('getQuizConnectToken');
            dispatch('getFormConnectToken').then(() => {
              dispatch('getRequiredForms');
            });
          })
          .catch((error) => {
            dispatch('errorHandler', error, { root: true });
          });
      }
      return resultPromise;
    },

    fetchIdentify({ dispatch }) {
      return api.account
        .fetchIdentify()
        .then((result) => result.data)
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    login({ commit, dispatch, getters }, payload) {
      commit('setIsLoadingData', true, { root: true });
      commit('setLogin', payload.email);
      return api.account
        .login(payload)
        .then((result) => {
          commit('setRegStepNum', 2); // for lvl 'registrant*'
          commit('setAccount', {
            isLoggedIn: true,
            accessToken: result.data.access_token,
            expiresIn: result.data.expires_in,
            refreshToken: result.data.refresh_token,
            tokenType: result.data.token_type,
            user: result.data.user,
            settings: result.data.settings,
            permissions: result.data.permissions,
            formconnect: result.data.formconnect,
            quizconnect: result.data.quizconnect,
            requiredForms: result.data.forms_required,
            steps: result.data.steps,
          });
          if (
            (Framework7.device.ios || Framework7.device.android) &&
            !getters.isUserLvlRegistrant
          ) {
            window.FirebasePlugin.getToken((firebaseToken) => {
              console.log('login:' + firebaseToken);
              api.account
                .addFirebaseDevice(firebaseToken)
                .then((deviceData) => {
                  api.account.setFirebaseDeviceToLocalStorage(
                    deviceData.data.id
                  );
                  commit('setFirebaseDevice', deviceData.data.id);
                })
                .catch((error) =>
                  dispatch('errorHandler', error, { root: true })
                );
            });
          }
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    resetPassword({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      commit('setLogin', payload.email);
      return api.account
        .resetPassword(payload)
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    updateFirebaseDevice({ state, dispatch, getters }, firebaseToken) {
      if (state.isLoggedIn && !getters.isUserLvlRegistrant) {
        api.account
          .updateFirebaseDevice({
            firebaseDevice: state.firebaseDevice,
            firebaseToken,
          })
          .catch((error) => dispatch('errorHandler', error, { root: true }));
      }
    },

    deleteFirebaseDevice({ state, commit, dispatch, getters }) {
      let resultPromise = Promise.resolve();
      const { firebaseDevice } = state;
      if (firebaseDevice !== '' && !getters.isUserLvlRegistrant) {
        api.account.setFirebaseDeviceToLocalStorage();
        commit('setFirebaseDevice');
        if (Framework7.device.ios || Framework7.device.android) {
          // window.FirebasePlugin.unregister();
        }
        resultPromise = api.account
          .deleteFirebaseDevice(firebaseDevice)
          .catch((error) => dispatch('errorHandler', error, { root: true }));
      }
      return resultPromise;
    },

    getFormConnectToken({ commit, dispatch }) {
      return api.account
        .getFormConnectToken()
        .then((result) => {
          commit('setFormConnectToken', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getQuizConnectToken({ commit, dispatch }) {
      return api.account
        .getQuizConnectToken()
        .then((result) => {
          commit('setQuizConnectToken', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getRequiredForms({ commit }) {
      const requiredForms = api.account.getFormsRequiredFromLocalStorage();
      if (requiredForms && requiredForms.length > 0) {
        commit('setRequiredForms', requiredForms);
      }
    },

    deleteFromRequiredFormsByID({ state, commit }, payload) {
      const requiredForms = [...state.requiredForms];
      _.remove(requiredForms, { id: payload });
      api.account.setFormsRequiredToLocalStorage(requiredForms);
      commit('setRequiredForms', requiredForms);
    },

    logout({ commit, dispatch }) {
      commit('setIsLoadingData', true, { root: true });
      return dispatch('deleteFirebaseDevice').then(() => {
        return api.account
          .logout()
          .then(() => {
            commit('setRegStepNum', 1); // for lvl 'registrant*'
            commit('setAccount', { isLoggedIn: false });
            api.account.setAccountToLocalStorage();
            api.account.setFormsRequiredToLocalStorage();
            commit('setIsLoadingData', false, { root: true });
          })
          .catch((error) => dispatch('errorHandler', error, { root: true }));
      });
    },

    registerNewUser({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.account
        .registerNewUser({
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          password: payload.password,
          mobileNumber: payload.mobileNumber,
        })
        .then((result) => {
          commit('setAccount', {
            isLoggedIn: true,
            accessToken: result.data.access_token,
            expiresIn: result.data.expires_in,
            refreshToken: result.data.refresh_token,
            tokenType: result.data.token_type,
            user: result.data.user,
            settings: result.data.settings,
            permissions: result.data.permissions,
            formconnect: result.data.formconnect,
            quizconnect: result.data.quizconnect,
            requiredForms: result.data.forms_required,
            steps: result.data.steps,
          });
          commit('setRegStepNum', 2);
          commit('setIsLoadingData', false, { root: true });
          return true;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    registerNextStep({ state, commit, dispatch }) {
      commit('setIsLoadingData', true, { root: true });
      return api.account
        .registerNextStep({
          step: `step${state.regStepNum - 1}`,
        })
        .then(() => {
          commit('setRegStepNum', state.regStepNum + 1);
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
  },
};

<template>
  <f7-page>
    <f7-block
      v-if="
        shiftFiles.files.length === 0 &&
        shift.files.length === 0 &&
        shiftFiles.surveys.length === 0
      "
    >
      <p class="text-color-gray">No files.</p>
    </f7-block>

    <template v-if="shiftFiles.surveys.length > 0">
      <f7-block-header> SHIFT SURVEYS </f7-block-header>
      <f7-list no-hairlines no-chevron class="no-margin">
        <f7-list-item
          v-for="survey in shiftFiles.surveys"
          :key="survey.id"
          :title="survey.rname"
          :footer="survey.uploader"
          link="#"
          swipeout
          @click="onSurveyClick(survey.id)"
        >
          <template #media>
            <img :src="survey.thumbnail" class="sc-shift__file__pic" />
          </template>
          <template #after>
            <f7-icon
              v-if="survey.approved"
              material="check_circle"
              color="green"
            />
          </template>
          <f7-swipeout-actions v-if="survey.delete || survey.approve" right>
            <f7-swipeout-button
              v-if="survey.delete"
              close
              color="red"
              @click="onSurveyDeleteClick(survey.id)"
            >
              <f7-icon material="delete" color="white" />
            </f7-swipeout-button>
            <f7-swipeout-button
              v-if="survey.approve && isClientEnable"
              close
              :color="survey.approved ? 'yellow' : 'green'"
              @click="onSurveyApproveClick(survey.id, survey.approved)"
            >
              <f7-icon
                :material="survey.approved ? 'clear' : 'done'"
                color="white"
              />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </template>

    <template v-if="shiftFiles.files.length > 0">
      <f7-block-header> SHIFT FILES </f7-block-header>
      <f7-list no-hairlines no-chevron class="no-margin">
        <f7-list-item
          v-for="file in shiftFiles.files"
          :key="file.id"
          :title="file.oname"
          :footer="file.uploader"
          link="#"
          swipeout
          @click="onExternalLinkClick(file.path)"
        >
          <template #media>
            <img :src="file.thumbnail" class="sc-shift__file__pic" />
          </template>
          <template #after>
            <f7-icon
              v-if="file.approved"
              material="check_circle"
              color="green"
            />
          </template>
          <f7-swipeout-actions v-if="file.delete || file.approve" right>
            <f7-swipeout-button
              v-if="file.delete"
              close
              color="red"
              @click="onFileDeleteClick(file.id)"
            >
              <f7-icon material="delete" color="white" />
            </f7-swipeout-button>
            <f7-swipeout-button
              v-if="file.approve && isClientEnable"
              close
              :color="file.approved ? 'yellow' : 'green'"
              @click="onFileApproveClick(file.id, file.approved)"
            >
              <f7-icon
                :material="file.approved ? 'clear' : 'done'"
                color="white"
              />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </template>

    <template v-if="shift.files.length > 0">
      <f7-block-header> SHARED FILES </f7-block-header>

      <f7-list no-hairlines no-chevron class="no-margin">
        <f7-list-item
          v-for="file in shift.files"
          :key="file.id"
          :title="file.oname"
          link="#"
          swipeout
          @click="onExternalLinkClick(file.link)"
        >
          <template #media>
            <img :src="file.thumb" class="sc-shift__file__pic" />
          </template>
          <f7-swipeout-actions v-if="isUserLvlOwnerOrAdmin" right>
            <f7-swipeout-button
              close
              color="red"
              @click="onFileDeleteClick(file.id)"
            >
              <f7-icon material="delete" color="white" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </template>
    <sc-calendar-shift-detail-role-form-quiz-connect-popup :f7route="f7route" />
  </f7-page>
</template>
<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';
import scCalendarShiftDetailRoleFormQuizConnectPopup from './Role/CalendarShiftDetailRoleFormQuizConnectPopup.vue';

export default {
  components: {
    scCalendarShiftDetailRoleFormQuizConnectPopup,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shift() {
      return this.$store.state.calendar.shift;
    },
    shiftFiles() {
      return this.$store.state.calendar.shiftFiles;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    isClientEnable() {
      return Number(this.$store.state.account.settings.client_enable) === 1;
    },
  },
  beforeCreate() {
    this.$store.dispatch('calendar/fetchCalendarShiftFiles', {
      shiftId: this.f7route.params.shift_id,
    });
  },
  methods: {
    onExternalLinkClick(link) {
      window.open(link, '_system');
    },
    onFileDeleteClick(documentId) {
      f7.dialog.confirm(
        "This file will be deleted and you won't be able to find it anymore.",
        'Delete the file?',
        () => {
          this.$store.dispatch('calendar/deleteCalendarShiftFile', {
            documentId,
          });
        }
      );
    },

    onFileApproveClick(documentId, approved) {
      this.$store.dispatch('calendar/approveCalendarShiftFile', {
        documentId,
        approved: !approved,
      });
    },

    onSurveyClick(surveyId) {
      const survey = _.find(this.shiftFiles.surveys, { id: surveyId });
      survey.completed_id = survey.other_id;
      this.$store.commit('calendar/setFormQuizConnectPopup', {
        isPopupOpened: true,
        type: 'SURVEY',
        action: survey.edit ? 'COMPLETE' : 'VIEW',
        quiz: survey,
      });
    },

    onSurveyDeleteClick(surveyId) {
      f7.dialog.confirm(
        "This completed survey will be deleted and you won't be able to find it anymore.",
        'Delete the survey?',
        () => {
          this.$store.dispatch('calendar/deleteCalendarShiftSurvey', {
            surveyId,
          });
        }
      );
    },
    onSurveyApproveClick(surveyId, approved) {
      this.$store.dispatch('calendar/approveCalendarShiftSurvey', {
        surveyId,
        approved: !approved,
      });
    },
  },
};
</script>
<style scoped>
.sc-shift__file__pic {
  height: 45px;
}
</style>

<template>
  <f7-page ptr ptr-bottom @ptr:refresh="loadMore">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title>{{
        isUserLvlOwnerOrAdmin ? 'Payroll' : 'Pay'
      }}</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-show="isShowWallets"
          icon-only
          icon-material="account_balance_wallet"
          href="wallets/"
        />
        <f7-link
          icon-only
          icon-material="filter_list"
          popup-open=".sc-payrolls-filter"
        />
      </f7-nav-right>
      <template #after-inner>
        <f7-progressbar
          v-show="isLoadingData && payrolls.length > 0"
          infinite
        />
      </template>
    </f7-navbar>
    <f7-navbar v-show="chekedPayrolls.length > 0" class="theme-dark">
      <f7-nav-left>
        <f7-link icon-material="close" icon-only @click="chekedPayrolls = []" />
      </f7-nav-left>
      <f7-nav-title>{{ chekedPayrolls.length }} selected</f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="sc-payrolls-batch-actions-link"
          icon-only
          icon-material="more_vert"
          @click="onPayrollsBatchActionsLinkClick"
        />
      </f7-nav-right>
    </f7-navbar>
    <f7-block
      v-show="isLoadingData && payrolls.length === 0"
      inset
      class="text-align-center"
    >
      <f7-preloader />
    </f7-block>
    <f7-toolbar v-if="canCreate" bottom no-shadow>
      <f7-link />
      <f7-link icon-material="add" popup-open=".sc-payrolls-generate">
        GENERATE
      </f7-link>
      <f7-link />
    </f7-toolbar>

    <f7-list
      v-if="payrolls.length > 0"
      media-list
      no-hairlines
      class="no-margin"
    >
      <f7-list-item
        v-for="payroll in payrolls"
        :key="payroll.id"
        no-chevron
        :after="`${payroll.total}`"
        class="sc-payroll-item"
      >
        <template #media>
          <f7-checkbox
            v-if="isUserLvlOwnerOrAdmin"
            :value="payroll.id"
            :checked="chekedPayrolls.indexOf(payroll.id) >= 0"
            @change="checkPayrolls"
          />
        </template>
        <template #title>
          <f7-link :href="`payroll/${payroll.id}/`">
            {{ payroll.display }}
          </f7-link>
        </template>
        <template #text>
          <f7-chip
            :text="payroll.status"
            class="sc-payrolls__chip"
            :class="{
              'color-red': payroll.status === 'Rejected',
              'color-yellow': payroll.status === 'Unpaid',
              'color-green': payroll.status === 'Paid',
              'color-orange': payroll.status === 'Processing',
              'color-blue': payroll.status === 'Approved',
            }"
          />
          <span>{{ formatDate(payroll.created_at) }}</span>
        </template>
        <template #subtitle>
          <span v-if="isUserLvlOwnerOrAdmin">{{ payroll.name }}</span>
        </template>
        <template #after>
          <f7-link
            v-if="payroll.actions !== undefined && payroll.actions.length > 0"
            :class="`sc-payroll-actions-link${payroll.id}`"
            style="margin-left: 14px"
            color="gray"
            icon-only
            icon-material="more_vert"
            @click="onPayrollActionsLinkClick(payroll.id)"
          />
        </template>
      </f7-list-item>
    </f7-list>
    <f7-block
      v-show="!isLoadingData && payrolls.length === 0"
      class="text-align-center"
    >
      <p class="text-color-gray">Payrolls not found</p>
    </f7-block>

    <sc-payrolls-filter-popup
      :init-payroll-type="payrollType"
      :init-selected-users="selectedUsers"
      :init-date-from="dateFrom"
      :init-date-to="dateTo"
    />
    <sc-payrolls-generate-popup />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import moment from 'moment';
import _ from 'lodash';
import scPayrollsFilterPopup from './Payrolls/PayrollsFilterPopup.vue';
import scPayrollsGeneratePopup from './Payrolls/PayrollsGeneratePopup.vue';

export default {
  components: {
    scPayrollsFilterPopup,
    scPayrollsGeneratePopup,
  },
  data() {
    return {
      chekedPayrolls: [],
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    isShowWallets() {
      return this.$store.state.account.settings.xtrm_enable == 1;
    },
    payrolls() {
      return this.$store.state.payrolls.payrolls;
    },
    payrollType() {
      return this.$store.state.payrolls.fetchFilter.payrollType;
    },
    selectedUsers() {
      return this.$store.state.payrolls.fetchFilter.selectedUsers;
    },
    dateFrom() {
      return this.$store.state.payrolls.fetchFilter.dateFrom;
    },
    dateTo() {
      return this.$store.state.payrolls.fetchFilter.dateTo;
    },
    canCreate() {
      return (
        this.$store.getters['account/isUserLvlOwnerOrAdmin'] ||
        this.$store.state.account.permissions.staff_invoice
      );
    },
  },
  mounted() {
    this.$store.commit('payrolls/setFetchFilter'); // for reset filter
    this.$store.dispatch('payrolls/fetchPayrolls');
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MMM D YYYY');
    },

    checkPayrolls(event) {
      const { value } = event.target;
      if (event.target.checked) {
        this.chekedPayrolls.push(Number(value));
      } else {
        this.chekedPayrolls.splice(
          this.chekedPayrolls.indexOf(Number(value)),
          1
        );
      }
    },
    loadMore(done) {
      this.$store.dispatch('payrolls/fetchPayrolls').then(() => {
        done();
      });
    },
    groupPayrollActionClick(actionType) {
      this.$store
        .dispatch('payrolls/groupPayrollActions', {
          actionType,
          ids: this.chekedPayrolls,
        })
        .then(() => {
          this.chekedPayrolls = [];
        });
    },
    onPayrollsBatchActionsLinkClick() {
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
        targetEl: '.sc-payrolls-batch-actions-link',
        buttons: [
          {
            text: 'Approve',
            onClick: () => {
              this.groupPayrollActionClick('approve');
            },
          },
          {
            text: 'Unapprove',
            onClick: () => {
              this.groupPayrollActionClick('unapprove');
            },
          },
          {
            text: 'Process',
            onClick: () => {
              this.groupPayrollActionClick('record_processing');
            },
          },
          {
            text: 'Mark Paid',
            onClick: () => {
              this.groupPayrollActionClick('record_payment');
            },
          },
        ],
      });
      actionSheet.open();
    },
    singlePayrollActionClick(actionType, payrollId) {
      if (actionType === 'reject') {
        f7.dialog.prompt(
          "Please enter the reason you're rejecting a payroll",
          '',
          (reason) => {
            this.$store.dispatch('payrolls/singlePayrollActions', {
              actionType,
              payrollId,
              reason,
            });
          }
        );
      } else {
        this.$store.dispatch('payrolls/singlePayrollActions', {
          actionType,
          payrollId,
        });
      }
    },
    onPayrollActionsLinkClick(payrollId) {
      const buttons = _.find(this.payrolls, { id: payrollId }).actions.map(
        (action) => {
          return {
            text: action.label,
            onClick: () => {
              this.singlePayrollActionClick(action.action, payrollId);
            },
          };
        }
      );
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
        targetEl: `.sc-payroll-actions-link${payrollId}`,
        buttons,
      });
      actionSheet.open();
    },
  },
};
</script>
<style scoped>
::v-deep(.sc-payroll-item .item-media) {
  margin: auto;
}
::v-deep(.sc-payroll-item .item-inner) {
  margin-left: 0px;
}
.sc-payrolls__chip {
  height: 20px;
}
</style>

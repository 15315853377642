<template>
  <f7-page
    name="login"
    class="sc-login-page"
    no-navbar
    no-toolbar
    no-swipeback
    login-screen
    @page:beforein="onPageBeforeIn"
  >
    <f7-login-screen-title>
      <img src="assets/img/logo.png" class="margin-vertical sc-login-logo" />
    </f7-login-screen-title>
    <f7-block>
      <f7-list form inset class="theme-dark">
        <f7-list-input
          class="sc-login-input"
          floating-label
          clear-button
          :value="email"
          type="email"
          placeholder="Your E-mail"
          label="E-mail"
          @input="email = $event.target.value"
          @change="email = $event.target.value"
        />
        <f7-list-input
          v-show="!resetPassword"
          class="sc-login-input"
          floating-label
          clear-button
          :value="password"
          type="password"
          placeholder="Your Password"
          label="Password"
          @input="password = $event.target.value"
          @change="password = $event.target.value"
          @keyup.enter="onLoginClick"
        />
      </f7-list>
      <f7-block>
        <f7-row class="margin-vertical justify-content-flex-end">
          <f7-button v-show="!isLoadingData" color="white" @click="onForgotPasswordClick">
            {{ resetPassword ? 'Back to Sign In' : 'Forgot Password?' }}
          </f7-button>
        </f7-row>
        <f7-row class="margin-vertical justify-content-center">
          <f7-button v-show="!isLoadingData" outline color="white" @click="onLoginClick">
            {{ resetPassword ? 'Reset Password' : 'Sign In' }}
          </f7-button>
          <f7-preloader v-show="isLoadingData" size="36" color="white" />
        </f7-row>
      </f7-block>
    </f7-block>
    <f7-block v-if="registrationEnable" class="padding-top">
      <f7-row v-show="!isLoadingData" class="theme-dark justify-content-center align-items-center">
        <span>Don't have an account?</span>
        <f7-button href="/signup/" color="white"> Sign Up </f7-button>
      </f7-row>
    </f7-block>
    <f7-block class="display-flex justify-content-center align-items-center">
      <template v-if="poweredBy">
        <span class="text-color-white">Powered by&nbsp;</span>
        <f7-link color="white" @click="onStaffConnectLinkClick">
          <img style="width: 15px" src="assets/img/staffconnect.png" />
          <span>StaffConnect</span>
        </f7-link>
      </template>
      <template v-else><span>&nbsp;</span></template>
    </f7-block>
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

export default {
  props: {
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      resetPassword: false,
      poweredBy: false,
      registrationEnable: false,
    };
  },
  computed: {
    login() {
      return this.$store.state.account.login;
    },
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },

  mounted() {
    this.$store.dispatch('account/fetchIdentify').then((result) => {
      if (result !== false) {
        this.poweredBy = Number(result.powered_by) === 1;
        this.registrationEnable = Number(result.registration_enable) === 1;
      }
    });
  },

  methods: {
    onPageBeforeIn() {
      this.email = this.login;
    },
    onStaffConnectLinkClick() {
      window.open('https://staffconnect-app.com/', '_system');
    },
    onLoginClick() {
      if (this.resetPassword) {
        this.$store.dispatch('account/resetPassword', { email: this.email }).then((result) => {
          if (result !== false) {
            f7.toast.show({
              text: result.data.message,
              closeTimeout: 2000,
              closeButton: true,
              closeButtonText: 'Close',
              closeButtonColor: 'blue',
              destroyOnClose: true,
            });
            this.resetPassword = false;
          }
        });
      } else {
        this.$store
          .dispatch('account/login', {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.f7router.navigate('/', {
              reloadCurrent: true,
              ignoreCache: true,
            });
          });
      }
    },
    onForgotPasswordClick() {
      this.resetPassword = !this.resetPassword;
    },
  },
};
</script>
<style scoped>
::v-deep(.sc-login-page .login-screen-content) {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.sc-login-logo {
  filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2));
  height: 100%;
  width: 40%;
  max-width: 140px;
  max-height: 140px;
}
.sc-login-input {
  --f7-input-placeholder-color: #fff;
}
</style>

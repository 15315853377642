<template>
  <f7-block-header v-if="!isLoadingData && Object.keys(shifts).length < 1">
    No shifts
  </f7-block-header>
  <div
    v-else
    class="timeline"
  >
    <sc-calendar-time-line-day-item
      v-for="(dayShifts, dayDate) in shifts"
      :key="dayDate"
      :day-shifts="dayShifts"
      :day-date="dayDate"
    />
  </div>
</template>

<script>
import scCalendarTimeLineDayItem from './TimeLine/CalendarTimeLineDayItem.vue';

export default {
  components: {
    scCalendarTimeLineDayItem,
  },
  computed: {
    isLoadingData() { return this.$store.state.isLoadingData; },
    shifts() {
      return this.$store.state.calendar.userShifts;
    },
  },
};
</script>

<template>
  <f7-page :page-content="false" @page:reinit="onReInit">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title>Calendar</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-only icon-material="color_lens" @click="calendarLegendPopupOpened = true" />

        <f7-link
          v-if="isUserLvlOwnerOrAdmin"
          icon-only
          icon-material="add"
          @click="
            $store.commit('calendar/setCalendarShiftAddEditPopup', {
              isPopupOpened: true,
              type: 'ADD',
            })
          "
        />
        <f7-link
          v-if="isUserLvlClient"
          icon-only
          icon-material="add"
          @click="calendarNewBookingPopupOpened = !calendarNewBookingPopupOpened"
        />
      </f7-nav-right>
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#all_shifts" tab-link-active>
        <f7-icon material="event" />
      </f7-link>
      <f7-link tab-link="#user_shifts">
        <f7-icon material="inbox" />
      </f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="all_shifts" class="page-content" tab-active>
        <div id="calendar" class="block block-strong no-padding-top no-padding-horizontal no-margin no-hairline-top" />

        <f7-list id="calendar-events" class="no-margin no-hairlines">
          <f7-list-item
            v-for="(shift, index) in dayShifts"
            :key="index"
            :title="shift.title"
            :after="shift.shiftTime"
            :popover-open="shift.type === 'u' ? '.popover-unavailability' : false"
            :link="shift.type === 's' ? `shift/${shift.id}/` : '#'"
            no-chevron
            @click="onClick(shift.type, shift.id)"
          >
            <template #root-start>
              <div class="event-color" :style="{ 'background-color': shift.eventBackgroundColor }" />
            </template>
            <template v-if="isUserLvlOwnerOrAdmin" #after>
              <f7-link
                class="sc-shift-actions-button margin-left"
                icon-material="more_vert"
                icon-only
                icon-color="gray"
                @click="onActionsClick(shift.id)"
              />
            </template>
          </f7-list-item>
          <f7-list-item v-if="dayShifts.length === 0">
            <template #title>
              <span class="text-color-gray">No events for this day</span>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-tab>
      <f7-tab id="user_shifts" class="page-content">
        <sc-calendar-time-line />
      </f7-tab>
    </f7-tabs>
    <sc-calendar-time-line-unavailability-popover />
    <sc-calendar-shift-add-edit-popup v-if="isUserLvlOwnerOrAdmin" :f7router="f7router" />
    <sc-calendar-new-booking-popup
      v-if="isUserLvlClient"
      :f7router="f7router"
      :popup-opened="calendarNewBookingPopupOpened"
      @popup:closed="calendarNewBookingPopupOpened = false"
    />
    <sc-calendar-legend-popup
      :popup-opened="calendarLegendPopupOpened"
      @sc-calendar-legend-popup:closed="calendarLegendPopupOpened = false"
    />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';
import $ from 'dom7';
import { toRaw } from 'vue';

import moment from 'moment';
import scCalendarTimeLine from './Calendar/CalendarTimeLine.vue';
import scCalendarTimeLineUnavailabilityPopover from './Calendar/TimeLine/CalendarTimeLineUnavailabilityPopover.vue';
import scCalendarShiftAddEditPopup from './Calendar/CalendarShiftAddEditPopup.vue';
import scCalendarNewBookingPopup from './Calendar/CalendarNewBookingPopup.vue';
import scCalendarLegendPopup from './Calendar/CalendarLegendPopup.vue';

export default {
  components: {
    scCalendarTimeLine,
    scCalendarTimeLineUnavailabilityPopover,
    scCalendarShiftAddEditPopup,
    scCalendarNewBookingPopup,
    scCalendarLegendPopup,
  },
  props: {
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      calendarNewBookingPopupOpened: false,
      calendarLegendPopupOpened: false,
      dayShifts: [],
      // calendar: undefined,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    isUserLvlClient() {
      return this.$store.getters['account/isUserLvlClient'];
    },
    shifts() {
      return this.$store.state.calendar.shifts;
    },
  },
  watch: {
    shifts(newShifts) {
      this.calendar.params.events = newShifts.reduce((acc, cur) => {
        acc.push({
          date: moment(cur.start).startOf('day').toDate(),
          color: cur.eventBackgroundColor,
        });
        return acc;
      }, []);
      this.calendar.update();
    },
  },
  mounted() {
    // framewor7 double calendar error fix
    if ($('#calendar')[0].children.length > 0) {
      $('#calendar')[0].children[0].remove();
    }
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.calendar = f7.calendar.create({
      containerEl: $('#calendar')[0],
      value: [new Date()],
      renderToolbar: () => {
        return `<div class="toolbar calendar-custom-toolbar no-shadow">
          <div class="toolbar-inner">
            <div class="left">
              <a href="#" class="link icon-only">
                <i class="icon material-icons">chevron_left</i>
              </a>
            </div>
            <div class="center"></div>
            <div class="right">
              <a href="#" class="link icon-only">
                <i class="icon material-icons">chevron_right</i>
              </a>
            </div>
          </div>
        </div>`;
      },
      on: {
        init: (c) => {
          $('.calendar-custom-toolbar .center').text(`${monthNames[c.currentMonth]}, ${c.currentYear}`);
          $('.calendar-custom-toolbar .left .link').on('click', () => {
            this.calendar.prevMonth();
          });
          $('.calendar-custom-toolbar .right .link').on('click', () => {
            this.calendar.nextMonth();
          });
        },
        monthYearChangeStart: (calendar, oldYear, oldMonth) => {
          $('.calendar-custom-toolbar .center').text(`${monthNames[calendar.currentMonth]}, ${calendar.currentYear}`);

          if (
            (calendar.currentMonth < oldMonth && calendar.currentYear === oldYear) ||
            calendar.currentYear < oldYear
          ) {
            this.onStepClick(-1);
          } else {
            this.onStepClick(+1);
          }
        },
        change: (calendar) => {
          this.renderDayShifts(calendar);
        },
      },
    });

    this.$store.commit('calendar/setCalendarStep', { step: 0 });
    this.$store.dispatch('calendar/getCalendarUserShifts');
    this.$store.dispatch('calendar/getCalendarShifts').then(() => this.renderDayShifts(this.calendar));
  },
  beforeUnmount() {
    if (this.calendar !== undefined) {
      this.calendar.destroy();
    }
  },
  methods: {
    renderDayShifts(calendar) {
      const dayShifts = this.shifts
        .filter((shift) => {
          const shiftDate = moment(shift.start).toDate();
          const currentDate = calendar.value[0];
          return (
            shiftDate.getTime() >= currentDate.getTime() &&
            shiftDate.getTime() < currentDate.getTime() + 24 * 60 * 60 * 1000
          );
        })
        .reduce((arr, el) => {
          arr.push(toRaw(el));
          return arr;
        }, []);
      dayShifts.forEach((shift) => {
        let start = moment(shift.start).isValid() ? moment(shift.start).format('LT') : '';
        if (['3:33 AM', '3:33 PM'].includes(start)) {
          start = 'TBA';
        }
        let end = moment(shift.end).isValid() ? ` - ${moment(shift.end).format('LT')}` : '';
        console.log(end);
        if ([' - 4:44 AM', ' - 4:44 PM'].includes(end)) {
          end = ' - TBA';
        }
        shift.shiftTime = start + end;
      });
      this.dayShifts = dayShifts;
    },
    onStepClick(step) {
      this.$store.commit('calendar/setCalendarStep', {
        step: this.$store.state.calendar.step + step,
      });
      this.$store.dispatch('calendar/getCalendarShifts');
    },
    onReInit() {
      this.$store.dispatch('calendar/getCalendarShifts').then(() => this.renderDayShifts(this.calendar));
    },
    onClick(type, unavailabilityId) {
      if (type === 'u') {
        const userId = this.$store.state.account.user.id;
        this.$store.dispatch('unavailability/getUnavailability', {
          unavailabilityId,
          userId,
        });
      }
    },
    onActionsClick(shiftId) {
      const buttons = [
        {
          id: 'duplicate',
          text: 'Duplicate',
          onClick: () =>
            this.$store
              .dispatch('calendar/getCalendarShift', {
                shift_id: shiftId,
              })
              .then(() =>
                this.$store.commit('calendar/setCalendarShiftAddEditPopup', {
                  isPopupOpened: true,
                  type: 'DUPLICATE',
                })
              ),
          icon: '<i class="icon material-icons color-gray">content_copy</i>',
        },

        {
          id: 'delete',
          text: 'Delete',
          onClick: () =>
            this.$store.dispatch('calendar/deleteCalendarShift', { shiftId: shiftId }).then(() => this.onReInit()),
          icon: '<i class="icon material-icons color-gray">delete</i>',
        },
      ];
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        buttons,
        targetEl: '.sc-shift-actions-button',
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
      });
      actionSheet.open();
    },
  },
};
</script>
<style scoped>
#calendar-events .event-color {
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
}
</style>

<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Unavailability" />
      <f7-nav-right>
        <f7-link
          icon-only
          icon-material="add"
          @click="addUnavailabilityPopupOpened = true"
        />
      </f7-nav-right>
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <f7-block v-show="unavailabilities.length === 0">
      <p class="text-color-gray">
        Please enter your unavailability here, so that we know when you are not
        available.
      </p>
    </f7-block>

    <f7-list media-list no-hairlines>
      <f7-list-item
        v-for="unavail in unavailabilities"
        :key="unavail.id"
        :title="unavail.title"
        :subtitle="unavail.dates"
        :text="unavail.recurring"
        swipeout
      >
        <f7-swipeout-actions right>
          <f7-swipeout-button
            color="red"
            delete
            @click="onDeleteClick(unavail.id)"
          >
            <f7-icon material="delete" color="white" />
          </f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>

    <sc-unavailability-add-popup
      :is-popup-opened="addUnavailabilityPopupOpened"
      :user-id="userId"
      @sc-unavailability-add-popup:closed="addUnavailabilityPopupOpened = false"
    />
  </f7-page>
</template>

<script>
import scUnavailabilityAddPopup from './Unavailability/UnavailabilityAddPopup.vue';

export default {
  components: {
    scUnavailabilityAddPopup,
  },
  data() {
    return {
      addUnavailabilityPopupOpened: false,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    unavailabilities() {
      return this.$store.state.unavailability.unavailabilities;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
  },
  beforeCreate() {
    const userId = this.$store.state.account.user.id;
    this.$store.dispatch('unavailability/getUnavailabilities', { userId });
  },
  methods: {
    onDeleteClick(unavailabilityId) {
      this.$store.dispatch('unavailability/deleteUnavailability', {
        unavailabilityId,
        userId: this.userId,
      });
    },
  },
};
</script>
<style scoped></style>

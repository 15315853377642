<template>
  <f7-page :page-content="false">
    <f7-toolbar bottom>
      <f7-link
        tab-link="#image"
        tab-link-active
        icon-only
        icon-material="collections"
      />
      <f7-link tab-link="#video" icon-only icon-material="video_library" />
      <f7-link tab-link="#document" icon-only icon-material="attach_file" />
    </f7-toolbar>
    <f7-tabs>
      <sc-profile-media-image-tab :user-id="userId" />
      <sc-profile-media-video-tab :user-id="userId" />
      <sc-profile-media-document-tab :user-id="userId" />
    </f7-tabs>
  </f7-page>
</template>

<script>
import scProfileMediaImageTab from '../../../components/Profile/Media/ProfileMediaImageTab.vue';
import scProfileMediaVideoTab from '../../../components/Profile/Media/ProfileMediaVideoTab.vue';
import scProfileMediaDocumentTab from '../../../components/Profile/Media/ProfileMediaDocumentTab.vue';

export default {
  components: {
    scProfileMediaImageTab,
    scProfileMediaVideoTab,
    scProfileMediaDocumentTab,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userId() {
      return Number(this.f7route.params.user_id);
    },
    isNewProfileLoading() {
      return (
        this.$store.state.profile.userId !== Number(this.f7route.params.user_id)
      );
    },
  },
};
</script>

<style scoped>
.sc-media-form {
  height: 0px;
}
</style>

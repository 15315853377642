<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:closed="onPopupClosed" @popup:open="onPopupOpen">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close icon-material="close" icon-only />
        </f7-nav-left>
        <f7-nav-title :title="type === 'ADD' ? 'New Expense' : expense.item_name" />
        <template #after-inner>
          <f7-progressbar v-show="isLoadingData" infinite />
        </template>
      </f7-navbar>
      <f7-toolbar bottom no-shadow>
        <f7-link />

        <f7-link v-show="!isFileLoading" icon-material="receipt" @click="onCompleteClick">SAVE</f7-link>
        <f7-preloader v-show="isFileLoading" :size="32" color="white" />
        <f7-link />
      </f7-toolbar>

      <sc-file-picker
        @sc-file-picker:ready="openFilePicker = $event"
        @sc-file-picker:loading="isFileLoading = true"
        @sc-file-picker:loaded="onFileLoaded"
      />

      <f7-list no-hairlines>
        <f7-list-input
          v-if="expenseCategory && expenseCategory.length > 0"
          key="input-category"
          outline
          floating-label
          label="Category"
          type="select"
          :default-value="itemCategory"
          :value="itemCategory"
          @change="itemCategory = $event.target.value"
        >
          <option v-for="cat in expenseCategory" :key="cat.id" :value="cat.id">
            {{ cat.cname }}
          </option>
        </f7-list-input>

        <f7-list-input
          outline
          floating-label
          label="Item Name*"
          placeholder="eg. Parking"
          required
          validate
          clear-button
          type="text"
          :value="itemName"
          @input="itemName = $event.target.value"
        />

        <f7-list-input
          v-if="isUserLvlOwnerOrAdmin"
          key="input-quantity"
          required
          validate
          outline
          floating-label
          label="Quantity"
          placeholder="How many?"
          type="number"
          min="1"
          :value="unitsNumber"
          @input="unitsNumber = $event.target.value"
        />

        <f7-list-input
          outline
          floating-label
          required
          validate
          label="Amount"
          placeholder="Rate per one unit"
          type="number"
          min="0"
          :value="unitsRate"
          @input="unitsRate = $event.target.value"
        />
      </f7-list>

      <f7-card v-show="file.src !== '' || file.blob !== null">
        <f7-card-header v-show="file.name">{{ file.name }}</f7-card-header>
        <f7-card-content class="text-align-center">
          <img v-show="file.type === 'image'" :src="file.src" width="100%" />
          <f7-icon
            v-show="file.type !== 'image'"
            material="insert_drive_file"
            icon="margin-vertical md-72"
            color="gray"
          />
        </f7-card-content>
        <f7-card-footer>
          <div>{{ file.createdAt }}</div>
          <f7-button icon-material="delete" color="gray" @click="onFileDeleteClick()" />
        </f7-card-footer>
      </f7-card>

      <f7-block v-show="!isFileLoading">
        <f7-row class="margin-vertical justify-content-center">
          <f7-button class="button-large button-fill button-raised" icon-material="attach_file" @click="openFilePicker"
            >UPLOAD RECEIPT</f7-button
          >
        </f7-row>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import { f7 } from 'framework7-vue';

import scFilePicker from '../../../../../components/SCFilePicker.vue';

export default {
  components: {
    scFilePicker,
  },
  data() {
    return {
      itemName: '',
      itemCategory: undefined,
      itemType: 'expense',
      unitsNumber: 1,
      unitsRate: undefined,

      isFileLoading: false,
      openFilePicker: () => {},
      file: {
        blob: null,
        name: '',
        type: '',
        createdAt: '',
        src: '',
      },
    };
  },
  computed: {
    isPopupOpened() {
      return this.$store.state.calendar.expenseAddEditPopup.isPopupOpened;
    },
    roleStaffId() {
      return this.$store.state.calendar.expenseAddEditPopup.roleStaffId;
    },
    type() {
      return this.$store.state.calendar.expenseAddEditPopup.type;
    },
    expense() {
      return this.$store.state.calendar.expenseAddEditPopup.expense;
    },
    expenseCategory() {
      return this.$store.state.calendar.shift.expense_categories;
    },
    isReceiptRequired() {
      return this.$store.state.calendar.shift.expense_receipt_required;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  methods: {
    onFileLoaded(file) {
      if (file !== null) {
        this.file.blob = file.blob;
        this.file.name = file.name;
        this.file.type = file.type;
        this.file.createdAt = file.createdAt;
        this.file.src = file.src;
        this.deleteFile = 0;
      }
      this.isFileLoading = false;
    },

    onPopupOpen() {
      if (this.type === 'EDIT') {
        const { file } = this.expense;
        if (file.thumb) {
          this.file.type = 'image';
          this.file.src = file.thumb;
          this.file.name = file.oname;
          this.file.createdAt = file.created_at;
        }
        this.itemName = this.expense.item_name;
        this.itemCategory = this.expense.expense_cat_id;
        this.itemType = this.expense.item_type;
        this.unitsNumber = this.expense.units;
        this.unitsRate = this.expense.unit_rate;
      } else if (this.expenseCategory && this.expenseCategory.length > 0) {
        this.itemCategory = this.expenseCategory[0].id;
      }
    },
    onPopupClosed() {
      this.$store.commit('calendar/setCalendarExpenseAddEditPopup', {
        isPopupOpened: false,
        roleStaffId: undefined,
        expense: {},
        type: 'ADD',
      });
      this.itemName = '';
      this.itemCategory = undefined;
      this.itemType = 'expense';
      this.unitsNumber = 1;
      this.unitsRate = undefined;
      this.file.blob = null;
      this.file.name = '';
      this.file.type = '';
      this.file.createdAt = '';
      this.file.src = '';
      this.isFileLoading = false;
    },
    onCompleteClick() {
      if (this.itemName !== '' && this.unitsRate > 0 && this.unitsNumber > 0) {
        if (this.type === 'ADD') {
          if (this.isReceiptRequired && this.file === undefined) {
            f7.toast
              .create({
                text: 'Please upload a receipt.',
                closeTimeout: 2000,
                closeButton: true,
                closeButtonText: 'Close',
                closeButtonColor: 'red',
              })
              .open();
          } else {
            this.$store
              .dispatch('calendar/newExpenseCalendarShiftRole', {
                roleStaffId: this.roleStaffId,
                file: this.file.blob,
                itemName: this.itemName,
                itemCategory: this.itemCategory,
                itemType: this.itemType,
                unitsNumber: this.unitsNumber,
                unitsRate: this.unitsRate,
              })
              .then((result) => {
                if (result !== false) {
                  f7.popup.close();
                }
              });
          }
        }
        if (this.type === 'EDIT') {
          this.$store
            .dispatch('calendar/editExpenseCalendarShiftRole', {
              payItemId: this.expense.id,
              file: this.file.blob,
              itemName: this.itemName,
              itemCategory: this.itemCategory,
              itemType: this.itemType,
              unitsNumber: this.unitsNumber,
              unitsRate: this.unitsRate,
            })
            .then((result) => {
              if (result !== false) {
                f7.popup.close();
              }
            });
        }
      }
    },

    onFileDeleteClick() {
      this.file.blob = null;
      this.file.name = '';
      this.file.type = '';
      this.file.createdAt = '';
      this.file.src = '';
    },
  },
};
</script>
<style scoped>
.sc-image-card {
  min-width: 120px;
}
.sc-image-card .card-content {
  min-height: 120px;
}
.sc-image-card .card-content img {
  width: 100%;
  display: block;
}
.sc-add-photo {
  flex-direction: column;
}
</style>

<template>
  <f7-page name="users">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Users" />
      <f7-nav-right>
        <f7-link
          icon-material="filter_list"
          icon-only
          popup-open=".sc-users-filter"
        />
        <f7-link
          icon-only
          searchbar-enable
          data-searchbar=".users-searchbar"
          icon-material="search"
        />
      </f7-nav-right>
      <f7-searchbar
        class="users-searchbar"
        expandable
        custom-search
        @input="onSearchInput"
        @searchbar:disable="onSearchDisable"
      />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>
    <f7-list
      v-if="usersList.length > 0"
      media-list
      no-hairlines
      class="no-margin-top"
    >
      <f7-list-item
        v-for="user in usersList"
        :key="user.id"
        :link="`profile/${user.id}/`"
        :title="`${user.fname} ${user.lname}`"
        :subtitle="user.email"
        :footer="user.mob"
        :after="user.last_login"
      >
        <template #media>
          <img :src="user.ppic_a || 'assets/img/default-user-image.png'" />
          <div
            v-if="user.user_status"
            :style="`background-color:${user.user_status.color};`"
            class="user_status"
            @taphold="onUserStatusClick(user.user_status.sname)"
          />
        </template>
      </f7-list-item>
    </f7-list>
    <f7-block v-else-if="!usersLoading" class="text-align-center">
      <p class="text-color-gray">Users not found</p>
    </f7-block>

    <f7-block v-show="showLoadMore && !usersLoading" class="text-align-center">
      <f7-link text="LOAD MORE" @click="onLoadMoreClick" />
    </f7-block>
    <sc-users-filter-popup />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';
import scUsersFilterPopup from './Users/UsersFilterPopup.vue';

export default {
  components: {
    scUsersFilterPopup,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userSearchValue: '',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    usersList() {
      return this.$store.state.users.usersList;
    },
    showLoadMore() {
      return this.$store.state.users.showLoadMore;
    },
    usersLoading() {
      return this.$store.state.users.loading;
    },
    fetchUsersFilter() {
      return this.$store.state.users.fetchUsersFilter;
    },
  },
  beforeCreate() {
    if (this.$store.state.users.usersList.length === 0) {
      this.$store.dispatch('users/fetchUsersList');
    }
    if (this.f7route.query.filter && this.f7route.query.filter === 'complete') {
      this.$store.commit('users/setFetchUsersFilter', ['utype:=:complete']);
      this.$store.dispatch('users/fetchUsersList', { filter: true });
    }
  },
  methods: {
    onUserStatusClick(userStatus) {
      f7.toast
        .create({
          text: userStatus,
          closeTimeout: 1000,
          destroyOnClose: true,
          position: 'center',
        })
        .open();
    },
    onLoadMoreClick() {
      this.$store.dispatch('users/fetchUsersList', { nextPage: true });
    },
    onSearchInput: _.debounce(function fetchUserListDebounce(event) {
      this.$store.commit('users/setFetchUsersFilter', ['utype:=:all']); // clean filter
      this.userSearchValue = event.target.value;
      const newFetchUsersFilter = [...this.fetchUsersFilter];
      _.remove(newFetchUsersFilter, (val) => _.startsWith(val, 'search'));
      newFetchUsersFilter.push(`search:=:${this.userSearchValue}`);
      this.$store.commit('users/setFetchUsersFilter', newFetchUsersFilter);
      this.$store.dispatch('users/fetchUsersList', { filter: true });
    }, 500),
    onSearchDisable() {
      if (
        _.findIndex(this.fetchUsersFilter, (val) =>
          _.startsWith(val, 'search')
        ) !== -1 &&
        this.userSearchValue !== ''
      ) {
        this.$store.commit('users/setFetchUsersFilter', ['utype:=:all']);
        this.$store.dispatch('users/fetchUsersList', { filter: true });
      }
    },
  },
};
</script>
<style scoped>
.item-media img {
  border-radius: 50%;
  width: 50px;
}

.user_status {
  bottom: 15px;
  left: 45px;
  min-width: 20px;
  position: absolute;
  height: 20px;
  border-radius: 50%;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
</style>

<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Experience" />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <div v-if="completedShifts.length > 0" class="data-table card">
      <div class="card-header">
        <div class="data-table-title">Shifts Completed</div>
      </div>
      <div class="card-content">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Role</th>
              <th>Shift</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="shift in completedShifts" :key="shift.shift_id">
              <td class="label-cell">{{ shift.shift_start }}</td>
              <td class="label-cell">{{ shift.rname }}</td>
              <td class="label-cell">{{ shift.title }}</td>
            </tr>
          </tbody>
        </table>
        <div v-show="showLoadMore && !isLoadingData" class="data-table-footer justify-content-center">
          <f7-link text="LOAD MORE" @click="onLoadMoreClick" />
        </div>
      </div>
    </div>

    <div v-for="(category, catIndx) in experience" :key="category.id" class="data-table card">
      <div class="card-header">
        <div class="data-table-title">{{ category.cname }}</div>
        <!-- Table actions -->
        <div class="data-table-actions">
          <f7-link icon-material="add_circle_outline" @click="onAddExperienceClick(category.id)" />
        </div>
      </div>
      <div class="card-content">
        <table>
          <thead>
            <tr>
              <th v-for="head in category.headings" :key="head.id" class="label-cell">{{ head.hname }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(exper, experIndx) in category.experience" :key="exper.id">
              <td v-for="(data, indx) in exper.data" :key="indx" class="label-cell">
                {{ getDataByOrderId(catIndx, experIndx, indx) }}
              </td>
              <td class="actions-cell">
                <f7-link icon-material="edit" @click="onEditExperienceClick(category.id, exper.id)" />
                <f7-link icon-material="delete" @click="onDeleteExperienceClick(exper.id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <sc-profile-experience-add-edit-popup
      :is-popup-opened="isPopupOpened"
      :action-type="actionType"
      :experience-category-id="experienceCategoryId"
      :experience-id="experienceId"
      @closed="onPopupClosed"
    />
  </f7-page>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import scProfileExperienceAddEditPopup from '../../components/Profile/Experience/ProfileExperienceAddEditPopup.vue';

export default {
  components: {
    scProfileExperienceAddEditPopup,
  },
  data() {
    return {
      isPopupOpened: false,
      actionType: 'ADD',
      experienceCategoryId: undefined,
      experienceId: undefined,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    experience() {
      return this.$store.state.profile.experience;
    },
    completedShifts() {
      return this.$store.state.profile.completedShifts;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    showLoadMore() {
      return this.$store.state.profile.showLoadMoreCompletedShifts;
    },
  },

  beforeCreate() {
    const userId = this.$store.state.account.user.id;

    if (this.$store.state.profile.completedShifts.length === 0) {
      this.$store.dispatch('profile/fetchProfileCompletedShifts', { userId });
    }
    this.$store.dispatch('profile/fetchProfileExperience', { userId });
  },
  methods: {
    getDataByOrderId(catIndx, experIndx, id) {
      const dataHeader = _.find(this.experience[catIndx].headings, { display_order: String(id) });
      const data = this.experience[catIndx].experience[experIndx].data[id];
      switch (dataHeader.type) {
        case 'list':
          return _.find(dataHeader.options, { id: Number(data) }).oname;
        case 'date':
          return moment(data).isValid ? moment(data).format(this.experience[catIndx].dformat || 'MMM DD YYYY') : '';
        default:
          return data;
      }
    },
    onDeleteExperienceClick(experienceId) {
      this.$store.dispatch('profile/deleteProfileExperience', { experienceId, userId: this.userId });
    },
    onAddExperienceClick(experienceCategoryId) {
      this.isPopupOpened = true;
      this.actionType = 'ADD';
      this.experienceCategoryId = experienceCategoryId;
    },
    onEditExperienceClick(experienceCategoryId, experienceId) {
      this.actionType = 'EDIT';
      this.experienceId = experienceId;
      this.experienceCategoryId = experienceCategoryId;
      this.isPopupOpened = true;
    },
    onPopupClosed() {
      this.isPopupOpened = false;
      this.actionType = 'ADD';
    },

    onLoadMoreClick() {
      this.$store.dispatch('profile/fetchProfileCompletedShifts', { userId: this.userId, nextPage: true });
    },
  },
};
</script>

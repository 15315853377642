<template>
  <f7-page>
    <f7-block v-show="!isNewProfileLoading" class="text-align-center">
      <f7-link no-link-class tab-link href="tab2/" route-tab-id="tab2">
        <!-- <img :src="userLogo" style="border-radius: 50%; height: 150px;"> -->
        <div class="sc-user-logo-img" :style="{ backgroundImage: `url(${userLogo})` }" />
      </f7-link>
      <f7-row v-if="isUserLvlRegistrant">
        <f7-col class="margin-top">
          <f7-link
            v-if="!isUserRejected"
            class="margin-right"
            color="red"
            icon-material="highlight_off"
            icon-size="32px"
            @click="onRejectUserClick"
          />
          <f7-link color="green" icon-material="check_circle" icon-size="32px" @click="onApproveUserClick" />
        </f7-col>
      </f7-row>

      <f7-row>
        <f7-col style="color: black; font-size: 18px; font-weight: 500" class="margin-top">
          {{ userFullName }}
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-link :href="`mailto:${userMail}`" :text="userMail" class="col color-gray" external />
      </f7-row>
      <f7-row>
        <f7-link
          :href="`tel:${userPhone ? userPhone.replace(/[^+\d]+/g, '') : ''}`"
          :text="userPhone"
          class="col color-gray"
          external
        />
      </f7-row>
    </f7-block>

    <f7-block-title v-if="structure.length" v-show="!isNewProfileLoading"> Categories </f7-block-title>
    <f7-list v-if="structure.length" v-show="!isNewProfileLoading" accordion-list>
      <sc-profile-info-category v-for="category in structure" :key="category.id" :category="category" />
    </f7-list>

    <template v-if="isUserLvlStaff" v-show="!isNewProfileLoading">
      <f7-block-title> Admin Notes </f7-block-title>
      <f7-list media-list class="sc-admin-notes-list">
        <f7-list-item media-item title="Add note" link no-chevron style="color: #007aff" @click="onAdminNoteAddClick">
          <template #media>
            <f7-icon material="note_add" />
          </template>
        </f7-list-item>

        <f7-list-item v-if="!adminNotes || adminNotes.length === 0" title="No notes for user" text-color="gray" />

        <f7-list-item
          v-for="note in adminNotes"
          :key="note.id"
          :title="note.creator_name"
          :text="note.note"
          :footer="formatDate(note.updated_at)"
          :badge="note.type"
          :badge-color="adminNotesBadgeColor(note.type)"
          swipeout
        >
          <f7-swipeout-actions right>
            <f7-swipeout-button color="green" @click="onAdminNoteEditClick(note.id)">
              <f7-icon material="edit" color="white" />
            </f7-swipeout-button>
            <f7-swipeout-button delete color="red" @click="onAdminNoteDeleteClick(note.id)">
              <f7-icon material="delete" color="white" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </template>
    <sc-profile-info-add-edit-admin-note-popup
      v-if="isUserLvlStaff"
      :is-opened="addEditAdminNotePopupOpened"
      :action-type="addEditAdminNoteActionType"
      :admin-note-id="editAdminNoteId"
      :f7route="f7route"
      @sc-profile-info-add-edit-admin-note-popup:closed="addEditAdminNotePopupOpened = false"
    />
  </f7-page>
</template>

<script>
import moment from 'moment';
import scProfileInfoCategory from '../../../components/Profile/Info/ProfileInfoCategory.vue';
import scProfileInfoAddEditAdminNotePopup from '../../../components/Profile/Info/ProfileInfoAddEditAdminNotePopup.vue';

export default {
  components: {
    scProfileInfoCategory,
    scProfileInfoAddEditAdminNotePopup,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addEditAdminNotePopupOpened: false,
      addEditAdminNoteActionType: 'ADD', // ADD or EDIT
      editAdminNoteId: undefined,
    };
  },
  computed: {
    structure() {
      return this.$store.state.profile.structure;
    },
    adminNotes() {
      return this.$store.state.profile.adminNotes;
    },
    isUserLvlStaff() {
      return this.$store.state.profile.lvl === 'staff';
    },
    isUserLvlRegistrant() {
      return this.$store.state.profile.lvl.indexOf('registrant') !== -1;
    },
    isUserRejected() {
      return this.$store.state.profile.active === 'rejected';
    },
    userLogo() {
      return this.$store.state.profile.ppic_a;
    },
    userMail() {
      return this.$store.state.profile.email;
    },
    userPhone() {
      return this.$store.state.profile.phone;
    },
    userFullName() {
      return this.$store.getters['profile/getUserProfileTitle'];
    },
    isReadOnly() {
      return this.$store.state.profile.isReadOnly;
    },
    userId() {
      return Number(this.f7route.params.user_id);
    },
    isNewProfileLoading() {
      return this.$store.state.profile.userId !== Number(this.f7route.params.user_id);
    },
  },
  created() {
    this.$store.commit('profile/setIsProfileReadonly', true);
    this.$store.commit('profile/resetErrorProfileElements');
    this.$store.commit('profile/resetProfileElementsForUpdate');
    this.$store.commit('profile/setProfileData', {
      userId: this.userId,
    });
    this.$store.dispatch('profile/getProfileStructure', { userId: this.userId }).then(() => {
      if (this.isUserLvlStaff) {
        this.$store.dispatch('profile/getProfileAdminNotes', {
          userId: this.userId,
        });
      }
    });
  },
  methods: {
    adminNotesBadgeColor(type) {
      let result;
      switch (type) {
        case 'positive':
          result = 'green';
          break;
        case 'negative':
          result = 'red';
          break;
        default:
          result = 'gray';
      }
      return result;
    },
    onAdminNoteAddClick() {
      this.addEditAdminNoteActionType = 'ADD';
      this.addEditAdminNotePopupOpened = true;
    },
    onAdminNoteEditClick(adminNoteId) {
      this.addEditAdminNoteActionType = 'EDIT';
      this.editAdminNoteId = adminNoteId;
      this.addEditAdminNotePopupOpened = true;
    },
    onAdminNoteDeleteClick(adminNoteId) {
      this.$store.dispatch('profile/deleteProfileAdminNote', {
        adminNoteId,
        userId: this.userId,
      });
    },
    formatDate(val) {
      const result = moment(val).isValid() ? moment(val) : false;
      return result.format('ll LT');
    },
    onApproveUserClick() {
      this.$store.dispatch('profile/approveUser', { userId: this.userId });
    },
    onRejectUserClick() {
      this.$store.dispatch('profile/rejectUser', { userId: this.userId });
    },
  },
};
</script>
<style scoped>
.sc-user-logo-img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
::v-deep(.sc-admin-notes-list .item-text) {
  max-height: none;
  display: block;
}
::v-deep(.sc-admin-notes-list .item-inner) {
  margin-left: 0;
}
</style>

import axios from 'axios';
import config from '../config';

axios.defaults.baseURL = config.axios.baseURL;
axios.defaults.headers.common.Accept = config.axios.headers.Accept;
axios.defaults.headers.post['Content-Type'] = config.axios.headers['Content-Type'];
axios.defaults.headers.put['Content-Type'] = config.axios.headers['Content-Type'];
axios.defaults.validateStatus = config.axios.validateStatus;
axios.defaults.timeout = config.axios.timeout;

export default axios;

<template>
  <f7-page>
    <div v-show="shift.lat && shift.lon" id="shift-map" />

    <f7-list inset>
      <f7-list-item v-if="shift.address">
        <template #media>
          <f7-icon material="place" />
        </template>
        <template #inner>{{ shift.address }}</template>
      </f7-list-item>
      <f7-list-item v-if="shift.location">
        <template #media>
          <f7-icon material="business" />
        </template>
        <template #inner>{{ shift.location }}</template>
      </f7-list-item>

      <f7-list-item v-if="shift.contact">
        <template #media>
          <f7-icon material="contact_phone" />
        </template>
        <template #inner>{{ shift.contact }}</template>
      </f7-list-item>
    </f7-list>

    <template v-if="shift.managers && shift.managers.length > 0">
      <f7-block-title>Managers</f7-block-title>
      <f7-list>
        <f7-list-item v-for="manager in shift.managers" :key="manager.id">
          <template #media>
            <img :src="manager.ppic_a || 'assets/img/default-user-image.png'" class="sc-shift__manager__pic" />
          </template>
          <template #inner-start>{{ manager.name }}</template>
          <template #after>
            <f7-link
              v-if="manager.mob"
              class="margin-right"
              icon-material="call"
              icon-only
              @click="onMobileClick(manager.mob)"
            />
            <f7-link v-if="isChatEnable" icon-material="mail" icon-only @click="onManagerMessageClick(manager.id)" />
          </template>
        </f7-list-item>
      </f7-list>
    </template>
    <f7-block-title> Admin Notes </f7-block-title>
    <f7-list media-list class="sc-admin-notes-list">
      <f7-list-item
        v-if="isUserLvlOwnerOrAdmin"
        media-item
        title="Add note"
        link
        no-chevron
        style="color: #007aff"
        @click="onAdminNoteAddClick"
      >
        <template #media>
          <f7-icon material="note_add" />
        </template>
      </f7-list-item>
      <f7-list-item
        v-if="!shift.shift_admin_notes || shift.shift_admin_notes.length === 0"
        title="No notes for this shift"
        text-color="gray"
      />
      <template v-for="note in shift.shift_admin_notes" :key="note.id">
        <f7-list-item
          v-if="isUserLvlOwnerOrAdmin || note.client_visible === '1'"
          :title="note.creator_name"
          :text="note.note"
          :footer="formatDate(note.updated_at)"
          :badge="note.tname"
          swipeout
        >
          <f7-swipeout-actions right>
            <f7-swipeout-button color="green" @click="onAdminNoteEditClick(note.id)">
              <f7-icon material="edit" color="white" />
            </f7-swipeout-button>
            <f7-swipeout-button delete color="red" @click="onAdminNoteDeleteClick(note.id)">
              <f7-icon material="delete" color="white" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </template>
    </f7-list>
    <sc-calendar-shift-detail-add-edit-admin-note-popup
      v-if="isUserLvlOwnerOrAdmin"
      :is-opened="addEditAdminNotePopupOpened"
      :action-type="addEditAdminNoteActionType"
      :admin-note-id="editAdminNoteId"
      @sc-calendar-shift-detail-add-edit-admin-note-sheet:closed="addEditAdminNotePopupOpened = false"
    />
  </f7-page>
</template>
<script>
import moment from 'moment';
import { f7 } from 'framework7-vue';
import scCalendarShiftDetailAddEditAdminNotePopup from './CalendarShiftDetailAddEditAdminNotePopup.vue';

export default {
  components: {
    scCalendarShiftDetailAddEditAdminNotePopup,
  },
  props: {
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addEditAdminNotePopupOpened: false,
      addEditAdminNoteActionType: 'ADD', // ADD or EDIT
      editAdminNoteId: undefined,
    };
  },
  computed: {
    shift() {
      return this.$store.state.calendar.shift;
    },
    isChatEnable() {
      return Number(this.$store.state.account.settings.chat_enable) === 1;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
  },
  mounted() {
    this.map = new google.maps.Map(document.getElementById('shift-map'), {
      gestureHandling: 'cooperative',
      zoom: 13,
    });
    if (this.shift.lat && this.shift.lon) {
      this.map.setCenter({
        lat: Number(this.shift.lat),
        lng: Number(this.shift.lon),
      });
      const marker = new google.maps.Marker({
        position: { lat: Number(this.shift.lat), lng: Number(this.shift.lon) },
        map: this.map,
      });
    }
  },
  methods: {
    onMobileClick(mob) {
      const mobile = mob.replace(/[^+\d]+/g, '');
      window.open(`tel:${mobile}`, '_system');
    },
    onManagerMessageClick(userId) {
      f7.dialog.prompt('', 'New Message', (message) => {
        this.$store
          .dispatch('chats/createChatThread', {
            content: message,
            recipient_id: userId,
          })
          .then((newThreadId) => {
            if (newThreadId !== false) {
              this.f7router.navigate(`/chats/${newThreadId}/`);
            }
          });
      });
    },
    formatDate(val) {
      const result = moment(val).isValid() ? moment(val) : false;
      return result.format('ll LT');
    },
    onAdminNoteAddClick() {
      this.addEditAdminNoteActionType = 'ADD';
      this.addEditAdminNotePopupOpened = true;
    },
    onAdminNoteEditClick(adminNoteId) {
      this.addEditAdminNoteActionType = 'EDIT';
      this.editAdminNoteId = adminNoteId;
      this.addEditAdminNotePopupOpened = true;
    },
    onAdminNoteDeleteClick(adminNoteId) {
      this.$store.dispatch('calendar/deleteCalendarShiftAdminNote', {
        adminNoteId,
      });
    },
  },
};
</script>
<style scoped>
#shift-map {
  height: 50%;
  width: 100%;
}

.sc-shift__notes {
  text-align: center;
}

.sc-shift__manager__pic {
  width: 45px;
  border-radius: 50%;
}

::v-deep(.sc-admin-notes-list .item-text) {
  max-height: none;
  display: block;
}
::v-deep(.sc-admin-notes-list .item-inner) {
  margin-left: 0;
}
</style>

import axios from '../../libs/axios';


export default {

  getChatThreads() {
    return axios.get('/chat/threads').then(response => response).catch(error => this.apiErrorHandler(error));
  },

  getShiftChatThread(payload) {
    return axios.get(`/chat/threads/${payload.threadId}`).then(response => response).catch(error => this.apiErrorHandler(error));
  },

  getChatThreadMessages(payload) {
    return axios.get(`/chat/thread/${payload.thread_id}/${payload.page_id}`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  getChatThreadUnreadMessages(payload) {
    return axios.get(`/chat/unread/${payload.thread_id}`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  getChatSearchUserList(payload) {
    return axios.get(`/autocomplete/chatThread/${payload.query}`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },
  getChatSearchUserListForThread(payload) {
    return axios.get(`/autocomplete/users/chatThread/${payload.thread_id}/${payload.query}`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },
  createChatThread(payload) {
    return axios.post(
      '/chat/message',
      {
        content: payload.content,
        recipient_id: payload.recipient_id,
        shift_id: payload.shift_id,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },
  sendChatThreadMessage(payload) {
    return axios.post(
      '/chat/message',
      {
        content: payload.content,
        sms: payload.sms,
        sender_id: payload.sender_id,
        thread_id: payload.thread_id,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },
  addUserChatThread(payload) {
    return axios.post(
      `/chat/thread/${payload.thread_id}/user/${payload.user_id}`,
      {},
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },
  deleteUserChatThread(payload) {
    return axios.delete(`/chat/thread/${payload.thread_id}/user/${payload.user_id}`).then(response => response).catch(error => this.apiErrorHandler(error));
  },
  deleteChatThread(payload) {
    return axios.delete(`/chat/thread/${payload.thread_id}`).then(response => response).catch(error => this.apiErrorHandler(error));
  },
  updateChatThreadSeen(payload) {
    return axios.put(
      `/chat/thread/${payload.thread_id}/read`,
      {},
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },

};

<template>
  <f7-tab id="video" class="page-content">
    <f7-block v-show="isLargeUpload" class="text-align-center">
      <f7-block-header
        >Uploading {{ fileName }}... {{ progress }}%</f7-block-header
      >
      <f7-progressbar :progress="progress" />
    </f7-block>
    <f7-card v-for="video in videos" :key="video.id" class="sc-video-card">
      <f7-card-content :padding="false">
        <video
          :poster="video.thumbnail"
          :src="video.path"
          class="display-block no-fastclick"
          controls
          width="100%"
        >
          <source :src="video.path" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </f7-card-content>
      <f7-card-footer>
        <div>{{ new Date(video.created_at).toDateString() }}</div>
        <f7-button
          icon-material="delete"
          color="gray"
          @click="onDeleteClick(video.id)"
        />
      </f7-card-footer>
    </f7-card>
    <f7-fab
      v-show="!isLargeUpload"
      position="right-bottom"
      style="position: fixed"
      @click="openFilePicker"
    >
      <f7-icon material="add" />
    </f7-fab>
    <sc-file-picker
      @sc-file-picker:ready="openFilePicker = $event"
      @sc-file-picker:loaded="onFileLoaded"
    />
  </f7-tab>
</template>
<script>
import { mapState } from 'vuex';
import { f7 } from 'framework7-vue';

import scFilePicker from '../../SCFilePicker.vue';

export default {
  components: {
    scFilePicker,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      openFilePicker: () => {},
    };
  },
  computed: mapState({
    videos: (state) => state.profile.videos,
    isLargeUpload: (state) => state.profile.largeVideoFileUpload.isLargeUpload,
    progress: (state) => state.profile.largeVideoFileUpload.progress,
    fileName: (state) => state.profile.largeVideoFileUpload.fileName,
    chunkSize: (state) => state.profile.largeVideoFileUpload.chunkSize,
  }),
  created() {
    this.$store.dispatch('profile/getProfileVideos', { userId: this.userId });
  },
  methods: {
    onDeleteClick(videoId) {
      f7.dialog.confirm(
        "This video will be deleted and you won't be able to find it anymore.",
        'Delete the video?',
        () => {
          this.$store.dispatch('profile/deleteProfileVideo', { videoId });
        }
      );
    },
    onFileLoaded(file) {
      if (file.type === 'video') {
        if (file.blob.size < this.chunkSize) {
          this.$store.dispatch('profile/setProfileVideo', {
            video_blob: file.blob,
            userId: this.userId,
          });
        } else {
          this.uploadLargeVideo(file.blob);
        }
      } else {
        f7.toast.show({
          text: 'Please select a video file.',
          closeTimeout: 3000,
          closeButton: true,
          closeButtonText: 'Close',
          closeButtonColor: 'red',
          destroyOnClose: true,
        });
      }
    },
    uploadLargeVideo(fileBlob) {
      this.$store.commit('profile/setLargeVideoFileUpload', {
        isLargeUpload: true,
        fileName: fileBlob.name,
      });
      const totalParts = Math.ceil(fileBlob.size / this.chunkSize);

      const chunkPromises = new Array(totalParts)
        .fill('dummy')
        .map((cur, index) => {
          const fileCunk = fileBlob.slice(
            index * this.chunkSize,
            index + 1 < totalParts
              ? (index + 1) * this.chunkSize
              : fileBlob.size
          );
          return () => {
            return this.$store
              .dispatch('profile/setLargeProfileVideoChunk', {
                video_blob: fileCunk,
                userId: this.userId,
                part: index + 1,
                isLastPart: index + 1 === totalParts,
              })
              .then(() => {
                this.$store.commit('profile/setLargeVideoFileUpload', {
                  progress: Math.round((100 / totalParts) * (index + 1)),
                });
                if (index + 1 === totalParts) {
                  this.$store.commit('profile/setLargeVideoFileUpload', {
                    isLargeUpload: false,
                    fileName: '',
                    progress: 0,
                  });
                }
              });
          };
        });
      chunkPromises
        .reduce((p, f) => p.then(f), Promise.resolve())
        .catch(() => {
          this.$store.commit('profile/setLargeVideoFileUpload', {
            isLargeUpload: false,
            fileName: '',
            progress: 0,
          });
        });
    },
  },
};
</script>

<style scoped>
.sc-video-card {
  margin-bottom: 20px;
}

::v-deep(.sc-video-card .card-footer .button) {
  max-width: 30px;
  min-width: 30px;
  padding: 0 0 0 5px;
}
</style>

<template>
  <f7-page>
    <div id="checks-map" style="height: 100%; width: 100%">
      <f7-block>
        <f7-block-header>Please verify address</f7-block-header>
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
export default {
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      map: undefined,
      centerMarker: undefined,
      checks: [],
    };
  },
  computed: {
    shiftLat() {
      return Number(this.$store.state.calendar.shift.lat);
    },
    shiftLng() {
      return Number(this.$store.state.calendar.shift.lon);
    },
    shiftChecks() {
      return this.$store.state.calendar.shiftChecks;
    },
  },
  watch: {
    shiftLat() {
      this.refreshMap();
    },
    shiftChecks() {
      this.refreshMap();
    },
  },
  beforeCreate() {
    this.$store.dispatch('calendar/fetchCalendarShiftChecks', {
      shiftId: this.f7route.params.shift_id,
    });
  },
  created() {},
  mounted() {
    this.refreshMap();
  },
  methods: {
    refreshMap() {
      if (this.map === undefined) {
        this.map = new google.maps.Map(document.getElementById('checks-map'), {
          gestureHandling: 'cooperative',
          center: { lat: this.shiftLat, lng: this.shiftLng },
          zoom: 13,
        });
      }
      this.map.setCenter({ lat: this.shiftLat, lng: this.shiftLng });
      if (this.centerMarker === undefined) {
        this.centerMarker = new google.maps.Marker({
          position: { lat: this.shiftLat, lng: this.shiftLng },
          map: this.map,
          draggable: true,
        });
        this.centerMarker.addListener('dragend', () => {
          const pos = this.centerMarker.getPosition();
          // this.map.setCenter(pos);
          this.$store.dispatch('calendar/updateCalendarShiftLatLng', {
            lat: pos.lat(),
            lng: pos.lng(),
          });
        });
      }
      this.centerMarker.setPosition({ lat: this.shiftLat, lng: this.shiftLng });
      if (this.map !== undefined) {
        this.checks.forEach((checkMarker) => checkMarker.setMap(null));
        this.checks = [];
        this.shiftChecks.forEach((check) => {
          const marker = new google.maps.Marker({
            position: { lat: Number(check.lat), lng: Number(check.lon) },
            map: this.map,
            icon: `assets/img/${
              check.type === 'in' ? 'green' : 'yellow'
            }-dot.png`,
          });
          const contentString = `<div style="display: flex;align-items: center;">
            <img src="${
              check.ppic_a
            }" style="border-radius: 50%;height: 30px;width: 30px;" />
            <div style="margin-left: 5px;">
              <div style="font-weight: bold;">${check.name}</div>
              <div>${
                check.type === 'in'
                  ? 'checked in'
                  : check.type === 'in_attempt'
                  ? 'checked in attempt'
                  : 'checked out'
              }</div>
              <div>${check.time}</div>              
            </div>
          </div>`;
          const infowindow = new google.maps.InfoWindow({
            content: contentString,
          });
          marker.addListener('click', () => {
            infowindow.open(this.map, marker);
          });
          this.checks.push(marker);
        });
      }
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-sheet
    :close-by-outside-click="true"
    backdrop
    class="sc-profile-sisp-add-edit-sheet"
    @sheet:open="onSheetOpen"
  >
    <f7-toolbar>
      <div class="left">
        <f7-link
          icon-material="close"
          icon-only
          sheet-close
        />
      </div>
      <div class="right">
        <f7-link
          sheet-close
          @click="onSaveClick"
        >SAVE</f7-link>
      </div>
    </f7-toolbar>
    <f7-page-content>
      <f7-list no-hairlines>
        <f7-list-input
          outline
          floating-label
          label="Number"
          required
          validate
          clear-button
          type="number"
          :value="number"
          @input="number=$event.target.value"
        />
        <f7-list-input
          v-if="!isSignUp"
          outline
          floating-label
          label="Email"
          required
          validate
          clear-button
          type="email"
          :value="email"
          @input="email=$event.target.value"
        />
      </f7-list>
    </f7-page-content>
  </f7-sheet>
</template>
<script>
export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },
    isSignUp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      number: '',
      email: '',
    };
  },
  computed: {
    isLoadingData() { return this.$store.state.isLoadingData; },
    sisp() {
      return this.$store.state.profile.sisp;
    },
    userEmail() {
      return this.$store.state.account.user.email;
    },
  },
  methods: {
    onSheetOpen() {
      if (this.sisp.sisp_number) {
        this.number = this.sisp.sisp_number;
        this.email = this.userEmail;
      } else {
        this.number = '';
        this.email = '';
      }
    },
    onSaveClick() {
      if (!this.isSignUp && this.number && this.email) {
        this.$store.dispatch('profile/setSafeInStorePass', {
          userId: this.userId,
          number: this.number,
          email: this.email,
        });
      } else if (this.isSignUp && this.number) {
        this.$store.dispatch('profile/setSafeInStorePass', {
          userId: this.userId,
          number: this.number,
        });
      }
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <f7-popup tablet-fullscreen class="sc-users-filter">
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>Users Filter</f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close @click="onResetClick">RESET</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-list no-hairlines no-hairlines-between>
          <f7-list-item :smart-select-params="{ openIn: 'sheet', closeOnSelect: true }" title="User Type" smart-select>
            <select name="users-type-filter" @change="onFilterChanged($event)">
              <optgroup v-for="(optionGroup, ogIndx) in usersTypeFilter" :key="ogIndx" :label="optionGroup.text">
                <option
                  v-for="option in optionGroup.children"
                  :key="option.id"
                  :value="option.id"
                  :selected="isSelected(option.id)"
                >
                  {{ option.text }}
                </option>
              </optgroup>
            </select>
          </f7-list-item>

          <f7-list-input
            v-for="(filter, fIndx) in usersFilter"
            :key="fIndx"
            :input-id="fIndx"
            :label="filter.text"
            floating-label
            outline
            type="select"
            :default-value="-1"
            @change="onFilterChanged($event)"
          >
            <option :value="-1">Not selected</option>
            <option
              v-for="option in filter.children"
              :key="option.id"
              :value="option.id"
              :selected="isSelected(option.id)"
            >
              {{ option.text }}
            </option>
          </f7-list-input>
        </f7-list>

        <f7-block>
          <f7-row class="justify-content-center">
            <f7-col width="35">
              <f7-button outline round popup-close color="red" @click="onApplyClick">APPLY</f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import _ from 'lodash';

export default {
  computed: {
    usersFilter() {
      return this.$store.state.users.usersFilter;
    },
    usersTypeFilter() {
      return this.$store.state.users.usersTypeFilter;
    },
    fetchUsersFilter() {
      return this.$store.state.users.fetchUsersFilter;
    },
  },
  beforeCreate() {
    if (_.isEmpty(this.$store.state.users.usersFilter)) {
      this.$store.dispatch('users/fetchUsersFilter');
    }
    if (_.isEmpty(this.$store.state.users.usersTypeFilter)) {
      this.$store.dispatch('users/fetchUsersTypeFilter');
    }
  },
  methods: {
    isSelected(value) {
      return _.indexOf(this.fetchUsersFilter, value) !== -1;
    },
    onFilterChanged(event) {
      const option = event.target.value;
      if (option !== -1) {
        const filterType = _.split(option, ':', 1)[0];
        const newFetchUsersFilter = [...this.fetchUsersFilter];
        _.remove(newFetchUsersFilter, (val) => _.startsWith(val, filterType));
        newFetchUsersFilter.push(option);
        this.$store.commit('users/setFetchUsersFilter', newFetchUsersFilter);
      }
    },
    onResetClick() {
      this.$store.commit('users/setFetchUsersFilter', ['utype:=:all']);
      this.$store.dispatch('users/fetchUsersList', { filter: true });
    },
    onApplyClick() {
      this.$store.dispatch('users/fetchUsersList', { filter: true });
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-popup tablet-fullscreen class="sc-home-notifications-popup">
    <f7-page>
      <f7-navbar no-shadow>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close />
        </f7-nav-left>
        <f7-nav-title>Notifications</f7-nav-title>
      </f7-navbar>
      <f7-list media-list no-hairlines class="no-margin-top">
        <f7-list-group>
          <f7-list-item v-if="pinnedPosts.length > 0" title="Pinned Posts" group-title />
          <f7-list-item
            v-for="post in pinnedPosts"
            :key="'p' + post.post_id"
            :title="post.title"
            :link="`/post/${post.post_id}/`"
            no-chevron
            popup-close
          />
        </f7-list-group>

        <f7-list-group>
          <f7-list-item v-if="notifications.length > 0" title="Notifications" group-title />
          <f7-list-item
            v-for="(not, index) in notifications"
            :key="'n' + index"
            :link="getNotificationLink(index)"
            :title="not.text"
            :text="not.ago ? not.ago : 'Just now'"
            no-chevron
            popup-close
            @click="onNotificationClick(index)"
          >
            <template #media>
              <img
                v-if="not.pic && not.pic.indexOf('https:') !== -1"
                :src="not.pic"
                width="50"
                style="border-radius: 50%"
              />
              <div v-else class="bg-color-blue text-color-white sc-notification-media">
                {{ !isNaN(not.pic) ? not.pic : '' }}
              </div>
            </template>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { f7 } from 'framework7-vue';

export default {
  computed: {
    pinnedPosts() {
      return this.$store.state.posts.pinnedPosts;
    },
    notifications() {
      return this.$store.state.posts.notifications;
    },
    user_id() {
      return this.$store.state.account.user.id;
    },
  },
  methods: {
    getNotificationLink(index) {
      let result = false;
      const not = this.notifications[index];
      if (['calendar', 'shift', 'profile', 'xtrm_setup', 'post', 'completed_registrants'].indexOf(not.action) !== -1) {
        switch (not.action) {
          case 'calendar':
            result = '/calendar/';
            break;
          case 'xtrm_setup':
            result = '/profile/payment/';
            break;
          case 'shift':
            result = `/calendar/shift/${not.other_id}/`;
            break;
          case 'post':
            result = `/post/${not.other_id}/`;
            break;
          case 'user':
            result = `/users/profile/${not.other_id}/`;
            break;
          case 'completed_registrants':
            result = '/users/?filter=complete';
            break;
          case 'profile':
            if (not.other_id === this.user_id) {
              result = '/profile/info/';
            }
            break;
          default:
            result = false;
        }
      }
      return result;
    },
    onNotificationClick(index) {
      const not = this.notifications[index];
      if (not.action === 'report') {
        f7.popup.close('.sc-home-notifications-popup');
        this.$store.commit('calendar/setFormQuizConnectPopup', {
          isPopupOpened: true,
          type: 'QUIZ',
          action: '',
          quiz: not.data,
        });
      }
    },
  },
};
</script>
<style scoped>
.sc-notification-media {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}
</style>

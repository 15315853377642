<template>
  <f7-page-content>
    <f7-block class="display-flex justify-content-center">
      <f7-icon material="done" size="55" class="color-green" />
    </f7-block>
    <f7-block>
      <p class="text-color-gray">{{ infoMessage }}</p>
    </f7-block>
    <f7-row class="margin justify-content-center">
      <f7-button :disabled="isLoadingData" @click="onQuitClick">
        {{ skillsLimits && skillsLimits.quit_text ? skillsLimits.quit_text : 'Quit' }}
      </f7-button>
    </f7-row>
  </f7-page-content>
</template>
<script>
import { f7 } from 'framework7-vue';

export default {
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    infoMessage() {
      return this.$store.state.account.settings.registration_msg_complete;
    },
    skillsLimits() {
      return this.$store.state.account.settings.registration_skills_limits;
    },
  },
  methods: {
    onQuitClick() {
      this.$store.dispatch('account/logout').then(() => {
        f7.views.main.router.navigate('/', {
          reloadAll: true,
          reloadCurrent: true,
          ignoreCache: true,
        });
      });
    },
  },
};
</script>

<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:open="onPopupOpen" @popup:closed="onPopupClosed">
    <f7-page name="shift-role-complete" bg-color="white">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close icon-material="close" icon-only />
        </f7-nav-left>
        <f7-nav-title :title="roleName" />
        <template #after-inner>
          <f7-progressbar v-show="isLoadingData" infinite />
        </template>
      </f7-navbar>
      <f7-toolbar bottom no-shadow>
        <f7-link />

        <f7-link v-show="!isLoadingData" icon-material="check_circle" @click="onCompleteClick">COMPLETE</f7-link>

        <f7-preloader v-show="isLoadingData" :size="32" color="white" />
        <f7-link />
      </f7-toolbar>

      <f7-block inset>{{ completionNotes }}</f7-block>

      <f7-block-title v-show="isStaffChangeTimes">Times</f7-block-title>
      <f7-block inset v-show="isStaffChangeTimes && isTimesLocked"
        >Your times have been confirmed and cannot be changed.</f7-block
      >
      <f7-block inset v-show="isStaffChangeTimes">
        <f7-list no-hairlines>
          <f7-list-input
            input-id="start-time"
            outline
            floating-label
            readonly
            label="Start"
            type="text"
            :disabled="isTimesLocked"
            :value="start"
            @change="start = $event.target.value"
          />
          <f7-list-input
            input-id="end-time"
            outline
            floating-label
            readonly
            label="End"
            type="text"
            :disabled="isTimesLocked"
            :value="end"
            @change="end = $event.target.value"
          />
        </f7-list>
      </f7-block>

      <template v-if="required.uploads || required.reports">
        <f7-block-title>Requirements</f7-block-title>
        <f7-list inset>
          <f7-list-item
            v-if="required.uploads && required.uploads.required"
            :title="`Uploads: ${required.uploads.numbers}`"
          >
            <template #after>
              <f7-icon v-if="required.uploads.completed" material="check_circle" color="green" />
              <f7-icon v-else material="error" color="red" />
            </template>
          </f7-list-item>
          <f7-list-item
            v-if="required.reports && required.reports.required"
            :title="`Surveys: ${required.reports.numbers}`"
          >
            <template #after>
              <f7-icon v-if="required.reports.completed" material="check_circle" color="green" />
              <f7-icon v-else material="error" color="red" />
            </template>
          </f7-list-item>
        </f7-list>
      </template>
    </f7-page>
  </f7-popup>
</template>

<script>
import { f7 } from 'framework7-vue';

export default {
  data() {
    return {
      start: '',
      end: '',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isStaffChangeTimes() {
      return this.$store.state.account.settings.shift_staff_change_times == 1;
    },
    isTimesLocked() {
      return this.$store.state.calendar.completePopup.timesLocked == 1;
    },
    shift() {
      return this.$store.state.calendar.shift;
    },
    isPopupOpened() {
      return this.$store.state.calendar.completePopup.isPopupOpened;
    },
    roleStaffId() {
      return this.$store.state.calendar.completePopup.roleStaffId;
    },
    roleName() {
      return this.$store.state.calendar.completePopup.roleName;
    },
    completionNotes() {
      return this.$store.state.calendar.completePopup.completionNotes;
    },
    required() {
      return this.$store.state.calendar.completePopup.required;
    },
  },
  mounted() {
    this.startTimeElement = f7.picker.create({
      inputEl: '#start-time',
      rotateEffect: true,
      formatValue: (values) => `${values[0]}:${values[1]} ${values[2]}`,
      cols: [
        {
          textAlign: 'left',
          values: '1,2,3,4,5,6,7,8,9,10,11,12'.split(','),
        },
        {
          divider: true,
          content: ':',
        },
        {
          values: (
            '00 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 ' +
            '21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 ' +
            '41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59'
          ).split(' '),
        },
        {
          divider: true,
          content: ' ',
        },
        {
          values: 'am,pm'.split(','),
        },
      ],
    });
    this.endTimeElement = f7.picker.create({
      inputEl: '#end-time',
      rotateEffect: true,
      formatValue: (values) => `${values[0]}:${values[1]} ${values[2]}`,
      cols: [
        {
          textAlign: 'left',
          values: '1,2,3,4,5,6,7,8,9,10,11,12'.split(','),
        },
        {
          divider: true,
          content: ':',
        },
        {
          values: (
            '00 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 ' +
            '21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 ' +
            '41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59'
          ).split(' '),
        },
        {
          divider: true,
          content: ' ',
        },
        {
          values: 'am,pm'.split(','),
        },
      ],
    });
  },
  methods: {
    onPopupOpen() {
      let arr, hour, min, meriden;
      arr = this.$store.state.calendar.completePopup.start.split(':');
      hour = arr[0];
      arr = arr[1].split(' ');
      min = arr[0];
      meriden = arr[1];
      this.startTimeElement.setValue([hour, min, meriden]);
      arr = this.$store.state.calendar.completePopup.end.split(':');
      hour = arr[0];
      arr = arr[1].split(' ');
      min = arr[0];
      meriden = arr[1];
      this.endTimeElement.setValue([hour, min, meriden]);
    },
    onPopupClosed() {
      this.$store.commit('calendar/setCalendarCompletePopup', {
        isPopupOpened: false,
        roleStaffId: null,
        roleName: '',
        completionNotes: '',
        start: '',
        end: '',
        timesLocked: 1,
        required: {},
      });
    },
    onCompleteClick() {
      if (this.required.uploads && this.required.uploads.required && !this.required.uploads.completed) {
        f7.toast
          .create({
            text: 'Please complete all requirements',
            closeTimeout: 2000,
            closeButton: true,
            closeButtonText: 'Close',
            closeButtonColor: 'red',
          })
          .open();
      } else {
        this.$store
          .dispatch('calendar/completeCalendarShiftRole', {
            roleStaffId: this.roleStaffId,
            start: this.start,
            end: this.end,
          })
          .then((result) => {
            f7.popup.close();
            f7.toast
              .create({
                text: result.data.message,
                closeTimeout: 2000,
                closeButton: true,
                closeButtonText: 'Close',
                closeButtonColor: 'blue',
              })
              .open();
          });
      }
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-page>
    <sc-file-picker-multiple
      @sc-file-picker:ready="openFilePicker = $event"
      @sc-file-picker:loaded="onFileLoaded"
    />
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Shared Files" />
      <f7-nav-right>
        <f7-link
          v-if="isUserLvlOwnerOrAdmin"
          icon-only
          icon-material="add"
          @click="openFilePicker"
        />
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-shared-files"
          icon-material="search"
        />
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-shared-files"
        expandable
        search-container=".search-list"
        search-in=".item-title"
      />
      <template #after-inner>
        <f7-progressbar
          v-show="isLoadingData && sharedFiles.length > 0"
          infinite
        />
      </template>
    </f7-navbar>
    <f7-block
      v-show="isLoadingData && sharedFiles.length === 0"
      inset
      class="text-align-center"
    >
      <f7-preloader />
    </f7-block>
    <f7-list no-hairlines no-chevron class="searchbar-not-found">
      <f7-list-item title="Nothing found" />
    </f7-list>
    <f7-list no-hairlines no-chevron class="search-list searchbar-found">
      <f7-list-item
        v-for="file in sharedFiles"
        :key="file.id"
        :title="file.name"
        :footer="file.uploader"
        link="#"
        swipeout
        @click="onExternalLinkClick(file.path)"
      >
        <template #media>
          <img :src="file.thumbnail" class="sc-shift__file__pic" />
        </template>
        <f7-swipeout-actions v-if="isUserLvlOwnerOrAdmin" right>
          <f7-swipeout-button
            close
            color="red"
            @click="onFileDeleteClick(file.id)"
          >
            <f7-icon material="delete" color="white" />
          </f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>
    <f7-block v-if="!isLoadingData && !(sharedFiles.length > 0)">
      <p class="text-color-gray">No files available.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';
import scFilePickerMultiple from '../components/FilePickerMultiple.vue';

export default {
  components: {
    scFilePickerMultiple,
  },

  data() {
    return {
      openFilePicker: () => {},
      isFileUploading: false,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    sharedFiles() {
      return this.$store.state.profile.sharedFiles;
    },
  },
  beforeCreate() {
    this.$store.dispatch('profile/fetchSharedFiles');
  },
  methods: {
    onExternalLinkClick(link) {
      window.open(link, '_system');
    },
    onFileLoaded(files) {
      this.$store.dispatch('profile/uploadSharedFiles', {
        files,
      });
    },
    onFileDeleteClick(documentId) {
      f7.dialog.confirm(
        "This file will be deleted and you won't be able to find it anymore.",
        'Delete the file?',
        () => {
          this.$store.dispatch('profile/deleteSharedFile', { documentId });
        }
      );
    },
  },
};
</script>
<style scoped>
.sc-shift__file__pic {
  height: 45px;
}
</style>

<template>
  <f7-popup tablet-fullscreen :opened="isOpened" @popup:closed="onClosed">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close />
        </f7-nav-left>
        <f7-nav-title>Select User</f7-nav-title>
      </f7-navbar>
      <f7-list media-list no-hairlines>
        <f7-list-item
          v-for="user in usersList"
          :key="user.id"
          :checked="isChecked(user.id)"
          :title="`${user.fname} ${user.lname}`"
          :subtitle="user.email"
          :value="user.id"
          checkbox
          @change="onUserItemChange"
        >
          <template #media>
            <img :src="user.ppic_a || 'assets/img/default-user-image.png'" />
          </template>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import _ from 'lodash';

export default {
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['sc-mentions-list-popup:closed'],

  data() {
    return {
      usersList: [],
      mentions: [],
    };
  },
  created() {
    this.$store.dispatch('users/fetchOwnerAdminUsersList').then((result) => {
      if (result !== false) {
        this.usersList = result;
      }
    });
  },
  methods: {
    onClosed() {
      this.$emit('sc-mentions-list-popup:closed', this.mentions);
      this.mentions = [];
    },
    isChecked(id) {
      return _.find(this.mentions, { id }) !== undefined;
    },
    onUserItemChange: function (event) {
      const selectedUser = _.find(this.usersList, { id: Number(event.target.value) });
      if (event.target.checked) {
        this.mentions.push({ id: selectedUser.id, name: `${selectedUser.fname} ${selectedUser.lname}` });
      } else {
        this.mentions = _.filter(this.mentions, (m) => m.id !== selectedUser.id);
      }
    },
  },
};
</script>
<style scoped>
.item-media img {
  border-radius: 50%;
  width: 50px;
}
</style>

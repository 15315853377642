<template>
  <!-- <f7-input
    class="margin-horizontal"
    outline
    floating-label
    label="Note"
    type="textarea"
    @input="onInput"
    @change="onChange"
  /> -->
  <f7-list no-hairlines class="no-margin-vertical">
    <f7-list-input
      input-id="textarea"
      outline
      floating-label
      label="Note"
      type="textarea"
      :value="initText"
      @input="onInput"
    />
  </f7-list>
  <sc-mentions-list-popup
    :is-opened="isMentionListPopupOpened"
    @sc-mentions-list-popup:closed="onMentionsListPopupClosed"
  />
</template>
<script>
import _ from 'lodash';
import $ from 'dom7';
import scMentionsListPopup from './MentionsListPopup.vue';
export default {
  components: {
    scMentionsListPopup,
  },
  props: {
    initText: {
      type: String,
      required: true,
    },
  },
  emits: ['sc-mentions-textarea:changed'],
  data() {
    return {
      isMentionListPopupOpened: false,
      noteText: '',
      metions: [],
      insertPosition: 0,
    };
  },
  watch: {
    noteText() {
      const mentions = this.metions.reduce((acc, curr) => {
        acc.push(curr.id);
        return acc;
      }, []);
      this.$emit('sc-mentions-textarea:changed', { noteText: this.noteText.trim(), mentions });
    },
  },
  methods: {
    onInput(event) {
      this.noteText = event.target.value;
      if (event.data === '@') {
        this.insertPosition = event.target.selectionEnd;
        this.isMentionListPopupOpened = true;
        $('#textarea').blur();
      } else {
        if ((this.noteText.match(/@/g) || []).length !== this.metions.length) {
          const toDeleteIds = [];
          this.metions.map((m) => {
            if (this.noteText.indexOf(`@${m.name}`) === -1) {
              toDeleteIds.push(m.id);
            }
          });
          toDeleteIds.map((id) => (this.metions = _.filter(this.metions, (m) => m.id !== id)));
        }
      }
    },
    onMentionsListPopupClosed(mentions) {
      this.isMentionListPopupOpened = false;
      mentions.map((m) => {
        if (_.find(this.metions, { id: m.id }) === undefined) {
          this.metions = [...this.metions, m];
        }
      });
      this.noteText = [
        this.noteText.slice(0, this.insertPosition - 1),
        mentions.reduce((acc, cur) => acc + `@${cur.name} `, ''),
        this.noteText.slice(this.insertPosition),
      ].join('');
    },
  },
};
</script>

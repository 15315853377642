<template>
  <f7-popup tablet-fullscreen class="sc-payrolls-filter" @popup:opened="onPopupOpened">
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>Filter</f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close @click="onResetClick">RESET</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-list no-hairlines no-hairlines-between>
          <f7-list-input
            label="Status"
            floating-label
            outline
            type="select"
            :value="payrollType"
            @change="payrollType = $event.target.value"
          >
            <option value="all">All</option>
            <option value="approved">Approved</option>
            <option value="processing">Processing</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            <option value="rejected">Rejected</option>
          </f7-list-input>
          <f7-list-input
            outline
            floating-label
            readonly
            label="Date From"
            type="datepicker"
            placeholder="Date From"
            :calendar-params="{
              closeOnSelect: true,
              dateFormat: 'M dd yyyy',
              locale: 'en',
              routableModals: false,
            }"
            :value="dateFrom"
            @change="dateFrom = $event.target.value ? [$event.target.value] : []"
          />
          <f7-list-input
            outline
            floating-label
            readonly
            label="Date To"
            type="datepicker"
            placeholder="Date To"
            :calendar-params="{
              closeOnSelect: true,
              dateFormat: 'M dd yyyy',
              locale: 'en',
              routableModals: false,
            }"
            :value="dateTo"
            @change="dateTo = $event.target.value ? [$event.target.value] : []"
          />

          <li
            v-if="isUserLvlOwnerOrAdmin"
            class="item-content item-input item-input-outline"
            :class="{ 'item-input-with-value': selectedUsers.length > 0 }"
            @click="onSelectUserClick"
          >
            <div class="item-inner">
              <div class="item-title item-floating-label">Users</div>
              <div class="item-input-wrap">
                <div style="margin: 10px 5px">
                  <f7-chip
                    v-for="user in selectedUsers"
                    :key="user.id"
                    style="margin-right: 5px"
                    :text="user.name"
                    deleteable
                    @delete.stop="onSelectedUserDeleteClick(user.id)"
                  />
                </div>
                <input
                  id="sc-users-select-input"
                  type="text"
                  style="width: 0px; height: 0px"
                  @focus="onSelectUserFocus"
                />
              </div>
            </div>
          </li>
        </f7-list>

        <f7-block>
          <f7-row class="justify-content-center">
            <f7-col width="35">
              <f7-button outline round popup-close @click="onApplyClick">APPLY</f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
        <sc-payrolls-filter-users-select-popup
          :selected-users="selectedUsers"
          @sc-payrolls-filter-users-select:selected="onUsersSelected($event)"
        />
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import { f7 } from 'framework7-vue';
import $ from 'dom7';

import _ from 'lodash';
import scPayrollsFilterUsersSelectPopup from './PayrollsFilter/PayrollsFilterUsersSelectPopup.vue';

export default {
  components: {
    scPayrollsFilterUsersSelectPopup,
  },
  props: {
    initPayrollType: {
      type: String,
      default: 'all',
    },
    initSelectedUsers: {
      type: Array,
      default: () => [],
    },
    initDateFrom: {
      type: Array,
      default: () => [],
    },
    initDateTo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      payrollType: this.initPayrollType,
      selectedUsers: this.initSelectedUsers,
      dateFrom: this.initDateFrom,
      dateTo: this.initDateTo,
    };
  },
  computed: {
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
  },
  methods: {
    onPopupOpened() {
      this.payrollType = this.initPayrollType;
      this.selectedUsers = this.initSelectedUsers;
      this.dateFrom = this.initDateFrom;
      this.dateTo = this.initDateTo;
    },
    onSelectUserFocus() {
      f7.popup.open('.sc-payrolls-filter-users-select');
    },
    onSelectUserClick() {
      $('#sc-users-select-input').focus();
    },
    onUsersSelected(selectedUsers) {
      this.selectedUsers = selectedUsers;
    },
    onResetClick() {
      this.$store.commit('payrolls/setFetchFilter');
      this.$store.dispatch('payrolls/fetchPayrolls');
    },
    onApplyClick() {
      this.$store.commit('payrolls/setFetchFilter', {
        payrollType: this.payrollType,
        selectedUsers: this.selectedUsers,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });
      this.$store.dispatch('payrolls/fetchPayrolls');
    },
    onSelectedUserDeleteClick(userId) {
      this.selectedUsers = _.filter(this.selectedUsers, (user) => user.id !== userId);
    },
  },
};
</script>
<style scoped></style>

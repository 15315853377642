import axios from '../../libs/axios';


export default {

  fetchUsersList(payload) {
    return axios.get(`/users/${payload.usersOnPage}/${payload.pageId}/${payload.filter}`)
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  fetchUsersFilter() {
    return axios.get('/users/filter')
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

  fetchUsersTypeFilter() {
    return axios.get('/users/typeFilter')
      .then(response => response)
      .catch(error => this.apiErrorHandler(error));
  },

};

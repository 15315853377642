<template>
  <f7-list no-hairlines class="no-margin-top">
    <f7-list-group v-for="category in skills" :key="category.id">
      <f7-list-item :title="category.cname" group-title />
      <f7-list-item
        v-for="skill in category.attributes"
        :key="skill.id"
        :checked="skill.set === 1"
        :title="skill.aname"
        :disabled="isDisabled(category.id, skill.id)"
        checkbox
        @change="onElementChanged(skill.id, $event)"
      >
        <template v-if="skill.expires" #after>
          <f7-input
            :input-id="`datepicker${skill.id}`"
            type="datepicker"
            :value="skill.expires_at ? [formatDate(skill.expires_at)] : []"
            placeholder="Expiry Date"
            readonly
            :disabled="skill.set !== 1"
            :calendar-params="{
              closeOnSelect: true,
              dateFormat: 'M dd yyyy',
              locale: 'en',
              routableModals: false,
            }"
            @click.stop
            @change="onElementExpiryDateChanged(skill.id, $event)"
          />
        </template>
      </f7-list-item>
    </f7-list-group>
  </f7-list>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import $ from 'dom7';

export default {
  props: {
    skills: {
      type: Array,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      attributes: undefined,
    };
  },
  computed: {
    skillsLimits() {
      return this.$store.state.account.settings.registration_skills_limits;
    },
  },
  beforeUpdate() {
    const attributes = [];
    this.skills.forEach((category) => {
      category.attributes.forEach((skill) => {
        attributes.push({
          categoryId: category.id,
          skillId: skill.id,
          set: skill.set,
          expires_at: skill.expires_at,
          expires: skill.expires,
        });
      });
    });
    this.attributes = attributes;
  },
  methods: {
    onElementChanged(skillId, event) {
      const set = event.target.checked ? 1 : 0;
      const skill = _.find(this.attributes, { skillId: skillId });
      skill.set = set;
      this.$store.dispatch('profile/setProfileSkillsQualifications', {
        attributeId: skillId,
        set,
        userId: this.userId,
      });
      if (skill.expires) {
        $(`#datepicker${skillId}`).prop({
          disabled: skill.set ? false : true,
        });
        setTimeout(() => {
          if (skill.set === 0) {
            $(`#datepicker${skillId}`).val('');
          }
        });
      }
    },
    onElementExpiryDateChanged(skillId, $event) {
      const expiryDate =
        $event.target.value !== '' ? moment($event.target.value, 'MMM DD YYYY').format('YYYY-MM-DD') : null;
      const skill = _.find(this.attributes, { skillId: skillId });
      if (skill && expiryDate !== skill.expires_at) {
        this.$store.dispatch('profile/setProfileSkillsQualifications', {
          attributeId: skillId,
          set: skill.set,
          expiryDate: expiryDate,
          userId: this.userId,
        });
      }
    },
    isDisabled(categoryId, skillId) {
      if (!this.skillsLimits || (this.attributes && _.find(this.attributes, { skillId: skillId }).set === 1)) {
        return false;
      }
      let isDisabled = false;
      const selected = _.filter(this.attributes, (skill) => skill.set === 1);
      const selectedCategoryes = _.groupBy(selected, (skill) => skill.categoryId);
      if (
        _.size(selectedCategoryes) >= this.skillsLimits.category_limit ||
        _.filter(selected, { categoryId: categoryId }).length >= this.skillsLimits.attribute_limit
      ) {
        isDisabled = true;
      }
      if (
        _.some(selected, { categoryId: categoryId }) &&
        _.filter(selected, { categoryId: categoryId }).length < this.skillsLimits.attribute_limit
      ) {
        isDisabled = false;
      }
      return isDisabled;
    },
    formatDate(date) {
      return moment(date).format('MMM D YYYY');
    },
  },
};
</script>

<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:closed="onPopupClosed" @popup:open="onPopupOpen">
    <f7-page name="shift-role-check-in-out">
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close />
        </f7-nav-left>
        <f7-nav-title :title="title" />
        <template #after-inner>
          <f7-progressbar v-show="isLoadingData" infinite />
        </template>
      </f7-navbar>
      <f7-toolbar bottom no-shadow>
        <f7-link />

        <f7-link
          v-if="type === 'check_in'"
          v-show="!isLoadingData"
          icon-material="location_on"
          @click="onCheckInOutClick('check_in')"
          >CHECK IN</f7-link
        >
        <f7-link
          v-if="type === 'end_break'"
          v-show="!isLoadingData"
          icon-material="directions_walk"
          @click="onCheckInOutClick('end_break')"
          >END BREAK</f7-link
        >
        <f7-link
          v-if="type === 'check_out'"
          v-show="!isLoadingData"
          icon-material="location_on"
          @click="onCheckInOutClick('check_out')"
          >CHECK OUT</f7-link
        >
        <f7-link
          v-if="type === 'start_break'"
          v-show="!isLoadingData"
          icon-material="directions_walk"
          @click="onCheckInOutClick('start_break')"
          >START BREAK</f7-link
        >

        <f7-preloader v-show="isLoadingData" :size="32" color="white" />
        <f7-link />
      </f7-toolbar>
      <sc-file-picker
        @sc-file-picker:ready="openFilePicker = $event"
        @sc-file-picker:loading="isFileLoading = true"
        @sc-file-picker:loaded="onFileLoaded"
      />

      <div
        id="check-in-out--map"
        ref="check-in-out--map"
        class="justify-content-center align-items-center display-flex text-align-center"
      >
        <f7-preloader v-if="!errorLocation" size="40px" />
        <span class="margin">{{ error_message }}</span>
      </div>

      <f7-card v-show="file.src !== '' || file.blob !== null">
        <f7-card-header v-show="file.name">{{ file.name }}</f7-card-header>
        <f7-card-content class="text-align-center">
          <img v-show="file.type === 'image'" :src="file.src" width="100%" />
          <f7-icon
            v-show="file.type !== 'image'"
            material="insert_drive_file"
            icon="margin-vertical md-72"
            color="gray"
          />
        </f7-card-content>
        <f7-card-footer>
          <div>{{ file.createdAt }}</div>
          <f7-button icon-material="delete" color="gray" @click="onFileDeleteClick()" />
        </f7-card-footer>
      </f7-card>

      <div
        v-if="(shift.check_out_photo || shift.check_in_photo) && file.blob === null"
        id="info"
        class="justify-content-center align-items-center display-flex text-align-center"
      >
        <div class="sc-add-photo justify-content-center align-items-center display-flex text-align-center">
          <span class="text-color-red">Photo is required</span>
        </div>
      </div>

      <f7-block v-show="!isFileLoading">
        <f7-row class="margin-vertical justify-content-center">
          <f7-button class="button-large button-fill button-raised" icon-material="attach_file" @click="openFilePicker"
            >UPLOAD PHOTO</f7-button
          >
        </f7-row>
      </f7-block>

      <f7-list no-hairlines>
        <f7-list-input
          :value="noteText"
          label="Optional - Notes"
          floating-label
          type="textarea"
          resizable
          placeholder="Write something ..."
          @input="noteText = $event.target.value"
        />
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { f7 } from 'framework7-vue';

import scFilePicker from '../../../../../components/SCFilePicker.vue';

export default {
  components: {
    scFilePicker,
  },
  data() {
    return {
      noteText: '',
      errorLocation: false,
      error_message: 'Please wait. Getting your location ...',
      complete: false,
      latitude: null,
      longitude: null,
      isFileLoading: false,
      openFilePicker: () => {},
      file: {
        blob: null,
        name: '',
        type: '',
        createdAt: '',
        src: '',
      },
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    shift() {
      return this.$store.state.calendar.shift;
    },
    isPopupOpened() {
      return this.$store.state.calendar.checkInOutPopup.isPopupOpened;
    },
    type() {
      return this.$store.state.calendar.checkInOutPopup.type;
    },
    roleStaffId() {
      return this.$store.state.calendar.checkInOutPopup.role_staff_id;
    },
    title() {
      switch (this.$store.state.calendar.checkInOutPopup.type) {
        case 'check_in':
          return 'Check In';
        case 'check_out':
          return 'Check Out';
        case 'start_break':
          return 'Start Break';
        case 'end_break':
          return 'End Break';
      }
    },
  },

  methods: {
    onFileLoaded(file) {
      if (file !== null) {
        this.file.blob = file.blob;
        this.file.name = file.name;
        this.file.type = file.type;
        this.file.createdAt = file.createdAt;
        this.file.src = file.src;
      }
      this.isFileLoading = false;
    },
    onFileDeleteClick() {
      this.file.blob = null;
      this.file.name = '';
      this.file.type = '';
      this.file.createdAt = '';
      this.file.src = '';
    },
    onPopupOpen() {
      this.noteText = '';
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.map = new google.maps.Map(document.getElementById('check-in-out--map'), {
            gestureHandling: 'cooperative',
            fullscreenControl: false,
            zoom: 15,
          });
          this.map.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          const marker = new google.maps.Marker({
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            map: this.map,
          });
        },
        (error) => {
          this.error_message = error.message;
          this.errorLocation = true;
        },
        { timeout: 20000, enableHighAccuracy: true }
      );
    },
    onPopupClosed() {
      this.$store.commit('calendar/setCalendarCheckInOutPopup', {
        isPopupOpened: false,
        type: 'check_in',
        role_staff_id: null,
      });
      this.file.blob = null;
      this.file.name = '';
      this.file.type = '';
      this.file.createdAt = '';
      this.file.src = '';
      this.isFileLoading = false;
    },

    onCheckInOutClick(type) {
      let checkInOutType;
      switch (type) {
        case 'check_in':
          checkInOutType = 'checkInCalendarShiftRole';
          break;
        case 'check_out':
          checkInOutType = 'checkOutCalendarShiftRole';
          break;
        case 'start_break':
          checkInOutType = 'startBreakCalendarShiftRole';
          break;
        case 'end_break':
          checkInOutType = 'endBreakCalendarShiftRole';
          break;
      }
      const payload = {
        image_blob: this.file.blob,
        role_staff_id: this.roleStaffId,
        latitude: this.latitude,
        longitude: this.longitude,
        note: this.noteText,
      };
      if (
        ((this.shift.check_in_photo && type === 'check_in') || (this.shift.check_out_photo && type === 'check_out')) &&
        this.file.blob === null
      ) {
        f7.toast
          .create({
            text: 'Photo is required',
            closeTimeout: 2000,
            closeButton: true,
            closeButtonText: 'Close',
            closeButtonColor: 'red',
          })
          .open();
      } else {
        this.$store.dispatch(`calendar/${checkInOutType}`, payload).then((result) => {
          if (result !== false) {
            f7.popup.close();
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.sc-media-form {
  height: 0px;
  visibility: hidden;
}
#check-in-out--map {
  height: 30%;
  width: 100%;
  background-color: #f5f5f5;
  font-size: 28px;
  color: #bdbdbd;
  flex-flow: column;
}
#info {
  height: 40%;
  width: 100%;
  font-size: 16px;
  color: #bdbdbd;
}
.sc-add-photo {
  flex-direction: column;
}

.sc-image-card {
  min-width: 120px;
}
.sc-image-card .card-content {
  min-height: 120px;
}
.sc-image-card .card-content img {
  width: 100%;
  display: block;
}
</style>

<template>
  <f7-page-content>
    <f7-block>
      <p class="text-color-gray">{{ infoMessage }}</p>
    </f7-block>
    <f7-list v-if="structure.length" no-hairlines accordion-list>
      <sc-profile-info-category
        v-for="category in structure"
        :key="category.id"
        :category="category"
      />
    </f7-list>
    <f7-row class="margin justify-content-flex-end">
      <f7-button
        :disabled="isLoadingData"
        raised
        fill
        @click="registerNextStep"
      >
        Save & Continue
      </f7-button>
    </f7-row>
  </f7-page-content>
</template>
<script>
import scProfileInfoCategory from '../../components/Profile/Info/ProfileInfoCategory.vue';

export default {
  components: {
    scProfileInfoCategory,
  },
  data() {
    return {};
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    structure() {
      return this.$store.state.profile.structure;
    },
    infoMessage() {
      return this.$store.state.account.settings.profile_info_message;
    },
  },
  beforeCreate() {
    this.$store.commit('profile/setIsProfileReadonly', false);
    this.$store.commit('profile/resetErrorProfileElements');
    this.$store.commit('profile/resetProfileElementsForUpdate');
    this.$store.dispatch('profile/getProfileStructure', {
      userId: this.$store.state.account.user.id,
    });
  },
  methods: {
    registerNextStep() {
      this.$store
        .dispatch('profile/updateProfileElements', {
          userId: this.$store.state.account.user.id,
        })
        .then((result) => {
          if (result !== false) {
            this.$store.dispatch('account/registerNextStep');
          }
        });
    },
  },
};
</script>

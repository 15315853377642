<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:opened="onPopupOpened" @popup:closed="$emit('closed')">
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>{{ actionType === 'ADD' ? 'New Experience' : 'Edit Experience' }}</f7-nav-title>
          <f7-nav-right />
          <template #after-inner>
            <f7-progressbar v-show="isLoadingData" infinite />
          </template>
        </f7-navbar>

        <f7-list no-hairlines>
          <template v-for="input in fieldList">
            <f7-list-input
              v-if="input.options && input.options.length > 0 && input.type === 'list'"
              :key="input.id"
              :label="input.hname"
              :input="false"
              floating-label
              outline
              :wrap="false"
              class="item-input-with-value"
            >
              <template #input>
                <select :id="`h${input.id}`" :value="experience[`h${input.id}`] || ''" @change="onChange">
                  <option :value="''" disabled>Not selected</option>
                  <option v-for="op in input.options" :key="op.id" :value="op.id">
                    {{ op.oname }}
                  </option>
                </select>
              </template>
            </f7-list-input>
            <f7-list-input
              v-else-if="input.type === 'date'"
              :key="input.id"
              :input-id="`h${input.id}`"
              floating-label
              outline
              :label="input.hname"
              type="datepicker"
              placeholder="Select date"
              readonly
              :value="experience[`h${input.id}`] ? [experience[`h${input.id}`]] : []"
              :calendar-params="{
                closeOnSelect: true,
                dateFormat: 'M dd yyyy',
                locale: 'en',
                routableModals: false,
              }"
              @change="onChange"
            />
            <f7-list-input
              v-else-if="input.type === 'text' || input.type === 'number'"
              :key="input.id"
              :input-id="`h${input.id}`"
              floating-label
              outline
              :label="input.hname"
              :type="input.type"
              :value="experience[`h${input.id}`] || undefined"
              clear-button
              @change="onChange"
            />
          </template>
        </f7-list>
        <f7-row class="margin justify-content-center">
          <f7-button :disabled="isLoadingData" raised fill @click="onAddSaveClick">
            {{ actionType === 'ADD' ? 'ADD' : 'SAVE' }}
          </f7-button>
        </f7-row>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { f7 } from 'framework7-vue';

export default {
  props: {
    isPopupOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
    actionType: {
      type: String,
      required: true,
      default: 'ADD',
    },
    experienceCategoryId: {
      type: Number,
      default: undefined,
    },
    experienceId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      experience: {},
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    fieldList() {
      return this.experienceCategoryId !== undefined
        ? _.find(this.$store.state.profile.experience, {
            id: this.experienceCategoryId,
          }).headings
        : [];
    },
    experienceData() {
      let result = [];
      if (this.experienceId !== undefined) {
        const experiences = _.find(this.$store.state.profile.experience, {
          id: this.experienceCategoryId,
        }).experience;
        result = _.find(experiences, { id: this.experienceId }).data;
      }
      return result;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
  },
  methods: {
    onAddSaveClick() {
      if (this.actionType === 'ADD') {
        this.experience.experience_cat_id = this.experienceCategoryId;
        this.$store
          .dispatch('profile/addProfileExperience', {
            experience: this.experience,
            userId: this.userId,
          })
          .then((result) => {
            if (result !== false) {
              f7.popup.close();
            }
          });
      } else {
        this.$store
          .dispatch('profile/editProfileExperience', {
            experience: this.experience,
            userId: this.userId,
          })
          .then((result) => {
            if (result !== false) {
              f7.popup.close();
            }
          });
      }
    },
    onPopupOpened() {
      if (this.actionType === 'EDIT') {
        this.experience = {};
        this.fieldList.forEach((element) => {
          if (element.type !== 'date') {
            this.experience[`h${element.id}`] = this.experienceData[Number(element.display_order)];
          } else {
            const dateMoment = moment(this.experienceData[Number(element.display_order)]);
            this.experience[`h${element.id}`] = dateMoment.isValid() ? dateMoment.format('MMM DD YYYY') : undefined;
          }
          this.experience.id = this.experienceId;
        });
      } else {
        this.experience = {};
      }
    },
    onChange(event) {
      this.experience = {
        ...this.experience,
        [event.target.id]: event.target.value,
      };
    },
  },
};
</script>

<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:closed="onPopupClosed" @popup:opened="onPopupOpened">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close icon-material="close" icon-only />
        </f7-nav-left>
        <f7-nav-title :title="formName" />
      </f7-navbar>

      <div style="width: 100%; height: 100%; overflow: auto !important; -webkit-overflow-scrolling: touch !important">
        <iframe :src="iframeSRC" class="fc-iframe">
          <p>Your app does not support iframes.</p>
        </iframe>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';
import config from '../../../../../config';

export default {
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    type() {
      return this.$store.state.calendar.formQuizConnectPopup.type;
    },
    action() {
      return this.$store.state.calendar.formQuizConnectPopup.action;
    },
    isPopupOpened() {
      return this.$store.state.calendar.formQuizConnectPopup.isPopupOpened;
    },
    formID() {
      let formID;
      const { type, action } = this.$store.state.calendar.formQuizConnectPopup;
      const { forms_apply: formsApply, forms_confirm: formsConfirm } = this.$store.state.calendar.shift;
      if (type === 'FORM' && action === 'APPLY' && formsApply.length > 0) {
        formID = formsApply[0].id;
      }
      if (type === 'FORM' && action === 'CONFIRM' && formsConfirm.length > 0) {
        formID = this.$store.state.calendar.shift.forms_confirm[0].id;
      }
      return formID;
    },
    formName() {
      let formName;
      const { type, action } = this.$store.state.calendar.formQuizConnectPopup;
      const { forms_apply: formsApply, forms_confirm: formsConfirm } = this.$store.state.calendar.shift;
      if (type === 'FORM' && action === 'APPLY' && formsApply.length > 0) {
        formName = formsApply[0].fname;
      }
      if (type === 'FORM' && action === 'CONFIRM' && formsConfirm.length > 0) {
        formName = formsConfirm[0].fname;
      }
      if (type === 'SURVEY' || type === 'QUIZ') {
        formName = this.$store.state.calendar.formQuizConnectPopup.quiz.rname;
      }

      return formName;
    },
    iframeSRC() {
      let iframeSRC;
      const { type, action } = this.$store.state.calendar.formQuizConnectPopup;
      const { forms_apply: formsApply, forms_confirm: formsConfirm } = this.$store.state.calendar.shift;

      if (type === 'FORM' && action === 'APPLY' && formsApply.length > 0) {
        const otherFormId = formsApply[0].other_id;
        const accessToken = this.$store.state.account.formconnectToken.access_token;
        iframeSRC = `${config.formConnect.baseURL}/${otherFormId}?access_token=${accessToken}`;
      }
      if (type === 'FORM' && action === 'CONFIRM' && formsConfirm.length > 0) {
        const otherFormId = formsConfirm[0].other_id;
        const accessToken = this.$store.state.account.formconnectToken.access_token;
        iframeSRC = `${config.formConnect.baseURL}/${otherFormId}?access_token=${accessToken}`;
      }
      if (type === 'SURVEY' && action === 'VIEW') {
        const { quiz } = this.$store.state.calendar.formQuizConnectPopup;
        const otherFormId = quiz.completed_id || quiz.other_id;
        const view = quiz.completed_id ? 'contentview' : 'view';
        const accessToken = this.$store.state.account.quizconnectToken.access_token;
        iframeSRC = `${config.quizConnect.baseURL}/${otherFormId}?access_token=${accessToken}&view=${view}`;
        if (quiz.pass_data) {
          iframeSRC = `${iframeSRC}&pass_data=${encodeURI(quiz.pass_data)}`;
        }
      }
      if ((type === 'SURVEY' && action === 'COMPLETE') || type === 'QUIZ') {
        const { quiz } = this.$store.state.calendar.formQuizConnectPopup;
        const otherFormId = quiz.completed_id || quiz.other_id;
        const view = quiz.completed_id ? 'contentedit' : 'customdata';
        const accessToken = this.$store.state.account.quizconnectToken.access_token;
        iframeSRC = `${config.quizConnect.baseURL}/${otherFormId}?access_token=${accessToken}&view=${view}`;
        if (quiz.pass_data) {
          iframeSRC = `${iframeSRC}&pass_data=${encodeURI(quiz.pass_data)}`;
        }
      }
      return iframeSRC;
    },
  },
  methods: {
    onPopupOpened() {
      if (window.addEventListener) {
        window.addEventListener('message', this.onMessage);
      }
    },
    onPopupClosed() {
      if (window.removeEventListener) {
        window.removeEventListener('message', this.onMessage);
      }
      this.$store.commit('calendar/setFormQuizConnectPopup', {
        isPopupOpened: false,
        type: '',
        action: '',
        quiz: {},
      });
    },
    onMessage(event) {
      const { roleId } = this.$store.state.calendar.formQuizConnectPopup;
      const { callback } = this.$store.state.calendar.formQuizConnectPopup;
      const { type } = this.$store.state.calendar.formQuizConnectPopup;
      const { quiz } = this.$store.state.calendar.formQuizConnectPopup;

      const quizName = this.formName;
      if (event.data.message === 'success') {
        if (this.type === 'FORM' && this.action === 'APPLY') {
          this.$store.commit('calendar/deleteFromCalendarShiftFormsApplyByID', this.formID);
        }
        if (this.type === 'FORM' && this.action === 'CONFIRM') {
          this.$store.commit('calendar/deleteFromCalendarShiftFormsConfirmByID', this.formID);
        }
        if ((this.type === 'SURVEY' && this.action === 'COMPLETE') || this.type === 'QUIZ') {
          if (this.f7route.params.shift_id) {
            this.$store
              .dispatch('calendar/getCalendarShift', {
                shift_id: this.f7route.params.shift_id,
              })
              .then(() => {
                if (type === 'QUIZ' && quiz.required_score !== undefined && quiz.required_score !== null) {
                  this.$store.commit('calendar/setQuizConnectResultPopup', {
                    isPopupOpened: true,
                    quizName,
                    score: event.data.score,
                    incorrect: _.filter(event.data.summary, { correct: false }),
                  });
                }
                if (callback !== undefined) {
                  const role = _.find(this.$store.state.calendar.shift.shift_roles, { id: roleId });
                  if (type === 'QUIZ') {
                    const quizRequred = role.quizs ? _.filter(role.quizs, (q) => Number(q.required) === 1) : [];
                    if (
                      quizRequred.length === 0 &&
                      (quiz.required_score === undefined || quiz.required_score === null)
                    ) {
                      callback();
                    }
                  } else {
                    const surveysNotCompleted = role.surveys
                      ? _.filter(role.surveys, (s) => s.completed_id === null)
                      : [];
                    if (surveysNotCompleted.length === 0) {
                      callback();
                    }
                  }
                }
              });
          }
        }
        // close popup
        f7.popup.close();
      }
    },
  },
};
</script>
<style scoped>
.fc-iframe {
  width: 99%;
  height: 99%;
  border: 1px solid lightgray;
}
</style>

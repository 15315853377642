<template>
  <f7-popup
    tablet-fullscreen
    class="sc-payrolls-activity-history"
    @popup:open="onPopupOpened"
    @popup:closed="onPopupClosed"
  >
    <f7-page ptr ptr-bottom @ptr:refresh="loadMore">
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close=".sc-payrolls-activity-history" />
        </f7-nav-left>
        <f7-nav-title title="Activity" />
        <f7-nav-right />
        <template #after-inner>
          <f7-progressbar v-show="isLoadingData && payrollActivityHistory.length > 0" infinite />
        </template>
      </f7-navbar>
      <f7-block v-show="isLoadingData && payrollActivityHistory.length === 0" inset class="text-align-center">
        <f7-preloader />
      </f7-block>
      <f7-list v-if="payrollActivityHistory.length > 0" accordion-list no-hairlines class="no-margin">
        <f7-list-item
          v-for="(activity, indx) in payrollActivityHistory"
          :key="indx"
          accordion-item
          :title="activity.description"
          :after="activity.ago"
          :footer="activity.user.name"
        >
          <f7-accordion-content>
            <f7-list class="margin-left" media-list>
              <f7-list-item v-for="(item, ind) in activity.changes" :key="ind" :text="`- ${item}`" />
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-block v-else-if="!isLoadingData" class="text-align-center">
        <p class="text-color-gray">Activity not found</p>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
export default {
  props: {
    payrollId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    payrollActivityHistory() {
      return this.$store.state.payrolls.payrollActivityHistory.data;
    },
  },
  methods: {
    onPopupOpened() {
      this.$store.dispatch('payrolls/fetchPayrollActivityHistory', {
        payrollId: this.payrollId,
      });
    },
    onPopupClosed() {
      this.$store.commit('payrolls/setPayrollActivityHistoryPageId'); // for reset
    },
    loadMore(done) {
      this.$store
        .dispatch('payrolls/fetchPayrollActivityHistory', {
          payrollId: this.payrollId,
        })
        .then(() => {
          done();
        });
    },
  },
};
</script>
<style scoped></style>

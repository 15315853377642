<template>
  <f7-page :page-content="false" name="user-profile">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="User Profile" />
      <f7-nav-right>
        <f7-link v-show="curTabId === 'tab1'" @click="onEditSaveClick">
          <span v-show="isReadOnly">EDIT</span>
          <span v-show="!isReadOnly">SAVE</span>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar top scrollable class="sc-toolbar-relative">
      <f7-link tab-link href="./" route-tab-id="tab1">Info</f7-link>
      <f7-link tab-link href="tab2/" route-tab-id="tab2">Media</f7-link>
      <f7-link tab-link href="tab3/" route-tab-id="tab3"
        >Skills & Qualifications</f7-link
      >
      <f7-link tab-link href="tab4/" route-tab-id="tab4">Work Areas</f7-link>
      <f7-link tab-link href="tab5/" route-tab-id="tab5"
        >Unavailability</f7-link
      >
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-toolbar>

    <f7-tabs routable>
      <f7-tab id="tab1" @tab:show="onTabShow('tab1')" />
      <f7-tab id="tab2" @tab:show="onTabShow('tab2')" />
      <f7-tab id="tab3" @tab:show="onTabShow('tab3')" />
      <f7-tab id="tab4" @tab:show="onTabShow('tab4')" />
      <f7-tab id="tab5" @tab:show="onTabShow('tab5')" />
    </f7-tabs>
  </f7-page>
</template>

<script>
export default {
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      curTabId: 'tab1',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isReadOnly() {
      return this.$store.state.profile.isReadOnly;
    },
    userId() {
      return Number(this.f7route.params.user_id);
    },
  },
  methods: {
    onTabShow(tabId) {
      this.curTabId = tabId;
    },
    onEditSaveClick() {
      if (this.isReadOnly) {
        this.$store.commit('profile/setIsProfileReadonly');
      } else {
        this.$store
          .dispatch('profile/updateProfileElements', {
            userId: Number(this.f7route.params.user_id),
          })
          .then((result) => {
            if (result !== false) {
              this.$store.commit('profile/setIsProfileReadonly');
            }
          });
      }
    },
  },
};
</script>
<style scoped>
::v-deep(.sc-toolbar-relative .toolbar-inner) {
  position: relative; /* for preloader */
}
</style>

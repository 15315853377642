<template>
  <f7-page :ptr="true" @ptr:refresh="onLoadMoreMessages">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title>
        {{ thread_name }}
        <span class="subtitle">{{ participants_num + ' people' }}</span>
      </f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="isNexmoEnable && isUserLvlOwnerOrAdmin"
          icon-material="phone_iphone"
          icon-only
          :icon-color="sendSMS ? 'green' : 'gray'"
          @click="sendSMS = !sendSMS"
        />
        <f7-link
          v-if="threadShiftId"
          :href="`/calendar/shift/${threadShiftId}/`"
          icon-material="event"
          icon-only
        />
        <f7-link
          v-if="isUserLvlOwnerOrAdmin"
          icon-material="more_vert"
          icon-only
          class="sc-chats-thread-page-action"
          @click="onActionMenuClick"
        />
      </f7-nav-right>
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <f7-messagebar
      v-model:value="messageText"
      :disabled="!isThreadActive"
      :placeholder="
        isThreadActive
          ? 'Send message'
          : 'The conversation is inactive, messages can no longer be sent.'
      "
      @input="onMessageTyping"
    >
      <template #inner-end>
        <f7-link
          :icon-material="sendSMS ? 'phone_iphone' : 'send'"
          :icon-color="sendSMS ? 'teal' : 'black'"
          icon-only
          @click="sendMessage"
        />
      </template>
    </f7-messagebar>

    <f7-messages ref="messages">
      <sc-chats-thread-message
        v-for="(message, index) in thread_messages"
        :key="index"
        :message-index="index"
        :f7route="f7route"
      />
      <f7-message
        v-if="participantTypingMessage"
        :typing="true"
        :first="true"
        :last="true"
        :tail="true"
        :header="`${participantTypingMessage.name} is typing`"
        :avatar="participantTypingMessage.avatar"
        type="received"
      />
    </f7-messages>

    <sc-chats-thread-remove-user-popup
      :is-popup-opened="removeUserPopupOpened"
      :socket="socket"
      :f7route="f7route"
      @sc-chats-thread-remove-user-popup:closed="removeUserPopupOpened = false"
    />
    <sc-chats-add-user-or-thread-popup
      :is-popup-opened="addUserOrThreadPopupOpened"
      :socket="socket"
      :f7router="f7router"
      :f7route="f7route"
      type="user"
      @sc-chats-add-user-or-thread-popup:closed="
        addUserOrThreadPopupOpened = false
      "
    />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';
import Socket from '../../libs/socket';
import scChatsThreadMessage from './ChatsThread/ChatsThreadMessage.vue';
import scChatsThreadRemoveUserPopup from './ChatsThread/ChatsThreadRemoveUserPopup.vue';
import scChatsAddUserOrThreadPopup from './ChatsAddUserOrThreadPopup.vue';

export default {
  components: {
    scChatsThreadMessage,
    scChatsThreadRemoveUserPopup,
    scChatsAddUserOrThreadPopup,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    const senderId = this.$store.state.account.user.id;
    return {
      sendSMS: false,
      socket: new Socket({ onMessage: this.onSocketMessage, senderId }),
      isParticipantTyping: false,
      participantTypingMessage: null,
      removeUserPopupOpened: false,
      addUserOrThreadPopupOpened: false,
      messageText: '',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    isNexmoEnable() {
      return Number(this.$store.state.account.settings.nexmo_enable) === 1;
    },

    sender_id() {
      return this.$store.state.account.user.id;
    },
    isThreadActive() {
      return this.$store.getters['chats/getChatThreadActive'](
        Number(this.f7route.params.thread_id)
      );
    },
    threadShiftId() {
      return this.$store.getters['chats/getChatThreadShiftId'](
        Number(this.f7route.params.thread_id)
      );
    },
    thread_name() {
      return this.$store.getters['chats/getChatThreadName'](
        Number(this.f7route.params.thread_id)
      );
    },
    participants_num() {
      return this.$store.getters['chats/getChatThreadParticipantsNum'](
        Number(this.f7route.params.thread_id)
      );
    },
    participants_ids() {
      return this.$store.getters['chats/getChatThreadParticipantsIds'](
        Number(this.f7route.params.thread_id)
      );
    },
    participants() {
      return this.$store.getters['chats/getChatThreadParticipants'](
        Number(this.f7route.params.thread_id)
      );
    },
    thread_messages() {
      return this.$store.getters['chats/getChatThreadMessages'](
        Number(this.f7route.params.thread_id)
      );
    },
  },
  created() {
    this.$store.commit('chats/setChatThread', {
      thread_id: Number(this.f7route.params.thread_id),
    });
    this.$store.dispatch('chats/getChatThreadMessages', {
      thread_id: Number(this.f7route.params.thread_id),
    });
    this.socket.sendData(
      JSON.stringify({
        type: 'readThread',
        payload: {
          thread: Number(this.f7route.params.thread_id),
          receipts: this.participants_ids,
          reader: this.sender_id,
        },
      })
    );
  },
  beforeUnmount() {
    this.socket.closeConnection();
  },
  methods: {
    onSocketMessage(payload) {
      const res = JSON.parse(payload);
      if (res && res.type) {
        switch (res.type) {
          case 'typing': {
            const participant = _.find(this.participants, {
              id: res.data.user,
            });
            if (!this.isParticipantTyping && res.data.status) {
              this.participantTypingMessage = {
                name: participant.name,
                avatar:
                  participant.ppic_a || 'assets/img/default-user-image.png',
              };
            }
            if (!res.data.status) {
              this.isParticipantTyping = false;
              this.participantTypingMessage = null;
            }
            break;
          }
          case 'newMessage':
            this.$store
              .dispatch('chats/getChatThreadUnreadMessages', {
                thread_id: Number(this.f7route.params.thread_id),
              })
              .then(() => {
                this.socket.sendData(
                  JSON.stringify({
                    type: 'readThread',
                    payload: {
                      thread: Number(this.f7route.params.thread_id),
                      receipts: this.participants_ids,
                      reader: this.sender_id,
                    },
                  })
                );
              });
            break;
          case 'newThread':
            this.$store.dispatch('chats/getChatThreads', { silent: true });
            break;
          case 'readThread':
            this.$store.commit('chats/updateChatThreadNewMessagesSeen', {
              reader: res.data.reader,
            });
            break;
          case 'renameThread':
            this.$store.dispatch('chats/getChatThreads', { silent: true });
            break;
          case 'removeUser': {
            const participant = _.find(this.participants, {
              id: res.data.user,
            });
            f7.toast
              .create({
                text: `${participant.name} removed from chat`,
                closeTimeout: 2000,
                closeButton: true,
                closeButtonText: 'Close',
                closeButtonColor: 'blue',
              })
              .open();
            this.$store.dispatch('chats/getChatThreads', { silent: true });
            break;
          }
          default:
            break;
        }
      }
    },
    onLoadMoreMessages(done) {
      this.$store
        .dispatch('chats/getChatThreadMessages', {
          thread_id: Number(this.f7route.params.thread_id),
          silent: true,
          prev: true,
        })
        .then(() => done());
    },
    sendMessage() {
      const content = this.messageText;
      if (content.length > 0) {
        this.$store
          .dispatch('chats/sendChatThreadMessage', {
            content: content,
            sms: this.sendSMS ? 1 : 0,
          })
          .then((result) => {
            if (result !== false) {
              this.socket.sendData(
                JSON.stringify({
                  type: 'message',
                  payload: {
                    content: result.data,
                    receipts: this.participants_ids,
                    sender: this.sender_id,
                    sms: this.sendSMS ? 1 : 0,
                  },
                })
              );
            }
          });
        this.messageText = '';
      }
    },
    onMessageTyping: _.debounce(
      function typing() {
        this.socket.sendData(
          JSON.stringify({
            type: 'typing',
            payload: {
              status: true,
              user: this.sender_id,
              thread: Number(this.f7route.params.thread_id),
              receipts: this.participants_ids,
            },
          })
        );
        setTimeout(() => {
          this.socket.sendData(
            JSON.stringify({
              type: 'typing',
              payload: {
                status: false,
                user: this.sender_id,
                thread: Number(this.f7route.params.thread_id),
                receipts: this.participants_ids,
              },
            })
          );
        }, 1000);
      },
      500,
      { leading: true, trailing: false }
    ),
    addUserToThread() {
      this.addUserOrThreadPopupOpened = true;
    },
    deleteUserFromThread() {
      this.removeUserPopupOpened = true;
    },
    deleteThread() {
      this.$store
        .dispatch('chats/deleteChatThread', {
          thread_id: Number(this.f7route.params.thread_id),
        })
        .then(() => {
          this.f7router.back();
        });
    },
    onActionMenuClick() {
      const buttons = [
        {
          id: 'Add User',
          text: 'Add User',
          onClick: this.addUserToThread,
        },
        {
          id: 'removeUser',
          text: 'Remove User',
          onClick: this.deleteUserFromThread,
        },
        {
          id: 'deleteThread',
          text: 'Delete Thread',
          onClick: this.deleteThread,
        },
      ];
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        buttons,
        targetEl: '.sc-chats-thread-page-action',
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
      });
      actionSheet.open();
    },
  },
};
</script>

<template>
  <f7-page-content>
    <f7-block>
      <p class="text-color-gray">{{ infoMessage }}</p>
    </f7-block>
    <div
      v-for="(category, catIndx) in experience"
      :key="category.id"
      class="data-table card"
    >
      <div class="card-header">
        <div class="data-table-title">{{ category.cname }}</div>
        <!-- Table actions -->
        <div class="data-table-actions">
          <f7-link icon-material="add_circle_outline" @click="onAddExperienceClick(category.id)" />
        </div>
      </div>
      <div class="card-content">
        <table>
          <thead>
            <tr>
              <th
                v-for="(head) in category.headings"
                :key="head.id"
                class="label-cell"
              >{{ head.hname }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(exper, experIndx) in category.experience"
              :key="exper.id"
            >
              <td
                v-for="(data, indx) in exper.data"
                :key="indx"
                class="label-cell"
              >{{ getDataByOrderId(catIndx, experIndx, indx) }}</td>
              <td class="actions-cell">
                <f7-link icon-material="edit" @click="onEditExperienceClick(category.id, exper.id)" />
                <f7-link icon-material="delete" @click="onDeleteExperienceClick(exper.id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <f7-row class="margin justify-content-space-between">
      <f7-button @click="registerBackStep">
        Back
      </f7-button>
      <f7-button
        :disabled="isLoadingData"
        raised
        fill
        @click="registerNextStep"
      >
        Save & Continue
      </f7-button>
    </f7-row>
    <sc-profile-experience-add-edit-popup
      :is-popup-opened="isPopupOpened"
      :action-type="actionType"
      :experience-category-id="experienceCategoryId"
      :experience-id="experienceId"
      @closed="onPopupClosed"
    />
  </f7-page-content>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import scProfileExperienceAddEditPopup from '../../components/Profile/Experience/ProfileExperienceAddEditPopup.vue';

export default {
  components: {
    scProfileExperienceAddEditPopup,
  },
  data() {
    return {
      isPopupOpened: false,
      actionType: 'ADD',
      experienceCategoryId: undefined,
      experienceId: undefined,
    };
  },
  computed: {
    isLoadingData() { return this.$store.state.isLoadingData; },
    infoMessage() {
      return this.$store.state.account.settings.profile_experience_msg;
    },
    experience() {
      return this.$store.state.profile.experience;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
  },
  beforeCreate() {
    const userId = this.$store.state.account.user.id;
    this.$store.dispatch('profile/fetchProfileExperience', { userId });
  },
  methods: {
    registerNextStep() {
      this.$store.dispatch('account/registerNextStep');
    },
    registerBackStep() {
      this.$store.commit('account/setRegStepNum', 3);
    },
    getDataByOrderId(catIndx, experIndx, id) {
      const dataHeader = _.find(this.experience[catIndx].headings, { display_order: String(id) });
      const data = this.experience[catIndx].experience[experIndx].data[id];
      switch (dataHeader.type) {
        case 'list': return _.find(dataHeader.options, { id: Number(data) }).oname;
        case 'date': return moment(data).isValid ? moment(data).format(this.experience[catIndx].dformat || 'MMM DD YYYY') : '';
        default: return data;
      }
    },
    onDeleteExperienceClick(experienceId) {
      this.$store.dispatch('profile/deleteProfileExperience', { experienceId, userId: this.userId });
    },
    onAddExperienceClick(experienceCategoryId) {
      this.isPopupOpened = true;
      this.actionType = 'ADD';
      this.experienceCategoryId = experienceCategoryId;
    },
    onEditExperienceClick(experienceCategoryId, experienceId) {
      this.actionType = 'EDIT';
      this.experienceId = experienceId;
      this.experienceCategoryId = experienceCategoryId;
      this.isPopupOpened = true;
    },
    onPopupClosed() {
      this.isPopupOpened = false;
      this.actionType = 'ADD';
    },

  },
};
</script>

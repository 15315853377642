<template>
  <f7-sheet
    class="home-post-likes-sheet"
    swipe-to-close
    backdrop
    :opened="isOpened"
    @sheet:close="onClose"
  >
    <f7-page-content>
      <f7-block-title medium>Likes</f7-block-title>
      <f7-block v-show="isLoadingData" inset class="text-align-center">
        <f7-preloader />
      </f7-block>

      <f7-block>
        <f7-list v-if="usersList.length > 0" no-hairlines class="no-margin-top">
          <f7-list-item
            v-for="user in usersList"
            :key="user.user_id"
            :link="
              isUserLvlOwnerOrAdmin ? `/users/profile/${user.user_id}/` : ''
            "
            :title="`${user.fname} ${isUserLvlOwnerOrAdmin ? user.lname : ''}`"
            sheet-close
            :no-chevron="isUserLvlOwnerOrAdmin ? false : true"
          >
            <template #media>
              <img :src="user.ppic_a || 'assets/img/default-user-image.png'" />
            </template>
          </f7-list-item>
        </f7-list>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    isOpened() {
      return this.$store.state.posts.postLikesSheetModal.isOpened;
    },
    usersList() {
      return this.$store.state.posts.postLikesSheetModal.likesUserList;
    },
  },
  methods: {
    onClose() {
      this.$store.commit('posts/setPostLikesSheetModal', { isOpened: false });
    },
  },
};
</script>
<style scoped>
.item-media img {
  border-radius: 50%;
  width: 50px;
}
</style>

<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title>Options</f7-nav-title>
      <template #after-inner>
        <f7-progressbar
          v-show="
            isLoadingData &&
            (Object.keys(timezones).length > 0 ||
              Object.keys(options).length > 0)
          "
          infinite
        />
      </template>
    </f7-navbar>
    <f7-block
      v-show="
        isLoadingData &&
        (Object.keys(timezones).length === 0 ||
          Object.keys(options).length === 0)
      "
      inset
      class="text-align-center"
    >
      <f7-preloader />
    </f7-block>
    <f7-list no-hairlines>
      <f7-list-input
        v-if="Object.keys(timezones).length > 0"
        label="Timezone"
        floating-label
        :wrap="false"
        class="item-input-with-value"
        outline
        type="select"
        :value="timezoneCode"
        @change="onTimezoneChange($event.target.value)"
      >
        <option v-for="(value, key) in timezones" :key="key" :value="key">
          {{ value }}
        </option>
      </f7-list-input>
      <f7-list-item v-for="(value, key) in options" :key="key">
        <span>{{ mapOptionName(value.oname) }}</span>
        <f7-toggle
          :checked="value.set"
          @toggle:change="onOptionChange(value.oname)"
        />
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      timezones: {},
      timezoneCode: this.$store.state.account.user.php_tz,
      options: [],
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    userId() {
      return this.$store.state.account.user.id;
    },
  },
  mounted() {
    this.$store
      .dispatch('profile/fetchSettingsOptionsTimezones')
      .then((result) => {
        this.timezones = result;
      });
    this.$store
      .dispatch('profile/fetchSettingsOptions', { userId: this.userId })
      .then((result) => {
        this.options = result;
      });
  },
  methods: {
    mapOptionName(optionName) {
      let result = optionName;
      switch (optionName) {
        case 'staff_email_available_shifts':
          result = 'Receive emails of jobs available in your areas';
          break;
        case 'staff_email_invitation':
          result =
            'Receive an email when you are invited to apply for a specfic job';
          break;
        case 'staff_email_notification':
          result =
            'Receive important system notifications by email, eg. if you are selected for a shift';
          break;
        case 'staff_email_reminder':
          result =
            'Receive reminders by email, eg. when it is time to check-in to a shift';
          break;
        case 'staff_push_invitation':
          result =
            'Receive a push notfication when you are invited to apply for a specfic job';
          break;
        case 'staff_push_notification':
          result =
            'Receive important system notifications by push notification';
          break;
        case 'staff_push_reminder':
          result = 'Receive reminders by push notification';
          break;
        default:
          break;
      }
      return result;
    },
    onTimezoneChange(timezoneCode) {
      this.timezoneCode = timezoneCode;
      this.$store
        .dispatch('profile/setTimezoneSettingsOptions', {
          userId: this.userId,
          timezoneCode,
        })
        .then((result) => {
          if (result !== false) {
            this.$store.dispatch('account/changeUserTimezone', {
              timezoneCode,
            });
          }
        });
    },
    onOptionChange(optionCode) {
      const optionValue = !this.options[optionCode].set;
      this.options[optionCode].set = optionValue;
      this.$store.dispatch('profile/setOptionSettingsOptions', {
        userId: this.userId,
        optionCode,
        optionValue,
      });
    },
  },
};
</script>
<style scoped></style>

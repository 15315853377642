<template>
  <f7-popup tablet-fullscreen class="sc-payrolls-generate-tracking-options-select" @popup:opened="onPopupOpened">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close=".sc-payrolls-generate-tracking-options-select" />
        </f7-nav-left>
        <f7-nav-title title="Select Tracking Option" />
        <f7-nav-right>
          <f7-link popup-close=".sc-payrolls-generate-tracking-options-select" @click="onDoneClick">DONE</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-searchbar :custom-search="true" @searchbar:search="onSearch" @searchbar:clear="onClear" />
      <f7-progressbar v-show="isLoadingData" infinite />
      <f7-list v-show="showNotFound" class="no-margin-top">
        <f7-list-item title="Nothing found" />
      </f7-list>
      <f7-list v-show="showResultList" class="no-margin-top">
        <f7-list-item
          v-for="opt in trackingOptions"
          :key="opt.id"
          :title="opt.oname"
          :checked="isSelected(opt.id)"
          :value="opt.id"
          radio
          @change="onChange(opt.id, $event)"
        />
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    selectedTrackingOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      trackingOptions: [],
      selected: this.selectedTrackingOptions,
      query: '',
      showResultList: true,
      showNotFound: false,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  methods: {
    isSelected(optionId) {
      return _.find(this.selected, { id: optionId }) !== undefined;
    },

    onChange(optionId) {
      const selectedOption = _.find(this.trackingOptions, { id: Number(optionId) });
      this.selected = [selectedOption];
    },
    onSearch(searchbar, query) {
      if (query) {
        this.query = query;
        this.fetchTrackingOptions();
      }
    },
    fetchTrackingOptions: _.debounce(function fetchTrackingOptions() {
      this.$store.dispatch('payrolls/fetchTrackingOptions', { query: this.query }).then((result) => {
        this.trackingOptions = result;
        this.showNotFound = false;
        this.showResultList = true;
        if (this.trackingOptions.length < 1) {
          this.showNotFound = true;
          this.showResultList = false;
        }
      });
    }, 500),
    onClear() {
      this.showNotFound = false;
      this.showResultList = true;
      this.trackingOptions = [];
    },
    onDoneClick() {
      this.$emit('sc-payrolls-generate-tracking-options-select:selected', this.selected);
    },
    onPopupOpened() {
      this.selected = this.selectedTrackingOptions;
    },
  },
};
</script>
<style scoped></style>

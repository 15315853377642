<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Info" />
      <f7-nav-right>
        <f7-link @click="onEditSaveClick">
          <span v-show="isReadOnly">EDIT</span>
          <span v-show="!isReadOnly">SAVE</span>
        </f7-link>
      </f7-nav-right>
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <f7-block-title v-if="structure.length">CATEGORIES</f7-block-title>
    <f7-list v-if="structure.length" no-hairlines accordion-list>
      <sc-profile-info-category
        v-for="category in structure"
        :key="category.id"
        :category="category"
      />
    </f7-list>
  </f7-page>
</template>

<script>
import scProfileInfoCategory from '../../components/Profile/Info/ProfileInfoCategory.vue';

export default {
  components: {
    scProfileInfoCategory,
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    structure() {
      return this.$store.state.profile.structure;
    },
    isReadOnly() {
      return this.$store.state.profile.isReadOnly;
    },
  },
  beforeCreate() {
    this.$store.commit('profile/setIsProfileReadonly', true);
    this.$store.commit('profile/resetErrorProfileElements');
    this.$store.commit('profile/resetProfileElementsForUpdate');
    this.$store.commit('profile/setProfileData', {
      userId: this.$store.state.account.user.id,
    });
    this.$store.dispatch('profile/getProfileStructure', {
      userId: this.$store.state.account.user.id,
    });
  },
  methods: {
    onEditSaveClick() {
      if (this.isReadOnly) {
        this.$store.commit('profile/setIsProfileReadonly');
      } else {
        this.$store
          .dispatch('profile/updateProfileElements', {
            userId: this.$store.state.account.user.id,
          })
          .then((result) => {
            if (result !== false) {
              this.$store.commit('profile/setIsProfileReadonly');
            }
          });
      }
    },
  },
};
</script>

<template>
  <form class="sc-file-picker">
    <input
      ref="file"
      type="file"
      multiple
      @change="onFileInputChange"
    >
  </form>
</template>
<script>

export default {
  mounted() {
    this.$emit('sc-file-picker:ready', this.openFilePicker);
  },
  methods: {
    openFilePicker() {
      this.$refs.file.click();
    },
    onFileInputChange(event) {
      this.$emit('sc-file-picker:loaded', [...event.target.files]);
    },
  },
};
</script>

<style scoped>
.sc-file-picker {
  height: 0px;
  visibility: hidden;
  position: absolute;
  top: -100px;
}
</style>

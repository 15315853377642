<template>
  <f7-page @page:reinit="onReInit">
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Chats" />
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-chats"
          icon-material="search"
        />
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-chats"
        expandable
        search-container=".search-list-chats"
      />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>
    <f7-list no-hairlines class="search-list-chats no-margin-top">
      <f7-list-item
        v-for="thread in threads"
        :key="thread.id"
        :title="thread.name"
        :link="`/chats/${thread.id}/`"
        :badge="thread.unread"
        badge-color="blue"
      >
        <template #media>
          <img
            :src="thread.ppic_a || 'assets/img/default-user-image.png'"
            class="sc-chats__thread__pic"
          />
        </template>
      </f7-list-item>
    </f7-list>
    <f7-fab @click="addUserOrThreadPopupOpened = true">
      <f7-icon material="chat" />
    </f7-fab>
    <sc-chats-add-user-or-thread-popup
      :is-popup-opened="addUserOrThreadPopupOpened"
      :f7route="f7route"
      :f7router="f7router"
      type="thread"
      @sc-chats-add-user-or-thread-popup:closed="
        addUserOrThreadPopupOpened = false
      "
    />
  </f7-page>
</template>

<script>
import scChatsAddUserOrThreadPopup from './Chats/ChatsAddUserOrThreadPopup.vue';

export default {
  components: {
    scChatsAddUserOrThreadPopup,
  },
  props: {
    f7router: {
      type: Object,
      required: true,
    },
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addUserOrThreadPopupOpened: false,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    threads() {
      return this.$store.state.chats.threads;
    },
  },
  beforeCreate() {
    this.$store.dispatch('chats/getChatThreads');
  },
  methods: {
    onReInit() {
      this.$store.dispatch('chats/getChatThreads');
    },
  },
};
</script>
<style scoped>
.sc-chats__thread__pic {
  width: 45px;
  border-radius: 50%;
}
</style>

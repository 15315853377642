<template>
  <f7-list-item
    v-show="!(sex && element.sex && sex !== element.sex)"
    v-if="element.options && element.options.length > 0 && element.etype === 'listm'"
    class="sc-display-block"
  >
    <f7-list-item-cell>
      {{ element.ename + (element.visibility === 'required' ? '*' : '') }}
      <f7-list>
        <f7-list-item
          v-for="op in element.options"
          :key="op.id"
          :value="op.option"
          :title="op.option"
          :checked="element.data !== null && element.data.indexOf(op.option) !== -1"
          :disabled="isProfileReadonly"
          checkbox
          @change="onCheckboxElementChanged"
        />
      </f7-list>
    </f7-list-item-cell>
  </f7-list-item>

  <f7-list-input
    v-show="!(sex && element.sex && sex !== element.sex)"
    v-else-if="element.options && element.options.length > 0 && element.etype === 'list'"
    :label="element.ename + (element.visibility === 'required' ? '*' : '')"
    :input="false"
    floating-label
    outline
    :wrap="false"
    class="item-input-with-value"
  >
    <template #input>
      <select
        :id="`element${element.id}`"
        :value="data"
        :disabled="isProfileReadonly"
        @change="onElementChanged(element.id)"
      >
        <option value="" disabled />
        <option v-for="op in element.options" :key="op.id" :value="op.option">
          {{ op.option }}
        </option>
      </select>
    </template>
  </f7-list-input>

  <f7-list-input
    v-show="!(sex && element.sex && sex !== element.sex)"
    v-else
    floating-label
    outline
    :input="false"
    :wrap="false"
    :class="{ 'item-input-with-value': data }"
    :label="element.ename + (element.visibility === 'required' ? '*' : '')"
    :error-message="errorMessage"
    :error-message-force="true"
  >
    <template #input>
      <f7-input
        v-if="element.etype === 'date'"
        :input-id="`element${element.id}`"
        :value="data ? [data] : []"
        :readonly="isProfileReadonly"
        :disabled="isProfileReadonly"
        type="datepicker"
        :calendar-params="{
          closeOnSelect: true,
          dateFormat: 'M dd yyyy',
          locale: 'en',
          routableModals: false,
        }"
        @change="onElementChanged(element.id)"
      />
      <input
        v-else-if="element.etype !== 'long'"
        :id="`element${element.id}`"
        :value="data"
        :readonly="isProfileReadonly || readOnlyEmail"
        :disabled="isProfileReadonly || readOnlyEmail"
        type="text"
        @input="onElementChanged(element.id)"
      />
      <textarea
        v-else
        :id="`element${element.id}`"
        :value="data"
        :readonly="isProfileReadonly"
        :disabled="isProfileReadonly"
        :placeholder="`Your ${element.ename}`"
        @input="onElementChanged(element.id)"
      />
    </template>
  </f7-list-input>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import $ from 'dom7';

export default {
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  computed: {
    readOnlyEmail() {
      return this.$store.getters['account/isUserLvlRegistrant'] && this.element.id === 5;
    },
    isProfileReadonly() {
      return this.$store.state.profile.isReadOnly;
    },
    sex() {
      return this.$store.state.profile.sex;
    },
    data() {
      let result = this.$store.getters['profile/getPofileElementDataById'](this.element.id);
      if (this.element.id === 3 && result) {
        // date of birth
        result = moment(result, 'YYYY-MM-DD').format('MMM DD YYYY');
      }
      return result;
    },
    errorMessage() {
      return this.$store.getters['profile/getErrorMessageOfProfileElement'](this.element.id);
    },
  },
  methods: {
    onElementChanged(id) {
      let { value } = $(`#element${id}`)[0];
      if (this.data !== value && !this.isProfileReadonly) {
        if (id === 3) {
          // date of birth
          value = moment(value, 'MMM DD YYYY').format('YYYY-MM-DD');
        }
        this.$store.commit('profile/setElementForUpdate', {
          id,
          data: value,
        });
        this.$store.commit('profile/deleteErrorProfileElements', { id });
      }
    },
    onCheckboxElementChanged(event) {
      let data = '';
      if (event.target.checked) {
        const dataArray = _.has(this.data, 'length') && this.data.length > 0 ? this.data.split(',') : [];
        dataArray.push(event.target.value);
        data = dataArray.join(',');
      } else {
        data = this.data
          .split(',')
          .filter((val) => val !== event.target.value)
          .join(',');
      }
      this.$store.commit('profile/setElementForUpdate', {
        id: this.element.id,
        data,
      });
    },
  },
};
</script>

<style scoped>
::v-deep(.sc-display-block .item-inner) {
  display: block;
}
</style>

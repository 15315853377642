<template>
  <f7-page
    :infinite-preloader="showPreloader"
    :infinite-distance="100"
    infinite
    @infinite="loadMoreComments"
    @page:afterout="$store.commit('posts/clearPostDetails')"
  >
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Comments" />
      <f7-nav-right />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>

    <f7-messagebar
      v-if="isTimelineCommentEnabled || isUserLvlOwnerOrAdmin"
      ref="postCommentMessagebar"
      :value="comment"
      placeholder="Add a comment..."
      @input="comment = $event.target.value"
    >
      <template #inner-end>
        <f7-link
          v-show="!isCommentSending"
          icon-material="send"
          icon-only
          @click="sendComment"
        />
        <div v-show="isCommentSending" style="width: 28px" class="margin-right">
          <f7-preloader :size="28" color="black" />
        </div>
      </template>
    </f7-messagebar>

    <sc-home-post-card
      v-if="post && post.id"
      :key="post.id"
      :page-type="'DETAIL'"
      :post-id="Number(post.id)"
      :post-ppic_a="post.ppic_a"
      :post-name="post.name"
      :post-created-at="post.created_at"
      :post-content="post.content"
      :post-link="post.link"
      :post-thumb="post.thumb"
      :post-liked="Number(post.liked)"
      :post-likes="Number(post.likes)"
      :post-likes-text="post.likes_text"
      :post-comments="Number(post.comments)"
      :post-pinned="Number(post.pinned)"
      :post-approved="Number(post.approved)"
      :f7router="f7router"
    />

    <f7-list media-list class="no-margin no-hairlines">
      <ul>
        <sc-home-post-comment
          v-for="comment in postComments"
          :key="comment.id"
          :comment-id="Number(comment.id)"
          :comment-ppic_a="comment.ppic_a"
          :comment-name="comment.name"
          :comment-created-at="comment.created_at"
          :comment-content="comment.content"
          :comment-liked="Number(comment.liked)"
          :comment-likes="Number(comment.likes)"
          :comment-approved="Number(comment.approved)"
          @sc-home-post-comment:comment-edit-click="onCommentEditClick"
        />
      </ul>
    </f7-list>
    <f7-sheet
      :opened="editCommentSheetOpened"
      backdrop
      @sheet:closed="editCommentSheetOpened = false"
    >
      <f7-toolbar>
        <f7-link sheet-close icon-material="close" icon-only />
        <f7-link sheet-close @click="editCommentSheetSaveClick">SAVE</f7-link>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <f7-list no-hairlines>
          <f7-list-input
            :value="editCommentSheetCommentContent"
            label="Edit"
            floating-label
            type="textarea"
            resizable
            placeholder="Write something ..."
            @input="editCommentSheetCommentContent = $event.target.value"
          />
        </f7-list>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>

<script>
import scHomePostCard from './Posts/HomePostCard.vue';
import scHomePostComment from './Posts/HomePostComment.vue';

export default {
  components: {
    scHomePostCard,
    scHomePostComment,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      postId: Number(this.f7route.params.post_id),
      showPreloader: true,
      allowInfinite: true,
      isCommentSending: false,
      comment: '',
      editCommentSheetOpened: false,
      editCommentSheetCommentContent: '',
      editCommentSheetcommentId: undefined,
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    postComments() {
      return this.$store.state.posts.post.comments;
    },
    post() {
      return this.$store.state.posts.post.postDetails;
    },
    commentsOnPage() {
      return this.$store.state.posts.post.commentsOnPage;
    },
    isTimelineCommentEnabled() {
      return (
        Number(this.$store.state.account.settings.staff_timeline_comment) === 1
      );
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
  },
  created() {
    this.$store
      .dispatch('posts/getPostDetails', { postId: this.postId })
      .then(() => {
        if (this.postComments.length !== this.commentsOnPage) {
          this.showPreloader = false;
          this.allowInfinite = false;
        }
      });
  },
  methods: {
    loadMoreComments() {
      if (this.allowInfinite) {
        this.allowInfinite = false;
        this.$store
          .dispatch('posts/getPostComments', {
            postId: this.postId,
            prev: true,
          })
          .then((result) => {
            if (result.data.length === this.commentsOnPage) {
              this.allowInfinite = true;
            } else {
              this.allowInfinite = false;
              this.showPreloader = false;
            }
          });
      }
    },
    sendComment() {
      const content = this.comment.trim();
      if (content.length > 0) {
        this.isCommentSending = true;
        this.$store
          .dispatch('posts/sendPostComment', {
            postId: this.postId,
            content,
          })
          .then(() => {
            this.isCommentSending = false;
            this.comment = '';
          });
      }
    },
    onCommentEditClick(payload) {
      this.editCommentSheetOpened = true;
      this.editCommentSheetCommentContent = payload.commentContent;
      this.editCommentSheetcommentId = payload.commentId;
    },
    editCommentSheetSaveClick() {
      this.$store.dispatch('posts/editPostComment', {
        commentId: this.editCommentSheetcommentId,
        commentContent: this.editCommentSheetCommentContent,
      });
    },
  },
};
</script>

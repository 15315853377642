<template>
  <f7-popup tablet-fullscreen :opened="isOpened" @popup:open="onOpen" @popup:closed="onClosed">
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>{{ actionType == 'ADD' ? 'Add' : 'Edit' }} Admin Note</f7-nav-title>
          <template #after-inner>
            <f7-progressbar v-show="isLoadingData" infinite />
          </template>
        </f7-navbar>
        <f7-list no-hairlines class="no-margin-bottom">
          <f7-list-input
            outline
            floating-label
            label="Note Type"
            type="select"
            default-value="info"
            :value="noteTypeId"
            @change="noteTypeId = $event.target.value"
          >
            <option v-for="type in adminNoteTypes" :key="type.id" :value="type.id || -1">{{ type.tname }}</option>
          </f7-list-input>
          <f7-list-input
            outline
            floating-label
            label="Visibility"
            type="select"
            default-value="info"
            :value="visibilityId"
            @change="visibilityId = $event.target.value"
          >
            <option value="0">Admin Only</option>
            <option value="1">Admin & Client</option>
          </f7-list-input>

          <!-- <f7-list-input
            outline
            floating-label
            label="Note"
            type="textarea"
            :value="noteText"
            @input="noteText = $event.target.value"
          /> -->
        </f7-list>
        <sc-mentions-textarea :init-text="noteText" @sc-mentions-textarea:changed="onMentionsTextareaChanged" />
        <f7-block>
          <f7-button raised fill :disabled="isAddDisabled" @click="onAddClick">
            {{ actionType == 'ADD' ? 'Add' : 'Save' }}
          </f7-button>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import scMentionsTextarea from '../../../../components/SCMentionsTextarea/SCMentionsTextarea.vue';
import { f7 } from 'framework7-vue';
import { nextTick } from 'vue';

export default {
  components: {
    scMentionsTextarea,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    adminNoteId: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      noteText: '',
      mentions: [],
      noteTypeId: '-1',
      visibilityId: '0',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    adminNoteTypes() {
      return this.$store.state.account.settings.admin_note_types || [];
    },
    adminNote() {
      return this.$store.getters['calendar/getCalendarShiftAdminNoteById'](this.adminNoteId);
    },
    isAddDisabled() {
      return this.isLoadingData || this.noteText === '';
    },
  },
  methods: {
    onAddClick() {
      let result;
      if (this.actionType === 'ADD') {
        result = this.$store.dispatch('calendar/addCalendarShiftAdminNote', {
          visibilityId: this.visibilityId,
          mentions: this.mentions,
          noteText: this.noteText,
          noteTypeId: this.noteTypeId === '-1' ? '' : this.noteTypeId,
        });
      } else if (this.actionType === 'EDIT') {
        result = this.$store.dispatch('calendar/updateCalendarShiftAdminNote', {
          adminNoteId: this.adminNoteId,
          visibilityId: this.visibilityId,
          mentions: this.mentions,
          noteText: this.noteText,
          noteTypeId: this.noteTypeId === '-1' ? '' : this.noteTypeId,
        });
      }
      result.then((result) => {
        if (result !== false) {
          f7.popup.close();
        }
      });
    },
    onClosed() {
      this.noteText = '';
      this.noteTypeId = '-1';
      this.visibilityId = '0';
      this.$emit('sc-calendar-shift-detail-add-edit-admin-note-sheet:closed');
    },
    async onOpen() {
      if (this.actionType === 'EDIT' && this.adminNote) {
        await nextTick();
        this.noteText = this.adminNote.note;
        this.noteTypeId = this.adminNote.type_id || '-1';
        this.visibilityId = this.adminNote.client_visible;
      }
    },
    onMentionsTextareaChanged(payload) {
      this.noteText = payload.noteText;
      this.mentions = payload.mentions;
    },
  },
};
</script>

<template>
  <f7-popup
    tablet-fullscreen
    :opened="isPopupOpened"
    @popup:close="$emit('sc-chats-add-user-or-thread-popup:closed')"
    @popup:closed="onClear"
  >
    <f7-page with-subnavbar>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close icon-material="close" icon-only />
        </f7-nav-left>
        <f7-nav-title title="Find user" />
        <f7-subnavbar :inner="false" style="flex-direction: column">
          <f7-searchbar
            :custom-search="true"
            search-container=".search-list"
            search-in=".item-title"
            @searchbar:search="onSearch"
            @searchbar:clear="onClear"
          />
          <f7-progressbar v-show="isLoadingData" infinite />
        </f7-subnavbar>
      </f7-navbar>

      <f7-list v-show="show_not_found" class="no-margin-top" no-hairlines>
        <f7-list-item title="Nothing found" />
      </f7-list>
      <f7-list v-show="show_result_list" class="search-list searchbar-found no-margin-top" no-hairlines>
        <f7-list-item
          v-for="user in user_list"
          :title="user.name"
          :key="`${user.type}_${user.id}`"
          link="#"
          @click="onUserClick(user.id, user.type)"
        >
          <template #media>
            <img :src="user.ppic_a || 'assets/img/default-user-image.png'" class="sc-chats__thread__pic" />
          </template>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';
import Socket from '../../libs/socket';

export default {
  props: {
    isPopupOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
    socket: {
      type: Socket,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: true,
      default: 'thread',
    },
    f7route: {
      type: Object,
      required: true,
    },
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      query: '',
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    show_not_found() {
      return this.$store.state.chats.add_user_or_thread.show_not_found;
    },
    show_result_list() {
      return this.$store.state.chats.add_user_or_thread.show_result_list;
    },
    user_list() {
      return this.$store.state.chats.add_user_or_thread.user_list;
    },
    new_thread_id() {
      return this.$store.state.chats.thread.thread_id;
    },
    participants_ids() {
      return this.$store.getters['chats/getChatThreadParticipantsIds'](Number(this.f7route.params.thread_id));
    },
  },
  methods: {
    onSearch(searchbar, query) {
      if (query) {
        this.query = query;
        this.getUserList();
      }
    },
    onClear() {
      this.$store.commit('chats/setChatAddUserOrThread', {
        show_not_found: false,
        show_result_list: true,
        user_list: [],
      });
    },
    getUserList: _.debounce(function getList() {
      if (this.type === 'thread') {
        this.$store.dispatch('chats/getChatSearchUserList', {
          query: this.query,
        });
      } else {
        this.$store.dispatch('chats/getChatSearchUserListForThread', {
          query: this.query,
          thread_id: this.f7route.params.thread_id,
        });
      }
    }, 500),
    onUserClick(userId, userType) {
      if (this.type === 'thread') {
        if (userType === 'user') {
          f7.dialog.prompt('', 'New Message', (message) => {
            this.$store
              .dispatch('chats/createChatThread', {
                content: message,
                recipient_id: userId,
              })
              .then(() => {
                f7.popup.close();
                this.f7router.navigate(`/chats/${this.new_thread_id}/`);
              });
          });
        }
        if (userType === 'thread') {
          f7.popup.close();
          this.f7router.navigate(`/chats/${userId}/`);
        }
      } else {
        // type === 'user'
        this.$store
          .dispatch('chats/addUserChatThread', {
            user_id: userId,
          })
          .then(() => {
            f7.popup.close();
            this.socket.sendData(
              JSON.stringify({
                type: 'thread',
                payload: {
                  thread: Number(this.f7route.params.thread_id),
                  receipt: this.participants_ids,
                },
              })
            );
          });
      }
    },
  },
};
</script>
<style scoped>
.sc-chats__thread__pic {
  width: 45px;
  border-radius: 50%;
}
</style>

const config = {
  axios: {
    baseURL:
      process.env.NODE_ENV === 'production'
        ? 'https://demo.staffconnect-app.com/api'
        : 'https://demo.staffconnect-app.com/api',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    validateStatus: (status) => status >= 200 && status < 400,
    timeout: 120000,
  },
};

export default config;

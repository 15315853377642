<template>
  <f7-card class="sc-image-card">
    <f7-card-content :padding="false">
      <img
        :src="image.thumbnail"
        class="lazy lazy-fade-in"
        @click="onImageClick()"
      />
    </f7-card-content>
    <f7-card-footer>
      <div>{{ new Date(image.created_at).toDateString() }}</div>
      <f7-button
        :class="'sc-profile-media-image-card-action' + image.id"
        icon-material="more_vert"
        color="gray"
        @click="onActionClick"
      />
    </f7-card-footer>
  </f7-card>
</template>
<script>
import { f7 } from 'framework7-vue';

export default {
  created() {},
  beforeUnmount() {
    if (this.actionSheet !== undefined) {
      this.actionSheet.destroy();
    }
  },
  methods: {
    onImageClick() {
      this.photoBrowser.open(this.imageIndx);
    },
    onActionClick() {
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
        targetEl: `.sc-profile-media-image-card-action${this.image.id}`,
        buttons: [
          {
            text: 'Set As Profile Photo',
            icon: '<i class="icon material-icons color-gray">portrait</i>',
            onClick: () => {
              this.$store
                .dispatch('profile/setAsMainProfileImage', {
                  imageId: this.image.id,
                  userId: this.userId,
                })
                .then((result) => {
                  this.$store.dispatch('account/changeUserProfilePhoto', {
                    imageUrl: this.image.thumbnail,
                  });
                  f7.toast
                    .create({
                      text: result.data.message,
                      closeTimeout: 2000,
                      closeButton: true,
                      closeButtonText: 'Close',
                      closeButtonColor: 'blue',
                    })
                    .open();
                });
            },
          },
          {
            text: 'Delete',
            icon: '<i class="icon material-icons color-gray">delete</i>',
            onClick: () => {
              f7.dialog.confirm(
                "This image will be deleted and you won't be able to find it anymore.",
                'Delete the image?',
                () => {
                  this.$store.dispatch('profile/deleteProfileImage', {
                    imageId: this.image.id,
                  });
                }
              );
            },
          },
        ],
      });
      actionSheet.open();
    },
  },
  props: ['image', 'imageIndx', 'photoBrowser', 'userId'],
};
</script>
<style scoped>
.sc-image-card {
  width: 44%;
  max-width: 200px;
  min-width: 120px;
}
::v-deep(.sc-image-card .card-content) {
  min-height: 120px;
}
::v-deep(.sc-image-card .card-content img) {
  width: 100%;
  display: block;
}
::v-deep(.sc-image-card .card-footer .button) {
  max-width: 30px;
  min-width: 30px;
  padding: 0 0 0 5px;
}
</style>

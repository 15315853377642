<template>
  <f7-page-content>
    <sc-sisp-block v-if="sispEnable" :user-id="userId" :is-sign-up="true" />
    <sc-skills-list :skills="skills" :user-id="userId" />
    <f7-row class="margin justify-content-space-between">
      <f7-button @click="registerBackStep"> Back </f7-button>
      <f7-button :disabled="isLoadingData" raised fill @click="registerNextStep"> Save & Continue </f7-button>
    </f7-row>
  </f7-page-content>
</template>
<script>
import scSispBlock from '../../components/Profile/SkillsQualifications/SispBlock.vue';
import scSkillsList from '../../components/Profile/SkillsQualifications/SkillsList.vue';

export default {
  components: {
    scSispBlock,
    scSkillsList,
  },
  data() {
    return {};
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    skills() {
      return this.$store.state.profile.skills;
    },
    sispEnable() {
      return this.$store.state.account.settings.sisp_enable === '1';
    },
  },
  beforeCreate() {
    const userId = this.$store.state.account.user.id;
    this.$store.dispatch('profile/getProfileSkillsQualifications', { userId });
  },
  methods: {
    registerNextStep() {
      this.$store.dispatch('account/registerNextStep');
    },
    registerBackStep() {
      this.$store.commit('account/setRegStepNum', 2);
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-page>
    <f7-list no-hairlines class="no-margin-top">
      <f7-list-group v-for="category in work_areas" :key="category.id">
        <f7-list-item :title="category.cname" group-title />
        <f7-list-item
          v-for="area in category.work_areas"
          :key="area.id"
          :checked="area.set === 1"
          :title="area.aname"
          checkbox
          @change="onElementChanged(area.id, $event)"
        />
      </f7-list-group>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userId() {
      return Number(this.f7route.params.user_id);
    },
    work_areas() {
      return this.$store.state.profile.work_areas;
    },
  },
  created() {
    this.$store.dispatch('profile/getProfileWorkAreas', {
      userId: this.userId,
    });
  },
  methods: {
    onElementChanged(areaId, event) {
      const set = event.target.checked ? 1 : 0;
      this.$store.dispatch('profile/setProfileWorkAreas', {
        workAreaId: areaId,
        set,
        userId: this.userId,
      });
    },
  },
};
</script>

<template>
  <div class="timeline-item">
    <div class="timeline-item-date">{{ dayNum }}</div>
    <div class="timeline-item-divider" />
    <div class="timeline-item-content">
      <sc-calendar-time-line-shift-component
        v-for="shift in dayShifts"
        :key="shift.id"
        :shift="shift"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import scCalendarTimeLineShiftComponent from './CalendarTimeLineShiftComponent.vue';

export default {
  components: {
    scCalendarTimeLineShiftComponent,
  },
  props: ['dayShifts', 'dayDate'],
  computed: {
    dayNum() { return moment(this.dayDate).format('MMM DD'); },
    stepForward() { return this.$store.state.calendar.stepForward; },
  },
  mounted() {
    const dayMoment = moment(this.dayDate);
    if (dayMoment.isSame(new Date(), 'day')) {
      this.$el.scrollIntoView();
    } else {
      const startOfMonth = moment(this.dayDate).startOf('month').format('YYYY-MM-DD');
      if (startOfMonth === this.dayDate) {
        this.$el.scrollIntoView();
      }
    }
  },
  methods: {
  },
};
</script>

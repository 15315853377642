/* eslint no-fallthrough: "error" */

import _ from 'lodash';
import moment from 'moment';
import api from '../../api';

export default {
  namespaced: true,
  state: {
    jobBoardShifts: [],
    shifts: [],
    userShifts: {},
    shift: {},
    shiftWebSocket: undefined,
    shiftFiles: {
      files: [],
      surveys: [],
    },
    checkInOutPopup: {
      isPopupOpened: false,
      type: 'check_in', // 'check_in', 'check_out'
      role_staff_id: null,
    },
    completePopup: {
      roleName: '',
      completionNotes: '',
      isPopupOpened: false,
      roleStaffId: null,
      start: '',
      end: '',
      timesLocked: 1,
      required: {},
    },
    expenseAddEditPopup: {
      isPopupOpened: false,
      roleStaffId: undefined,
      expense: {},
      type: 'ADD', // 'ADD' or 'EDIT'
    },
    shiftAddEditPopup: {
      isPopupOpened: false,
      type: 'ADD', // 'ADD' or 'EDIT' or DUPLICATE
    },
    shiftRoleAddEditPopup: {
      isPopupOpened: false,
      shiftRoleId: undefined,
      type: 'ADD', // 'ADD' or 'EDIT'
    },
    shiftRoleApplyPopup: {
      roleId: undefined,
      roleName: 'Role Name',
      isPopupOpened: false,
    },
    formQuizConnectPopup: {
      type: '', // 'FORM' or 'QUIZ' or 'SURVEY'
      isPopupOpened: false,
      action: '', // 'APPLY' or 'CONFIRM' or 'COMPLETE' or 'VIEW'
      quiz: {},
      callback: undefined,
      roleId: undefined,
    },
    quizConnectResultPopup: {
      isPopupOpened: false,
      quizName: undefined,
      score: 0,
      incorrect: [],
    },
    step: 0,
    stepForward: true,
    managers: [],
    clients: [],
    workAreas: [],
    roleRequirement: [],
    roleSurvey: [],
    rolePayCategory: [],
    roleCurrency: [],
    shiftChecks: [],
    roleStaff: {
      selected: [],
      standby: [],
      applicants: [],
      invited: [],
      na: [],
      actionMenuStaffId: undefined,
    },
  },
  getters: {
    calendarMonth(state) {
      return moment().add(state.step, 'M').format('MMMM').toUpperCase();
    },

    calendarYear(state) {
      return moment().add(state.step, 'M').format('YYYY');
    },
    getCalendarShiftAdminNoteById: (state) => (id) => _.find(state.shift.shift_admin_notes, { id }),
  },
  mutations: {
    setCalendarJobBoardShifts(state, payload) {
      state.jobBoardShifts = payload.shifts;
    },
    setCalendarShifts(state, payload) {
      state.shifts = payload.shifts;
    },

    setCalendarUserShifts(state, payload) {
      state.userShifts = payload.shifts;
    },

    setCalendarStep(state, payload) {
      const prevStep = state.step;
      state.step = payload.step;
      state.stepForward = prevStep < state.step;
    },

    setCalendarShift(state, payload) {
      state.shift = payload.shift;
    },

    setCalendarShiftWebSocket(state, payload) {
      state.shiftWebSocket = payload;
    },
    closeCalendarShiftWebSocket(state) {
      state.shiftWebSocket.closeConnection();
    },

    setCalendarShiftFiles(state, payload) {
      state.shiftFiles = payload;
    },

    setCalendarCheckInOutPopup(state, payload) {
      state.checkInOutPopup.isPopupOpened = payload.isPopupOpened;
      state.checkInOutPopup.type = payload.type;
      state.checkInOutPopup.role_staff_id = payload.role_staff_id;
    },
    setCalendarCompletePopup(state, payload) {
      state.completePopup.isPopupOpened = payload.isPopupOpened;
      state.completePopup.roleStaffId = payload.roleStaffId;
      state.completePopup.roleName = payload.roleName;
      state.completePopup.completionNotes = payload.completionNotes;
      state.completePopup.start = payload.start;
      state.completePopup.end = payload.end;
      state.completePopup.timesLocked = payload.timesLocked;
      state.completePopup.required = payload.required;
    },
    setCalendarExpenseAddEditPopup(state, payload) {
      state.expenseAddEditPopup.isPopupOpened = payload.isPopupOpened;
      state.expenseAddEditPopup.roleStaffId = payload.roleStaffId;
      state.expenseAddEditPopup.expense = payload.expense;
      state.expenseAddEditPopup.type = payload.type;
    },
    setCalendarShiftAddEditPopup(state, payload) {
      state.shiftAddEditPopup.isPopupOpened = payload.isPopupOpened;
      state.shiftAddEditPopup.type = payload.type;
    },
    setCalendarShiftRoleAddEditPopup(state, payload) {
      state.shiftRoleAddEditPopup.isPopupOpened = payload.isPopupOpened;
      state.shiftRoleAddEditPopup.type = payload.type;
      state.shiftRoleAddEditPopup.shiftRoleId = payload.shiftRoleId || undefined;
    },
    setCalendarShiftRoleApplyPopup(state, payload) {
      state.shiftRoleApplyPopup.isPopupOpened = payload.isPopupOpened;
      state.shiftRoleApplyPopup.roleId = payload.roleId;
      state.shiftRoleApplyPopup.roleName = payload.roleName;
    },

    setFormQuizConnectPopup(state, payload) {
      state.formQuizConnectPopup.isPopupOpened = payload.isPopupOpened;
      state.formQuizConnectPopup.type = payload.type;
      state.formQuizConnectPopup.action = payload.action;
      state.formQuizConnectPopup.quiz = payload.quiz || {};
      state.formQuizConnectPopup.callback = payload.callback || undefined;
      state.formQuizConnectPopup.roleId = payload.roleId || undefined;
    },

    setQuizConnectResultPopup(state, payload) {
      state.quizConnectResultPopup.isPopupOpened = payload.isPopupOpened;
      state.quizConnectResultPopup.quizName = payload.quizName || undefined;
      state.quizConnectResultPopup.score = payload.score || 0;
      state.quizConnectResultPopup.incorrect = payload.incorrect || [];
    },

    deleteFromCalendarShiftFormsApplyByID(state, payload) {
      const forms = [...state.shift.forms_apply];
      _.remove(forms, { id: payload });
      state.shift.forms_apply = forms;
    },

    deleteFromCalendarShiftFormsConfirmByID(state, payload) {
      const forms = [...state.shift.forms_confirm];
      _.remove(forms, { id: payload });
      state.shift.forms_confirm = forms;
    },

    setCalendarShiftManagers(state, payload) {
      state.managers = payload;
    },

    setCalendarShiftClients(state, payload) {
      state.clients = payload;
    },

    setCalendarShiftWorkAreas(state, payload) {
      state.workAreas = payload;
    },
    setCalendarShiftChecks(state, payload) {
      state.shiftChecks = payload;
    },
    setCalendarShiftRoleRequirement(state, payload) {
      state.roleRequirement = payload;
    },

    setCalendarShiftRoleSurvey(state, payload) {
      state.roleSurvey = payload;
    },

    setCalendarShiftRolePayCategory(state, payload) {
      state.rolePayCategory = payload;
    },

    setCalendarShiftRoleCurrency(state, payload) {
      state.roleCurrency = payload;
    },

    setCalendarShiftRoleStaff(state, payload) {
      if (payload.selected) {
        state.roleStaff.selected = payload.selected;
      } else if (payload.standby) {
        state.roleStaff.standby = payload.standby;
      } else if (payload.applicants) {
        state.roleStaff.applicants = payload.applicants;
      } else if (payload.invited) {
        state.roleStaff.invited = payload.invited;
      } else if (payload.na) {
        state.roleStaff.na = payload.na;
      }
    },

    setCalendarActionMenuSfattId(state, payload) {
      state.roleStaff.actionMenuStaffId = payload;
    },
  },
  actions: {
    sendUpdateCalendarWebSocket({ state, rootState }) {
      state.shiftWebSocket.sendData(
        JSON.stringify({
          type: 'shift',
          payload: {
            action: 'shiftUpdate',
            shift: state.shift.id,
            user: rootState.account.user.id,
          },
        })
      );
    },
    fetchCalendarJobBoardShifts({ dispatch, commit }) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .fetchCalendarJobBoardShifts()
        .then((result) => {
          console.dir(result);
          commit('setCalendarJobBoardShifts', { shifts: result.data });
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getCalendarShifts({ state, dispatch, commit }) {
      commit('setIsLoadingData', true, { root: true });
      const beginDate = moment()
        .add(state.step - 1, 'M')
        .startOf('month');
      const endDate = moment()
        .add(state.step + 1, 'M')
        .endOf('month');
      return api.calendar
        .getCalendarShifts({
          begin_date: beginDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
        })
        .then((result) => {
          // const shifts = _.groupBy(result.data, shift => shift.start.split(' ')[0]);
          commit('setCalendarShifts', { shifts: result.data });
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getCalendarUserShifts({ dispatch, commit }) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .getCalendarUserShifts()
        .then((result) => {
          const shifts = _.groupBy(result.data, (shift) => shift.start.split(' ')[0]);
          commit('setCalendarUserShifts', { shifts });
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getCalendarShift({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .getCalendarShift({
          shift_id: payload.shift_id,
        })
        .then((result) => {
          commit('setCalendarShift', { shift: result.data });
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    applyCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .applyCalendarShiftRole({
          roleId: payload.roleId,
          reason: payload.reason,
          shiftRoleIds: payload.shiftRoleIds,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          dispatch('getCalendarShift', { shift_id: state.shift.id });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    cancelApplicationCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .cancelApplicationCalendarShiftRole({
          role_staff_id: payload.role_staff_id,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          dispatch('getCalendarShift', { shift_id: state.shift.id });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    notAvailableCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .notAvailableCalendarShiftRole({
          role_id: payload.role_id,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('getCalendarShift', { shift_id: state.shift.id });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    repalceCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .repalceCalendarShiftRole({
          role_staff_id: payload.role_staff_id,
          reason: payload.reason,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          dispatch('getCalendarShift', { shift_id: state.shift.id });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    cancelRepalceCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .cancelRepalceCalendarShiftRole({
          role_staff_id: payload.role_staff_id,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          dispatch('getCalendarShift', { shift_id: state.shift.id });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    confirmCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .confirmCalendarShiftRole({
          role_staff_id: payload.role_staff_id,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          dispatch('getCalendarShift', { shift_id: state.shift.id });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    checkInCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .checkInCalendarShiftRole({
          role_staff_id: payload.role_staff_id,
          image_blob: payload.image_blob,
          latitude: payload.latitude,
          longitude: payload.longitude,
          note: payload.note,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    checkOutCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .checkOutCalendarShiftRole({
          role_staff_id: payload.role_staff_id,
          image_blob: payload.image_blob,
          latitude: payload.latitude,
          longitude: payload.longitude,
          note: payload.note,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    endBreakCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .endBreakCalendarShiftRole({
          role_staff_id: payload.role_staff_id,
          image_blob: payload.image_blob,
          latitude: payload.latitude,
          longitude: payload.longitude,
          note: payload.note,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    startBreakCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .startBreakCalendarShiftRole({
          role_staff_id: payload.role_staff_id,
          image_blob: payload.image_blob,
          latitude: payload.latitude,
          longitude: payload.longitude,
          note: payload.note,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    completeCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .completeCalendarShiftRole({
          roleStaffId: payload.roleStaffId,
          start: payload.start,
          end: payload.end,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('getCalendarShift', { shift_id: state.shift.id });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    uploadFileCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .uploadFileCalendarShiftRole({
          roleStaffId: payload.roleStaffId,
          files: payload.files,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    newExpenseCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .newExpenseCalendarShiftRole({
          roleStaffId: payload.roleStaffId,
          file: payload.file,
          itemName: payload.itemName,
          itemCategory: payload.itemCategory,
          itemType: payload.itemType,
          unitsNumber: payload.unitsNumber,
          unitsRate: payload.unitsRate,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    editExpenseCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .editExpenseCalendarShiftRole({
          payItemId: payload.payItemId,
          file: payload.file,
          itemName: payload.itemName,
          itemCategory: payload.itemCategory,
          itemType: payload.itemType,
          unitsNumber: payload.unitsNumber,
          unitsRate: payload.unitsRate,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteExpenseCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .deleteExpenseCalendarShiftRole({
          payItemId: payload.payItemId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftManagers({ dispatch, commit }) {
      return api.calendar
        .fetchCalendarShiftManagers()
        .then((result) => {
          commit('setCalendarShiftManagers', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftClients({ dispatch, commit }) {
      return api.calendar
        .fetchCalendarShiftClients()
        .then((result) => {
          commit('setCalendarShiftClients', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftWorkAreas({ dispatch, commit }) {
      return api.calendar
        .fetchCalendarShiftWorkAreas()
        .then((result) => {
          commit('setCalendarShiftWorkAreas', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftChecks({ dispatch, commit }, payload) {
      return api.calendar
        .fetchCalendarShiftChecks({
          shiftId: payload.shiftId,
        })
        .then((result) => {
          commit('setCalendarShiftChecks', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftRoleRequirement({ dispatch, commit }, payload) {
      return api.calendar
        .fetchCalendarShiftRoleRequirement({
          query: payload.query,
        })
        .then((result) => {
          commit('setCalendarShiftRoleRequirement', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftRoleSurvey({ dispatch, commit }, payload) {
      return api.calendar
        .fetchCalendarShiftRoleSurvey({
          query: payload.query,
        })
        .then((result) => {
          commit('setCalendarShiftRoleSurvey', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftRolePayCategory({ dispatch, commit }) {
      return api.calendar
        .fetchCalendarShiftRolePayCategory()
        .then((result) => {
          commit('setCalendarShiftRolePayCategory', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftRoleCurrency({ dispatch, commit }) {
      return api.calendar
        .fetchCalendarShiftRoleCurrency()
        .then((result) => {
          commit('setCalendarShiftRoleCurrency', result.data);
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    addCalendarShift({ dispatch, commit, state }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .addCalendarShift({
          shiftId: payload.isDuplicate ? state.shift.id : undefined,
          title: payload.title,
          genericTitle: payload.genericTitle,
          location: payload.location,
          genericLocation: payload.genericLocation,
          address: payload.address,
          venueContact: payload.venueContact,
          shiftStart: payload.shiftStart,
          shiftEnd: payload.shiftEnd,
          clientId: payload.clientId,
          managerIds: payload.managerIds,
          workAreasIds: payload.workAreasIds,
          notes: payload.notes,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('getCalendarShifts').then(() => {});
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    editCalendarShift({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .editCalendarShift({
          shiftId: state.shift.id,
          title: payload.title,
          genericTitle: payload.genericTitle,
          location: payload.location,
          genericLocation: payload.genericLocation,
          address: payload.address,
          venueContact: payload.venueContact,
          shiftStart: payload.shiftStart,
          shiftEnd: payload.shiftEnd,
          clientId: payload.clientId,
          managerIds: payload.managerIds,
          workAreasIds: payload.workAreasIds,
          notes: payload.notes,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    addCalendarNewBooking({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .addCalendarNewBooking({
          title: payload.title,
          location: payload.location,
          address: payload.address,
          venueContact: payload.venueContact,
          shiftStart: payload.shiftStart,
          shiftEnd: payload.shiftEnd,
          workAreasIds: payload.workAreasIds,
          roles: payload.roles,
          notes: payload.notes,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('getCalendarShifts').then(() => {});
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    updateCalendarShiftLatLng({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .updateCalendarShiftLatLng({
          shiftId: state.shift.id,
          lat: payload.lat,
          lng: payload.lng,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteCalendarShift({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .deleteCalendarShift({
          shiftId: payload && payload.shiftId ? payload.shiftId : state.shift.id,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    publishCalendarShift({ state, commit, dispatch }) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .publishCalendarShift({
          shiftId: state.shift.id,
          live: Number(state.shift.live) === 1 ? 0 : 1,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          dispatch('getCalendarShift', { shift_id: state.shift.id });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftFiles({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .fetchCalendarShiftFiles({
          shiftId: payload.shiftId,
        })
        .then((result) => {
          commit('setCalendarShiftFiles', result.data);
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    addCalendarShiftFile({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .addCalendarShiftFile({
          shiftId: state.shift.id,
          document_blob: payload.document_blob,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('fetchCalendarShiftFiles', { shiftId: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteCalendarShiftFile({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .deleteCalendarShiftFile({
          documentId: payload.documentId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('fetchCalendarShiftFiles', { shiftId: state.shift.id });
          dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    approveCalendarShiftFile({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .approveCalendarShiftFile({
          documentId: payload.documentId,
          approved: payload.approved,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('fetchCalendarShiftFiles', { shiftId: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteCalendarShiftSurvey({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .deleteCalendarShiftSurvey({
          surveyId: payload.surveyId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('fetchCalendarShiftFiles', { shiftId: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    approveCalendarShiftSurvey({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .approveCalendarShiftSurvey({
          surveyId: payload.surveyId,
          approved: payload.approved,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('fetchCalendarShiftFiles', { shiftId: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    addCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .addCalendarShiftRole({
          shiftId: state.shift.id,
          name: payload.name,
          numberRequired: payload.numberRequired,
          applicationDeadlineDate: payload.applicationDeadlineDate,
          notes: payload.notes,
          roleStartTime: payload.roleStartTime,
          roleEndTime: payload.roleEndTime,
          requirements: payload.requirements,
          completionNotes: payload.completionNotes,
          uploadsRequired: payload.uploadsRequired,
          surveys: payload.surveys,
          payCategoryId: payload.payCategoryId,
          payCurrency: payload.payCurrency,
          payRate: payload.payRate,
          payRateType: payload.payRateType,
          billCurrency: payload.billCurrency,
          billRate: payload.billRate,
          billRateType: payload.billRateType,
          expenseLimit: payload.expenseLimit,
          payItems: payload.payItems,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    editCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .editCalendarShiftRole({
          shiftRoleId: state.shiftRoleAddEditPopup.shiftRoleId,
          name: payload.name,
          numberRequired: payload.numberRequired,
          applicationDeadlineDate: payload.applicationDeadlineDate,
          notes: payload.notes,
          roleStartTime: payload.roleStartTime,
          roleEndTime: payload.roleEndTime,
          requirements: payload.requirements,
          completionNotes: payload.completionNotes,
          uploadsRequired: payload.uploadsRequired,
          surveys: payload.surveys,
          payCategoryId: payload.payCategoryId,
          payCurrency: payload.payCurrency,
          payRate: payload.payRate,
          payRateType: payload.payRateType,
          billCurrency: payload.billCurrency,
          billRate: payload.billRate,
          billRateType: payload.billRateType,
          expenseLimit: payload.expenseLimit,
          payItems: payload.payItems,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteCalendarShiftRole({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .deleteCalendarShiftRole({
          shiftRoleId: payload.shiftRoleId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftRoleStaffByType({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .fetchCalendarShiftRoleStaffByType({
          staffType: payload.staffType, // selected, standby, applicants, invited, na
          shiftRoleId: payload.shiftRoleId,
        })
        .then((result) => {
          commit('setCalendarShiftRoleStaff', {
            [payload.staffType]: result.data,
          });
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarShiftRoleStaffAdd({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .fetchCalendarShiftRoleStaffAdd({
          pageId: payload.pageId,
          staffOnPage: payload.staffOnPage,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    selectCalendarShiftRoleStaff({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .selectCalendarShiftRoleStaff({
          shiftRoleId: payload.shiftRoleId,
          selectedStaffIds: payload.selectedStaffIds,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'selected',
            shiftRoleId: payload.shiftRoleId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    inviteCalendarShiftRoleStaff({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .inviteCalendarShiftRoleStaff({
          shiftRoleId: payload.shiftRoleId,
          selectedStaffIds: payload.selectedStaffIds,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'invited',
            shiftRoleId: payload.shiftRoleId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    changeStatusCalendarShiftRoleStaff({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .changeStatusCalendarShiftRoleStaff({
          staffId: payload.staffId,
          staffStatusId: payload.staffStatusId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });

          dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'selected',
            shiftRoleId: payload.shiftRoleId,
          });
          dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'standby',
            shiftRoleId: payload.shiftRoleId,
          });
          dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'applicants',
            shiftRoleId: payload.shiftRoleId,
          });
          dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'invited',
            shiftRoleId: payload.shiftRoleId,
          });
          dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'na',
            shiftRoleId: payload.shiftRoleId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    removeCalendarShiftRoleStaff({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .removeCalendarShiftRoleStaff({
          staffId: payload.staffId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'selected',
            shiftRoleId: payload.shiftRoleId,
          });
          dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'standby',
            shiftRoleId: payload.shiftRoleId,
          });
          dispatch('fetchCalendarShiftRoleStaffByType', {
            staffType: 'na',
            shiftRoleId: payload.shiftRoleId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    changeStatusCalendarShiftStaffSelected({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .changeStatusCalendarShiftStaffSelected({
          staffId: payload.staffId,
          staffStatusId: payload.staffStatusId,
          staffStatusTime: payload.staffStatusTime,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchCalendarLegend({ dispatch, commit }) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .fetchCalendarLegend()
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    addCalendarShiftAdminNote({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .addCalendarShiftAdminNote({
          shiftId: state.shift.id,
          visibilityId: payload.visibilityId,
          mentions: payload.mentions,
          noteText: payload.noteText,
          noteTypeId: payload.noteTypeId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    updateCalendarShiftAdminNote({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .updateCalendarShiftAdminNote({
          adminNoteId: payload.adminNoteId,
          visibilityId: payload.visibilityId,
          mentions: payload.mentions,
          noteText: payload.noteText,
          noteTypeId: payload.noteTypeId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteCalendarShiftAdminNote({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.calendar
        .deleteCalendarShiftAdminNote({
          adminNoteId: payload.adminNoteId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          dispatch('sendUpdateCalendarWebSocket');
          return dispatch('getCalendarShift', { shift_id: state.shift.id });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
  },
};

<template>
  <f7-tab id="image" class="page-content" tab-active>
    <div class="sc-image-gallery">
      <sc-profile-media-image-card
        v-for="(image, imageIndx) in images"
        :key="image.id"
        :image="image"
        :image-indx="imageIndx"
        :photo-browser="photoBrowser"
        :user-id="userId"
      />
    </div>
    <f7-fab
      position="right-bottom"
      style="position: fixed"
      @click="openFilePicker"
    >
      <f7-icon material="add" />
    </f7-fab>
    <sc-file-picker
      @sc-file-picker:ready="openFilePicker = $event"
      @sc-file-picker:loaded="onFileLoaded"
    />
  </f7-tab>
</template>
<script>
import { f7 } from 'framework7-vue';

import scFilePicker from '../../SCFilePicker.vue';

import scProfileMediaImageCard from './Image/ProfileMediaImageCard.vue';

export default {
  components: {
    scFilePicker,
    scProfileMediaImageCard,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      openFilePicker: () => {},
      photoBrowser: undefined,
    };
  },
  computed: {
    images() {
      if (this.photoBrowser !== undefined) {
        this.photoBrowser.destroy();
      }
      const photos = this.$store.state.profile.images.map(
        (image) => image.path
      );
      this.photoBrowser = f7.photoBrowser.create({
        photos: photos,
        backLinkText: '',
      });
      return this.$store.state.profile.images;
    },
  },

  created() {
    this.$store.dispatch('profile/getProfileImages', { userId: this.userId });
  },
  beforeUnmount() {
    if (this.photoBrowser !== undefined) {
      this.photoBrowser.destroy();
    }
  },
  methods: {
    onImageClick(imageId) {
      this.photoBrowser.open(imageId);
    },
    onFileLoaded(file) {
      if (file.type === 'image') {
        this.$store.dispatch('profile/setProfileImage', {
          image_blob: file.blob,
          userId: this.userId,
        });
      } else {
        f7.toast.show({
          text: 'Please select an image file.',
          closeTimeout: 3000,
          closeButton: true,
          closeButtonText: 'Close',
          closeButtonColor: 'red',
          destroyOnClose: true,
        });
      }
    },
  },
};
</script>
<style scoped>
.sc-image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sc-image-card {
  width: 44%;
  max-width: 200px;
  min-width: 120px;
}
.sc-image-card .card-content {
  min-height: 120px;
}
.sc-image-card .card-content img {
  width: 100%;
  display: block;
}
</style>

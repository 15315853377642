<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title title="Job Board" />
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>
    <template v-for="(month, indx) in jobBoardMonths" :key="indx">
      <f7-block-title>{{ month }}</f7-block-title>
      <f7-list accordion-list media-list no-hairlines class="sc-list">
        <f7-list-item v-for="shift in jobBoardShifts[month]" :key="shift.id" :title="shift.title" accordion-item>
          <template #media>
            <div class="display-flex flex-direction-column text-align-center">
              <span style="font-size: 18px">{{ shift.date_num }}</span>
              <span style="font-size: 12px">{{ shift.day }}</span>
            </div>
          </template>
          <template #text>
            <f7-icon color="gray" material="place" size="16" />
            {{ `${shift.location}${shift.distance ? ' (' + shift.distance + ')' : ''}` }}
          </template>
          <f7-accordion-content>
            <f7-list media-list>
              <f7-list-item
                v-for="(role, r_indx) in shift.shift_roles"
                :key="r_indx"
                :title="role.rname"
                :link="`/calendar/shift/${shift.id}/`"
              >
                <template #content-start>
                  <div class="role-color" :style="`background-color:${role.backgroundColor};`" />
                </template>
                <template #header>
                  {{ role.start + ' - ' + role.end }}
                </template>
                <template #subtitle>
                  <f7-icon color="gray" material="people" size="16" />
                  {{ `${role.num_required} person required` }}
                </template>
                <template #text>
                  <f7-icon color="gray" material="notifications" size="16" />
                  {{ role.message }}
                </template>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </template>
  </f7-page>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    jobBoardShifts() {
      return this.$store.state.calendar.jobBoardShifts;
    },
    jobBoardMonths() {
      return Object.keys(this.$store.state.calendar.jobBoardShifts);
    },
  },
  beforeCreate() {
    this.$store.dispatch('calendar/fetchCalendarJobBoardShifts');
  },
  methods: {},
};
</script>
<style scoped>
::v-deep(.sc-list .item-media) {
  justify-content: center;
  color: gray;
}
.role-color {
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
}
</style>

<template>
  <f7-sheet
    :close-by-outside-click="true"
    backdrop
    class="sc-shift-role-new-pay-item"
    @sheet:open="onSheetOpen"
  >
    <f7-toolbar>
      <div class="left">
        <f7-link
          icon-material="close"
          icon-only
          sheet-close
        />
      </div>
      <div class="right">
        <f7-link
          sheet-close
          @click="onAddClick"
        >ADD</f7-link>
      </div>
    </f7-toolbar>
    <f7-page-content>
      <f7-list
        no-hairlines
        class="no-margin"
      >
        <f7-list-input
          outline
          floating-label
          label="Item Type"
          type="select"
          :default-value="payItemTypeId"
          :value="payItemTypeId"
          @change="payItemTypeId = $event.target.value"
        >
          <option
            v-for="type in payItemTypes"
            :key="type.id"
            :value="type.id"
          >{{ type.name }}</option>
        </f7-list-input>

        <f7-list-input
          outline
          floating-label
          label="Item Name*"
          required
          validate
          clear-button
          type="text"
          :value="payItemName"
          @input="payItemName=$event.target.value"
        />


        <f7-list-group>
          <f7-list-item title="Pay" group-title />

          <f7-list-input
            outline
            floating-label
            label="Currency"
            type="select"
            :default-value="payCurrency"
            :value="payCurrency"
            @change="payCurrency = $event.target.value"
          >
            <option
              v-for="currency in roleCurrency"
              :key="currency.id"
              :value="currency.currency_code"
            >{{ currency.currency_code }}</option>
          </f7-list-input>
          <f7-list-input
            outline
            floating-label
            label="Amount"
            type="number"
            min="0"
            :value="payUnitRate"
            @input="payUnitRate=$event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Qty"
            type="number"
            min="0"
            :value="payUnits"
            @input="payUnits=$event.target.value"
          />
        </f7-list-group>

        <f7-list-group>
          <f7-list-item
            title="Bill"
            group-title
          />
          <f7-list-input
            outline
            floating-label
            label="Currency"
            type="select"
            :default-value="billCurrency"
            :value="billCurrency"
            @change="billCurrency = $event.target.value"
          >
            <option
              v-for="currency in roleCurrency"
              :key="currency.id"
              :value="currency.currency_code"
            >{{ currency.currency_code }}</option>
          </f7-list-input>
          <f7-list-input
            outline
            floating-label
            label="Amount"
            type="number"
            min="0"
            :value="billUnitRate"
            @input="billUnitRate=$event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Qty"
            type="number"
            min="0"
            :value="billUnits"
            @input="billUnits=$event.target.value"
          />
        </f7-list-group>
      </f7-list>
    </f7-page-content>
  </f7-sheet>
</template>
<script>
export default {
  data() {
    return {
      payItemId: 0,
      payItemName: '',
      payItemTypeId: 'bonus',
      payUnits: 0,
      payUnitRate: 0,
      payCurrency: 'USD',
      billUnits: 0,
      billUnitRate: 0,
      billCurrency: 'USD',
      payItemTypes: [
        { id: 'bonus', name: 'Bonus' },
        { id: 'deduction', name: 'Deduction' },
        { id: 'expense', name: 'Expense' },
        { id: 'travel', name: 'Travel' },
        { id: 'time', name: 'Time' },
        { id: 'mileage', name: 'Mileage' },
        { id: 'other', name: 'Other' },
      ],
    };
  },
  computed: {
    roleCurrency() { return this.$store.state.calendar.roleCurrency; },
  },
  methods: {
    onSheetOpen() {
      this.payItemName = '';
      this.payItemTypeId = 'bonus';
      this.payUnits = 0;
      this.payUnitRate = 0;
      this.payCurrency = 'USD';
      this.billUnits = 0;
      this.billUnitRate = 0;
      this.billCurrency = 'USD';
    },
    onAddClick() {
      this.payItemId -= 1;
      const payItem = {
        id: this.payItemId,
        item_type: this.payItemTypeId,
        item_name: this.payItemName,
        units: this.payUnits,
        unit_rate: this.payUnitRate,
        bill_units: this.billUnits,
        bill_unit_rate: this.billUnitRate,
        currency: this.payCurrency,
        bill_currency: this.billCurrency,
        type: 'role',
      };
      this.$emit('sc-new-pay-item-sheet:add-pay-item', payItem);
    },
  },
};
</script>

<style scoped>
</style>

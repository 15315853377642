<template>
  <f7-tab
    id="form"
    class="page-content"
  >
    <template v-if="forms.length > 0">
      <f7-list
        no-hairlines
      >
        <f7-list-item
          v-for="form in forms"
          :key="form.id"
          :title="form.fname"
          link
          @click="onClick(form.id)"
        />
      </f7-list>
    </template>
    <f7-block v-else>
      <p class="text-color-gray">There are no forms available to complete.</p>
    </f7-block>
    <sc-profile-media-form-connect-popup
      :is-popup-opened="isPopupOpened"
      :form-id="formId"
      :user-id="userId"
      @closed="onClosed"
    />
  </f7-tab>
</template>
<script>
import scProfileMediaFormConnectPopup from './Form/ProfileMediaFormConnectPopup.vue';

export default {
  components: {
    scProfileMediaFormConnectPopup,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isPopupOpened: false,
      formId: undefined,
    };
  },
  computed: {
    forms() {
      return this.$store.state.profile.forms;
    },
  },
  created() {
    this.$store.dispatch('profile/getProfileForms', { userId: this.userId });
  },
  methods: {
    onClick(formId) {
      this.formId = formId;
      this.isPopupOpened = true;
    },
    onClosed() {
      this.formId = undefined;
      this.isPopupOpened = false;
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <f7-popup
    tablet-fullscreen
    :opened="isPopupOpened"
    @popup:closed="
      $store.commit('calendar/setCalendarShiftRoleAddEditPopup', {
        isPopupOpened: false,
        type: 'ADD',
      })
    "
    @popup:open="onPopupOpen"
  >
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>{{ actionType === 'ADD' ? 'New Role' : 'Edit Role' }}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="onAddSaveClick">
              {{ actionType === 'ADD' ? 'ADD' : 'SAVE' }}
            </f7-link>
          </f7-nav-right>
          <template #after-inner>
            <f7-progressbar v-show="isLoadingData" infinite />
          </template>
        </f7-navbar>

        <f7-block-title>General Information</f7-block-title>
        <f7-list no-hairlines>
          <f7-list-input
            outline
            floating-label
            label="Name*"
            required
            validate
            clear-button
            type="text"
            :value="name"
            @input="name = $event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Number Required"
            type="number"
            min="1"
            :value="numberRequired"
            @input="numberRequired = $event.target.value"
          />
          <f7-list-input
            input-id="application-deadline-date"
            outline
            floating-label
            readonly
            label="Application Deadline"
            type="text"
            :value="applicationDeadlineDate"
            @change="applicationDeadlineDate = $event.target.value"
          />
        </f7-list>

        <f7-block-title>Role Notes</f7-block-title>
        <f7-list no-hairlines>
          <f7-list-input
            outline
            floating-label
            label="Notes"
            type="textarea"
            :value="notes"
            @input="notes = $event.target.value"
          />
        </f7-list>

        <f7-block-title>Times</f7-block-title>
        <f7-list :no-hairlines-between="timeSameAsShift" no-hairlines>
          <f7-list-item>
            <span>Same as shift</span>
            <f7-toggle
              :disabled="actionType === 'EDIT'"
              :checked="timeSameAsShift"
              @toggle:change="timeSameAsShift = !timeSameAsShift"
            />
          </f7-list-item>
          <f7-list-item v-show="!timeSameAsShift">
            <f7-list-item-row>
              <f7-list-item-cell>
                <f7-list-input
                  input-id="role-start-time"
                  outline
                  floating-label
                  readonly
                  label="From"
                  type="text"
                  :value="roleStartTime"
                  @change="roleStartTime = $event.target.value"
                />
              </f7-list-item-cell>
              <f7-list-item-cell>
                <f7-list-input
                  input-id="role-end-time"
                  outline
                  floating-label
                  readonly
                  label="To"
                  type="text"
                  :value="roleEndTime"
                  @change="roleEndTime = $event.target.value"
                />
              </f7-list-item-cell>
            </f7-list-item-row>
          </f7-list-item>
        </f7-list>

        <f7-block-title>Requirements</f7-block-title>
        <f7-list no-hairlines>
          <f7-list-item title="Requirements" link popup-open=".shift-role-requirements-select">
            <template #root>
              <div v-show="requirements.length > 0" class="padding-left padding-bottom">
                <f7-chip
                  v-for="req in requirements"
                  :key="req.id"
                  :text="req.text"
                  style="margin-right: 5px"
                  deleteable
                  @delete="onSelectedRequirementDeleteClick(req.id)"
                />
              </div>
            </template>
          </f7-list-item>
        </f7-list>

        <f7-block-title>Pay Items</f7-block-title>
        <f7-block>
          <f7-block-header class="margin-left">Pay</f7-block-header>
          <f7-list no-hairlines>
            <f7-list-input
              outline
              floating-label
              label="Category"
              type="select"
              :default-value="payCategoryId"
              :value="payCategoryId"
              @change="payCategoryId = $event.target.value"
            >
              <option :key="-1" :value="-1" disabled>Not selected</option>
              <option v-for="category in rolePayCategory" :key="category.id" :value="category.id">
                {{ category.cname }}
              </option>
            </f7-list-input>
            <f7-list-input
              outline
              floating-label
              label="Currency"
              type="select"
              :default-value="payCurrency"
              :value="payCurrency"
              @change="payCurrency = $event.target.value"
            >
              <option v-for="currency in roleCurrency" :key="currency.id" :value="currency.currency_code">
                {{ currency.currency_code }}
              </option>
            </f7-list-input>
            <f7-list-input
              outline
              floating-label
              label="Rate"
              type="number"
              min="0"
              :value="payRate"
              @input="payRate = $event.target.value"
            />
            <f7-list-input
              outline
              floating-label
              label="Rate Type"
              type="select"
              :default-value="payRateType"
              :value="payRateType"
              @change="payRateType = $event.target.value"
            >
              <option value="phr">per hours</option>
              <option value="flat">flat</option>
            </f7-list-input>
            <f7-list-input
              outline
              floating-label
              label="Expense Limit"
              type="number"
              min="0"
              :value="expenseLimit"
              @input="expenseLimit = $event.target.value"
            />
          </f7-list>

          <f7-block-header class="margin-left">Bill</f7-block-header>
          <f7-list no-hairlines>
            <f7-list-input
              outline
              floating-label
              label="Currency"
              type="select"
              :default-value="billCurrency"
              :value="billCurrency"
              @change="billCurrency = $event.target.value"
            >
              <option v-for="currency in roleCurrency" :key="currency.id" :value="currency.currency_code">
                {{ currency.currency_code }}
              </option>
            </f7-list-input>
            <f7-list-input
              outline
              floating-label
              label="Rate"
              type="number"
              min="0"
              :value="billRate"
              @input="billRate = $event.target.value"
            />
            <f7-list-input
              outline
              floating-label
              label="Rate Type"
              type="select"
              :default-value="billRateType"
              :value="billRateType"
              @change="billRateType = $event.target.value"
            >
              <option value="phr">per hours</option>
              <option value="flat">flat</option>
            </f7-list-input>
          </f7-list>

          <f7-block-header class="margin-horizontal display-flex justify-content-space-between align-items-center">
            <span>Pay Items</span>
            <f7-button sheet-open=".sc-shift-role-new-pay-item">ADD</f7-button>
          </f7-block-header>
          <f7-list no-hairlines media-list>
            <f7-list-item
              v-for="payItem in payItems"
              :key="payItem.id"
              :title="payItem.item_name"
              :after="payItem.item_type"
              swipeout
            >
              <template #text>
                <div>
                  Pay: {{ payItem.units }} x {{ getCurrencySymbolByCode(payItem.currency) }}{{ payItem.unit_rate }}
                </div>
                <div>
                  Bill: {{ payItem.bill_units }} x {{ getCurrencySymbolByCode(payItem.bill_currency)
                  }}{{ payItem.bill_unit_rate }}
                </div>
              </template>
              <f7-swipeout-actions right>
                <f7-swipeout-button close color="red" @click="onPayItemDeleteClick(payItem.id)">
                  <f7-icon material="delete" color="white" />
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-block>

        <f7-block-title>Role Completion Notes</f7-block-title>
        <f7-list no-hairlines>
          <f7-list-input
            outline
            floating-label
            label="Role Completion Notes"
            type="textarea"
            :value="completionNotes"
            @input="completionNotes = $event.target.value"
          />
          <f7-list-input
            outline
            floating-label
            label="Uploads Required"
            type="number"
            min="0"
            max="99"
            :value="uploadsRequired"
            @input="uploadsRequired = $event.target.value"
          />

          <f7-list-item title="Surveys" link popup-open=".shift-role-surveys-select">
            <template #root>
              <div v-show="surveys.length > 0" class="padding-left padding-bottom">
                <f7-chip
                  v-for="sur in surveys"
                  :key="sur.id"
                  :text="sur.text"
                  style="margin-right: 5px"
                  deleteable
                  @delete="onSelectedSurveyDeleteClick(sur.id)"
                />
              </div>
            </template>
          </f7-list-item>
        </f7-list>

        <sc-calendar-shift-detail-role-add-edit-new-pay-item-sheet @sc-new-pay-item-sheet:add-pay-item="onAddPayItem" />

        <sc-calendar-shift-detail-role-requirements-select-popup
          :requirements="requirements"
          @selected="onRoleRequirementsSelected"
        />
        <sc-calendar-shift-detail-role-surveys-select-popup :surveys="surveys" @selected="onRoleSurveysSelected" />
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';
import moment from 'moment';
import scCalendarShiftDetailRoleAddEditNewPayItemSheet from './AddEdit/CalendarShiftDetailRoleAddEditNewPayItemSheet.vue';
import scCalendarShiftDetailRoleRequirementsSelectPopup from './AddEdit/CalendarShiftDetailRoleRequirementsSelectPopup.vue';
import scCalendarShiftDetailRoleSurveysSelectPopup from './AddEdit/CalendarShiftDetailRoleSurveysSelectPopup.vue';

export default {
  components: {
    scCalendarShiftDetailRoleAddEditNewPayItemSheet,
    scCalendarShiftDetailRoleRequirementsSelectPopup,
    scCalendarShiftDetailRoleSurveysSelectPopup,
  },
  data() {
    return {
      name: '',
      numberRequired: 1,
      applicationDeadlineDate: '',
      notes: '',
      timeSameAsShift: false,
      roleStartTime: 'AM 09:00',
      roleEndTime: 'PM 05:00',
      requirements: [],
      completionNotes: '',
      uploadsRequired: undefined,
      surveys: [],
      payCategoryId: -1,
      payCurrency: 'USD',
      payRate: 0,
      payRateType: 'phr',
      billCurrency: 'USD',
      billRate: 0,
      billRateType: 'phr',
      expenseLimit: 0,
      payItems: [],
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isPopupOpened() {
      return this.$store.state.calendar.shiftRoleAddEditPopup.isPopupOpened;
    },
    actionType() {
      return this.$store.state.calendar.shiftRoleAddEditPopup.type;
    },
    shiftRoleId() {
      return this.$store.state.calendar.shiftRoleAddEditPopup.shiftRoleId;
    },
    rolePayCategory() {
      return this.$store.state.calendar.rolePayCategory;
    },
    roleCurrency() {
      return this.$store.state.calendar.roleCurrency;
    },
  },
  beforeCreate() {
    this.$store.dispatch('calendar/fetchCalendarShiftRolePayCategory');
    this.$store.dispatch('calendar/fetchCalendarShiftRoleCurrency');
  },
  created() {},
  mounted() {
    const today = new Date();
    this.applicationDeadlineDateElement = f7.calendar.create({
      inputEl: '#application-deadline-date',
      dateFormat: 'MM dd yyyy',
      locale: 'en',
      minDate: today,
      closeOnSelect: true,
      routableModals: false,
    });

    this.startTimeElement = f7.picker.create({
      inputEl: '#role-start-time',
      rotateEffect: true,
      formatValue: (values) => `${values[0]}:${values[1]}`,
      cols: [
        {
          textAlign: 'left',
          values: 'AM 01, AM 02, AM 03, AM 04, AM 05, AM 06, AM 07, AM 08, AM 09, AM 10, AM 11, AM 12, PM 01, PM 02, PM 03, PM 04, PM 05, PM 06, PM 07, PM 08, PM 09, PM 10, PM 11, PM 12'.split(
            ','
          ),
        },
        {
          divider: true,
          content: ':',
        },
        {
          values: (
            '00 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 ' +
            '21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 ' +
            '41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59'
          ).split(' '),
        },
      ],
    });
    this.endTimeElement = f7.picker.create({
      inputEl: '#role-end-time',
      rotateEffect: true,
      formatValue: (values) => `${values[0]}:${values[1]}`,
      cols: [
        {
          textAlign: 'left',
          values: 'AM 01, AM 02, AM 03, AM 04, AM 05, AM 06, AM 07, AM 08, AM 09, AM 10, AM 11, AM 12, PM 01, PM 02, PM 03, PM 04, PM 05, PM 06, PM 07, PM 08, PM 09, PM 10, PM 11, PM 12'.split(
            ','
          ),
        },
        {
          divider: true,
          content: ':',
        },
        {
          values: (
            '00 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 ' +
            '21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 ' +
            '41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59'
          ).split(' '),
        },
      ],
    });
  },
  methods: {
    onPopupOpen() {
      this.name = '';
      this.numberRequired = 1;
      this.applicationDeadlineDate = '';
      this.notes = '';
      this.timeSameAsShift = false;
      this.roleStartTime = 'AM 09:00';
      this.roleEndTime = 'PM 05:00';
      this.requirements = [];
      this.completionNotes = '';
      this.uploadsRequired = undefined;
      this.surveys = [];
      this.payCategoryId = -1;
      this.payCurrency = 'USD';
      this.payRate = 0;
      this.payRateType = 'phr';
      this.billCurrency = 'USD';
      this.billRate = 0;
      this.billRateType = 'phr';
      this.expenseLimit = 0;
      this.payItems = [];

      if (this.actionType === 'EDIT') {
        const shiftRole = _.find(this.$store.state.calendar.shift.shift_roles, {
          id: this.shiftRoleId,
        });
        this.name = shiftRole.rname;
        this.numberRequired = shiftRole.num_required;
        this.applicationDeadlineDate = moment(shiftRole.application_deadline, 'MMMM DD YYYY').isValid()
          ? moment(shiftRole.application_deadline, 'DD/MM/YYYY').format('MMMM DD YYYY')
          : '';
        this.notes = shiftRole.notes;
        this.timeSameAsShift = false;
        this.roleStartTime = moment(shiftRole.start, 'h:mm a').format('A hh:mm');
        this.roleEndTime = moment(shiftRole.end, 'h:mm a').format('A hh:mm');
        this.requirements = shiftRole.requirements;
        this.completionNotes = shiftRole.completion_notes;
        this.uploadsRequired = shiftRole.uploads_required;
        this.surveys = shiftRole.surveys;
        this.payCategoryId = shiftRole.pay_category_id;
        this.payCurrency = shiftRole.pay_currency;
        this.payRate = Number(shiftRole.pay_rate);
        this.payRateType = shiftRole.pay_rate_type;
        this.billCurrency = shiftRole.bill_currency;
        this.billRate = Number(shiftRole.bill_rate);
        this.billRateType = shiftRole.bill_rate_type;
        this.expenseLimit = Number(shiftRole.expense_limit);
        this.payItems = shiftRole.pay_items;
      }
    },
    isSelected(value) {
      return _.indexOf(this.requirements, value) !== -1;
    },
    onRequirementsChanged(event) {
      const option = event.target.value;
      const filterType = _.split(option, ':', 1)[0];
      const newFetchUsersFilter = [...this.requirements];
      _.remove(newFetchUsersFilter, (val) => _.startsWith(val, filterType));
      newFetchUsersFilter.push(option);
      this.requirements = newFetchUsersFilter;
    },

    onReportsChanged(event) {
      const result = [];
      _.forEach(event.target.options, (option) => {
        if (option.selected) {
          result.push(Number(option.value));
        }
      });
      this.reports = result;
    },
    onAddSaveClick() {
      if (this.name !== '') {
        const actionName = this.actionType === 'ADD' ? 'addCalendarShiftRole' : 'editCalendarShiftRole';
        this.$store
          .dispatch(`calendar/${actionName}`, {
            name: this.name,
            numberRequired: this.numberRequired,
            applicationDeadlineDate: moment(this.applicationDeadlineDate, 'MMMM DD YYYY').isValid()
              ? moment(this.applicationDeadlineDate, 'MMMM DD YYYY').format('YYYY-MM-DD')
              : undefined,
            notes: this.notes,
            roleStartTime: !this.timeSameAsShift ? moment(this.roleStartTime, 'A hh:mm').format('HH:mm') : undefined,
            roleEndTime: !this.timeSameAsShift ? moment(this.roleEndTime, 'A hh:mm').format('HH:mm') : undefined,
            requirements: _.reduce(
              this.requirements,
              (result, req) => {
                result.push(req.id);
                return result;
              },
              []
            ),
            completionNotes: this.completionNotes,
            uploadsRequired: this.uploadsRequired,
            surveys: _.reduce(
              this.surveys,
              (result, s) => {
                result.push(Number(s.id));
                return result;
              },
              []
            ),
            payCategoryId: this.payCategoryId === -1 ? undefined : this.payCategoryId,
            payCurrency: this.payCurrency,
            payRate: this.payRate,
            payRateType: this.payRateType,
            billCurrency: this.billCurrency,
            billRate: this.billRate,
            billRateType: this.billRateType,
            expenseLimit: this.expenseLimit,
            payItems: _.reduce(
              this.payItems,
              (result, pi) => {
                result.push(
                  `${pi.id}|${pi.item_type}|${pi.item_name}|${pi.currency}|${pi.unit_rate}|${pi.units}|${pi.bill_currency}|${pi.bill_unit_rate}|${pi.bill_units}`
                );
                return result;
              },
              []
            ),
          })
          .then((result) => {
            if (result !== false) {
              f7.popup.close();
            }
          });
      }
    },
    onSelectedRequirementDeleteClick(id) {
      this.requirements = _.filter(this.requirements, (r) => r.id !== id);
    },
    onRoleRequirementsSelected(roleRequirements) {
      this.requirements = roleRequirements;
    },

    onSelectedSurveyDeleteClick(id) {
      this.surveys = _.filter(this.surveys, (r) => r.id !== id);
    },
    onRoleSurveysSelected(roleSurveys) {
      this.surveys = roleSurveys;
    },
    onAddPayItem(payItem) {
      const newPayItems = [...this.payItems];
      newPayItems.push(payItem);
      this.payItems = newPayItems;
    },
    onPayItemDeleteClick(payItemId) {
      this.payItems = _.filter(this.payItems, (item) => item.id !== payItemId);
    },
    getCurrencySymbolByCode(code) {
      return _.find(this.roleCurrency, { currency_code: code }).currency_symbol;
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:closed="$emit('sc-chats-thread-remove-user-popup:closed')">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close icon-material="close" icon-only />
        </f7-nav-left>
        <f7-nav-title title="Remove User" />
      </f7-navbar>
      <f7-list class="no-margin-top">
        <f7-list-item
          v-for="participant in participants"
          :key="participant.id"
          :title="participant.name"
          link="#"
          popup-close
          @click="onUserClick(participant.id)"
        >
          <template #media>
            <img :src="participant.ppic_a || 'assets/img/default-user-image.png'" class="sc-chats__thread__pic" />
          </template>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import Socket from '../../../libs/socket';

export default {
  props: {
    isPopupOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
    socket: {
      type: Socket,
      required: true,
    },
    f7route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    participants() {
      return this.$store.getters['chats/getChatThreadParticipants'](Number(this.f7route.params.thread_id));
    },
    participants_ids() {
      return this.$store.getters['chats/getChatThreadParticipantsIds'](Number(this.f7route.params.thread_id));
    },
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    onUserClick(userId) {
      this.$store
        .dispatch('chats/deleteUserChatThread', {
          user_id: userId,
        })
        .then(() => {
          this.socket.sendData(
            JSON.stringify({
              type: 'removeUser',
              payload: {
                thread: Number(this.f7route.params.thread_id),
                userId,
                receipt: this.participants_ids,
              },
            })
          );
        });
    },
  },
};
</script>
<style scoped>
.sc-chats__thread__pic {
  width: 45px;
  border-radius: 50%;
}
</style>

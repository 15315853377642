<template>
  <f7-card
    :style="postApproved === 0 ? 'background: lightgrey;' : ''"
    class="sc-post-card"
  >
    <f7-card-header
      class="no-border display-flex justify-content-space-between"
    >
      <div class="sc-post-avatar">
        <img :src="postPpic_a" class="sc-post-avatar-image" />
      </div>
      <div class="sc-post-title">
        <div class="sc-post-name">
          {{ postName }}
          <span v-show="postPinned === 1">
            <f7-icon material="bookmark" color="red" />
          </span>
        </div>
        <div class="sc-post-date">{{ createdAt }}</div>
      </div>
      <div class="sc-post-menu">
        <f7-link
          v-if="isUserLvlOwnerOrAdmin && pageType === 'HOME'"
          :class="'sc-home-post-action' + postId"
          icon-material="more_vert"
          style="min-width: 0px"
          color="gray"
          @click="onMenuClick"
        />
      </div>
    </f7-card-header>
    <f7-card-content>
      <div
        :id="`sc-post-content-${postId}`"
        style="white-space: pre-line"
        v-html="postContent"
      />
      <a @click="onExternalLinkClick(postLink)">
        <img v-if="postThumb" :src="postThumb" style="width: 100%" />
      </a>
    </f7-card-content>
    <f7-card-footer v-if="pageType === 'HOME'">
      <div class="display-flex">
        <f7-button
          :icon-color="postLiked === 1 ? 'red' : 'gray'"
          icon-material="favorite"
          color="gray"
          @click="onLikeClick"
        />
        <f7-link color="gray" @click="onLikeTextClick">{{
          postLikesText
        }}</f7-link>
      </div>
      <f7-button :href="`/post/${postId}/`" icon-material="forum" color="gray">
        {{ postComments }}
      </f7-button>
    </f7-card-footer>
  </f7-card>
</template>

<script>
import { f7 } from 'framework7-vue';
import $ from 'dom7';

import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    postPpic_a: {
      type: String,
      default: '',
    },
    postName: {
      type: String,
      default: '',
    },
    postCreatedAt: {
      type: String,
      default: '',
    },
    postContent: {
      type: String,
      default: '',
    },
    postLink: {
      type: String,
      default: '',
    },
    postThumb: {
      type: String,
      default: '',
    },
    postLiked: {
      type: Number,
      default: 0,
    },
    postLikes: {
      type: Number,
      default: 0,
    },
    postLikesText: {
      type: String,
      default: '',
    },
    postComments: {
      type: Number,
      default: 0,
    },
    postId: {
      type: Number,
      required: true,
    },
    postPinned: {
      type: Number,
      default: 0,
    },
    postApproved: {
      type: Number,
      default: 0,
    },
    pageType: {
      // 'HOME' or 'DETAIL'
      type: String,
      required: true,
    },
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    createdAt() {
      return moment(this.postCreatedAt).isValid()
        ? moment(this.postCreatedAt).format('ll LTS')
        : 'Date unknown';
    },
    ...mapGetters('account', {
      isUserLvlOwnerOrAdmin: 'isUserLvlOwnerOrAdmin',
    }),
  },
  mounted() {
    $(`#sc-post-content-${this.postId} a`).on('click', (e) => {
      this.onExternalLinkClick(e.target.href);
    });
  },

  methods: {
    onExternalLinkClick(link) {
      window.open(link, '_system');
    },
    onLikeClick() {
      this.$store.dispatch('posts/setPostLiked', { postId: this.postId });
    },
    onPinClick() {
      if (!this.postPinned) {
        f7.dialog.prompt('Enter title for pinned post', 'Pin Post', (title) => {
          this.$store.dispatch('posts/setPostPinned', {
            postId: this.postId,
            title,
          });
        });
      } else {
        this.$store.dispatch('posts/setPostPinned', {
          postId: this.postId,
          title: '',
        });
      }
    },
    onApproveClick() {
      this.$store.dispatch('posts/setPostApproved', { postId: this.postId });
    },
    onDeleteClick() {
      f7.dialog.confirm(
        "This post will be deleted and you won't be able to find it anymore. You can also edit this post if you just want to change something.",
        'Delete the post?',
        () => {
          this.$store
            .dispatch('posts/deletePost', { postId: this.postId })
            .then(() => {
              if (this.pageType === 'HOME') {
                this.$store.dispatch('posts/getPosts', { silent: true });
                this.$store.dispatch('posts/getPinnedPosts');
              } else {
                this.f7router.back();
              }
            });
        }
      );
    },
    onEditClick() {
      this.$emit('sc-home-post-card:post-edit-click', this.postId);
    },
    onMenuClick() {
      const buttons = [
        {
          id: 'pin',
          text: this.postPinned ? 'Unpin' : 'Pin',
          onClick: this.onPinClick,
          icon: `<i class="icon material-icons color-gray">${
            this.postPinned ? 'turned_in_not' : 'turned_in'
          }</i>`,
        },
        {
          id: 'approve',
          text: this.postApproved ? 'Unapprove' : 'Approve',
          onClick: this.onApproveClick,
          icon: `<i class="icon material-icons color-gray">${
            this.postApproved ? 'assignment_returned' : 'assignment_turned_in'
          }</i>`,
        },
        {
          id: 'edit',
          text: 'Edit',
          onClick: this.onEditClick,
          icon: '<i class="icon material-icons color-gray">edit</i>',
        },
        {
          id: 'delete',
          text: 'Delete',
          onClick: this.onDeleteClick,
          icon: '<i class="icon material-icons color-gray">delete</i>',
        },
      ];
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        // forceToPopover: true,
        buttons,
        targetEl: `.sc-home-post-action${this.postId}`,
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
      });
      actionSheet.open();
    },
    onLikeTextClick() {
      this.$store
        .dispatch('posts/getPostLikesUserList', { postId: this.postId })
        .then(() => {});
    },
  },
};
</script>
<style scoped>
.sc-post-card .card-header {
  display: block;
  padding: 10px;
}
.sc-post-card .sc-post-avatar {
  float: left;
}
.sc-post-card .sc-post-avatar .sc-post-avatar-image {
  width: 34px;
  border-radius: 50%;
}
.sc-post-card .sc-post-title {
  width: 100%;
}
.sc-post-card .sc-post-name {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}
.sc-post-card .sc-post-date {
  margin-left: 10px;
  font-size: 13px;
  color: #8e8e93;
}
.sc-post-card .card-footer a {
  font-weight: 500;
}
.sc-post-card .card-content img {
  display: block;
}
.sc-post-card .card-content-padding {
  padding: 15px 10px;
}
.sc-post-card .card-content-padding .likes {
  color: #8e8e93;
}
</style>

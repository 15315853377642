<template>
  <f7-popup
    tablet-fullscreen
    :opened="isPopupOpened"
    @popup:closed="$emit('sc-home-add-edit-post-popup:closed')"
    @popup:open="onPopupOpen"
  >
    <f7-page>
      <f7-navbar no-shadow>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close />
        </f7-nav-left>
        <f7-nav-title>{{ popupActionType === 'ADD' ? 'New Post' : 'Edit Post' }}</f7-nav-title>
      </f7-navbar>
      <f7-toolbar bottom no-shadow>
        <f7-link />
        <f7-link v-show="!isLoadingData" icon-material="add" @click="onAddPostClick">{{
          popupActionType === 'ADD' ? 'POST' : 'SAVE'
        }}</f7-link>
        <!-- <f7-preloader v-show="isFileLoading" :size="32" color="white" /> -->
        <f7-link />
      </f7-toolbar>

      <sc-file-picker
        @sc-file-picker:ready="openFilePicker = $event"
        @sc-file-picker:loading="isFileLoading = true"
        @sc-file-picker:loaded="onFileLoaded"
      />

      <f7-card v-show="file.src !== '' || file.blob !== null" outline>
        <f7-card-content :padding="false">
          <img v-show="file.type === 'image'" :src="file.src" width="100%" style="display: block" />
          <f7-icon
            v-show="file.type !== 'image'"
            material="insert_drive_file"
            icon="margin-vertical md-72"
            color="gray"
          />
        </f7-card-content>
        <f7-card-footer class="no-padding-right">
          <span>{{ file.createdAt }}</span>
          <f7-button icon-material="delete" color="gray" @click="onFileDeleteClick()" />
        </f7-card-footer>
      </f7-card>

      <!-- <f7-list no-hairlines>
        <f7-list-input
          :value="content"
          label="Message"
          floating-label
          type="textarea"
          resizable
          placeholder="Write something ..."
          @input="content = $event.target.value"
        />
      </f7-list> -->
      <f7-text-editor placeholder="Enter text..." :value="content" @texteditor:change="(v) => (content = v)" />
      <f7-block v-show="!isFileLoading">
        <f7-row class="margin-vertical justify-content-center">
          <f7-button class="button-large button-fill button-raised" icon-material="attach_file" @click="openFilePicker">
            UPLOAD PHOTO
          </f7-button>
        </f7-row>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>
<script>
import { f7 } from 'framework7-vue';

import scFilePicker from '../../components/SCFilePicker.vue';

export default {
  components: {
    scFilePicker,
  },
  props: {
    isPopupOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
    popupActionType: {
      type: String,
      default: 'ADD',
    },
    editPostId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      content: '',
      isFileLoading: false,
      deleteFile: 0,
      openFilePicker: () => {},
      file: {
        blob: null,
        name: '',
        type: '',
        createdAt: '',
        src: '',
      },
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  methods: {
    onFileLoaded(file) {
      if (file !== null) {
        this.file.blob = file.blob;
        this.file.name = file.name;
        this.file.type = file.type;
        this.file.createdAt = file.createdAt;
        this.file.src = file.src;
        this.deleteFile = 0;
      }
      this.isFileLoading = false;
    },
    onPopupOpen() {
      this.content = '';
      this.file.blob = null;
      this.file.name = '';
      this.file.type = '';
      this.file.createdAt = '';
      this.file.src = '';
      this.deleteFile = 0;
      this.isFileLoading = false;
      if (this.popupActionType === 'EDIT') {
        const post = this.$store.getters['posts/getPostById'](this.editPostId);
        if (post.thumb) {
          this.file.type = 'image';
          this.file.src = post.thumb;
          this.file.createdAt = post.created_at;
        }
        this.content = post.content;
      }
    },
    onAddPostClick() {
      if (this.popupActionType === 'ADD') {
        this.$store
          .dispatch('posts/addNewPost', {
            file: this.file.blob,
            content: this.content,
          })
          .then(() => {
            f7.popup.close();
          });
      } else {
        this.$store
          .dispatch('posts/editPost', {
            postId: this.editPostId,
            file: this.file.blob,
            content: this.content,
            deleteFile: this.deleteFile,
          })
          .then(() => {
            f7.popup.close();
          });
      }
    },
    onFileDeleteClick() {
      this.file.blob = null;
      this.file.name = '';
      this.file.type = '';
      this.file.createdAt = '';
      this.file.src = '';
      this.deleteFile = 1;
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-segmented>
    <f7-button
      v-if="rolePrimariesActions && ~rolePrimariesActions.indexOf('complete')"
      outline
      color="green"
      style="width: 105px"
      @click="onCompleteClick"
      >Complete</f7-button
    >
    <f7-button
      v-if="rolePrimariesActions && ~rolePrimariesActions.indexOf('check_out')"
      outline
      color="red"
      style="width: 105px"
      @click="onCheckOutClick"
      >Check Out</f7-button
    >
    <f7-button
      v-if="rolePrimariesActions && ~rolePrimariesActions.indexOf('check_in')"
      outline
      color="green"
      style="width: 95px"
      @click="onCheckInClick"
      >Check In</f7-button
    >
    <f7-button
      v-if="
        rolePrimariesActions && ~rolePrimariesActions.indexOf('start_break')
      "
      outline
      color="red"
      style="width: 115px"
      @click="onStartBreakClick"
      >Start Break</f7-button
    >
    <f7-button
      v-if="rolePrimariesActions && ~rolePrimariesActions.indexOf('end_break')"
      outline
      color="green"
      style="width: 115px"
      @click="onEndBreakClick"
      >End Break</f7-button
    >
    <f7-button
      v-if="
        rolePrimariesActions && ~rolePrimariesActions.indexOf('cancel_replace')
      "
      outline
      color="blue"
      style="width: 145px"
      @click="onCancelReplaceClick"
      >Cancel Replace</f7-button
    >
    <f7-button
      v-if="rolePrimariesActions && ~rolePrimariesActions.indexOf('replace')"
      outline
      color="red"
      style="width: 85px"
      @click="onReplaceClick"
      >Replace</f7-button
    >
    <f7-button
      v-if="rolePrimariesActions && ~rolePrimariesActions.indexOf('confirm')"
      outline
      color="green"
      style="width: 85px"
      @click="onConfirmClick"
      >Confirm</f7-button
    >
    <f7-button
      v-if="
        rolePrimariesActions && ~rolePrimariesActions.indexOf('day_confirm')
      "
      outline
      color="green"
      style="width: 125px"
      @click="onConfirmClick"
      >Day Confirm</f7-button
    >
    <f7-button
      v-if="
        rolePrimariesActions && ~rolePrimariesActions.indexOf('not_available')
      "
      outline
      color="red"
      style="width: 135px"
      @click="onNotAvailableClick"
      >Not Available</f7-button
    >
    <f7-button
      v-if="
        rolePrimariesActions &&
        ~rolePrimariesActions.indexOf('cancel_application')
      "
      outline
      color="red"
      style="width: 175px"
      @click="onCancelApplicationClick"
      >Cancel Application</f7-button
    >
    <f7-button
      v-if="rolePrimariesActions && ~rolePrimariesActions.indexOf('apply')"
      outline
      color="green"
      style="width: 75px"
      @click="onApplyClick"
      >Apply</f7-button
    >
    <f7-button
      v-if="rolePrimariesActions && ~rolePrimariesActions.indexOf('sub_menu')"
      :class="`shift-role-sub-actions-button${role.id}`"
      outline
      color="blue"
      style="width: 40px"
      @click="onSubMenuClick"
      >...</f7-button
    >
  </f7-segmented>
</template>
<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';

export default {
  props: ['role'],
  computed: {
    rolePrimariesActions() {
      let result = [];
      if (this.role.actions && this.role.actions.length > 2) {
        result = this.role.actions.slice(0, 1);
        result.push('sub_menu');
      } else {
        result = this.role.actions;
      }
      return result;
    },
    roleSecondariesActions() {
      let result = [];
      if (this.role.actions && this.role.actions.length > 2) {
        result = this.role.actions.slice(1);
      }
      return result;
    },
    shift() {
      return this.$store.state.calendar.shift;
    },
  },
  methods: {
    onApplyClick() {
      const mainAction = () => {
        this.$store.commit('calendar/setCalendarShiftRoleApplyPopup', {
          isPopupOpened: true,
          roleId: this.role.id,
          roleName: this.role.rname,
        });
      };
      const quizRequred = this.role.quizs
        ? _.filter(this.role.quizs, (q) => Number(q.required) === 1)
        : [];
      if (this.shift.forms_apply && this.shift.forms_apply.length > 0) {
        this.$store.commit('calendar/setFormQuizConnectPopup', {
          isPopupOpened: true,
          type: 'FORM',
          action: 'APPLY',
        });
      } else if (quizRequred.length > 0) {
        this.$store.commit('calendar/setFormQuizConnectPopup', {
          isPopupOpened: true,
          type: 'QUIZ',
          action: '',
          quiz: quizRequred[0],
          callback: mainAction,
          roleId: this.role.id,
        });
      } else {
        mainAction();
      }
    },
    onCancelApplicationClick() {
      const message =
        this.role.linked_roles && this.role.linked_roles.apply_all_or_nothing
          ? 'Really cancel your application? This is a multi-day role, you will be cancelling for all dates.'
          : 'Really cancel your application?';
      f7.dialog.confirm(message, 'Confirm', () => {
        this.$store
          .dispatch('calendar/cancelApplicationCalendarShiftRole', {
            role_staff_id: this.role.role_staff_id,
          })
          .then((result) => {
            if (result.data.warning) {
              f7.toast
                .create({
                  text: result.data.warning,
                  closeTimeout: 3000,
                  closeButton: true,
                  closeButtonText: 'Close',
                  closeButtonColor: 'blue',
                })
                .open();
            }
            f7.toast
              .create({
                text: result.data.message,
                closeTimeout: 2000,
                closeButton: true,
                closeButtonText: 'Close',
                closeButtonColor: 'blue',
              })
              .open();
          });
      });
    },
    onNotAvailableClick() {
      f7.dialog.confirm('Are you sure?', '', () => {
        this.$store
          .dispatch('calendar/notAvailableCalendarShiftRole', {
            role_id: this.role.id,
          })
          .then((result) => {
            f7.toast
              .create({
                text: result.data.message,
                closeTimeout: 2000,
                closeButton: true,
                closeButtonText: 'Close',
                closeButtonColor: 'blue',
              })
              .open();
          });
      });
    },
    onConfirmClick() {
      const mainAction = () => {
        f7.dialog.confirm('Really confirm?', '', () => {
          this.$store
            .dispatch('calendar/confirmCalendarShiftRole', {
              role_staff_id: this.role.role_staff_id,
            })
            .then((result) => {
              f7.toast
                .create({
                  text: result.data.message,
                  closeTimeout: 2000,
                  closeButton: true,
                  closeButtonText: 'Close',
                  closeButtonColor: 'blue',
                })
                .open();
            });
        });
      };
      const quizRequred = this.role.quizs
        ? _.filter(this.role.quizs, (q) => Number(q.required) === 1)
        : [];

      if (this.shift.forms_confirm && this.shift.forms_confirm.length > 0) {
        this.$store.commit('calendar/setFormQuizConnectPopup', {
          isPopupOpened: true,
          type: 'FORM',
          action: 'CONFIRM',
        });
      } else if (quizRequred.length > 0) {
        this.$store.commit('calendar/setFormQuizConnectPopup', {
          isPopupOpened: true,
          type: 'QUIZ',
          action: '',
          quiz: quizRequred[0],
          callback: mainAction,
          roleId: this.role.id,
        });
      } else {
        mainAction();
      }
    },
    onReplaceClick() {
      f7.dialog.prompt(
        "Please enter the reason you're requesting a replacement",
        '',
        (reason) => {
          this.$store
            .dispatch('calendar/repalceCalendarShiftRole', {
              role_staff_id: this.role.role_staff_id,
              reason,
            })
            .then((result) => {
              if (result) {
                f7.toast
                  .create({
                    text: result.data.message,
                    closeTimeout: 2000,
                    closeButton: true,
                    closeButtonText: 'Close',
                    closeButtonColor: 'blue',
                  })
                  .open();
              }
            });
        }
      );
    },
    onCancelReplaceClick() {
      f7.dialog.confirm('Really cancel?', '', () => {
        this.$store
          .dispatch('calendar/cancelRepalceCalendarShiftRole', {
            role_staff_id: this.role.role_staff_id,
          })
          .then((result) => {
            f7.toast
              .create({
                text: result.data.message,
                closeTimeout: 2000,
                closeButton: true,
                closeButtonText: 'Close',
                closeButtonColor: 'blue',
              })
              .open();
          });
      });
    },
    onCheckInClick() {
      const mainAction = () => {
        this.$store.commit('calendar/setCalendarCheckInOutPopup', {
          isPopupOpened: true,
          type: 'check_in',
          role_staff_id: this.role.role_staff_id,
        });
      };
      const quizRequred = this.role.quizs
        ? _.filter(this.role.quizs, (q) => Number(q.required) === 1)
        : [];
      if (quizRequred.length > 0) {
        this.$store.commit('calendar/setFormQuizConnectPopup', {
          isPopupOpened: true,
          type: 'QUIZ',
          action: '',
          quiz: quizRequred[0],
          callback: mainAction,
          roleId: this.role.id,
        });
      } else {
        mainAction();
      }
    },
    onCheckOutClick() {
      this.$store.commit('calendar/setCalendarCheckInOutPopup', {
        isPopupOpened: true,
        type: 'check_out',
        role_staff_id: this.role.role_staff_id,
      });
    },
    onEndBreakClick() {
      this.$store.commit('calendar/setCalendarCheckInOutPopup', {
        isPopupOpened: true,
        type: 'end_break',
        role_staff_id: this.role.role_staff_id,
      });
    },
    onStartBreakClick() {
      this.$store.commit('calendar/setCalendarCheckInOutPopup', {
        isPopupOpened: true,
        type: 'start_break',
        role_staff_id: this.role.role_staff_id,
      });
    },
    onCompleteClick() {
      const mainAction = () => {
        const required = {
          uploads:
            this.role.num_required && this.role.num_required > 0
              ? {
                  required: this.role.uploads_required > 0,
                  numbers: `${this.role.num_uploaded || 0} / ${
                    this.role.uploads_required
                  }`,
                  completed:
                    this.role.num_uploaded >= this.role.uploads_required,
                }
              : undefined,
          reports:
            Array.isArray(this.role.surveys) && this.role.surveys.length > 0
              ? {
                  required: this.role.surveys.length > 0,
                  numbers: `${
                    _.filter(this.role.surveys, (s) => s.completed_id !== null)
                      .length
                  } / ${this.role.surveys.length}`,
                  completed:
                    _.filter(this.role.surveys, (s) => s.completed_id !== null)
                      .length >= this.role.surveys.length,
                }
              : undefined,
        };
        this.$store.commit('calendar/setCalendarCompletePopup', {
          isPopupOpened: true,
          roleStaffId: this.role.role_staff_id,
          roleName: this.role.rname,
          completionNotes: this.role.completion_notes,
          start: this.role.start,
          end: this.role.end,
          timesLocked: this.role.times_locked,
          required,
        });
      };

      const surveysNotCompleted = _.filter(
        this.role.surveys,
        (s) => s.completed_id === null
      );
      if (surveysNotCompleted && surveysNotCompleted.length > 0) {
        this.$store.commit('calendar/setFormQuizConnectPopup', {
          isPopupOpened: true,
          type: 'SURVEY',
          action: 'COMPLETE',
          quiz: surveysNotCompleted[0],
          callback: mainAction,
          roleId: this.role.id,
        });
      } else {
        mainAction();
      }
    },
    onSubMenuClick() {
      const buttons = [
        {
          id: 'apply',
          text: 'Apply',
          color: 'green',
          onClick: this.onApplyClick,
        },
        {
          id: 'cancel_application',
          text: 'Cancel Application',
          color: 'red',
          onClick: this.onCancelApplicationClick,
        },
        {
          id: 'not_available',
          text: 'Not Available',
          color: 'red',
          onClick: this.onNotAvailableClick,
        },
        {
          id: 'confirm',
          text: 'Confirm',
          color: 'green',
          onClick: this.onConfirmClick,
        },
        {
          id: 'replace',
          text: 'Replace',
          color: 'red',
          onClick: this.onReplaceClick,
        },
        {
          id: 'cancel_replace',
          text: 'Cancel Replace',
          color: 'blue',
          onClick: this.onCancelReplaceClick,
        },
        {
          id: 'cancel_replace_standby',
          text: 'Cancel Repl. StandBy',
          color: 'blue',
          onClick: this.onCancelReplaceStandByClick,
        },
        {
          id: 'check_in',
          text: 'Check In',
          color: 'green',
          onClick: this.onCheckInClick,
        },
        {
          id: 'check_out',
          text: 'Check Out',
          color: 'red',
          onClick: this.onCheckOutClick,
        },
        {
          id: 'end_break',
          text: 'End Break',
          color: 'green',
          onClick: this.onEndBreakClick,
        },
        {
          id: 'start_break',
          text: 'Start Break',
          color: 'orange',
          onClick: this.onStartBreakClick,
        },
        {
          id: 'complete',
          text: 'Complete',
          color: 'green',
          onClick: this.onCompleteClick,
        },
      ];
      const resActionButtons = this.roleSecondariesActions.map((element) =>
        _.find(buttons, { id: element })
      );
      const actinSheet = f7.actions.create({
        convertToPopover: true,
        buttons: resActionButtons,
        targetEl: `.shift-role-sub-actions-button${this.role.id}`,
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
      });
      actinSheet.open();
    },
  },
};
</script>

import axios from '../../libs/axios';
import config from '../../config';

export default {
  setAuthorizationHeader(payload) {
    axios.defaults.headers.common.Authorization = `${payload.token_type} ${payload.access_token}`;
  },

  setAccountToLocalStorage(data) {
    if (data) {
      localStorage.setItem('sc_settings.account', JSON.stringify(data));
    } else {
      localStorage.removeItem('sc_settings.account');
    }
  },

  getAccountFromLocalStorage() {
    let result = null;
    if (localStorage.getItem('sc_settings.account')) {
      result = JSON.parse(localStorage.getItem('sc_settings.account'));
    }
    return result;
  },

  refreshTokensInLocalStorage(payload) {
    if (localStorage.getItem('sc_settings.account')) {
      const account = JSON.parse(localStorage.getItem('sc_settings.account'));
      account.token_type = payload.token_type;
      account.access_token = payload.access_token;
      account.refresh_token = payload.refresh_token;
      account.expires_in = payload.expires_in;
      localStorage.setItem('sc_settings.account', JSON.stringify(account));
    }
  },

  setFormsRequiredToLocalStorage(data) {
    if (data) {
      localStorage.setItem('sc_settings.forms_required', JSON.stringify(data));
    } else {
      localStorage.removeItem('sc_settings.forms_required');
    }
  },

  getFormsRequiredFromLocalStorage() {
    let result = null;
    if (localStorage.getItem('sc_settings.forms_required')) {
      result = JSON.parse(localStorage.getItem('sc_settings.forms_required'));
    }
    return result;
  },

  setFirebaseDeviceToLocalStorage(device) {
    if (device) {
      localStorage.setItem('sc_settings.firebase_device', device.toString());
    } else {
      localStorage.removeItem('sc_settings.firebase_device');
    }
  },

  getFirebaseDeviceFromLocalStorage() {
    let result = null;
    if (localStorage.getItem('sc_settings.firebase_device')) {
      result = localStorage.getItem('sc_settings.firebase_device');
    }
    return result;
  },

  fetchIdentify() {
    return axios
      .get(`https://admin.staffconnect-app.com/api/identify/${config.webSocket.tenant}.staffconnect-app.com`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  login(payload) {
    return axios
      .post('/auth/login', {
        username: payload.email,
        password: payload.password,
        client_id: 3,
      })
      .then((response) => {
        this.setAuthorizationHeader({
          token_type: response.data.token_type,
          access_token: response.data.access_token,
        });
        this.setAccountToLocalStorage({
          access_token: response.data.access_token,
          expires_in: response.data.expires_in,
          refresh_token: response.data.refresh_token,
          token_type: response.data.token_type,
          user: response.data.user,
          settings: response.data.settings,
          permissions: response.data.permissions,
          steps: response.data.steps,
        });
        if (response.data.forms_required && response.data.forms_required.length > 0) {
          this.setFormsRequiredToLocalStorage(response.data.forms_required);
        }
        return response;
      })
      .catch((error) => this.apiErrorHandler(error));
  },

  resetPassword(payload) {
    return axios
      .post('/auth/forgottenPassword', {
        email: payload.email,
        client_id: 3,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => this.apiErrorHandler(error));
  },

  getFormConnectToken() {
    return axios
      .get('/token/formconnect')
      .then((response) => {
        return response;
      })
      .catch((error) => this.apiErrorHandler(error));
  },

  getQuizConnectToken() {
    return axios
      .get('/token/quizconnect')
      .then((response) => {
        return response;
      })
      .catch((error) => this.apiErrorHandler(error));
  },

  refreshToken(payload) {
    return axios
      .post('/auth/refresh', {
        refresh_token: payload.refreshToken,
        client_id: 3,
      })
      .then((response) => {
        this.setAuthorizationHeader({
          token_type: response.data.token_type,
          access_token: response.data.access_token,
        });
        this.refreshTokensInLocalStorage({
          token_type: response.data.token_type,
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
          expires_in: response.data.expires_in,
        });
        return response;
      })
      .catch((error) => this.apiErrorHandler(error));
  },

  addFirebaseDevice(firebaseToken) {
    return axios
      .post('/user/device', {
        firebase_token: firebaseToken,
        client_id: 3,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  updateFirebaseDevice(payload) {
    return axios
      .post('/user/device', {
        firebase_token: payload.firebaseToken,
        device_id: payload.firebaseDevice,
        client_id: 3,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteFirebaseDevice(firebaseDevice) {
    return axios
      .delete(`/user/device/${firebaseDevice}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  logout() {
    return axios
      .post('/auth/logout')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  registerNewUser(payload) {
    return axios
      .post('/auth/register', {
        fname: payload.firstName,
        lname: payload.lastName,
        email: payload.email,
        password: payload.password,
        mob: payload.mobileNumber,
        client_id: 3,
      })
      .then((response) => {
        this.setAuthorizationHeader({
          token_type: response.data.token_type,
          access_token: response.data.access_token,
        });
        this.setAccountToLocalStorage({
          access_token: response.data.access_token,
          expires_in: response.data.expires_in,
          refresh_token: response.data.refresh_token,
          token_type: response.data.token_type,
          user: response.data.user,
          settings: response.data.settings,
          permissions: response.data.permissions,
          steps: response.data.steps,
        });
        if (response.data.forms_required && response.data.forms_required.length > 0) {
          this.setFormsRequiredToLocalStorage(response.data.forms_required);
        }
        return response;
      })
      .catch((error) => this.apiErrorHandler(error));
  },

  registerNextStep(payload) {
    return axios
      .post('/register', {
        step: payload.step,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },
};

import config from '../config';

export default class Socket {
  constructor({ onMessage, senderId }) {
    this.conn = undefined;
    this.onMessage = onMessage;
    this.senderId = senderId;
    this.isReconnectable = true;
    this.isActive = true;
    this.disconnectedTime = 0;
    this.openConnection();
  }

  openConnection() {
    this.conn = new WebSocket(config.webSocket.socketServerUrl);
    this.isReconnectable = true;
    this.setOnOpen();
    this.setOnClose();
    this.setOnMessage();
    this.setOnError();
    this.sendData(JSON.stringify({ type: 'init', payload: this.senderId }));
  }

  closeConnection() {
    this.conn.close();
    this.isReconnectable = false;
    this.isActive = false;
  }

  setOnOpen() {
    this.conn.onopen = () => {
      console.log('WebSocket Connection is open');
      console.log(this.conn);
    };
  }

  setOnClose() {
    this.conn.onclose = () => {
      console.log('WebSocket Connection is closed');
      if (this.isReconnectable && this.isActive) {
        this.openConnection();
      }
    };
  }

  setOnMessage() {
    this.conn.onmessage = (event) => {
      console.dir(event);
      this.onMessage(event.data);
    };
  }

  setOnError() {
    this.conn.onerror = (err) => {
      console.dir(err);
      this.isReconnectable = false;
      setTimeout(() => {
        console.log('settimeout');
        if (this.isActive && this.conn.readyState !== WebSocket.OPEN) {
          this.openConnection();
        }
      }, 10000);
    };
  }

  sendData(data) {
    const dataMessage = JSON.stringify({ tenant: config.webSocket.tenant, ...JSON.parse(data) });

    if (this.conn.readyState === WebSocket.OPEN) {
      this.conn.send(dataMessage);
    } else {
      const interval = setInterval(() => {
        if (this.conn.readyState === WebSocket.OPEN) {
          clearInterval(interval);
          this.conn.send(dataMessage);
        } else {
          this.disconnectedTime += 1;
          if (this.disconnectedTime > 10) {
            clearInterval(interval);
          }
        }
      }, 1000);
    }
  }
}

import { createStore } from 'vuex';

import _ from 'lodash';
import api from '../api';
import account from './modules/account.store';
import posts from './modules/posts.store';
import unavailability from './modules/unavailability.store';
import profile from './modules/profile.store';
import users from './modules/users.store';
import chats from './modules/chats.store';
import calendar from './modules/calendar.store';
import payrolls from './modules/payrolls.store';

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    account,
    posts,
    unavailability,
    profile,
    users,
    chats,
    calendar,
    payrolls,
  },
  state: {
    showError: false,
    errorMessage: '',
    errorAfterGoHome: false,
    isLoadingData: false,
  },
  mutations: {
    setError(state, payload) {
      state.showError = payload.showError || false;
      state.errorMessage = payload.errorMessage || '';
      state.errorAfterGoHome = payload.errorAfterGoHome || false;
    },
    setIsLoadingData(state, isLoadingData = false) {
      state.isLoadingData = isLoadingData;
    },
  },
  actions: {
    errorHandler({ state, commit }, error) {
      console.error(error);
      commit('setIsLoadingData', false, { root: true });
      if (error.response && error.response.status === 401) {
        // Unauthorized
        if (_.has(state.account.user, 'email')) {
          commit('account/setLogin', state.account.user.email);
        }
        commit('account/setAccount', { isLoggedIn: false });
        api.account.setAccountToLocalStorage();
        api.account.setFormsRequiredToLocalStorage();
        commit('setError', {
          showError: true,
          errorAfterGoHome:
            error.response.config.url !== '/auth/login' ? true : false,
          errorMessage: error.response
            ? error.response.data.message || error.response.data.error
            : error.message,
        });

        // if (state.f7app.$('.dialog.modal-in').length > 0) { // some popup opened
        //   state.f7app.dialog.close();
        // } else if (state.f7app.$('.popup.modal-in').length > 0) {
        //   state.f7app.popup.close();
        // }
      } else {
        let msg = '';
        if (error.response && !_.isEmpty(error.response.data.errors)) {
          const { errors } = error.response.data;
          Object.keys(errors).forEach((key) => {
            msg = `${msg} ${errors[key]} <br/>`;
          });
        } else {
          msg = error.response
            ? error.response.data.message || error.response.data.error
            : error.message;
        }
        commit('setError', {
          showError: true,
          errorMessage: msg,
        });
      }
      return false;
    },
  },
});

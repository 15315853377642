import axios from '../../libs/axios';

export default {
  fetchCalendarJobBoardShifts(payload) {
    return axios
      .get(`/shifts/jobboard`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getCalendarShifts(payload) {
    return axios
      .get(`/shifts/${payload.begin_date}/${payload.end_date}/calendar`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getCalendarUserShifts() {
    return axios
      .get('/user/shifts/upcoming')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  getCalendarShift(payload) {
    return axios
      .get(`/shift/${payload.shift_id}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  applyCalendarShiftRole(payload) {
    return axios
      .post(`/role/${payload.roleId}/apply`, {
        reason: payload.reason,
        shift_role_ids: payload.shiftRoleIds,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  cancelApplicationCalendarShiftRole(payload) {
    return axios
      .post(`/role/applyCancel/${payload.role_staff_id}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  notAvailableCalendarShiftRole(payload) {
    return axios
      .post(`/role/${payload.role_id}/notAvailable`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  repalceCalendarShiftRole(payload) {
    return axios
      .post(`/role/replace/${payload.role_staff_id}`, {
        reason: payload.reason,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  cancelRepalceCalendarShiftRole(payload) {
    return axios
      .post(`/role/replaceCancel/${payload.role_staff_id}`, {})
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  confirmCalendarShiftRole(payload) {
    return axios
      .post(`/role/confirm/${payload.role_staff_id}`, {})
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  checkInCalendarShiftRole(payload) {
    const data = new FormData();
    if (payload.image_blob) {
      data.append('photo', payload.image_blob, payload.image_blob.name);
    }
    data.append('lat', payload.latitude);
    data.append('lon', payload.longitude);
    data.append('note', payload.note);
    return axios
      .post(`/role/checkIn/${payload.role_staff_id}`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  checkOutCalendarShiftRole(payload) {
    const data = new FormData();
    if (payload.image_blob) {
      data.append('photo', payload.image_blob, payload.image_blob.name);
    }
    data.append('lat', payload.latitude);
    data.append('lon', payload.longitude);
    data.append('note', payload.note);
    return axios
      .post(`/role/checkOut/${payload.role_staff_id}`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  endBreakCalendarShiftRole(payload) {
    const data = new FormData();
    if (payload.image_blob) {
      data.append('photo', payload.image_blob, payload.image_blob.name);
    }
    data.append('lat', payload.latitude);
    data.append('lon', payload.longitude);
    data.append('note', payload.note);
    return axios
      .post(`/role/checkIn/${payload.role_staff_id}/1`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  startBreakCalendarShiftRole(payload) {
    const data = new FormData();
    if (payload.image_blob) {
      data.append('photo', payload.image_blob, payload.image_blob.name);
    }
    data.append('lat', payload.latitude);
    data.append('lon', payload.longitude);
    data.append('note', payload.note);
    return axios
      .post(`/role/checkOut/${payload.role_staff_id}/1`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  completeCalendarShiftRole(payload) {
    const data = new FormData();
    data.append('start', payload.start);
    data.append('end', payload.end);
    return axios
      .post(`/role/complete/${payload.roleStaffId}`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  uploadFileCalendarShiftRole(payload) {
    const data = new FormData();
    if (payload.files.length > 0) {
      payload.files.forEach((file) => {
        data.append('files[]', file, file.name);
      });
    }
    return axios
      .post(`/role/upload/${payload.roleStaffId}`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  newExpenseCalendarShiftRole(payload) {
    const data = new FormData();
    if (payload.file) {
      data.append('receipt', payload.file, payload.file.name);
    }
    if (payload.itemCategory) {
      data.append('expense_cat_id', payload.itemCategory);
    }
    data.append('item_name', payload.itemName);
    data.append('item_type', payload.itemType);
    data.append('units', payload.unitsNumber);
    data.append('unit_rate', payload.unitsRate);
    data.append('role_staff_id', payload.roleStaffId);
    return axios
      .post('/payItem', data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  editExpenseCalendarShiftRole(payload) {
    const data = new FormData();
    if (payload.file) {
      data.append('receipt', payload.file, payload.file.name);
    }
    if (payload.itemCategory) {
      data.append('expense_cat_id', payload.itemCategory);
    }
    data.append('item_name', payload.itemName);
    data.append('item_type', payload.itemType);
    data.append('units', payload.unitsNumber);
    data.append('unit_rate', payload.unitsRate);
    return axios
      .post(`/payItem/${payload.payItemId}`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteExpenseCalendarShiftRole(payload) {
    return axios
      .delete(`/payItem/${payload.payItemId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftManagers() {
    return axios
      .get('/autocomplete/manager')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftClients() {
    return axios
      .get('/autocomplete/client')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftWorkAreas() {
    return axios
      .get('/autocomplete/workArea')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftChecks(payload) {
    return axios
      .get(`/shift/${payload.shiftId}/checks`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftRoleRequirement(payload) {
    return axios
      .get(`/autocomplete/roleRequirement/${payload.query}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftRoleSurvey(payload) {
    return axios
      .get(`/autocomplete/report/survey/${payload.query}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftRolePayCategory() {
    return axios
      .get('/payLevel/category')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftRoleCurrency() {
    return axios
      .get('/helpers/currencies')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  addCalendarShift(payload) {
    return axios
      .post('/shift', {
        shift_id: payload.shiftId,
        title: payload.title,
        generic_title: payload.genericTitle,
        location: payload.location,
        generic_location: payload.genericLocation,
        address: payload.address,
        contact: payload.venueContact,
        shift_start: payload.shiftStart,
        shift_end: payload.shiftEnd,
        client_id: payload.clientId,
        manager_ids: payload.managerIds,
        work_area_ids: payload.workAreasIds,
        notes: payload.notes,
        grouped: 0,
        isGroup: false,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  editCalendarShift(payload) {
    return axios
      .put(`/shift/${payload.shiftId}`, {
        title: payload.title,
        generic_title: payload.genericTitle,
        location: payload.location,
        generic_location: payload.genericLocation,
        address: payload.address,
        contact: payload.venueContact,
        shift_start: payload.shiftStart,
        shift_end: payload.shiftEnd,
        client_id: payload.clientId,
        manager_ids: payload.managerIds,
        work_area_ids: payload.workAreasIds,
        notes: payload.notes,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  addCalendarNewBooking(payload) {
    return axios
      .post('/booking', {
        title: payload.title,
        location: payload.location,
        address: payload.address,
        contact: payload.venueContact,
        shift_start: payload.shiftStart,
        shift_end: payload.shiftEnd,
        work_area_ids: payload.workAreasIds,
        roles: payload.roles,
        notes: payload.notes,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  updateCalendarShiftLatLng(payload) {
    return axios
      .put(`/shift/${payload.shiftId}`, {
        lat: payload.lat,
        lon: payload.lng,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteCalendarShift(payload) {
    return axios
      .delete(`/shift/${payload.shiftId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  publishCalendarShift(payload) {
    return axios
      .put(`/shift/${payload.shiftId}/publish/${payload.live}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftFiles(payload) {
    return axios
      .get(`/reportsUploads/shift/${payload.shiftId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  addCalendarShiftFile(payload) {
    const data = new FormData();
    data.append('file[]', payload.document_blob, payload.document_blob.name);
    data.append('folder', 'shift');
    data.append('id', payload.shiftId);
    return axios
      .post('/reportsUploads/upload', data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteCalendarShiftFile(payload) {
    return axios
      .delete(`/file/file/${payload.documentId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  approveCalendarShiftFile(payload) {
    const approved = payload.approved ? 1 : 0;
    return axios
      .put(`/reportsUploads/approve/upload/${payload.documentId}/${approved}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteCalendarShiftSurvey(payload) {
    return axios
      .delete(`/report/completed/${payload.surveyId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  approveCalendarShiftSurvey(payload) {
    const approved = payload.approved ? 1 : 0;
    return axios
      .put(`/reportsUploads/approve/report/${payload.surveyId}/${approved}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  addCalendarShiftRole(payload) {
    return axios
      .post(`/shift/${payload.shiftId}/role`, {
        rname: payload.name,
        num_required: payload.numberRequired,
        application_deadline: payload.applicationDeadlineDate,
        notes: payload.notes,
        role_start: payload.roleStartTime,
        role_end: payload.roleEndTime,
        requirements: payload.requirements,
        completion_notes: payload.completionNotes,
        uploads_required: payload.uploadsRequired,
        reports: payload.surveys,
        pay_category_id: payload.payCategoryId,
        pay_currency: payload.payCurrency,
        pay_rate: payload.payRate,
        pay_rate_type: payload.payRateType,
        bill_currency: payload.billCurrency,
        bill_rate: payload.billRate,
        bill_rate_type: payload.billRateType,
        expense_limit: payload.expenseLimit,
        pay_items: payload.payItems,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  editCalendarShiftRole(payload) {
    return axios
      .put(`/shift/role/${payload.shiftRoleId}`, {
        rname: payload.name,
        num_required: payload.numberRequired,
        application_deadline: payload.applicationDeadlineDate,
        notes: payload.notes,
        role_start: payload.roleStartTime,
        role_end: payload.roleEndTime,
        requirements: payload.requirements,
        completion_notes: payload.completionNotes,
        uploads_required: payload.uploadsRequired,
        reports: payload.surveys,
        pay_category_id: payload.payCategoryId,
        pay_currency: payload.payCurrency,
        pay_rate: payload.payRate,
        pay_rate_type: payload.payRateType,
        bill_currency: payload.billCurrency,
        bill_rate: payload.billRate,
        bill_rate_type: payload.billRateType,
        expense_limit: payload.expenseLimit,
        pay_items: payload.payItems,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  deleteCalendarShiftRole(payload) {
    return axios
      .delete(`/shift/role/${payload.shiftRoleId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftRoleStaffByType(payload) {
    return axios
      .get(`/shift/role/${payload.shiftRoleId}/${payload.staffType}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarShiftRoleStaffAdd(payload) {
    return axios
      .get(`/users/${payload.staffOnPage}/${payload.pageId}/["utype:=:staff"]`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  selectCalendarShiftRoleStaff(payload) {
    return axios
      .post(`/role/${payload.shiftRoleId}/assign`, {
        staff_status_id: 'STAFF_STATUS_SELECTED',
        user_ids: payload.selectedStaffIds,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  inviteCalendarShiftRoleStaff(payload) {
    return axios
      .post(`/role/${payload.shiftRoleId}/invite`, {
        user_ids: payload.selectedStaffIds,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  changeStatusCalendarShiftRoleStaff(payload) {
    return axios
      .put(`/role/update/${payload.staffId}`, {
        staff_status_id: payload.staffStatusId,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  changeStatusCalendarShiftStaffSelected(payload) {
    let data = {};
    if (payload.staffStatusId === 'STAFF_STATUS_CHECKED_IN') {
      data = {
        staff_status_id: payload.staffStatusId,
        check_in_time: payload.staffStatusTime,
      };
    } else if (payload.staffStatusId === 'STAFF_STATUS_CHECKED_OUT') {
      data = {
        staff_status_id: payload.staffStatusId,
        check_out_time: payload.staffStatusTime,
      };
    }
    return axios
      .put(`/role/update/${payload.staffId}`, data)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  removeCalendarShiftRoleStaff(payload) {
    return axios
      .put(`/role/remove/${payload.staffId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  fetchCalendarLegend() {
    return axios
      .get('/shiftStatuses')
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },

  addCalendarShiftAdminNote(payload) {
    return axios
      .post(`/shift/${payload.shiftId}/adminNote`, {
        client_visible: payload.visibilityId,
        mentions: payload.mentions,
        note: payload.noteText,
        shift_id: payload.shiftId,
        type_id: payload.noteTypeId,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },
  updateCalendarShiftAdminNote(payload) {
    return axios
      .put(`/shift/adminNote/${payload.adminNoteId}`, {
        client_visible: payload.visibilityId,
        mentions: payload.mentions,
        note: payload.noteText,
        type_id: payload.noteTypeId,
      })
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },
  deleteCalendarShiftAdminNote(payload) {
    return axios
      .delete(`/shift/adminNote/${payload.adminNoteId}`)
      .then((response) => response)
      .catch((error) => this.apiErrorHandler(error));
  },
};

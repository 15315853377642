<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title>Payment</f7-nav-title>
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData && wallets.length > 0" infinite />
      </template>
    </f7-navbar>
    <f7-block v-show="isLoadingData && wallets.length === 0 && banks.length === 0" inset class="text-align-center">
      <f7-preloader />
    </f7-block>

    <template v-if="wallets.length > 0">
      <f7-block-title>WALLETS</f7-block-title>
      <sc-payroll-wallet-card
        v-for="wallet in wallets"
        :key="wallet.id"
        :wallet="wallet"
        @sc-payroll-wallet-card:withdraw="onWalletWithdraw"
      />
    </template>
    <template v-if="!isLoadingData && setUp === true">
      <f7-block-title class="display-flex align-items-center justify-content-space-between">
        <span>BANKS</span>
        <f7-button text="ADD BANK" icon-material="account_balance" small popup-open=".sc-profile-payment-link-bank" />
      </f7-block-title>
      <f7-list media-list no-hairlines>
        <f7-list-item v-for="bank in banks" :key="bank.id" :title="bank.bname + ' ' + bank.currency">
          <template #subtitle>
            <span style="color: gray">{{ bank.status }}</span>
          </template>
          <template #after>
            <f7-link color="gray" icon-material="delete" icon-only @click="onDeleteBankClick(bank.id)" />
          </template>
        </f7-list-item>
      </f7-list>
    </template>
    <template v-if="!isLoadingData && setUp === false">
      <f7-block class="text-align-center">
        <p class="text-color-gray">{{ message }}</p>
      </f7-block>
      <f7-row class="justify-content-center">
        <f7-button fill raised @click="onCreateLinkXTRMAccountClick">Create / Link Payment Account</f7-button>
      </f7-row>
      <f7-block class="display-flex justify-content-space-around">
        <f7-link external target="_system" href="https://xtrm.freshdesk.com/en/support/solutions/articles/158219">
          XTRM Terms & Conditions
        </f7-link>
        <f7-link external target="_system" href="https://xtrm.freshdesk.com/en/support/solutions/articles/128204">
          XTRM Privacy Policy
        </f7-link>
      </f7-block>
    </template>
    <sc-profile-payment-link-bank-popup />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import scPayrollWalletCard from '../Payrolls/Wallets/PayrollWalletCard.vue';
import scProfilePaymentLinkBankPopup from './Payment/ProfilePaymentLinkBankPopup.vue';

export default {
  components: {
    scPayrollWalletCard,
    scProfilePaymentLinkBankPopup,
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    wallets() {
      return this.$store.state.profile.settings.payment.wallets;
    },
    banks() {
      return this.$store.state.profile.settings.payment.banks;
    },
    setUp() {
      return this.$store.state.profile.settings.payment.setUp;
    },
    message() {
      return this.$store.state.profile.settings.payment.message;
    },
  },
  mounted() {
    this.$store.dispatch('profile/fetchSettingsPaymentSetup', {
      userId: this.userId,
    });
  },
  methods: {
    onWalletWithdraw() {
      this.$store.dispatch('profile/fetchWallets', { userId: this.userId });
    },
    onCreateLinkXTRMAccountClick() {
      this.$store.dispatch('profile/createLinkXTRMAccountSettingsPayment', {
        userId: this.userId,
      });
    },
    onDeleteBankClick(bankId) {
      f7.dialog.confirm('Are you sure?', '', () => {
        this.$store.dispatch('profile/deleteBankAccountSettingsPayment', {
          userId: this.userId,
          bankId: bankId,
        });
      });
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-page no-navbar>
    <div
      style="
        width: 100%;
        height: 100%;
        overflow: auto !important;
        -webkit-overflow-scrolling: touch !important;
      "
    >
      <iframe :src="iframeSRC" class="fc-iframe">
        <p>Your app does not support iframes.</p>
      </iframe>
    </div>
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import config from '../config';

export default {
  data() {
    const formID = this.$store.state.account.requiredForms[0].id;
    const otherFormId = this.$store.state.account.requiredForms[0].other_id;
    const accessToken = this.$store.state.account.formconnectToken.access_token;
    const iframeSRC = `${config.formConnect.baseURL}/${otherFormId}?access_token=${accessToken}`;

    return {
      formID,
      iframeSRC,
    };
  },
  computed: {},
  mounted() {
    if (window.addEventListener) {
      window.addEventListener('message', this.onMessage);
    }
  },
  beforeUnmount() {
    if (window.removeEventListener) {
      window.removeEventListener('message', this.onMessage);
    }
  },
  methods: {
    onMessage(event) {
      if (event.data.message === 'success') {
        this.$store.dispatch(
          'account/deleteFromRequiredFormsByID',
          this.formID
        );
        f7.views.main.router.refreshPage();
      }
    },
  },
};
</script>
<style scoped>
.fc-iframe {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <f7-page-content>
    <f7-block>
      <p class="text-color-gray">{{ infoMessage }}</p>
    </f7-block>
    <f7-list
      no-hairlines
      class="no-margin-top search-list-work-areas searchbar-found"
    >
      <f7-list-group v-for="category in workAreas" :key="category.id">
        <f7-list-item :title="category.cname" group-title />
        <f7-list-item
          v-for="area in category.work_areas"
          :key="area.id"
          :checked="area.set === 1"
          :title="area.aname"
          checkbox
          @change="onElementChanged(area.id, $event)"
        />
      </f7-list-group>
    </f7-list>
    <f7-row class="margin justify-content-space-between">
      <f7-button @click="registerBackStep"> Back </f7-button>
      <f7-button
        :disabled="isLoadingData"
        raised
        fill
        @click="registerNextStep"
      >
        Save & Continue
      </f7-button>
    </f7-row>
  </f7-page-content>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    infoMessage() {
      return this.$store.state.account.settings.profile_work_areas_msg;
    },
    workAreas() {
      return this.$store.state.profile.work_areas;
    },
    userId() {
      return this.$store.state.account.user.id;
    },
  },
  beforeCreate() {
    const userId = this.$store.state.account.user.id;
    this.$store.dispatch('profile/getProfileWorkAreas', { userId });
  },
  methods: {
    onElementChanged(areaId, event) {
      const set = event.target.checked ? 1 : 0;
      this.$store.dispatch('profile/setProfileWorkAreas', {
        workAreaId: areaId,
        set,
        userId: this.userId,
      });
    },
    registerNextStep() {
      this.$store.dispatch('account/registerNextStep');
    },
    registerBackStep() {
      this.$store.commit('account/setRegStepNum', 6);
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-page>
    <f7-block>
      <f7-block-header>
        <h2 class="text-align-center">{{ shift.long_date }}</h2>
        <h3 v-if="shift.location" class="text-align-center">
          {{ shift.location }}
        </h3>
      </f7-block-header>
      <div
        class="sc-shift__notes"
        style="white-space: pre-line"
        v-html="shift.notes"
      />
    </f7-block>

    <sc-calendar-shift-detail-role-card
      v-for="role in shift.shift_roles"
      :key="role.id"
      :role="role"
      :f7router="f7router"
    />

    <sc-calendar-shift-detail-role-add-edit-popup
      v-if="isUserLvlOwnerOrAdmin"
    />
    <sc-calendar-shift-detail-role-form-quiz-connect-popup :f7route="f7route" />
    <sc-calendar-shift-detail-role-quiz-connect-result-popup />
    <sc-calendar-shift-detail-role-expense-add-edit-popup />
    <sc-calendar-shift-detail-role-complete-popup />
    <sc-shift-role-check-in-out />
    <sc-calendar-shift-detail-role-apply-popup />
  </f7-page>
</template>
<script>
import $ from 'dom7';

import scCalendarShiftDetailRoleCard from './Role/CalendarShiftDetailRoleCard.vue';
import scCalendarShiftDetailRoleAddEditPopup from './Role/CalendarShiftDetailRoleAddEditPopup.vue';
import scCalendarShiftDetailRoleExpenseAddEditPopup from './Role/CalendarShiftDetailRoleExpenseAddEditPopup.vue';
import scCalendarShiftDetailRoleFormQuizConnectPopup from './Role/CalendarShiftDetailRoleFormQuizConnectPopup.vue';
import scCalendarShiftDetailRoleQuizConnectResultPopup from './Role/CalendarShiftDetailRoleQuizConnectResultPopup.vue';

import scShiftRoleCheckInOut from './Role/ShiftRoleCheckInOut.vue';
import scCalendarShiftDetailRoleCompletePopup from './Role/CalendarShiftDetailRoleCompletePopup.vue';

import scCalendarShiftDetailRoleApplyPopup from './Role/CalendarShiftDetailRoleApplyPopup.vue';

export default {
  components: {
    scCalendarShiftDetailRoleCard,
    scCalendarShiftDetailRoleAddEditPopup,
    scCalendarShiftDetailRoleFormQuizConnectPopup,
    scCalendarShiftDetailRoleQuizConnectResultPopup,
    scCalendarShiftDetailRoleExpenseAddEditPopup,
    scCalendarShiftDetailRoleCompletePopup,
    scShiftRoleCheckInOut,
    scCalendarShiftDetailRoleApplyPopup,
  },
  props: {
    f7route: {
      type: Object,
      required: true,
    },
    f7router: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shift() {
      return this.$store.state.calendar.shift;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
  },
  mounted() {
    this.$store
      .dispatch('calendar/getCalendarShift', {
        shift_id: this.f7route.params.shift_id,
      })
      .then(() => {
        $('.sc-shift__notes a').on('click', (e) => {
          this.onExternalLinkClick(e.target.href);
        });
      });
  },
  methods: {
    onExternalLinkClick(link) {
      window.open(link, '_system');
    },
  },
};
</script>

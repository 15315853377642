<template>
  <f7-page>
    <f7-list class="no-margin">
      <f7-list-item
        v-for="staff in roleStaff"
        :key="staff.id"
        :title="staff.name"
      >
        <template #media>
          <img
            :src="staff.ppic_a || 'assets/img/default-user-image.png'"
            :style="`border-color:${staff.staff_status.border_color};`"
          />
          <div
            v-if="staff.user_status"
            :style="`background-color:${staff.user_status.color};`"
            class="user_status"
            @click="onUserStatusClick(staff.user_status.sname)"
          />
        </template>
        <template #after>
          <f7-link
            :class="`shift-role-staff-actions-button${staff.id}`"
            icon-material="more_vert"
            color="gray"
            icon-only
            @click="onActionMenuClick(staff.id)"
          />
        </template>
      </f7-list-item>
    </f7-list>
    <sc-calendar-shift-detail-role-staff-change-status-popup
      :tab-type="tabType"
      :is-opened="staffChangeStatusPopupOpened"
      :f7route="f7route"
      @sc-calendar-shift-detail-role-staff-change-status-popup:closed="
        staffChangeStatusPopupOpened = false
      "
    />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import scCalendarShiftDetailRoleStaffChangeStatusPopup from './CalendarShiftDetailRoleStaffChangeStatusPopup.vue';

export default {
  components: {
    scCalendarShiftDetailRoleStaffChangeStatusPopup,
  },
  props: {
    tabType: {
      // invited, selected , applicants na standby
      type: String,
      required: true,
    },
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      staffChangeStatusPopupOpened: false,
    };
  },
  computed: {
    roleStaff() {
      return this.$store.state.calendar.roleStaff[this.tabType];
    },
    actionMenuStaffId() {
      return this.$store.state.calendar.roleStaff.actionMenuStaffId;
    },
  },
  created() {
    this.$store.dispatch('calendar/fetchCalendarShiftRoleStaffByType', {
      staffType: this.tabType,
      shiftRoleId: this.f7route.params.role_id,
    });
  },
  mounted() {},
  methods: {
    onUserStatusClick(userStatus) {
      f7.toast
        .create({
          text: userStatus,
          closeTimeout: 1000,
          destroyOnClose: true,
          position: 'center',
        })
        .open();
    },
    onChangeStatusClick(staffStatusId) {
      this.$store.dispatch('calendar/changeStatusCalendarShiftRoleStaff', {
        staffId: this.actionMenuStaffId,
        staffStatusId,
        shiftRoleId: this.f7route.params.role_id,
      });
    },
    onRemoveClick() {
      this.$store.dispatch('calendar/removeCalendarShiftRoleStaff', {
        staffId: this.actionMenuStaffId,
        shiftRoleId: this.f7route.params.role_id,
      });
    },
    onActionMenuClick(staffId) {
      this.$store.commit('calendar/setCalendarActionMenuSfattId', staffId);
      const buttons = [];
      if (this.tabType !== 'selected' && this.tabType !== 'standby') {
        buttons.push(
          {
            id: 'selected',
            text: 'Select',
            onClick: () => this.onChangeStatusClick('STAFF_STATUS_SELECTED'),
            icon: '<i class="icon material-icons color-gray">check</i>',
          },
          {
            id: 'standby',
            text: 'Standby',
            onClick: () => this.onChangeStatusClick('STAFF_STATUS_STANDBY'),
            icon:
              '<i class="icon material-icons color-gray">power_settings_new</i>',
          }
        );
      }
      if (this.tabType === 'selected' || this.tabType === 'standby') {
        buttons.push(
          {
            id: 'change_status',
            text: 'Change Status',
            onClick: () => {
              this.staffChangeStatusPopupOpened = true;
            },
            icon: '<i class="icon material-icons color-gray">cached</i>',
          },
          {
            id: 'remove',
            text: 'Remove',
            onClick: () => this.onRemoveClick(),
            icon: '<i class="icon material-icons color-gray">clear</i>',
          }
        );
      }
      const actionSheet = f7.actions.create({
        convertToPopover: true,
        buttons,
        targetEl: `.shift-role-staff-actions-button${staffId}`,
        on: {
          closed: (action) => {
            action.destroy();
          },
        },
      });
      actionSheet.open();
    },
  },
};
</script>
<style scoped>
.item-media img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 3px solid gray;
}

.user_status {
  bottom: 10px;
  left: 45px;
  min-width: 20px;
  position: absolute;
  height: 20px;
  border-radius: 50%;
}
</style>

import _ from 'lodash';
import api from '../../api';

export default {
  namespaced: true,
  state: {
    posts: [],
    pinnedPosts: [],
    notifications: [],
    post: {
      postDetails: {},
      comments: [],
      pageId: 1,
      commentsOnPage: 10,
    },
    postLikesSheetModal: {
      isOpened: false,
      likesUserList: [],
    },

  },
  getters: {
    getPostById: state => id => _.find(state.posts, { id }),
    getNotificationsNum: state => state.notifications.length,
  },
  mutations: {
    setPosts(state, payload) {
      state.posts = payload.posts;
    },
    setPinnedPosts(state, payload) {
      state.pinnedPosts = payload.pinnedPosts;
    },
    setPostDetails(state, payload) {
      state.post.postDetails = payload.postDetails;
    },
    setPostCommentOption(state, payload) {
      const comments = [...state.post.comments];
      const index = _.findIndex(comments, { id: payload.commentId });
      if (Number(comments[index][payload.option]) === 0) {
        comments[index][payload.option] = 1;
        if (payload.option === 'liked') {
          comments[index].likes += 1;
        }
      } else {
        comments[index][payload.option] = 0;
        if (payload.option === 'liked') {
          comments[index].likes -= 1;
        }
      }
      state.post.comments = comments;
    },
    setPostOption(state, payload) {
      const posts = [...state.posts];
      const index = _.findIndex(posts, { id: payload.postId });
      if (Number(posts[index][payload.option]) === 0) {
        posts[index][payload.option] = 1;
        if (payload.option === 'liked') {
          posts[index].likes += 1;
          const comma = posts[index].likes > 1 ? ',' : '';
          posts[index].likes_text = `You${comma} ${posts[index].likes_text}`;
        }
      } else {
        posts[index][payload.option] = 0;
        if (payload.option === 'liked') {
          posts[index].likes -= 1;
          if (posts[index].likes > 0) {
            posts[index].likes_text = posts[index].likes_text.replace('You, ', '');
          } else {
            posts[index].likes_text = '';
          }
        }
      }
      state.posts = posts;

      // if (state.post.postDetails.id === payload.postId) {
      //   const post = { ...state.post.postDetails };
      //   if (Number(post[payload.option]) === 0) {
      //     post[payload.option] = 1;
      //     if (payload.option === 'liked') {
      //       post.likes += 1;
      //       const comma = post.likes > 1 ? ',' : '';
      //       post.likes_text = `You${comma} ${post.likes_text}`;
      //     }
      //   } else {
      //     post[payload.option] = 0;
      //     if (payload.option === 'liked') {
      //       post.likes -= 1;
      //       if (post.likes > 0) {
      //         post.likes_text = post.likes_text.replace('You, ', '');
      //       } else {
      //         post.likes_text = '';
      //       }
      //     }
      //   }
      //   state.post.postDetails = post;
      // }
    },
    setPostComments(state, payload) {
      const union = _.unionBy(payload.comments, state.post.comments, 'id');
      state.post.comments = _.reverse(_.sortBy(union, ['id']));
      state.post.pageId = payload.pageId || state.post.pageId;
    },
    clearPostDetails(state) {
      state.post.comments = [];
      state.post.postDetails = {};
      state.post.pageId = 1;
    },
    deletePostComment(state, payload) {
      state.post.comments = _.reject(state.post.comments, { id: payload.commentId });
    },
    setHomeNotifications(state, payload) {
      state.notifications = payload.notifications;
    },
    setPostLikesSheetModal(state, payload) {
      state.postLikesSheetModal.isOpened = payload.isOpened !== undefined ? payload.isOpened : state.postLikesSheetModal.isOpened;
      state.postLikesSheetModal.likesUserList = payload.likesUserList || [];
    },
  },
  actions: {
    getPosts({ commit, dispatch }, payload) {
      if (!payload || !payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.posts.getPosts()
        .then((result) => {
          commit('setPosts', { posts: result.data });
          commit('setIsLoadingData', false, { root: true });
        }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

    getPinnedPosts({ commit, dispatch }) {
      return api.posts.getPinnedPosts()
        .then((result) => {
          commit('setPinnedPosts', { pinnedPosts: result.data });
        }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

    getHomeNotifications({ commit, dispatch }) {
      return api.posts.getHomeNotifications()
        .then((result) => {
          commit('setHomeNotifications', { notifications: result.data });
        }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

    getPostDetails({ commit, dispatch }, payload) {
      if (!payload || !payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.posts.getPostDetails({
        postId: payload.postId,
      })
        .then((result) => {
          commit('setPostDetails', { postDetails: result.data });
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getPostComments', {
            postId: payload.postId,
            silent: payload.silent,
          });
        }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

    setPostLiked({ state, commit, dispatch }, payload) {
      const { liked } = _.find(state.posts, { id: payload.postId });
      const data = { ...payload, option: 'liked' };
      // payload.option = 'liked';
      commit('setPostOption', data);
      return api.posts.setPostLiked({
        postId: payload.postId,
        liked: Number(liked) === 0 ? 1 : 0,
      }).catch((error) => {
        commit('setPostOption', payload);
        return dispatch('errorHandler', error, { root: true });
      });
    },

    setPostPinned({ state, commit, dispatch }, payload) {
      const { pinned } = _.find(state.posts, { id: payload.postId });
      payload.option = 'pinned';
      commit('setPostOption', payload);
      return api.posts.setPostPinned({
        postId: payload.postId,
        title: payload.title,
        pinned: Number(pinned) === 0 ? 1 : 0,
      }).then(() => {
        return dispatch('getPinnedPosts');
      }).catch((error) => {
        commit('setPostOption', payload);
        return dispatch('errorHandler', error, { root: true });
      });
    },

    setPostApproved({ state, commit, dispatch }, payload) {
      const { approved } = _.find(state.posts, { id: payload.postId });
      payload.option = 'approved';
      commit('setPostOption', payload);
      return api.posts.setPostApproved({
        postId: payload.postId,
        approved: Number(approved) === 0 ? 1 : 0,
      }).catch((error) => {
        commit('setPostOption', payload);
        return dispatch('errorHandler', error, { root: true });
      });
    },

    deletePost({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.posts.deletePost({
        postId: payload.postId,
      }).then(() => {
        commit('setIsLoadingData', false, { root: true });
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },


    setPostCommentLiked({ state, commit, dispatch }, payload) {
      const { liked } = _.find(state.post.comments, { id: payload.commentId });
      payload.option = 'liked';
      commit('setPostCommentOption', payload);
      return api.posts.setPostCommentLiked({
        commentId: payload.commentId,
        liked: Number(liked) === 0 ? 1 : 0,
      }).catch((error) => {
        commit('setPostCommentOption', payload);
        return dispatch('errorHandler', error, { root: true });
      });
    },

    setPostCommentApproved({ state, commit, dispatch }, payload) {
      const { approved } = _.find(state.post.comments, { id: payload.commentId });
      payload.option = 'approved';
      commit('setPostCommentOption', payload);
      return api.posts.setPostCommentApproved({
        commentId: payload.commentId,
        approved: Number(approved) === 0 ? 1 : 0,
      }).catch((error) => {
        commit('setPostCommentOption', payload);
        return dispatch('errorHandler', error, { root: true });
      });
    },

    editPostComment({ state, dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.posts.editPostComment({
        commentId: payload.commentId,
        commentContent: payload.commentContent,
      }).then(() => {
        commit('setIsLoadingData', false, { root: true });
        return dispatch('getPostComments', { postId: state.post.postDetails.id });
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

    deletePostComment({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.posts.deletePostComment({
        commentId: payload.commentId,
      }).then(() => {
        commit('deletePostComment', payload);
        commit('setIsLoadingData', false, { root: true });
        return dispatch('getPostDetails', { postId: state.post.postDetails.id, silent: true });
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },


    getPostComments({ state, commit, dispatch }, payload) {
      if (!payload || !payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.posts.getPostComments({
        postId: payload.postId,
        pageId: !payload.prev ? 0 : state.post.pageId,
        commentsOnPage: state.post.commentsOnPage,
      }).then((result) => {
        if (result.data.length > 0) {
          if (!payload.prev) {
            commit('setPostComments', { comments: result.data });
          } else {
            const pageId = state.post.pageId + 1;
            commit('setPostComments', { comments: result.data, pageId });
          }
        }
        commit('setIsLoadingData', false, { root: true });
        return result;
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

    addNewPost({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.posts.addNewPost({
        content: payload.content,
        file: payload.file,
      }).then(() => {
        commit('setIsLoadingData', false, { root: true });
        return dispatch('getPosts', { silent: true });
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

    editPost({ state, dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.posts.editPost({
        postId: payload.postId,
        content: payload.content,
        file: payload.file,
        deleteFile: payload.deleteFile,
      }).then(() => {
        if (state.post.postDetails.id === payload.postId) {
          dispatch('getPostDetails', { postId: payload.postId, silent: true });
        }
        dispatch('getPosts', { silent: true });
        commit('setIsLoadingData', false, { root: true });
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },


    sendPostComment({ dispatch }, payload) {
      return api.posts.sendPostComment({
        content: payload.content,
        postId: payload.postId,
      }).then(() => dispatch('getPostDetails', { postId: payload.postId, silent: true }))
        .catch(error => dispatch('errorHandler', error, { root: true }));
    },

    getPostLikesUserList({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      commit('setPostLikesSheetModal', { isOpened: true });
      return api.posts.getPostLikesUserList({
        postId: payload.postId,
      })
        .then((result) => {
          commit('setPostLikesSheetModal', { likesUserList: result.data });
          commit('setIsLoadingData', false, { root: true });
        }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

  },
};

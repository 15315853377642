<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon-material="arrow_back" icon-only />
      </f7-nav-left>
      <f7-nav-title>Wallets</f7-nav-title>
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData && wallets.length > 0" infinite />
      </template>
    </f7-navbar>
    <f7-block
      v-show="isLoadingData && wallets.length === 0"
      inset
      class="text-align-center"
    >
      <f7-preloader />
    </f7-block>

    <template v-if="wallets.length > 0">
      <sc-payroll-wallet-card
        v-for="wallet in wallets"
        :key="wallet.id"
        :wallet="wallet"
        @sc-payroll-wallet-card:withdraw="onWalletWithdraw"
      />
    </template>
    <f7-block v-else-if="!isLoadingData" class="text-align-center">
      <p class="text-color-gray">Wallets not found</p>
    </f7-block>
  </f7-page>
</template>

<script>
import scPayrollWalletCard from './Wallets/PayrollWalletCard.vue';

export default {
  components: {
    scPayrollWalletCard,
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    userId() {
      return this.$store.state.account.user.id;
    },
    wallets() {
      return this.$store.state.payrolls.wallets;
    },
  },
  mounted() {
    this.$store.dispatch('payrolls/fetchWallets', { userId: this.userId });
  },
  methods: {
    onWalletWithdraw() {
      this.$store.dispatch('payrolls/fetchWallets', { userId: this.userId });
    },
  },
};
</script>
<style scoped></style>

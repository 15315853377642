<template>
  <f7-block class="no-margin no-padding">
    <f7-list no-hairlines class="no-margin">
      <f7-list-group media-list>
        <f7-list-item title="Safe In-Store Pass" group-title />
        <f7-list-item
          v-if="sisp.sisp_number"
          :title="'Number: ' + sisp.sisp_number"
          :subtitle="'Expiry: ' + sisp.sisp_expiry"
          :text="'Type ' + sisp.sisp_type"
        >
          <template #media>
            <img :src="sisp.sisp_image" style="height: 45px" />
          </template>
          <template #after>
            <f7-button icon-material="edit" round color="gray" sheet-open=".sc-profile-sisp-add-edit-sheet" />
          </template>
        </f7-list-item>
        <f7-list-item v-else>
          <template #footer>
            <f7-button
              v-show="!isLoadingData"
              icon-material="add"
              sheet-open=".sc-profile-sisp-add-edit-sheet"
              text="Add"
            />
            <f7-block v-show="isLoadingData" inset class="text-align-center no-margin">
              <f7-preloader />
            </f7-block>
          </template>
        </f7-list-item>
      </f7-list-group>
    </f7-list>
    <sc-sisp-add-edit-sheet :user-id="userId" :is-sign-up="isSignUp" />
  </f7-block>
</template>
<script>
import scSispAddEditSheet from './SispAddEditSheet.vue';

export default {
  components: {
    scSispAddEditSheet,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    isSignUp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    skills() {
      return this.$store.state.profile.skills;
    },
    sisp() {
      return this.$store.state.profile.sisp;
    },
  },
  beforeCreate() {
    this.$store.dispatch('profile/getSafeInStorePass', { userId: this.userId });
  },
};
</script>

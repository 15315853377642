<template>
  <f7-popover
    class="popover-unavailability"
    @popover:closed="$store.commit('unavailability/setUnavailability', {})"
  >
    <div v-if="unavailability.id">
      <f7-list no-hairlines media-list>
        <f7-list-item
          :title="unavailability.title"
          :subtitle="unavailability.dates"
          :text="unavailability.recurring"
        >
          <template #after-title>
            <f7-link
              icon-material="delete"
              icon-only
              color="gray"
              @click="onDeleteClick(unavailability.id)"
            />
          </template>
        </f7-list-item>
      </f7-list>
    </div>
    <div v-else class="sc-unavailability-preloader">
      <f7-preloader size="40px" />
    </div>
  </f7-popover>
</template>
<script>
import { f7 } from 'framework7-vue';

export default {
  computed: {
    unavailability() {
      return this.$store.state.unavailability.unavailability;
    },
  },
  methods: {
    onDeleteClick(unavailabilityId) {
      f7.popover.close();
      const userId = this.$store.state.account.user.id;
      this.$store
        .dispatch('unavailability/deleteUnavailability', {
          unavailabilityId,
          userId,
        })
        .then(() => {
          this.$store.dispatch('calendar/getCalendarShifts');
        });
    },
  },
};
</script>
<style scoped>
.sc-unavailability-preloader {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

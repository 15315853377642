<template>
  <f7-app v-bind="f7params">
    <f7-panel left cover>
      <f7-view url="/panel-left/" links-view=".view-main" />
    </f7-panel>
    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/" />
  </f7-app>
</template>
<script>
import { onMounted } from 'vue';
import { f7, f7ready } from 'framework7-vue';
import { getDevice } from 'framework7/lite-bundle';
import cordovaApp from '../cordova-app.js';

import routes from '../routes.js';
import { useStore } from 'vuex';

export default {
  setup() {
    const device = getDevice();
    // Framework7 Parameters
    const f7params = {
      name: 'StaffConnect', // App name
      theme: 'md', // Automatic theme detection

      id: 'net.staffconnect.mobile.demo', // App bundle ID
      // App routes
      routes: routes,
      store: useStore(),
      touch: {
        tapHold: true, // enable tap hold events
      },
      // Input settings
      input: {
        scrollIntoViewOnFocus: device.cordova && !device.electron,
        scrollIntoViewCentered: device.cordova && !device.electron,
      },
      // Cordova Statusbar settings
      statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
        androidBackgroundColor: '#2f4052',
        androidTextColor: 'white',
      },
    };

    onMounted(() => {
      f7ready(() => {
        // Init cordova APIs (see cordova-app.js)
        if (device.cordova) {
          cordovaApp.init(f7);
        }

        // Call F7 APIs here
      });
    });

    return {
      f7params,
    };
  },
  computed: {
    showError() {
      return this.$store.state.showError;
    },
    errorMessage() {
      return this.$store.state.errorMessage;
    },
    errorAfterGoHome() {
      return this.$store.state.errorAfterGoHome;
    },
  },
  watch: {
    showError(showError) {
      this.showErrorToast(showError);
    },
  },
  mounted() {
    this.showErrorToast(this.showError);
    // console.dir(this.$store);
  },
  methods: {
    showErrorToast(showError) {
      if (showError) {
        f7.toast.show({
          text: this.errorMessage,
          closeTimeout: 5000,
          closeButton: true,
          closeButtonText: 'Close',
          closeButtonColor: 'red',
          destroyOnClose: true,
          on: {
            closed: () => {
              if (this.errorAfterGoHome) {
                f7.views.main.router.navigate('/', {
                  reloadAll: true,
                  reloadCurrent: true,
                  ignoreCache: true,
                });
              }
              this.$store.commit('setError', { showError: false });
            },
          },
        });
      }
    },
  },
};
</script>

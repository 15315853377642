<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:closed="onPopupClosed">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close icon-material="close" icon-only />
        </f7-nav-left>
        <f7-nav-title :title="quizName" />
      </f7-navbar>
      <h1 class="text-align-center">Your score: {{ score }}%</h1>
      <f7-block v-if="incorrect.length > 0">
        <h3>Your incorrect answers:</h3>
        <div v-for="(answer, index) in incorrect" :key="index">
          <f7-block-header>{{ index + 1 }}. {{ answer.title }}</f7-block-header>
          <ul>
            <li class="text-color-red">{{ answer.userAnswer }}</li>
            <li class="text-color-green">{{ answer.correctAnswer }}</li>
          </ul>
        </div>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from 'lodash';

export default {
  computed: {
    isPopupOpened() {
      return this.$store.state.calendar.quizConnectResultPopup.isPopupOpened;
    },
    quizName() {
      return this.$store.state.calendar.quizConnectResultPopup.quizName;
    },
    score() {
      return this.$store.state.calendar.quizConnectResultPopup.score;
    },
    incorrect() {
      return this.$store.state.calendar.quizConnectResultPopup.incorrect;
    },
  },
  methods: {
    onPopupClosed() {
      const { callback } = this.$store.state.calendar.formQuizConnectPopup;
      if (callback !== undefined) {
        const { roleId } = this.$store.state.calendar.formQuizConnectPopup;
        const role = _.find(this.$store.state.calendar.shift.shift_roles, { id: roleId });
        const quizRequred = role.quizs ? _.filter(role.quizs, (q) => Number(q.required) === 1) : [];
        if (quizRequred.length === 0) {
          callback();
        }
      }
      this.$store.commit('calendar/setQuizConnectResultPopup', {
        isPopupOpened: false,
      });
    },
  },
};
</script>
<style scoped>
.fc-iframe {
  width: 99%;
  height: 99%;
  border: 1px solid lightgray;
}
</style>

<template>
  <f7-page>
    <f7-list no-hairlines class="no-margin search-list-check-in-out searchbar-found">
      <f7-list-group v-for="shiftRole in shift.shift_roles" :key="shiftRole.id" media-list>
        <f7-list-item :title="shiftRole.rname" group-title />
        <f7-list-item
          v-for="staff in shiftRole.role_staff"
          :key="staff.id"
          media-item
          :subtitle="staff.staff_status.status"
          @click="onItemClick(staff)"
        >
          <template #media>
            <img
              :src="staff.ppic_a || 'assets/img/default-user-image.png'"
              :style="`border-color:${staff.staff_status.border_color};`"
            />
          </template>
          <template #title>
            {{ staff.fname }} {{ staff.lname }}
            <f7-icon v-if="staff.team_leader === '1'" material="flag" color="red" />
          </template>
          <template #footer>
            <div class="display-flex">
              <div class="margin-right">
                <f7-icon material="access_time" color="gray" size="12px" />
                {{ staff.start ? staff.start : '' }}{{ staff.end ? ' - ' + staff.end : '' }}
              </div>
              <div>
                <f7-icon v-show="staff.check_in" material="done_all" color="gray" size="12px" />
                {{ staff.check_in ? staff.check_in : '' }}{{ staff.check_out ? ' - ' + staff.check_out : '' }}
              </div>
            </div>
          </template>
          <template #after>
            <f7-link
              v-if="staff.mob"
              color="gray"
              icon-material="call"
              icon-only
              @click.stop="onMobileClick(staff.mob)"
            />
          </template>
        </f7-list-item>
      </f7-list-group>
    </f7-list>
    <f7-actions id="sc-check-in-out-action" :opened="actionOpened" @actions:closed="actionOpened = false">
      <f7-actions-group>
        <f7-actions-button color="green" @click="onCheckInOutClick('CHECKIN')"> Check In </f7-actions-button>
        <f7-actions-button color="orange" @click="onCheckInOutClick('CHECKOUT')"> Check Out </f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red"> Cancel </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </f7-page>
</template>
<script>
import { f7 } from 'framework7-vue';

import moment from 'moment';

export default {
  props: {
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shiftId: this.f7route.params.shift_id,
      actionOpened: false,
      selectedStaff: undefined,
    };
  },
  computed: {
    shift() {
      return this.$store.state.calendar.shift;
    },
  },
  beforeCreate() {},
  mounted() {
    f7.searchbar.create({
      el: '.searchbar-check-in-out',
      backdrop: false,
      searchContainer: '.search-list-check-in-out',
      searchIn: '.item-title',
    });
  },
  methods: {
    onItemClick(staff) {
      this.selectedStaff = staff;
      this.actionOpened = true;
    },
    onMobileClick(mob) {
      const mobile = mob.replace(/[^+\d]+/g, '');
      window.open(`tel:${mobile}`, '_system');
    },
    onCheckInOutClick(type) {
      const staff = this.selectedStaff;
      if (type === 'CHECKIN') {
        f7.dialog.prompt(
          '',
          'Set Check In',
          (statusTime) => {
            this.$store.dispatch('calendar/changeStatusCalendarShiftStaffSelected', {
              staffId: staff.id,
              staffStatusId: 'STAFF_STATUS_CHECKED_IN',
              staffStatusTime: statusTime,
              shiftId: this.shiftId,
            });
          },
          undefined,
          moment().format('hh:mm A')
        );
      } else {
        f7.dialog.prompt(
          '',
          'Set Check Out',
          (statusTime) => {
            this.$store.dispatch('calendar/changeStatusCalendarShiftStaffSelected', {
              staffId: staff.id,
              staffStatusId: 'STAFF_STATUS_CHECKED_OUT',
              staffStatusTime: statusTime,
              shiftId: this.shiftId,
            });
          },
          undefined,
          moment().format('hh:mm A')
        );
      }
    },
  },
};
</script>
<style scoped>
.item-media img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: 3px solid gray;
}
</style>

<template>
  <div style="margin-top: 8px;">
    <div class="timeline-item-time">{{ shiftDate }}</div>
    <f7-link
      :popover-open="shift.type==='u' ? '.popover-unavailability': false"
      :href="shift.type==='s' ? `shift/${shift.id}/`: '#'"
      @click="onClick(shift.type, shift.id)"
    >
      <div
        :style="`background-color:${shift.eventBackgroundColor}; color: ${shift.eventTextColor}; border: 2px ${shift.eventBorderColor} solid;`"
        class="timeline-item-inner"
      >
        <div class="timeline-item-title">{{ shift.title }}</div>
      </div>
    </f7-link>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['shift'],
  computed: {
    shiftDate() {
      const start = moment(this.shift.start).isValid() ? moment(this.shift.start).format('LT') : '';
      const end = moment(this.shift.end).isValid() ? ` - ${moment(this.shift.end).format('LT')}` : '';
      return start + end;
    },
  },
  methods: {
    onClick(type, unavailabilityId) {
      if (type === 'u') {
        const userId = this.$store.state.account.user.id;
        this.$store.dispatch('unavailability/getUnavailability', { unavailabilityId, userId });
      }
    },
  },
};
</script>

<template>
  <f7-page :page-content="false">
    <f7-page-content class="display-flex flex-direction-column justify-content-space-between">
      <div class="sc-left-side-panel__top">
        <f7-block
          style="padding-top: calc(var(--f7-safe-area-top)) !important"
          class="no-margin no-padding sc-bg-color text-color-white"
        >
          <f7-block class="no-margin padding-vertical display-flex flex-direction-column">
            <img :src="userPic" width="50" style="border-radius: 50%" />
            <span style="font-size: 17px">{{ userName }}</span>
            <span class="text-color-gray">{{ userEmail }}</span>
          </f7-block>
        </f7-block>
        <f7-list no-hairlines no-hairlines-between accordion-list class="no-margin-top sc-no-margin-left sc-list">
          <f7-list-item v-if="isUserLvlOwnerOrAdmin" key="Users" title="Users" link="/users/" no-chevron panel-close>
            <template #media>
              <f7-icon color="gray" material="group" />
            </template>
          </f7-list-item>
          <f7-list-item
            v-if="isUserLvlStaff && isShiftEnable"
            title="Job Board"
            link="/job_board/"
            no-chevron
            panel-close
          >
            <template #media>
              <f7-icon color="gray" material="view_list" />
            </template>
          </f7-list-item>
          <f7-list-item v-if="isShiftEnable" title="Calendar" link="/calendar/" no-chevron panel-close>
            <template #media>
              <f7-icon color="gray" material="event" />
            </template>
          </f7-list-item>
          <f7-list-item v-if="isChatEnable" title="Chats" link="/chats/" no-chevron panel-close>
            <template #media>
              <f7-icon color="gray" material="forum">
                <f7-badge v-show="chatThreadsUnreadMessagesNum > 0" color="red">
                  {{ chatThreadsUnreadMessagesNum }}
                </f7-badge>
              </f7-icon>
            </template>
          </f7-list-item>
          <f7-list-item
            v-if="isUserLvlStaff || isUserLvlOwnerOrAdmin"
            :title="isUserLvlOwnerOrAdmin ? 'Payroll' : 'Pay'"
            link="/payrolls/"
            no-chevron
            panel-close
          >
            <template #media>
              <f7-icon color="gray" material="attach_money" />
            </template>
          </f7-list-item>
          <f7-list-item key="SharedFiles" title="Shared Files" link="/shared_files/" no-chevron panel-close>
            <template #media>
              <f7-icon color="gray" material="folder_shared" />
            </template>
          </f7-list-item>
          <f7-list-item
            v-if="isUserLvlStaff"
            key="Unavailability"
            title="Unavailability"
            link="/unavailability/"
            no-chevron
            panel-close
          >
            <template #media>
              <f7-icon color="gray" material="event_busy" />
            </template>
          </f7-list-item>
          <f7-list-item accordion-item title="Profile">
            <template #media>
              <f7-icon color="gray" material="person" />
            </template>
            <f7-accordion-content>
              <f7-list>
                <f7-list-item title="Info" link="/profile/info/" no-chevron panel-close>
                  <template #media>
                    <f7-icon color="gray" material="info" />
                  </template>
                </f7-list-item>
                <f7-list-item title="Media" link="/profile/media/" no-chevron panel-close>
                  <template #media>
                    <f7-icon color="gray" material="perm_media" />
                  </template>
                </f7-list-item>
                <template v-if="!isUserLvlClient">
                  <f7-list-item title="Skills & Qualifications" link="/profile/skills/" no-chevron panel-close>
                    <template #media>
                      <f7-icon color="gray" material="school" />
                    </template>
                  </f7-list-item>
                  <f7-list-item title="Experience" link="/profile/experience/" no-chevron panel-close>
                    <template #media>
                      <f7-icon color="gray" material="work" />
                    </template>
                  </f7-list-item>
                  <f7-list-item
                    v-if="isWorkAreasEnable"
                    title="Work Areas"
                    link="/profile/work_areas/"
                    no-chevron
                    panel-close
                  >
                    <template #media>
                      <f7-icon color="gray" material="language" />
                    </template>
                  </f7-list-item>
                </template>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
          <f7-list-item accordion-item title="Settings">
            <template #media>
              <f7-icon color="gray" material="settings" />
            </template>
            <f7-accordion-content>
              <f7-list>
                <f7-list-item v-if="isXtrmEnable" title="Payment" link="/profile/payment/" no-chevron panel-close>
                  <template #media>
                    <f7-icon color="gray" material="payment" />
                  </template>
                </f7-list-item>
                <f7-list-item title="Options" link="/profile/options/" no-chevron panel-close>
                  <template #media>
                    <f7-icon color="gray" material="list" />
                  </template>
                </f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
          <f7-list-item accordion-item title="Help">
            <template #media>
              <f7-icon color="gray" material="help" />
            </template>
            <f7-accordion-content>
              <f7-list>
                <f7-list-item
                  v-if="isUserLvlOwnerOrAdmin"
                  title="Admin Guide"
                  link
                  no-chevron
                  panel-close
                  @click="onExternalLinkClick('https://staffconnect.helpwise.help/')"
                >
                  <template #media>
                    <f7-icon color="gray" material="" />
                  </template>
                </f7-list-item>
              </f7-list>
              <f7-list>
                <f7-list-item
                  title="User Guide"
                  link
                  no-chevron
                  panel-close
                  @click="onExternalLinkClick('https://staffsupport.helpwise.help/')"
                >
                  <template #media>
                    <f7-icon color="gray" material="" />
                  </template>
                </f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
          <f7-list-item title="Log Out" link="#" no-chevron panel-close @click="onLogoutClick">
            <template #media>
              <f7-icon color="gray" material="exit_to_app" />
            </template>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="sc-left-side-bottom sc-version">
        <div>App Version: {{ config.appVersion }}</div>
        <div>Code Version: {{ config.codeVersion }}</div>
      </div>
    </f7-page-content>
  </f7-page>
</template>

<script>
import config from '../config';
import { f7 } from 'framework7-vue';

export default {
  computed: {
    isUserLvlStaff() {
      return this.$store.getters['account/isUserLvlStaff'];
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    isUserLvlClient() {
      return this.$store.getters['account/isUserLvlClient'];
    },
    isShiftEnable() {
      return Number(this.$store.state.account.settings.shift_enable) === 1;
    },
    isWorkAreasEnable() {
      return Number(this.$store.state.account.settings.work_areas_enable) === 1;
    },
    isXtrmEnable() {
      return Number(this.$store.state.account.settings.xtrm_enable) === 1;
    },
    isChatEnable() {
      return Number(this.$store.state.account.settings.chat_enable) === 1;
    },
    chatThreadsUnreadMessagesNum() {
      return this.$store.getters['chats/getChatThreadsUnreadMessagesNum'];
    },
    userName() {
      return `${this.$store.state.account.user.fname} ${this.$store.state.account.user.lname}`;
    },
    userEmail() {
      return this.$store.state.account.user.email;
    },
    userPic() {
      return this.$store.state.account.user.ppic_a;
    },
  },
  beforeMount() {
    this.config = config.version;
  },
  methods: {
    onLogoutClick() {
      this.$store.dispatch('account/logout').then(() => {
        f7.views.main.router.navigate('/', {
          reloadAll: true,
          reloadCurrent: true,
          ignoreCache: true,
        });
      });
    },
    onExternalLinkClick(link) {
      window.open(link, '_system');
    },
  },
};
</script>
<style scoped>
.sc-version {
  text-align: center;
  color: gray;
  width: 100%;
  font-size: 10px;
  margin-bottom: 10px;
}
::v-deep(.sc-list .item-inner) {
  margin-left: 0;
}
</style>

<template>
  <f7-popup
    tablet-fullscreen
    :opened="isPopupOpened"
    @popup:open="onPopupOpen"
    @popup:closed="
      $store.commit('calendar/setCalendarShiftRoleApplyPopup', {
        isPopupOpened: false,
        roleName: 'Role Name',
      })
    "
  >
    <f7-page name="shift-detail-role-apply-popup">
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close />
        </f7-nav-left>
        <f7-nav-title :title="`Apply for ${roleName}`" />
        <template #after-inner>
          <f7-progressbar v-show="isLoadingData" infinite />
        </template>
      </f7-navbar>

      <template v-if="linked_roles">
        <f7-block>
          <p v-if="!linked_roles.apply_all_or_nothing">
            This is a multi-day role, please choose the dates you're applying for:
          </p>
          <p v-if="linked_roles.apply_all_or_nothing">This is a multi-day role, you will be applying for all dates:</p>
        </f7-block>
        <f7-list>
          <template v-if="!linked_roles.apply_all_or_nothing">
            <f7-list-item
              v-for="linkedRole in linked_roles.roles"
              :key="linkedRole.id"
              checkbox
              checked
              media-item
              :disabled="linkedRole.id === roleId"
              :text="`${linkedRole.location}, ${linkedRole.address}`"
              :value="linkedRole.id"
              :title="`${linkedRole.date} ${linkedRole.start} - ${linkedRole.end}`"
              @change="onlinkedRoleChange"
            />
          </template>
          <template v-else>
            <f7-list-item
              v-for="linkedRole in linked_roles.roles"
              :key="linkedRole.id"
              :title="`${linkedRole.date} ${linkedRole.start} - ${linkedRole.end}`"
            />
          </template>
        </f7-list>
      </template>
      <f7-list no-hairlines>
        <f7-list-input
          :value="reason"
          outline
          label="Message"
          floating-label
          type="textarea"
          placeholder="Tell us why you're applying to this role ..."
          @input="reason = $event.target.value"
        />
      </f7-list>
      <f7-block v-show="!isLoadingData">
        <f7-button raised fill color="green" @click="onApplyClick">Apply</f7-button>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';

export default {
  data() {
    return {
      reason: '',
      linkedRoleIds: [],
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isPopupOpened() {
      return this.$store.state.calendar.shiftRoleApplyPopup.isPopupOpened;
    },
    roleName() {
      return this.$store.state.calendar.shiftRoleApplyPopup.roleName;
    },
    roleId() {
      return this.$store.state.calendar.shiftRoleApplyPopup.roleId;
    },
    linked_roles() {
      return this.roleId
        ? _.find(this.$store.state.calendar.shift.shift_roles, {
            id: this.roleId,
          }).linked_roles
        : undefined;
    },
  },
  watch: {
    linked_roles(newValue) {
      this.linkedRoleIds = [];
      if (newValue && newValue.roles instanceof Array) {
        const ids = [];
        newValue.roles.forEach((element) => ids.push(element.id));
        this.linkedRoleIds = ids;
      }
    },
  },
  methods: {
    onPopupOpen() {},
    onPopupClosed() {},
    onApplyClick() {
      this.$store
        .dispatch('calendar/applyCalendarShiftRole', {
          roleId: this.roleId,
          reason: this.reason,
          shiftRoleIds: this.linkedRoleIds.length > 0 ? this.linkedRoleIds : undefined,
        })
        .then((result) => {
          if (result !== false) {
            f7.popup.close();
            f7.toast
              .create({
                text: result.data.message,
                closeTimeout: 3000,
                closeButton: true,
                closeButtonText: 'Close',
                closeButtonColor: 'blue',
              })
              .open();
          }
        });
    },
    onlinkedRoleChange(e) {
      const val = e.target.value;
      if (e.target.checked) {
        this.linkedRoleIds.push(Number(val));
      } else {
        this.linkedRoleIds.splice(this.linkedRoleIds.indexOf(Number(val)), 1);
      }
    },
  },
};
</script>
<style scoped></style>

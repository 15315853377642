<template>
  <f7-popup tablet-fullscreen class="sc-payrolls-generate" @popup:open="onPopupOpen">
    <f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link icon-material="close" icon-only popup-close />
          </f7-nav-left>
          <f7-nav-title>
            {{ isUserLvlOwnerOrAdmin ? 'Generate Payroll' : 'New Invoice' }}
          </f7-nav-title>
          <template #after-inner>
            <f7-progressbar v-show="isLoadingData" infinite />
          </template>
        </f7-navbar>
        <f7-toolbar v-show="payrolls.length > 0" bottom no-shadow>
          <f7-link />
          <f7-link icon-material="save" @click="onPayrollSaveClick(-1)">{{
            payrolls.length > 1 ? 'SAVE ALL' : 'SAVE'
          }}</f7-link>
          <f7-link />
        </f7-toolbar>

        <f7-card v-show="payrolls.length === 0" outline>
          <f7-list no-hairlines no-hairlines-between accordion-list>
            <f7-list-input
              v-if="isUserLvlOwnerOrAdmin"
              label="Payroll Type"
              floating-label
              outline
              type="select"
              :value="payrollType"
              @change="onPayrollTypeChange"
            >
              <option value="invoice">Invoices</option>
              <option value="timesheet">Timesheets</option>
              <option value="wm_assignment">WorkMarket</option>
              <option value="xero_payslip">Xero Payroll</option>
            </f7-list-input>
            <f7-list-input
              v-show="payrollType !== 'xero_payslip'"
              outline
              floating-label
              readonly
              label="Date From"
              type="datepicker"
              placeholder="Date From"
              :calendar-params="{
                closeOnSelect: true,
                dateFormat: 'M dd yyyy',
                locale: 'en',
                routableModals: false,
              }"
              :value="dateFrom"
              @change="dateFrom = [$event.target.value]"
            />
            <f7-list-input
              v-show="payrollType !== 'xero_payslip'"
              outline
              floating-label
              readonly
              label="Date To"
              type="datepicker"
              placeholder="Date To"
              :calendar-params="{
                closeOnSelect: true,
                dateFormat: 'M dd yyyy',
                locale: 'en',
                routableModals: false,
              }"
              :value="dateTo"
              @change="dateTo = [$event.target.value]"
            />
            <template v-if="isUserLvlOwnerOrAdmin">
              <f7-list-input
                v-if="payrollType === 'xero_payslip' && xeroDates !== undefined"
                :wrap="false"
                class="item-input-with-value"
                outline
                floating-label
                :label="xeroDates.calendar"
                type="select"
                @change="onXeroDatesChange"
              >
                <option v-for="(period, indx) in xeroDates.periods" :key="indx" :value="indx">
                  {{ period.label }}
                </option>
              </f7-list-input>
              <f7-list-item accordion-item title="Additional options">
                <f7-accordion-content>
                  <f7-list>
                    <f7-list-item>
                      <span>Completed Only</span>
                      <f7-toggle :checked="сompletedOnly" @toggle:change="сompletedOnly = !сompletedOnly" />
                    </f7-list-item>
                    <f7-list-item>
                      <span>Approved Pay Items Only</span>
                      <f7-toggle
                        :checked="approvedPayItemsOnly"
                        @toggle:change="approvedPayItemsOnly = !approvedPayItemsOnly"
                      />
                    </f7-list-item>
                    <li
                      class="item-content item-input item-input-outline"
                      :class="{
                        'item-input-with-value': selectedTrackingOptions.length > 0,
                      }"
                      @click="onSelectTrackingOptionClick"
                    >
                      <div class="item-inner">
                        <div class="item-title item-floating-label">Tracking Options</div>
                        <div class="item-input-wrap">
                          <div style="margin: 10px 5px">
                            <f7-chip
                              v-for="opt in selectedTrackingOptions"
                              :key="opt.id"
                              style="margin-right: 5px"
                              :text="opt.oname"
                              deleteable
                              @delete.stop="onSelectedTrackingOptionDeleteClick(opt.id)"
                            />
                          </div>
                          <input
                            id="sc-tracking-option-select-input"
                            type="text"
                            style="width: 0px; height: 0px"
                            @focus="onSelectTrackingOptionFocus"
                          />
                        </div>
                      </div>
                    </li>
                  </f7-list>
                </f7-accordion-content>
              </f7-list-item>
            </template>
          </f7-list>

          <f7-block class="margin-vertical">
            <f7-row class="justify-content-center">
              <f7-col width="35">
                <f7-button
                  :class="{
                    disabled: isLoadingData || (payrollType === 'xero_payslip' && xeroDates === undefined),
                  }"
                  outline
                  round
                  @click="onGenerateClick"
                  >GENERATE</f7-button
                >
              </f7-col>
            </f7-row>
          </f7-block>
        </f7-card>

        <f7-list v-show="payrolls.length > 0 && staffInvoiceCombine !== 0" simple-list no-hairlines>
          <f7-list-item>
            <span>Combine with other pending invoices</span>
            <f7-toggle
              :checked="invoiceCombine > 0"
              :disabled="staffInvoiceCombine === 2"
              @toggle:change="onInvoiceCombineChange"
            ></f7-toggle>
          </f7-list-item>
        </f7-list>

        <f7-list no-hairlines accordion-list>
          <f7-list-item
            v-for="(payroll, payrollIndx) in payrolls"
            :key="payrollIndx"
            :title="isUserLvlOwnerOrAdmin ? payroll.name : `${payroll.ptype} ${payrollIndx + 1}`"
            :after="`${payroll.currency}${payroll.total}`"
            accordion-item
          >
            <f7-accordion-content>
              <f7-card outline>
                <f7-list media-list>
                  <template v-for="type in payrollItemTypes">
                    <template v-if="payroll[type].length > 0">
                      <f7-list-item :key="type" divider :title="type.charAt(0).toUpperCase() + type.slice(1)" />
                      <f7-list-item
                        v-for="(item, indx) in payroll[type]"
                        :key="`${type}${indx}`"
                        :title="item.title"
                        :text="`${item.qty} x ${item.u_amt}`"
                        :after="item.l_amt"
                        swipeout
                      >
                        <f7-swipeout-actions right>
                          <f7-swipeout-button
                            color="red"
                            close
                            @click="onPayrollItemRemoveClick(payrollIndx, type, indx)"
                          >
                            <f7-icon material="delete" color="white" />
                          </f7-swipeout-button>
                        </f7-swipeout-actions>
                      </f7-list-item>
                    </template>
                  </template>
                </f7-list>
                <f7-card-footer>
                  <f7-button color="red" @click="onPayrollRemoveClick(payrollIndx)">REMOVE</f7-button>
                  <f7-button @click="onPayrollSaveClick(payrollIndx)">SAVE</f7-button>
                </f7-card-footer>
              </f7-card>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>

        <sc-payrolls-generate-tracking-options-select-popup
          :selected-tracking-options="selectedTrackingOptions"
          @sc-payrolls-generate-tracking-options-select:selected="onTrackingOptionsSelected($event)"
        />
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import { f7 } from 'framework7-vue';
import $ from 'dom7';

import moment from 'moment';
import _ from 'lodash';
import scPayrollsGenerateTrackingOptionsSelectPopup from './Generate/PayrollsGenerateTrackingOptionsSelectPopup.vue';

export default {
  components: {
    scPayrollsGenerateTrackingOptionsSelectPopup,
  },
  data() {
    return {
      payrollItemTypes: ['shift', 'bonus', 'deduction', 'expense', 'travel', 'time', 'mileage', 'other'],
      payrollType: 'invoice',
      dateFrom: [moment().subtract(14, 'days').format('MMM DD YYYY')],
      dateTo: [moment().format('MMM DD YYYY')],
      xeroDates: undefined,
      сompletedOnly: true,
      approvedPayItemsOnly: false,
      selectedTrackingOptions: [],
      payrolls: [],
      invoiceCombine:
        Number(this.$store.state.account.settings.staff_invoice_combine) === 2
          ? 1
          : Number(this.$store.state.account.settings.staff_invoice_combine),
    };
  },
  computed: {
    userId() {
      return this.$store.state.account.user.id;
    },
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    isUserLvlOwnerOrAdmin() {
      return this.$store.getters['account/isUserLvlOwnerOrAdmin'];
    },
    staffInvoiceCombine() {
      return Number(this.$store.state.account.settings.staff_invoice_combine);
    },
  },
  methods: {
    onPopupOpen() {
      this.payrollType = 'invoice';
      this.dateFrom = [moment().subtract(14, 'days').format('MMM DD YYYY')];
      this.dateTo = [moment().format('MMM DD YYYY')];
      this.xeroDates = undefined;
      this.сompletedOnly = true;
      this.approvedPayItemsOnly = false;
      this.selectedTrackingOptions = [];
      this.payrolls = [];
    },
    onPayrollTypeChange(event) {
      this.payrollType = event.target.value;
      if (this.payrollType === 'xero_payslip') {
        this.$store.dispatch('payrolls/fetchXeroDates').then((result) => {
          if (result !== false) {
            this.xeroDates = result;
            if (this.xeroDates.periods.length > 0) {
              this.dateFrom = [moment(this.xeroDates.periods[0].from, 'YYYY-MM-DD').format('MMM DD YYYY')];
              this.dateTo = [moment(this.xeroDates.periods[0].to, 'YYYY-MM-DD').format('MMM DD YYYY')];
            }
          }
        });
      }
    },
    onXeroDatesChange(event) {
      const indx = event.target.value;
      this.dateFrom = [moment(this.xeroDates.periods[indx].from, 'YYYY-MM-DD').format('MMM DD YYYY')];
      this.dateTo = [moment(this.xeroDates.periods[indx].to, 'YYYY-MM-DD').format('MMM DD YYYY')];
    },
    onSelectTrackingOptionFocus() {
      f7.popup.open('.sc-payrolls-generate-tracking-options-select');
    },
    onSelectTrackingOptionClick() {
      $('#sc-tracking-option-select-input').focus();
    },
    onTrackingOptionsSelected(selectedTrackingOptions) {
      this.selectedTrackingOptions = selectedTrackingOptions;
    },
    onSelectedTrackingOptionDeleteClick(optionId) {
      this.selectedTrackingOptions = _.filter(this.selectedTrackingOptions, (opt) => opt.id !== optionId);
    },
    onGenerateClick() {
      const action = this.isUserLvlOwnerOrAdmin ? 'payrolls/generatePayrollByType' : 'payrolls/generatePayrollForStaff';
      this.$store
        .dispatch(action, {
          payrollType: this.payrollType,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          сompletedOnly: this.сompletedOnly,
          approvedPayItemsOnly: this.approvedPayItemsOnly,
          selectedTrackingOptions: this.selectedTrackingOptions,
        })
        .then((result) => {
          if (result !== false && result.payrolls.length > 0) {
            this.payrolls = result.payrolls;
          } else if (result !== false) {
            f7.toast.show({
              text: 'Nothing outstanding was found between your selected dates.',
              closeTimeout: 2000,
              closeButton: true,
              closeButtonText: 'Close',
              closeButtonColor: 'blue',
              destroyOnClose: true,
            });
          }
        });
    },
    onPayrollRemoveClick(payrollIndx) {
      this.payrolls.splice(payrollIndx, 1);
    },
    onPayrollSaveClick(payrollIndx) {
      const payrollItems = {};
      const resultPayrolls = payrollIndx >= 0 ? [this.payrolls[payrollIndx]] : this.payrolls;
      resultPayrolls.forEach((payroll) => {
        const userId = this.isUserLvlOwnerOrAdmin ? payroll.user_id : this.userId;
        this.payrollItemTypes.forEach((type) => {
          payroll[type].forEach((item) => {
            if (payrollItems[userId] === undefined) {
              payrollItems[userId] = [];
            }
            payrollItems[userId].push(item.id);
          });
        });
      });

      const action = this.isUserLvlOwnerOrAdmin
        ? 'payrolls/generatePayrollSave'
        : 'payrolls/generatePayrollSaveForStaff';
      this.$store
        .dispatch(action, {
          payrollType: this.payrollType,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          сompletedOnly: this.сompletedOnly,
          approvedPayItemsOnly: this.approvedPayItemsOnly,
          payrollItems,
          invoiceCombine: this.invoiceCombine,
        })
        .then((result) => {
          if (result !== false) {
            f7.toast.show({
              text: result.message,
              closeTimeout: 2000,
              closeButton: true,
              closeButtonText: 'Close',
              closeButtonColor: 'blue',
              destroyOnClose: true,
            });

            if (payrollIndx >= 0) {
              this.onPayrollRemoveClick(payrollIndx);
            } else {
              this.payrolls = [];
              f7.popup.close('.sc-payrolls-generate');
            }
          }
        });
    },
    onPayrollItemRemoveClick(payrollIndx, itemType, itemIndx) {
      this.payrolls[payrollIndx][itemType].splice(payrollIndx, 1);
      let removePayroll = true;
      this.payrollItemTypes.forEach((type) => {
        if (this.payrolls[payrollIndx][type].length > 0) {
          removePayroll = false;
        }
      });
      if (removePayroll) {
        this.onPayrollRemoveClick(payrollIndx);
      }
    },
    onInvoiceCombineChange() {
      this.invoiceCombine = this.invoiceCombine === 1 ? 0 : 1;
    },
  },
};
</script>
<style scoped></style>

<template>
  <f7-popup tablet-fullscreen :opened="isPopupOpened" @popup:closed="onPopupClosed" @popup:opened="onPopupOpened">
    <f7-page name="sc-shift-role-form-quiz-connect">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close icon-material="close" icon-only />
        </f7-nav-left>
        <f7-nav-title :title="formName" />
      </f7-navbar>
      <div style="width: 100%; height: 100%; overflow: auto !important; -webkit-overflow-scrolling: touch !important">
        <iframe :src="iframeSRC" class="fc-iframe">
          <p>Your app does not support iframes.</p>
        </iframe>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from 'lodash';
import { f7 } from 'framework7-vue';

import config from '../../../../config';

export default {
  props: {
    formId: {
      type: Number,
      required: false,
      default: undefined,
    },
    isPopupOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
    userId: {
      type: Number,
      required: true,
      default: undefined,
    },
  },
  computed: {
    formName() {
      let formName = '';
      const form = _.find(this.$store.state.profile.forms, { id: this.formId });
      if (form !== undefined) {
        formName = form.fname;
      }
      return formName;
    },
    iframeSRC() {
      let iframeSRC;
      const form = _.find(this.$store.state.profile.forms, { id: this.formId });
      if (form !== undefined) {
        const otherFormId = form.other_id;
        const accessToken = this.$store.state.account.formconnectToken.access_token;
        iframeSRC = `${config.formConnect.baseURL}/${otherFormId}?access_token=${accessToken}`;
      }
      return iframeSRC;
    },
  },
  methods: {
    onPopupOpened() {
      if (window.addEventListener) {
        window.addEventListener('message', this.onMessage);
      }
    },
    onPopupClosed() {
      if (window.removeEventListener) {
        window.removeEventListener('message', this.onMessage);
      }
      this.$emit('closed');
    },
    onMessage(event) {
      if (event.data.message === 'success') {
        this.$store.commit('profile/deleteFromProfileFormsByID', this.formId);
        this.$store.dispatch('profile/getProfileDocuments', {
          userId: this.userId,
        });
        f7.popup.close();
      }
    },
  },
};
</script>
<style scoped>
.fc-iframe {
  width: 99%;
  height: 99%;
  border: 1px solid lightgray;
}
</style>

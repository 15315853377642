<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="close" icon-only @click="onCloseClick" />
      </f7-nav-left>
      <f7-nav-title title="Sign Up" :subtitle="subTitle" />
      <f7-nav-right>
        <f7-gauge
          class="display-flex align-items-center margin-horizontal"
          type="circle"
          :value="stepNum * 0.125"
          :size="40"
          border-color="#2196f3"
          :border-width="3"
          :value-text="stepNum"
          :value-font-size="18"
          value-text-color="#fff"
        />
      </f7-nav-right>
      <template #after-inner>
        <f7-progressbar v-show="isLoadingData" infinite />
      </template>
    </f7-navbar>
    <component :is="`sc-step${stepNum}`" :f7router="f7router" />
  </f7-page>
</template>

<script>
import { f7 } from 'framework7-vue';

import scStep1 from './SignUp/Step1.vue';
import scStep2 from './SignUp/Step2.vue';
import scStep3 from './SignUp/Step3.vue';
import scStep4 from './SignUp/Step4.vue';
import scStep5 from './SignUp/Step5.vue';
import scStep6 from './SignUp/Step6.vue';
import scStep7 from './SignUp/Step7.vue';
import scStep8 from './SignUp/Step8.vue';

export default {
  components: {
    scStep1,
    scStep2,
    scStep3,
    scStep4,
    scStep5,
    scStep6,
    scStep7,
    scStep8,
  },
  props: {
    f7router: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    stepNum() {
      return this.$store.state.account.regStepNum;
    },
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
    subTitle() {
      let subTitle = '';
      switch (this.stepNum) {
        case 1:
          subTitle = 'Register';
          break;
        case 2:
          subTitle = 'Profile';
          break;
        case 3:
          subTitle = 'Skills & Qualifications';
          break;
        case 4:
          subTitle = 'Experience';
          break;
        case 5:
          subTitle = 'Photos';
          break;
        case 6:
          subTitle = 'Documents';
          break;
        case 7:
          subTitle = 'Work Areas';
          break;
        case 8:
          subTitle = 'Complete';
          break;
        default:
          break;
      }
      return subTitle;
    },
  },
  beforeCreate() {},
  methods: {
    onCloseClick() {
      if (this.stepNum > 1) {
        this.$store.dispatch('account/logout').then(() => {
          f7.views.main.router.navigate('/', {
            reloadAll: true,
            reloadCurrent: true,
            ignoreCache: true,
          });
        });
      } else {
        f7.views.main.router.back();
      }
    },
  },
};
</script>
<style scoped></style>

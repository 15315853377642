import store from './store';

import LoginPage from './containers/LoginPage.vue';
import LeftSidePanel from './containers/LeftSidePanel.vue';
import HomePage from './containers/HomePage.vue';
import HomePostDetailPage from './containers/Home/HomePostDetailPage.vue';
import UnavailabilityPage from './containers/UnavailabilityPage.vue';

import ProfileInfoPage from './containers/Profile/ProfileInfoPage.vue';
import ProfileMediaPage from './containers/Profile/ProfileMediaPage.vue';
import ProfileSkillsQualificationsPage from './containers/Profile/ProfileSkillsQualificationsPage.vue';
import ProfileExperiencePage from './containers/Profile/ProfileExperiencePage.vue';
import ProfileWorkAreasPage from './containers/Profile/ProfileWorkAreasPage.vue';
import ProfilePaymentPage from './containers/Profile/ProfilePaymentPage.vue';
import ProfileOptionsPage from './containers/Profile/ProfileOptionsPage.vue';

import JobBoardPage from './containers/JobBoardPage.vue';

import CalendarPage from './containers/CalendarPage.vue';
import CalendarShiftDetailPage from './containers/Calendar/CalendarShiftDetailPage.vue';
import CalendarShiftDetailInfoTab from './containers/Calendar/Shift/Detail/CalendarShiftDetailInfoTab.vue';
import CalendarShiftDetailRoleTab from './containers/Calendar/Shift/Detail/CalendarShiftDetailRoleTab.vue';
import CalendarShiftDetailCheckInOutTab from './containers/Calendar/Shift/Detail/CalendarShiftDetailCheckInOutTab.vue';
import CalendarShiftDetailDocumentTab from './containers/Calendar/Shift/Detail/CalendarShiftDetailDocumentTab.vue';
import CalendarShiftDetailMapTab from './containers/Calendar/Shift/Detail/CalendarShiftDetailMapTab.vue';

import CalendarShiftDetailRoleStaffPage from './containers/Calendar/Shift/Detail/Role/CalendarShiftDetailRoleStaffPage.vue';
import ChatsPage from './containers/ChatsPage.vue';
import ChatsThreadPage from './containers/Chats/ChatsThreadPage.vue';

import RequiredFormConnect from './containers/RequiredFormConnect.vue';

import UsersPage from './containers/UsersPage.vue';
import UsersProfilePage from './containers/Users/UsersProfilePage.vue';
import UsersProfileInfoTab from './containers/Users/UsersProfile/UsersProfileInfoTab.vue';
import UsersProfileMediaTab from './containers/Users/UsersProfile/UsersProfileMediaTab.vue';
import UsersProfileSkillsQualificationsTab from './containers/Users/UsersProfile/UsersProfileSkillsQualificationsTab.vue';
import UsersProfileWorkAreasTab from './containers/Users/UsersProfile/UsersProfileWorkAreasTab.vue';
import UsersProfileUnavailabilityTab from './containers/Users/UsersProfile/UsersProfileUnavailabilityTab.vue';

import PayrollsPage from './containers/PayrollsPage.vue';
import PayrollsDetailPage from './containers/Payrolls/PayrollsDetailPage.vue';
import PayrollsWalletsPage from './containers/Payrolls/PayrollsWalletsPage.vue';

import SignUpPage from './containers/SignUpPage.vue';

import SharedFilesPage from './containers/SharedFilesPage.vue';

export default [
  {
    name: 'post',
    path: '/post/:post_id/',
    component: HomePostDetailPage,
  },
  {
    path: '/panel-left/',
    component: LeftSidePanel,
  },
  {
    path: '/shared_files/',
    name: 'sharedFiles',
    component: SharedFilesPage,
  },
  {
    path: '/unavailability/',
    name: 'unavailability',
    component: UnavailabilityPage,
  },
  {
    path: '/chats/',
    name: 'chats',
    component: ChatsPage,
  },
  {
    path: '/chats/:thread_id/',
    name: 'thread',
    component: ChatsThreadPage,
  },
  {
    path: '/payrolls/',
    name: 'payrolls',
    component: PayrollsPage,
    routes: [
      {
        name: 'payroll',
        path: 'payroll/:payroll_id/',
        component: PayrollsDetailPage,
      },
      {
        name: 'payrolls_wallets',
        path: 'wallets/',
        component: PayrollsWalletsPage,
      },
    ],
  },
  {
    name: 'job_board',
    path: '/job_board/',
    component: JobBoardPage,
  },
  {
    name: 'calendar',
    path: '/calendar/',
    component: CalendarPage,
    routes: [
      {
        name: 'shift',
        path: 'shift/:shift_id/',
        component: CalendarShiftDetailPage,
        routes: [
          {
            name: 'shiftRoleStaff',
            path: 'role/:role_id/staff/',
            component: CalendarShiftDetailRoleStaffPage,
          },
        ],
        tabs: [
          {
            path: '/',
            id: 'roles',
            component: CalendarShiftDetailRoleTab,
          },
          {
            path: '/detail/',
            id: 'detail',
            component: CalendarShiftDetailInfoTab,
          },
          {
            path: '/check-in-out/',
            id: 'check-in-out',
            component: CalendarShiftDetailCheckInOutTab,
          },
          {
            path: '/document/',
            id: 'document',
            component: CalendarShiftDetailDocumentTab,
          },
          {
            path: '/map/',
            id: 'map',
            component: CalendarShiftDetailMapTab,
          },
        ],
      },
    ],
  },
  {
    path: '/profile/info/',
    component: ProfileInfoPage,
  },
  {
    path: '/profile/media/',
    component: ProfileMediaPage,
  },
  {
    path: '/profile/skills/',
    component: ProfileSkillsQualificationsPage,
  },
  {
    path: '/profile/experience/',
    component: ProfileExperiencePage,
  },
  {
    path: '/profile/work_areas/',
    component: ProfileWorkAreasPage,
  },
  {
    path: '/profile/payment/',
    component: ProfilePaymentPage,
  },
  {
    path: '/profile/options/',
    component: ProfileOptionsPage,
  },
  {
    name: 'users',
    path: '/users/',
    component: UsersPage,
    routes: [
      {
        name: 'userProfile',
        path: 'profile/:user_id/',
        component: UsersProfilePage,
        tabs: [
          {
            path: '/',
            id: 'tab1',
            component: UsersProfileInfoTab,
          },
          {
            path: '/tab2/',
            id: 'tab2',
            component: UsersProfileMediaTab,
          },
          {
            path: '/tab3/',
            id: 'tab3',
            component: UsersProfileSkillsQualificationsTab,
          },
          {
            path: '/tab4/',
            id: 'tab4',
            component: UsersProfileWorkAreasTab,
          },
          {
            path: '/tab5/',
            id: 'tab5',
            component: UsersProfileUnavailabilityTab,
          },
        ],
      },
    ],
  },

  {
    path: '/',
    async: ({ resolve }) => {
      if (store.state.account.isLoggedIn) {
        if (store.state.account.requiredForms.length > 0) {
          resolve({ component: RequiredFormConnect });
        } else if (store.state.account.user.lvl.indexOf('registrant') !== -1) {
          resolve({ component: SignUpPage });
        } else {
          resolve({ component: HomePage });
        }
      } else {
        resolve({ component: LoginPage });
      }
    },
  },

  {
    name: 'signup',
    path: '/signup/',
    component: SignUpPage,
    // async: ({ resolve }) => {
    //   if (store.state.account.requiredForms.length > 0) {
    //     resolve({ component: RequiredFormConnect });
    //   } else {
    //     resolve({ component: SignUpPage });
    //   }
    // },
  },
];

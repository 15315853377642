<template>
  <f7-popup
    tablet-fullscreen
    :opened="isOpened"
    class="shift-role-staff-add-popup"
    @popup:open="onPopupOpen"
    @popup:closed="onPopupClosed"
  >
    <f7-page name="shift-role-staff-add-popup">
      <f7-navbar>
        <f7-nav-left>
          <f7-link icon-material="close" icon-only popup-close=".shift-role-staff-add-popup" />
        </f7-nav-left>
        <f7-nav-title :title="addStaffActionType === 'INVITE' ? 'Invite Staff' : 'Select Staff'" />
        <f7-nav-right>
          <f7-link popup-close=".shift-role-staff-add-popup" @click="onDoneClick">DONE</f7-link>
        </f7-nav-right>
        <template #after-inner>
          <f7-progressbar v-show="isLoadingData" infinite />
        </template>
      </f7-navbar>

      <f7-list media-list class="no-margin">
        <f7-list-item
          v-for="staff in staffList"
          :key="staff.id"
          :title="`${staff.fname} ${staff.lname}`"
          :subtitle="staff.email"
          :text="staff.mob"
          :after="staff.last_login"
          :value="staff.id"
          :checked="isSelected(staff.id)"
          checkbox
          @change="onChange($event)"
        >
          <template #media>
            <img :src="staff.ppic_a || 'assets/img/default-user-image.png'" />
            <div
              v-if="staff.user_status"
              :style="`background-color:${staff.user_status.color};`"
              class="user_status"
              @click.stop="onUserStatusClick(staff.user_status.sname)"
            />
          </template>
        </f7-list-item>
      </f7-list>

      <f7-block v-show="showLoadMore && !loading" class="text-align-center">
        <f7-link text="LOAD MORE" @click="onLoadMoreClick" />
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import { f7 } from 'framework7-vue';

import _ from 'lodash';

export default {
  props: {
    addStaffActionType: {
      // 'INVITE' or 'SELECT'
      type: String,
      required: true,
    },
    isOpened: {
      type: Boolean,
      required: true,
    },
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      staffList: [],
      pageId: 0,
      staffOnPage: 10,
      showLoadMore: false,
      loading: false,
      selectedStaffIds: [],
    };
  },
  computed: {
    isLoadingData() {
      return this.$store.state.isLoadingData;
    },
  },
  methods: {
    isSelected(value) {
      return _.indexOf(this.selectedStaffIds, value) !== -1;
    },
    onUserStatusClick(userStatus) {
      f7.toast
        .create({
          text: userStatus,
          closeTimeout: 1000,
          destroyOnClose: true,
          position: 'center',
        })
        .open();
    },
    onChange(event) {
      if (event.target.checked) {
        this.selectedStaffIds.push(Number(event.target.value));
      } else {
        this.selectedStaffIds = _.filter(this.selectedStaffIds, (r) => r !== Number(event.target.value));
      }
    },
    loadData() {
      this.loading = true;
      this.$store
        .dispatch('calendar/fetchCalendarShiftRoleStaffAdd', {
          pageId: this.pageId,
          staffOnPage: this.staffOnPage,
        })
        .then((result) => {
          if (result !== false) {
            const union = _.unionBy(this.staffList, result.data.data, 'id');
            this.staffList = union;
            this.loading = false;
            if (Math.ceil(Number(result.data.total_counts) / Number(result.data.page_size)) > this.pageId + 1) {
              this.showLoadMore = true;
            } else {
              this.showLoadMore = false;
            }
          }
        });
    },
    onPopupOpen() {
      this.loadData();
    },
    onPopupClosed() {
      this.staffList = [];
      this.pageId = 0;
      this.showLoadMore = false;
      this.selectedStaffIds = [];
      this.$emit('sc-calendar-shift-detail-role-staff-add-popup:closed');
    },
    onLoadMoreClick() {
      this.pageId += 1;
      this.loadData();
    },
    onDoneClick() {
      const actionName =
        this.addStaffActionType === 'INVITE' ? 'inviteCalendarShiftRoleStaff' : 'selectCalendarShiftRoleStaff';
      this.$store.dispatch(`calendar/${actionName}`, {
        shiftRoleId: this.f7route.params.role_id,
        selectedStaffIds: this.selectedStaffIds,
      });
    },
  },
};
</script>
<style scoped>
.item-media img {
  border-radius: 50%;
  width: 50px;
}

.user_status {
  bottom: 10px;
  left: 85px;
  min-width: 20px;
  position: absolute;
  height: 20px;
  border-radius: 50%;
}
</style>

<template>
  <f7-popup
    tablet-fullscreen
    :opened="isOpened"
    @popup:close="$emit('sc-calendar-shift-detail-role-staff-change-status-popup:closed')"
  >
    <f7-page class="bg-color-white">
      <f7-navbar title="Choose Status">
        <f7-nav-right>
          <f7-link icon-material="close" icon-only popup-close />
        </f7-nav-right>
      </f7-navbar>
      <f7-list inset no-hairlines>
        <f7-list-button popup-close title="Applied" @click="onChangeStatusClick('STAFF_STATUS_APPLIED')" />
        <f7-list-button
          popup-close
          v-show="tabType !== 'standby'"
          title="Standby"
          @click="onChangeStatusClick('STAFF_STATUS_STANDBY')"
        />
        <f7-list-button
          popup-close
          v-show="tabType !== 'selected'"
          title="Selected"
          @click="onChangeStatusClick('STAFF_STATUS_SELECTED')"
        />
        <f7-list-button popup-close title="Invited" @click="onChangeStatusClick('STAFF_STATUS_INVITED')" />
        <f7-list-button popup-close title="Confirmed" @click="onChangeStatusClick('STAFF_STATUS_CONFIRMED')" />
        <f7-list-button popup-close title="Checked In" @click="onChangeStatusClick('STAFF_STATUS_CHECKED_IN')" />
        <f7-list-button popup-close title="Checked Out" @click="onChangeStatusClick('STAFF_STATUS_CHECKED_OUT')" />
        <f7-list-button popup-close title="Completed" @click="onChangeStatusClick('STAFF_STATUS_COMPLETED')" />
        <f7-list-button popup-close title="Invoiced" @click="onChangeStatusClick('STAFF_STATUS_INVOICED')" />
        <f7-list-button popup-close title="Paid" @click="onChangeStatusClick('STAFF_STATUS_PAID')" />
        <f7-list-button popup-close title="No Show" @click="onChangeStatusClick('STAFF_STATUS_NO_SHOW')" />
        <f7-list-button popup-close title="No Pay" />
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
export default {
  props: {
    tabType: {
      type: String,
      required: true,
    },
    isOpened: {
      type: Boolean,
      required: true,
    },
    f7route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    actionMenuStaffId() {
      return this.$store.state.calendar.roleStaff.actionMenuStaffId;
    },
  },
  methods: {
    onChangeStatusClick(staffStatusId) {
      this.$store.dispatch('calendar/changeStatusCalendarShiftRoleStaff', {
        staffId: this.actionMenuStaffId,
        staffStatusId,
        shiftRoleId: this.f7route.params.role_id,
      });
    },
  },
};
</script>

<style scoped></style>

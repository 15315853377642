// Import Vue
import { createApp } from 'vue';
import store from './store';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from './containers/App.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App

store.dispatch('account/loadAccountFromStorage').then(() => {
  const app = createApp(App);
  app.use(store);

  // Register Framework7 Vue components
  registerComponents(app);

  app.config.errorHandler = (err, vm, info) => {
    store.commit('setError', {
      showError: true,
      errorMessage: err.message,
    });
    console.error(err);
  };
  // Mount the app
  app.mount('#app');
});

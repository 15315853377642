<template>
  <f7-sheet
    :close-by-outside-click="true"
    backdrop
    class="sc-calendar-new-booking-add-role-sheet"
    @sheet:open="onSheetOpen"
  >
    <f7-toolbar>
      <div class="left">
        <f7-link
          icon-material="close"
          icon-only
          sheet-close
        />
      </div>
      <div class="right">
        <f7-link
          sheet-close
          @click="onAddClick"
        >ADD</f7-link>
      </div>
    </f7-toolbar>
    <f7-page-content>
      <f7-list no-hairlines>
        <f7-list-input
          outline
          floating-label
          label="Name*"
          required
          validate
          clear-button
          type="text"
          :value="roleName"
          @input="roleName=$event.target.value"
        />
        <f7-list-input
          outline
          floating-label
          label="Number Required"
          type="number"
          min="1"
          :value="numberRequired"
          @input="numberRequired=$event.target.value"
        />
        <f7-list-input
          label="Sex"
          type="select"
          default-value="either"
          :value="sex"
          @change="sex = $event.target.value"
        >
          <option value="either">Either</option>
          <option value="female">Female</option>
          <option value="male">Male</option>
        </f7-list-input>
      </f7-list>
    </f7-page-content>
  </f7-sheet>
</template>
<script>
export default {
  data() {
    return {
      sex: 'either',
      roleName: '',
      numberRequired: 1,
    };
  },
  computed: {
    roleCurrency() { return this.$store.state.calendar.roleCurrency; },
  },
  methods: {
    onSheetOpen() {
      this.sex = 'either';
      this.roleName = '';
      this.numberRequired = 1;
    },
    onAddClick() {
      if (this.roleName !== '') {
        const role = {
          num_required: this.numberRequired,
          rname: this.roleName,
          sex: this.sex,
        };
        this.$emit('sc-calendar-new-booking-add-role-sheet:add-role', role);
      }
    },
  },
};
</script>

<style scoped>
</style>

import axios from '../../libs/axios';


export default {
  getUnavailabilities(payload) {
    return axios.get(`/user/${payload.userId}/unavailability`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  getUnavailability(payload) {
    return axios.get(`/user/${payload.userId}/unavailability/${payload.unavailabilityId}`)
      .then(response => response).catch(error => this.apiErrorHandler(error));
  },

  addUnavailability(payload) {
    return axios.post(
      '/user/unavailability',
      {
        title: payload.title,
        start: payload.start,
        end: payload.end,
        weekday: payload.weekday,
      },
    ).then(response => response).catch(error => this.apiErrorHandler(error));
  },
  deleteUnavailability(payload) {
    return axios.delete(`/user/unavailability/${payload.unavailabilityId}`).then(response => response).catch(error => this.apiErrorHandler(error));
  },

};

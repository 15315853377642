// import Vue from 'vue';
import _ from 'lodash';
import api from '../../api';

export default {
  namespaced: true,
  state: {
    isReadOnly: true,
    userId: undefined,
    active: '',
    fname: '',
    lname: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country_name: '',
    postcode: '',
    sex: '',
    dob: '',
    ppic_a: '',
    lvl: '',
    structure: [],
    for_update_elements: [],
    error_elements: [],
    images: [],
    videos: [],
    largeVideoFileUpload: {
      progress: 0,
      isLargeUpload: false,
      fileName: '',
      chunkSize: 2097152,
    },
    documents: [],
    forms: [],
    work_areas: [],
    skills: [],
    sisp: {},
    experience: [],
    completedShifts: [],
    completedShiftsPageId: 0,
    showLoadMoreCompletedShifts: false,
    adminNotes: [],
    settings: {
      payment: {
        wallets: [],
        banks: [],
        setUp: undefined,
        message: '',
      },
      options: {},
    },
    sharedFiles: [],
  },
  getters: {
    getPofileElementDataById: (state, getters) => (id) => {
      const element = _.find(state.for_update_elements, { id: id });
      return element !== undefined ? element.data : getters.findPofileElementById(id, state.structure)[0].data;
    },

    findPofileElementById: (state, getters) => (id, elements) => {
      let result = [];
      if (elements && elements.length > 0) {
        elements.forEach((el) => {
          if (el.cname && el.elements) {
            result = result.concat(getters.findPofileElementById(id, el.elements));
          } else {
            if (el.id === id) {
              result = result.concat([el]);
              return result;
            }
          }
        });
      }
      return result;
    },
    getErrorMessageOfProfileElement: (state) => (id) => {
      const element = _.find(state.error_elements, { id: id });
      return element != undefined ? element.message : '';
    },
    getAdminNoteById: (state) => (id) => _.find(state.adminNotes, { id }),
    getUserProfileTitle: (state) => `${state.fname} ${state.lname}`,
  },
  mutations: {
    setProfileData(state, payload) {
      state.userId = payload.userId;
      state.fname = payload.fname || '';
      state.lname = payload.lname || '';
      state.email = payload.email || '';
      state.phone = payload.phone || '';
      state.address = payload.address || '';
      state.city = payload.city || '';
      state.state = payload.state || '';
      state.country_name = payload.country_name || '';
      state.postcode = payload.postcode || '';
      state.sex = payload.sex || '';
      state.dob = payload.dob || '';
      state.lvl = payload.lvl || '';
      state.ppic_a = payload.ppic_a || '';
      state.structure = payload.structure || [];
      state.active = payload.active || '';
    },

    setIsProfileReadonly(state, payload) {
      if (payload !== undefined) {
        state.isReadOnly = payload;
      } else {
        state.isReadOnly = !state.isReadOnly;
      }
    },

    setElementForUpdate(state, payload) {
      const element = _.find(state.for_update_elements, { id: payload.id });
      if (element === undefined) {
        state.for_update_elements.push({ id: payload.id, data: payload.data });
      } else {
        element.data = payload.data;
      }
    },

    resetProfileElementsForUpdate(state) {
      state.for_update_elements = [];
    },

    setErrorProfileElements(state, payload) {
      const element = _.find(state.error_elements, { id: payload.id });
      if (element === undefined) {
        state.error_elements.push({ id: payload.id, message: payload.message });
      } else {
        element.message = payload.message;
      }
    },

    deleteErrorProfileElements(state, payload) {
      this.error_elements = _.filter(this.error_elements, (el) => el.id != payload.id);
    },

    resetErrorProfileElements(state) {
      state.error_elements = [];
    },

    setProfileImages(state, payload) {
      state.images = payload.images;
    },

    setProfileVideos(state, payload) {
      state.videos = payload.videos;
    },
    setLargeVideoFileUpload(state, payload) {
      state.largeVideoFileUpload.isLargeUpload = !_.isNil(payload.isLargeUpload)
        ? payload.isLargeUpload
        : state.largeVideoFileUpload.isLargeUpload;
      state.largeVideoFileUpload.progress = !_.isNil(payload.progress)
        ? payload.progress
        : state.largeVideoFileUpload.progress;
      state.largeVideoFileUpload.fileName = !_.isNil(payload.fileName)
        ? payload.fileName
        : state.largeVideoFileUpload.fileName;
    },
    setProfileDocuments(state, payload) {
      state.documents = payload.documents;
    },

    setProfileForms(state, payload) {
      state.forms = payload.forms;
    },
    deleteFromProfileFormsByID(state, payload) {
      const forms = [...state.forms];
      _.remove(forms, { id: payload });
      state.forms = forms;
    },
    setProfileWorkAreas(state, payload) {
      state.work_areas = payload.work_areas;
    },

    setProfileSkillsQualifications(state, payload) {
      state.skills = payload.skills;
    },

    setSafeInStorePass(state, payload) {
      state.sisp = payload.sisp;
    },

    setProfileExperience(state, payload) {
      state.experience = payload.experience;
    },
    setProfileCompletedShifts(state, payload) {
      const union = _.unionBy(state.completedShifts, payload.completedShifts, 'shift_id');
      state.completedShifts = union;
    },
    setCompletedShiftsPageId(state, payload) {
      state.completedShiftsPageId = payload;
    },
    setShowLoadMoreCompletedShifts(state, payload) {
      state.showLoadMoreCompletedShifts = payload;
    },

    setProfileAdminNotes(state, payload) {
      state.adminNotes = payload.adminNotes;
    },
    setSettingsPayment(state, payload) {
      state.settings.payment.setUp = payload.set_up;
      if (payload.set_up) {
        state.settings.payment.wallets = payload.wallets;
        state.settings.payment.banks = payload.banks;
      } else {
        state.settings.payment.message = payload.message;
      }
    },
    setWallets(state, payload) {
      state.settings.payment.wallets = payload;
    },
    setSharedFiles(state, payload) {
      state.sharedFiles = payload;
    },
  },
  actions: {
    getProfileStructure({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .getProfileStructure({ user_id: payload.userId })
        .then((result) => {
          commit('setProfileData', {
            userId: payload.userId,
            fname: result.data.fname,
            lname: result.data.lname,
            email: result.data.email,
            phone: result.data.mob,
            address: result.data.address,
            city: result.data.city,
            state: result.data.state,
            country_name: result.data.country_name,
            postcode: result.data.postcode,
            sex: result.data.sex,
            dob: result.data.dob,
            lvl: result.data.lvl,
            ppic_a: result.data.ppic_a,
            structure: result.data.data,
            active: result.data.active,
          });
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    updateProfileElements({ state, commit, dispatch }, payload) {
      let result = true;
      if (state.for_update_elements.length > 0) {
        commit('setIsLoadingData', true, { root: true });
        const reqs = state.for_update_elements.map((element) => {
          const payloadProfileElement = {
            user_id: payload.userId,
            element_id: element.id,
            data: element.data,
          };
          return api.profile.updateProfileElement(payloadProfileElement);
        });
        result = Promise.all(reqs)
          .then(() => {
            commit('setIsLoadingData', false, { root: true });
            commit('resetErrorProfileElements');
            return dispatch('getProfileStructure', { userId: payload.userId })
              .then(() => {
                commit('resetProfileElementsForUpdate');
              })
              .catch((error) => dispatch('errorHandler', error, { root: true }));
          })
          .catch((error) => {
            const url = error.response.config.url.split('/');
            const elementId = url[url.length - 1];
            commit('setErrorProfileElements', {
              id: Number(elementId),
              message: error.response.data.errors
                ? error.response.data.errors.data.join(' ')
                : error.response.data.message,
            });
            return dispatch('errorHandler', error, { root: true });
          });
      }
      return result;
    },

    getProfileImages({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .getProfileImages({
          user_id: payload.userId,
        })
        .then((result) => {
          commit('setProfileImages', {
            images: result.data,
          });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setProfileImage({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setProfileImage({
          user_id: payload.userId,
          image_blob: payload.image_blob,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('getProfileImages', { userId: payload.userId });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setAsMainProfileImage({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setAsMainProfileImage({
          userId: payload.userId,
          imageId: payload.imageId,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteProfileImage({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .deleteProfileImage({
          imageId: payload.imageId,
        })
        .then(() => {
          commit('setProfileImages', {
            images: _.filter(state.images, (img) => img.id !== payload.imageId),
          });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getProfileVideos({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .getProfileVideos({
          user_id: payload.userId,
        })
        .then((result) => {
          commit('setProfileVideos', {
            videos: result.data,
          });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setProfileVideo({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setProfileVideo({
          user_id: payload.userId,
          video_blob: payload.video_blob,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('getProfileVideos', { userId: payload.userId });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setLargeProfileVideoChunk({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setLargeProfileVideoChunk({
          user_id: payload.userId,
          video_blob: payload.video_blob,
          part: payload.part,
          isLastPart: payload.isLastPart,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return !payload.isLastPart ? result : dispatch('getProfileVideos', { userId: payload.userId });
        })
        .catch((error) => {
          dispatch('errorHandler', error, { root: true });
          throw error; // because it is the part of a chain
        });
    },

    deleteProfileVideo({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .deleteProfileVideo({
          videoId: payload.videoId,
        })
        .then(() => {
          commit('setProfileVideos', {
            videos: _.filter(state.videos, (video) => video.id !== payload.videoId),
          });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getProfileDocuments({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .getProfileDocuments({
          user_id: payload.userId,
        })
        .then((result) => {
          commit('setProfileDocuments', {
            documents: result.data,
          });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setProfileDocument({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setProfileDocument({
          user_id: payload.userId,
          document_blob: payload.document_blob,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('getProfileDocuments', { userId: payload.userId });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteProfileDocument({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .deleteProfileDocument({
          document_id: payload.document_id,
        })
        .then(() => {
          commit('setProfileDocuments', {
            documents: _.filter(state.documents, (document) => document.id !== payload.document_id),
          });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getProfileForms({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .getProfileForms({
          user_id: payload.userId,
        })
        .then((result) => {
          commit('setProfileForms', {
            forms: result.data,
          });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getProfileWorkAreas({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .getProfileWorkAreas({
          user_id: payload.userId,
        })
        .then((result) => {
          commit('setProfileWorkAreas', { work_areas: result.data });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setProfileWorkAreas({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setProfileWorkAreas({
          userId: payload.userId,
          workAreaId: payload.workAreaId,
          set: payload.set,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getProfileSkillsQualifications({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .getProfileSkillsQualifications({
          user_id: payload.userId,
        })
        .then((result) => {
          commit('setProfileSkillsQualifications', { skills: result.data });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setProfileSkillsQualifications({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setProfileSkillsQualifications({
          userId: payload.userId,
          attributeId: payload.attributeId,
          set: payload.set,
          expiryDate: payload.expiryDate,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getSafeInStorePass({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .getSafeInStorePass({
          userId: payload.userId,
        })
        .then((result) => {
          commit('setSafeInStorePass', { sisp: result.data });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setSafeInStorePass({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setSafeInStorePass({
          userId: payload.userId,
          number: payload.number,
          email: payload.email,
        })
        .then((result) => {
          commit('setSafeInStorePass', { sisp: result.data.data });
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchProfileCompletedShifts({ state, commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      if (_.get(payload, 'nextPage')) {
        commit('setCompletedShiftsPageId', state.completedShiftsPageId + 1);
      }
      return api.profile
        .fetchProfileCompletedShifts({
          userId: payload.userId,
          pageId: state.completedShiftsPageId,
        })
        .then((result) => {
          if (result.data.data.length > 0) {
            commit('setProfileCompletedShifts', { completedShifts: result.data.data });
          }
          commit('setIsLoadingData', false, { root: true });
          if (
            Math.ceil(Number(result.data.total_counts) / Number(result.data.page_size)) >
            state.completedShiftsPageId + 1
          ) {
            commit('setShowLoadMoreCompletedShifts', true);
          } else {
            commit('setShowLoadMoreCompletedShifts', false);
          }
          return result;
        })
        .catch((error) => {
          commit('setUsersLoading', false);
          dispatch('errorHandler', error, { root: true });
        });
    },

    fetchProfileExperience({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .fetchProfileExperience({
          user_id: payload.userId,
        })
        .then((result) => {
          commit('setProfileExperience', { experience: result.data });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    addProfileExperience({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .addProfileExperience({
          userId: payload.userId,
          experience: payload.experience,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('fetchProfileExperience', { userId: payload.userId });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    editProfileExperience({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .editProfileExperience({
          experience: payload.experience,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('fetchProfileExperience', { userId: payload.userId });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteProfileExperience({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .deleteProfileExperience({
          experienceId: payload.experienceId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('fetchProfileExperience', { userId: payload.userId });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    getProfileAdminNotes({ commit, dispatch }, payload) {
      if (!payload || !payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.profile
        .getProfileAdminNotes({
          user_id: payload.userId,
        })
        .then((result) => {
          commit('setProfileAdminNotes', { adminNotes: result.data });
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    addProfileAdminNote({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .addProfileAdminNote({
          user_id: payload.userId,
          note: payload.note,
          mentions: payload.mentions,
          type: payload.type,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('getProfileAdminNotes', {
            userId: payload.userId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    editProfileAdminNote({ dispatch, commit }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .editProfileAdminNote({
          adminNoteId: payload.adminNoteId,
          note: payload.note,
          mentions: payload.mentions,
          type: payload.type,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          return dispatch('getProfileAdminNotes', {
            userId: payload.userId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteProfileAdminNote({ dispatch }, payload) {
      return api.profile
        .deleteProfileAdminNote({
          adminNoteId: payload.adminNoteId,
        })
        .then(() => {
          return dispatch('getProfileAdminNotes', {
            silent: true,
            userId: payload.userId,
          });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchSettingsPaymentSetup({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .fetchSettingsPaymentSetup({
          userId: payload.userId,
        })
        .then((result) => {
          commit('setSettingsPayment', result.data);
          commit('setIsLoadingData', false, { root: true }); // close preloader
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    createLinkXTRMAccountSettingsPayment({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .createLinkXTRMAccountSettingsPayment({
          userId: payload.userId,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          dispatch('fetchSettingsPaymentSetup', { userId: payload.userId });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchSettingsPaymentWallets({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .fetchSettingsPaymentWallets({
          userId: payload.userId,
        })
        .then((result) => {
          commit('setWallets', result.data);
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchSettingsPaymentСountries({ dispatch }) {
      return api.profile
        .fetchSettingsPaymentСountries()
        .then((result) => {
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchSettingsPaymentСurrencies({ dispatch }) {
      return api.profile
        .fetchSettingsPaymentСurrencies()
        .then((result) => {
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    linkBankAccountSettingsPayment({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .linkBankAccountSettingsPayment({
          userId: payload.userId,
          accountNumber: payload.accountNumber,
          countryCode: payload.countryCode,
          bankName: payload.bankName,
          routingNumber: payload.routingNumber,
          SWIFTCode: payload.SWIFTCode,
          accountName: payload.accountName,
          country: payload.country,
          currencyCode: payload.currencyCode,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          dispatch('fetchSettingsPaymentSetup', { userId: payload.userId });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
    deleteBankAccountSettingsPayment({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .deleteBankAccountSettingsPayment({
          userId: payload.userId,
          bankId: payload.bankId,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true }); // close preloader
          dispatch('fetchSettingsPaymentSetup', { userId: payload.userId });
          return result;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchSettingsOptionsTimezones({ commit, dispatch }) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .fetchSettingsOptionsTimezones()
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchSettingsOptions({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .fetchSettingsOptions({
          userId: payload.userId,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setTimezoneSettingsOptions({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setTimezoneSettingsOptions({
          userId: payload.userId,
          timezoneCode: payload.timezoneCode,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    setOptionSettingsOptions({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .setOptionSettingsOptions({
          userId: payload.userId,
          optionCode: payload.optionCode,
          optionValue: payload.optionValue ? 1 : 0,
        })
        .then((result) => {
          commit('setIsLoadingData', false, { root: true });
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchProfileInfoСountries({ dispatch }) {
      return api.profile
        .fetchProfileInfoСountries()
        .then((result) => {
          return result.data;
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    fetchSharedFiles({ commit, dispatch }, payload) {
      if (!payload || !payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.profile
        .fetchSharedFiles()
        .then((result) => {
          commit('setSharedFiles', result.data);
          commit('setIsLoadingData', false, { root: true });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    uploadSharedFiles({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .uploadSharedFiles({
          files: payload.files,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('fetchSharedFiles');
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    deleteSharedFile({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .deleteSharedFile({
          documentId: payload.documentId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('fetchSharedFiles');
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    approveUser({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .approveUser({
          userId: payload.userId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getProfileStructure', { userId: payload.userId });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },

    rejectUser({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.profile
        .rejectUser({
          userId: payload.userId,
        })
        .then(() => {
          commit('setIsLoadingData', false, { root: true });
          return dispatch('getProfileStructure', { userId: payload.userId });
        })
        .catch((error) => dispatch('errorHandler', error, { root: true }));
    },
  },
};

import api from '../../api';

export default {
  namespaced: true,
  state: {
    unavailabilities: [],
    unavailability: {},

  },
  getters: {
  },
  mutations: {
    setUnavailabilities(state, payload) {
      state.unavailabilities = payload.unavailabilities || [];
    },
    setUnavailability(state, payload) {
      state.unavailability = payload.unavailability || {};
    },
  },
  actions: {
    getUnavailabilities({ commit, dispatch }, payload) {
      if (!payload || !payload.silent) {
        commit('setIsLoadingData', true, { root: true });
      }
      return api.unavailability.getUnavailabilities({
        userId: payload.userId,
      }).then((result) => {
        commit('setUnavailabilities', { unavailabilities: result.data });
        commit('setIsLoadingData', false, { root: true });
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },
    getUnavailability({ commit, dispatch }, payload) {
      return api.unavailability.getUnavailability({
        userId: payload.userId,
        unavailabilityId: payload.unavailabilityId,
      }).then((result) => {
        commit('setUnavailability', { unavailability: result.data });
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },
    addUnavailability({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.unavailability.addUnavailability({
        title: payload.title,
        start: payload.start,
        end: payload.end,
        weekday: payload.weekday || undefined,
      }).then((result) => {
        commit('setIsLoadingData', false, { root: true });
        dispatch('getUnavailabilities', { silent: true, userId: payload.userId });
        return result;
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },

    deleteUnavailability({ commit, dispatch }, payload) {
      commit('setIsLoadingData', true, { root: true });
      return api.unavailability.deleteUnavailability({
        unavailabilityId: payload.unavailabilityId,
      }).then((result) => {
        commit('setIsLoadingData', false, { root: true });
        dispatch('getUnavailabilities', { silent: true, userId: payload.userId });
        return result;
      }).catch(error => dispatch('errorHandler', error, { root: true }));
    },
  },
};
